import { useState } from 'react'

type Event = { target: { value: string } }

export type InputType = 'text' | 'password'

export type TextInputBinding = {
  onChange: (event: Event) => void
  type: InputType
  value: string
}

export const useWithTextInput = (
  type: InputType = 'text',
): TextInputBinding => {
  const [inputValue, setInputValue] = useState<string>('')
  const onChange = (e: Event) => {
    setInputValue(e.target.value)
  }

  return {
    onChange,
    type,
    value: inputValue,
  }
}
