import { CouponDTO } from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { TData, TRow } from '@campgladiator/cgui-core.organisms.table'
import { Dropdown } from 'components/form-ui/dropdown'
import { DropdownItem } from 'components/form-ui/dropdown/dropdown'
import { subscriptionStatusOptions } from 'services/data/subscription'
import { Subscription, SubscriptionStatus } from 'types/subscription.d'
import { displayPrices } from 'utils/currency'
import {
  currentDate,
  displayDate,
  displayDateTime,
  displayUTCDateTime,
  tomorrowDate,
} from 'utils/date'
import { ProductTypes } from '../../../../../types/product.d'
import { TrainerAutoComplete } from './components'
import styles from './membership-item.module.scss'
import useWithMembershipItem from './useWithMembershipItem'

export type MembershipItemProps = {
  data: Subscription
  otherActiveSubscriptions: Subscription[]
  onUpgradeSuccess: () => void
  onUpgradeModalOpen: () => void
  cancellationReasonOptions: DropdownItem[]
  coupons: CouponDTO[]
  onMembershipManagementClick?: (subscription: Subscription) => void
}

const MembershipItem = ({
  data,
  onUpgradeSuccess,
  cancellationReasonOptions,
  coupons,
  onMembershipManagementClick,
}: MembershipItemProps) => {
  const {
    errors,
    getCancellationReasonLabel,
    getCouponNameByCode,
    getFieldProps,
    handleSubmit,
    isCancellationReasonOptionsVisible,
    isSubmitting,
    scheduleOptions,
    setIsCancellationReasonOptionsVisible,
    isCancellationReasonFeatureVisible,
    setFieldValue,
    touched,
    values,
    couponCodeDropdownItems,
    handleDeletedTrainer,
  } = useWithMembershipItem({
    data,
    onUpgradeSuccess,
    cancellationReasonOptions,
    coupons,
  })

  return (
    <>
      <TRow>
        <TData>
          <Paragraph size="small" weight="book">
            {data.productName}
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            {data?.contractStatistics?.paymentsRemaining}
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            {displayDateTime(data.purchaseDate)}
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            {displayUTCDateTime(data.billingDate)}
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            {data.acceptedTermsAndConditions
              ? displayDateTime(data.acceptedTermsAndConditions)
              : '-'}
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            {displayPrices(data.purchasePrice)}
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            {getCouponNameByCode(data.couponCode)}
            {!data.couponCode && ['ACTIVE', 'TRIAL'].includes(data.status) && (
              <FormPart
                forId="couponCode"
                errorMsg={(touched.couponCode && errors.couponCode) || ''}
              >
                <Dropdown
                  id="type"
                  label="Choose Coupon Code"
                  options={couponCodeDropdownItems}
                  {...getFieldProps('couponCode')}
                />
              </FormPart>
            )}
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            <TrainerAutoComplete
              id="salesRep"
              onSelect={(record: any) =>
                setFieldValue('salesRep', record?.itemId || '')
              }
              initialSelectedItem={data.salesRepName}
              productType={data.productType}
              productSubType={data.productSubType}
              {...getFieldProps('salesRep')}
            />
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            <FormPart
              forId="servicingTrainer"
              errorMsg={
                (touched.servicingTrainer && errors.servicingTrainer) || ''
              }
            >
              <TrainerAutoComplete
                id="servicingTrainer"
                initialSelectedItem={data.servicingTrainerName}
                onSelect={(record: any) => {
                  handleDeletedTrainer(record?.itemId?.archived ?? null)
                  setFieldValue('servicingTrainer', record?.itemId || '')
                }}
                productType={data.productType}
                productSubType={data.productSubType}
                {...getFieldProps('servicingTrainer')}
              />
            </FormPart>
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            <Dropdown
              id="programScheduleId"
              className={styles.filterDropdown}
              label="Select a schedule"
              options={scheduleOptions || []}
              {...getFieldProps('programScheduleId')}
            />
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            {[
              SubscriptionStatus.ACTIVE,
              SubscriptionStatus.FROZEN,
              SubscriptionStatus.CANCELED,
              SubscriptionStatus.TRIAL,
            ].includes(data.status as SubscriptionStatus) ? (
              <Dropdown
                id="status"
                options={subscriptionStatusOptions}
                {...getFieldProps('status')}
                onChange={(option) => {
                  setFieldValue('status', option.target.value)
                  setIsCancellationReasonOptionsVisible(
                    option.target.value === SubscriptionStatus.CANCELED &&
                      isCancellationReasonFeatureVisible,
                  )
                }}
              />
            ) : (
              data.status
            )}
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            {values.status === SubscriptionStatus.FROZEN ? (
              <FormPart
                label=""
                forId="resumesAt"
                errorMsg={(touched.resumesAt && errors.resumesAt) || ''}
              >
                <Input
                  id="resumesAt"
                  placeholder="Resumes At"
                  type="date"
                  min={tomorrowDate}
                  {...getFieldProps('resumesAt')}
                />
              </FormPart>
            ) : (
              displayDate(data.resumesAt)
            )}
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            {values.status === SubscriptionStatus.CANCELED ? (
              <FormPart
                label=""
                forId="archivedAt"
                errorMsg={(touched.archivedAt && errors.archivedAt) || ''}
              >
                <Input
                  id="archivedAt"
                  placeholder="Canceled At"
                  type="date"
                  min={currentDate}
                  {...getFieldProps('archivedAt')}
                />
              </FormPart>
            ) : (
              displayDate(data.archivedAt)
            )}
          </Paragraph>
        </TData>
        <TData>
          <Paragraph size="small" weight="book">
            <FormPart
              label=""
              forId="cancellationReason"
              errorMsg={
                (touched.cancellationReason && errors.cancellationReason) || ''
              }
            >
              {isCancellationReasonOptionsVisible ? (
                <Dropdown
                  id="cancellationReason"
                  options={cancellationReasonOptions}
                  {...getFieldProps('cancellationReason')}
                />
              ) : (
                getCancellationReasonLabel(data.cancellationReason)
              )}
            </FormPart>
          </Paragraph>
        </TData>
        <TData className={styles.actionButtons}>
          <Button
            type="button"
            emphasis="secondary"
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
            className={styles.actionButton}
            theme="trainer"
          >
            {isSubmitting ? <Loader size="xsmall" /> : 'Update'}
          </Button>
          {[SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIAL].includes(
            data.status,
          ) &&
            data.productType !== ProductTypes.STRENGTH && (
              <Button
                type="button"
                emphasis="secondary"
                onClick={() =>
                  onMembershipManagementClick &&
                  onMembershipManagementClick(data)
                }
                className={styles.actionButton}
                theme="trainer"
              >
                Change Membership Type
              </Button>
            )}
        </TData>
      </TRow>
    </>
  )
}

export default MembershipItem
