import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  TData,
  THead,
  TRow,
  Table,
} from '@campgladiator/cgui-core.organisms.table'
import {
  getPaymentMethodBySubscriptionId,
  putPaymentMethod,
} from 'services/api/payment'
import { getUserSubscriptions } from 'services/api/subscriptions'
import { Payment } from 'types/payment'
import { PaymentCardItem } from '../payment-card/payment-card-item'
import styles from './membership-payment.module.scss'
import { PaymentMethod } from './payment-method'

const tableColumns = ['Product', 'Payment Method']

const MembershipPayment = () => {
  const { id } = useParams()
  const [subscriptionData, setSubscriptionData] = useState<Payment[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [toggleModal, setToggleModal] = useState<boolean>(false)
  const [selectedSubscription, setSelectedSubscription] = useState<Payment>()

  const fetchSubscriptionData = async (id: string) => {
    setLoading(true)
    try {
      const response = await getUserSubscriptions(id)
      const filteredData = response.filter((data) => data.status === 'ACTIVE')
      setSubscriptionData(
        await Promise.all(
          filteredData.map(async (it) => ({
            ...it,
            cards: await getPaymentMethodBySubscriptionId(it.id),
          })),
        ),
      )
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      fetchSubscriptionData(id)
    }
  }, [id])

  const handleChangeClick = (data: Payment) => {
    setToggleModal(true)
    setSelectedSubscription(data)
  }

  const handleSubmit = async (stripePaymentId: string) => {
    const data = {
      stripeSubscriptionId: selectedSubscription.stripeSubscriptionId,
      stripePaymentId: stripePaymentId
        ? stripePaymentId
        : selectedSubscription.cards.id,
    }
    setSubmitting(true)
    try {
      await putPaymentMethod(data, selectedSubscription.id)
      closeModal()
      if (id) fetchSubscriptionData(id)
    } catch (error) {
      closeModal()
    }
  }

  const closeModal = () => {
    setSubmitting(false)
    setToggleModal(false)
  }

  return (
    <div className={styles.formContainer}>
      <Heading className={styles.title} type="h4">
        Membership Payment
      </Heading>
      <div className={styles.tableContainer}>
        <Table.Manual>
          <thead>
            <TRow>
              {tableColumns.map((column, index) => (
                <THead key={index}>
                  <Heading type="h6">{column}</Heading>
                </THead>
              ))}
            </TRow>
          </thead>
          <tbody>
            {subscriptionData.length === 0 || loading ? (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    {loading ? <Loader /> : 'No records found'}
                  </Paragraph>
                </TData>
              </TRow>
            ) : (
              subscriptionData.map((data, index) => (
                <TRow key={index}>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {data.productName}
                    </Paragraph>
                  </TData>
                  {data.cards && Object.keys(data.cards).length > 0 ? (
                    <TData>
                      <PaymentCardItem
                        card={data.cards}
                        buttonTitle="Change"
                        onEditButtonClick={() => handleChangeClick(data)}
                      />
                    </TData>
                  ) : (
                    <TData>
                      <Paragraph size="small" weight="book">
                        Card details not available
                      </Paragraph>
                    </TData>
                  )}
                </TRow>
              ))
            )}
          </tbody>
        </Table.Manual>
      </div>

      <PaymentMethod
        productName={selectedSubscription?.productName}
        defaultSelectedId={selectedSubscription?.cards?.id}
        submitting={submitting}
        isVisible={toggleModal}
        setToggleModal={(isVisible) => setToggleModal(isVisible)}
        onConfirm={(stripePaymentId) => handleSubmit(stripePaymentId)}
      />
    </div>
  )
}

export default MembershipPayment
