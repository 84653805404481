import { MembershipManagement } from '@campgladiator/cg-common.ui.membership-management'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  TData,
  THead,
  TRow,
  Table,
} from '@campgladiator/cgui-core.organisms.table'
import { PageTitleWithSearchInputAndNewButton } from 'components/module/shared/page-title-search-input-new-button'
import { SubscriptionStatus } from 'types/subscription.d'
import {
  ChangePageFunction,
  MembershipPages,
} from './membership-container/membership-container'
import { MembershipItem } from './membership-item'
import styles from './memberships.module.scss'
import useWithMemberships from './useWithMemberships'

export type MembershipProps = {
  changePage: ChangePageFunction
}

const Memberships = ({ changePage }: MembershipProps) => {
  const {
    cancellationReasonOptions,
    coupons,
    loading,
    subscriptionData,
    tableColumns,
    closeGrowl,
    handleUpgradeSuccess,
    membershipManagementUpgradeProps,
    handleMembershipManagementClick,
  } = useWithMemberships()

  return (
    <>
      <PageTitleWithSearchInputAndNewButton
        title="Memberships"
        placeholder=""
        newButtonText="Membership"
        showSearchInput={false}
        onSearch={() => {}}
        onNewButtonClick={() => {
          changePage(MembershipPages.Add)
        }}
        className={styles.headerView}
      />
      {membershipManagementUpgradeProps && (
        <MembershipManagement.Upgrade {...membershipManagementUpgradeProps} />
      )}
      <div className={styles.tableContainer}>
        <Table.Manual>
          <thead>
            <TRow>
              {tableColumns.map((column) => (
                <THead>
                  <Heading type="h6">{column}</Heading>
                </THead>
              ))}
            </TRow>
          </thead>
          <tbody>
            {!subscriptionData.length || loading ? (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    {loading ? <Loader /> : 'No records found'}
                  </Paragraph>
                </TData>
              </TRow>
            ) : (
              subscriptionData.map((data, index) => (
                <MembershipItem
                  key={`membership_${index}`}
                  data={data}
                  otherActiveSubscriptions={subscriptionData.filter(
                    (it) =>
                      it.productType === data.productType &&
                      it.status !== SubscriptionStatus.CANCELED,
                  )}
                  onUpgradeSuccess={handleUpgradeSuccess}
                  onUpgradeModalOpen={closeGrowl}
                  cancellationReasonOptions={cancellationReasonOptions}
                  coupons={coupons}
                  onMembershipManagementClick={handleMembershipManagementClick}
                />
              ))
            )}
          </tbody>
        </Table.Manual>
      </div>
    </>
  )
}

export default Memberships
