import { UserDTO } from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import { configuration } from 'config'
import { objectToQueryString } from 'utils/url'

const baseUrl = configuration.apiGatewayUrl
let controller: any = null

export const getAllUsers = async (): Promise<UserDTO[]> => {
  try {
    const endpoint = '/user/v1/users'
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-all-users-error',
      attributeName: 'get-all-users',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const UpdateUser = async (
  type: string,
  args: Partial<UserDTO>,
  userId?: string,
): Promise<UserDTO> => {
  const endpoint =
    type === 'Add' ? '/user/v1/users' : `/user/v1/users/${userId}`
  const method = type === 'Add' ? 'POST' : 'PUT'
  const body = JSON.stringify(args)

  try {
    const response = await request({
      baseUrl,
      body,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok ? result : Promise.reject(new Error(result.message))
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName:
        type === 'Add' ? 'keanu-create-user-error' : 'keanu-update-user-error',
      attributeName: type === 'Add' ? 'post-create-user' : 'put-update-user',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getUsersByName = async (
  name: string,
  signal: AbortSignal,
): Promise<UserDTO[]> => {
  try {
    const endpoint = `/user/v1/users?name=${name}`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method, signal })
    const result = await response.json()

    return response.ok
      ? result
      : Promise.reject({ statusCode: response.status, message: result.message })
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-users-by-name-error',
      attributeName: 'get-users-by-name',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getUsersByEmail = async (
  email: string,
  signal: AbortSignal,
): Promise<UserDTO[]> => {
  try {
    const endpoint = `/user/v1/users?emailContains=${email}`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method, signal })
    const result = await response.json()

    return response.ok
      ? result
      : Promise.reject({ statusCode: response.status, message: result.message })
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-users-by-email-error',
      attributeName: 'get-users-by-email',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getUsersByPhoneNumber = async (
  phoneNumber: string,
  signal: AbortSignal,
): Promise<UserDTO[]> => {
  try {
    const requestPhoneNumber = phoneNumber.startsWith('+')
      ? phoneNumber.substring(1)
      : phoneNumber

    const endpoint = `/user/v1/users?phoneContains=${requestPhoneNumber}`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method, signal })
    const result = await response.json()

    return response.ok
      ? result
      : Promise.reject({ statusCode: response.status, message: result.message })
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-users-by-phone-number-error',
      attributeName: 'get-users-by-phone-number',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getUserById = async (userId: string): Promise<UserDTO> => {
  try {
    const endpoint = `/user/v1/users/${userId}`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-user-by-id-error',
      attributeName: 'get-user-by-id',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const uploadUserImage = async (
  id: string,
  formData: FormData,
): Promise<UserDTO> => {
  try {
    const endpoint = `/user/v1/users/${id}/images`
    const method = 'POST' as HttpMethod
    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: formData,
      fileUpload: true,
    })
    const result = await response.json()
    return response.ok
      ? result
      : Promise.reject(
          new Error(result.message ? result.message : result.error),
        )
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-upload-user-image-error',
      attributeName: 'upload-user-image',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getUsersByFilter = async (params?: any): Promise<UserDTO[]> => {
  try {
    const endpoint = '/user/v1/users'
    const method = 'GET' as HttpMethod

    if (controller) {
      controller.abort()
    }

    controller = new AbortController()

    const response = await request({
      baseUrl,
      endpoint,
      method,
      params: objectToQueryString(params),
      signal: controller.signal,
    })

    const result = await response.json()
    const parsedResult = result.length ? result : [result]
    if (!response.ok) throw new Error(result.message)
    return parsedResult
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: `keanu-get-users-by-${params}-error`,
      attributeName: `get-users-by-${params}`,
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
