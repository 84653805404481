import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { LocaleForm } from 'components/module/locale'
import { CreateNewLocale } from 'services/api/locale'
import { Locale } from 'types/locale'
import styles from './create.module.scss'

const Create = () => {
  const [isGrowlVisible, setIsGrowlVisible] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [growlContent, setGrowlContent] = useState('')
  const navigate = useNavigate()

  const handleSubmit = async (value: Locale) => {
    setSubmitting(true)
    CreateNewLocale(value, 'REGION')
      .then((response: Locale) => {
        if (response) {
          setGrowlView(true, 'Region saved successfully')
          const id = response.id
          setTimeout(
            () => navigate(`/regions/${response.id}`, { state: { id } }),
            1000,
          )
        }
      })
      .catch((e) => {
        console.log('error', e)
        setGrowlView(true, e.message)
      })
      .finally(() => setSubmitting(false))
  }

  const setGrowlView = (isVisible: boolean, growlContent: string) => {
    setIsGrowlVisible(isVisible)
    setGrowlContent(growlContent)
  }

  const clearError = () => {
    setIsGrowlVisible(false)
  }

  return (
    <Card flat className={styles.container}>
      <header className={styles.header}>
        <Heading className={styles.title} type="h3">
          Create New Region
        </Heading>
      </header>

      <div>
        <GrowlMessage
          autoClose={false}
          isVisible={isGrowlVisible}
          onClick={clearError}
        >
          {growlContent}
        </GrowlMessage>

        <LocaleForm
          onSubmit={(value: Locale) => {
            handleSubmit(value)
          }}
          isSubmitting={isSubmitting}
        />
      </div>
    </Card>
  )
}

export default Create
