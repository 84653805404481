import { configuration } from 'config'
import { AuthToken } from 'types/authentication'

const {
  cookieKeys: { authCookieKey },
} = configuration

export const setAuthToken = ({ token, type, refreshToken }: AuthToken) => {
  const cookieValue = `${JSON.stringify({
    token,
    type,
    refreshToken,
  })}`

  document.cookie = `${authCookieKey}=${cookieValue};expires=${getExpireTime(
    3600,
  )}`
}

const getExpireTime = (secondsFromNow: number) => {
  const now = new Date()
  const expiredDateTime = new Date(now.getTime() + 1000 * secondsFromNow)
  return expiredDateTime.toUTCString()
}

export const getAuthToken = (): AuthToken | undefined => {
  const cookies = document.cookie.split(';').map((cookie) => cookie.trim())

  for (const cookie of cookies) {
    const [name, value] = cookie.split('=')

    if (name === authCookieKey) {
      try {
        const { token, type, refreshToken } = JSON.parse(
          decodeURIComponent(value),
        )
        return { token, type, refreshToken }
      } catch (error) {
        return undefined
      }
    }
  }

  return undefined
}
