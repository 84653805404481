import { AppThunk } from 'app/redux/store'
import { getOrganization } from 'services/api/organization'
import {
  setActiveOrganizationPending,
  setActiveOrganization,
  setOrganizationFailure,
} from './active-organization-reducer'

export const loadOrganizationDetails =
  (orgId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setActiveOrganizationPending(true))
    try {
      const response = await getOrganization(orgId)
      dispatch(setActiveOrganization(response))
    } catch (error: any) {
      if (error.statusCode === 404) {
        dispatch(setOrganizationFailure('Unable to find Organization'))
      } else {
        dispatch(setOrganizationFailure(error.message))
      }
    }
  }
