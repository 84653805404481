import { loginUser } from 'services/api/authentication'
import { AppThunk } from '../redux/store'
import { AuthError } from '.'
import {
  LoginParams,
  setClearError,
  setLoginFailure,
  setLoginPending,
  setLoginSuccess,
  setLogoutSuccess,
} from '.'

//const backendURL = 'https://staging.api-gateway.campgladiator.com'

const invalidCredentialsErrorMessages = [
  '[401 unauthorized] during [POST]',
  'Unable to find User with email',
  'email address must follow RFC 5322 standard',
]

export const parseError = (errorMessage: any): AuthError => {
  if (typeof errorMessage !== 'string') return 'unknownError'

  const hasInvalidCredentials = invalidCredentialsErrorMessages.some(
    (message) => errorMessage.toLowerCase().includes(message.toLowerCase()),
  )

  return hasInvalidCredentials ? 'invalidCredentials' : 'unknownError'
}

export const login =
  (params: LoginParams): AppThunk =>
  async (dispatch) => {
    dispatch(setLoginPending(true))
    try {
      const authResponse = await loginUser(params)
      dispatch(setLoginSuccess(authResponse))
    } catch (errorMessage) {
      const errorType = parseError(errorMessage)
      /*       setHoneycombTracer({
            tracerName: 'default',
            spanName: 'keanu-error',
            attributeName: 'post-login-data',
            value: errorType,
          }) */
      dispatch(setLoginFailure(errorType))
    }
  }

export const logout = () => (dispatch: any) => dispatch(setLogoutSuccess())

export const resetError = () => (dispatch: any) => dispatch(setClearError())
