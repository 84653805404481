import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { activeUserMembershipSummarySelector } from 'app/redux/features/active-user/active-user-reducer'
import { useAppSelector } from 'app/redux/store'
import { isEmpty } from 'lodash'
import { ProductSubTypes, ProductTypes } from 'types/product.d'
import styles from './user-membership-info.module.scss'

type UserMembershipInfoProps = {
  userId: string
}

const ProductIconMapping = {
  [`${ProductTypes.BOOTCAMP}-${ProductSubTypes.ONLINE}`]: {
    title: 'Online',
    icon: <Icon.Monochrome className={styles.prodIcon} name="location-point" />,
  },
  [`${ProductTypes.BOOTCAMP}-${ProductSubTypes.IN_PERSON}`]: {
    title: 'Outdoor',
    icon: <Icon.Monochrome className={styles.prodIcon} name="location-point" />,
  },
  [`${ProductTypes.STRENGTH}-${ProductSubTypes.ONLINE}`]: {
    title: 'Strength',
    icon: <Icon.Solid className={styles.prodIcon} name="icon-dumbbell" />,
  },
  [`${ProductTypes.STRENGTH}-${ProductSubTypes.IN_PERSON}`]: {
    title: 'Strength',
    icon: <Icon.Solid className={styles.prodIcon} name="icon-dumbbell" />,
  },
  [`${ProductTypes.NUTRITION}-${ProductSubTypes.ONLINE}`]: {
    title: 'Nutrition',
    icon: <Icon.Solid className={styles.prodIcon} name="icon-utensils" />,
  },
  [`${ProductTypes.NUTRITION}-${ProductSubTypes.IN_PERSON}`]: {
    title: 'Nutrition',
    icon: <Icon.Solid className={styles.prodIcon} name="icon-utensils" />,
  },
}

const UserMembershipInfo = ({ userId }: UserMembershipInfoProps) => {
  const membershipSummaries = useAppSelector(
    activeUserMembershipSummarySelector,
  )

  return (
    <div className={styles.container}>
      {membershipSummaries && !isEmpty(membershipSummaries) && (
        <>
          <Paragraph weight="normal" size="small" className={styles.title}>
            Memberships
          </Paragraph>
          {membershipSummaries.map((it, index) => (
            <div className={styles.row} key={index}>
              {ProductIconMapping[`${it.type}-${it.subType}`].icon}
              <Paragraph weight="book" size="xsmall" className={styles.label1}>
                {ProductIconMapping[`${it.type}-${it.subType}`].title}
              </Paragraph>
              <Paragraph weight="book" size="xsmall" className={styles.label2}>
                |
              </Paragraph>
              <Paragraph weight="book" size="xsmall" className={styles.label2}>
                {it.status}
              </Paragraph>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default UserMembershipInfo
