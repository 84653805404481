import { Input } from '@campgladiator/cgui-core.atoms.input'
import { ListItemProps } from '@campgladiator/cgui-core.atoms.list-item'
import styles from './auto-complete.module.scss'
import useAutoComplete from './hooks/use-auto-complete'

export type OptionType = 'organization' | 'other'

export type AutocompleteProps = {
  disabled?: boolean
  id: string
  name: string
  options: OptionType extends 'organization' ? never : any[]
  optionType?: 'organization' | 'other'
  placeholder: string
  setRecord: (record: ListItemProps & { type: 'item' }) => void
  type?: 'text'
  value: string
}

const Autocomplete = ({
  id,
  placeholder,
  name,
  type = 'text',
  value,
  options,
  optionType = 'other',
  setRecord,
  disabled,
}: AutocompleteProps) => {
  const {
    contentRef,
    inputIcon,
    optionList,
    userInput,
    handleClear,
    handleOnChange,
    onKeyDown,
  } = useAutoComplete({
    options,
    optionType,
    value,
    setRecord,
  })

  return (
    <div ref={contentRef} className={styles.autocompleteContainer}>
      <Input
        id={id}
        name={name}
        placeholder={placeholder}
        type={type}
        value={userInput}
        onChange={handleOnChange}
        onKeyDown={onKeyDown}
        onIconClick={() => handleClear()}
        icon={inputIcon}
        autoComplete="off"
        disabled={disabled}
      />
      {optionList}
    </div>
  )
}

export default Autocomplete
