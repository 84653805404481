import { useParams } from 'react-router-dom'
import {
  FinderLauncherDTO,
  FinderLauncherType,
} from '@campgladiator/cg-common.types.types/dist/billing'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { useGrowlContext } from 'app/contexts/growl-context'
import {
  TrainerSplitAllocation,
  assignmentSchema,
  defaultAssignment,
  totalAssignedPercentSchema,
} from 'components/module/trainer/trainer-split-allocation'
import { useFormik } from 'formik'
import { flatMap, isEmpty } from 'lodash'
import {
  postFindersLaunchers,
  putFindersLaunchers,
} from 'services/api/finderLauncher'
import { PayConfigResponse, PayConfigurationValues } from 'types/pay-config.d'
import * as Yup from 'yup'
import styles from './pay-configurations.module.scss'

type PayConfigurationProps = {
  payConfig: PayConfigResponse | null
  childId: string | null
  onSuccess: () => void
}

const PayConfiguration = ({
  payConfig,
  childId,
  onSuccess,
}: PayConfigurationProps) => {
  const { id } = useParams()
  const { showGrowl } = useGrowlContext()

  const formik = useFormik({
    initialValues: {
      finders:
        childId && payConfig && !isEmpty(payConfig.finders)
          ? payConfig.finders
          : [{ ...defaultAssignment }],
      launchers:
        childId && payConfig && !isEmpty(payConfig.launchers)
          ? payConfig.launchers
          : [{ ...defaultAssignment }],
    } as PayConfigurationValues,
    validationSchema: Yup.object({
      finders: assignmentSchema,
      launchers: assignmentSchema,
      findersTotalPercent: totalAssignedPercentSchema('finders'),
      launchersTotalPercent: totalAssignedPercentSchema('launchers'),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!id) return

      try {
        const payload: Partial<FinderLauncherDTO>[] = [
          ...values.finders
            .filter((it) => it.trainerId)
            .map((it) => ({
              childId: id,
              parentId: it.trainerId,
              percent: it.percentage,
              type: 'FINDER' as FinderLauncherType,
            })),
          ...values.launchers
            .filter((it) => it.trainerId)
            .map((it) => ({
              childId: id,
              parentId: it.trainerId,
              percent: it.percentage,
              type: 'LAUNCHER' as FinderLauncherType,
            })),
        ]

        if (childId) {
          await putFindersLaunchers(childId, payload)
        } else {
          await postFindersLaunchers(payload)
        }

        showGrowl('SUCCESS', 'Pay configuration updated')
        onSuccess()
      } catch (error: any) {
        showGrowl('FAILED', error)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const { handleSubmit, setSubmitting, isSubmitting, values } = formik

  const selectedFinders = [
    ...flatMap(values.finders).map((it) => it.trainerId),
    id!,
  ]

  const selectedLaunchers = [
    ...flatMap(values.launchers).map((it) => it.trainerId),
    id!,
  ]

  return (
    <div className={styles.container}>
      <Heading className={styles.title} type="h4">
        Finders & Launchers
      </Heading>
      <form onSubmit={handleSubmit} className={styles.formSection}>
        <div className={styles.sectionContainer}>
          <div className={styles.seciton}>
            <Heading type="h6">Finders</Heading>
            <div className={styles.formSection}>
              <TrainerSplitAllocation
                splitKey="finders"
                selectedTrainers={selectedFinders}
                // @ts-ignore
                formik={formik}
              />
            </div>
          </div>
          <div className={styles.seciton}>
            <Heading type="h6">Launchers</Heading>
            <div className={styles.formSection}>
              <TrainerSplitAllocation
                splitKey="launchers"
                selectedTrainers={selectedLaunchers}
                // @ts-ignore
                formik={formik}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <Button
              emphasis="primary"
              className={styles.submitButton}
              type="submit"
              disabled={isSubmitting}
              theme="trainer"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PayConfiguration
