import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import ApiAutoComplete from 'components/form-ui/api-auto-complete'
import { Autocomplete } from 'components/form-ui/auto-complete'
import { Dropdown } from 'components/form-ui/dropdown'
import { getTrainers } from 'services/api/trainer'
import { TimeZoneDropDownOptions } from 'services/data/timezone'
import { Program } from 'types/program.d'
import useScheduleProgramForm from './hooks/use-schedule-program-form'
import styles from './schedule-program-form.module.scss'

export type ScheduleProgramFormProps = {
  onSubmitForm: (params: Program) => void
  initialData?: Program
}

const ScheduleProgramForm = ({
  onSubmitForm,
  initialData,
}: ScheduleProgramFormProps) => {
  const {
    errors,
    isSubmitting,
    productOptions,
    timezoneName,
    touched,
    trainersFilterParams,
    values,
    approvalAttrMapping,
    getFieldProps,
    getMinDate,
    handleProductChange,
    handleSetTimezone,
    handleSubmit,
    setFieldValue,
  } = useScheduleProgramForm({
    initialData,
    onSubmitForm,
  })

  return (
    <form className={styles.formSection} onSubmit={handleSubmit}>
      <FormPart
        label="Product*"
        forId="productDetailsId"
        errorMsg={(touched.productDetailsId && errors.productDetailsId) || ''}
      >
        <Dropdown
          id="productDetailsId"
          label="Choose a product"
          options={productOptions}
          {...getFieldProps('productDetailsId')}
          onChange={handleProductChange}
        />
      </FormPart>
      <FormPart
        label="Trainer*"
        forId="trainerId"
        errorMsg={(touched.trainerId && errors.trainerId) || ''}
      >
        <ApiAutoComplete
          id="trainerId"
          placeholder="Trainer"
          getMethod={getTrainers}
          searchKey="name"
          disabled={values.productDetailsId.length === 0}
          initialSelectedItem={initialData?.trainerName}
          renderOptionLabel={(record) =>
            `${record.firstName} ${record.lastName}`
          }
          filterOption={(record: any) =>
            record.approvedPrograms[
              approvalAttrMapping[trainersFilterParams.approvedProgram]
            ] === true
          }
          onSelect={(record: any) =>
            setFieldValue('trainerId', record?.itemId || '')
          }
          {...getFieldProps('trainerId')}
        />
      </FormPart>
      <Fieldset className={styles.fieldset}>
        <FormPart
          label="Start Date*"
          forId="startDate"
          className={styles.fieldsetItem}
          errorMsg={(touched.startDate && errors.startDate) || ''}
        >
          <Input
            id="startDate"
            placeholder="Start Date"
            type="date"
            min={getMinDate()}
            {...getFieldProps('startDate')}
          />
        </FormPart>
        <FormPart
          label="End Date"
          forId="endDate"
          className={styles.fieldsetItem}
          errorMsg={(touched.endDate && errors.endDate) || ''}
        >
          <Input
            id="endDate"
            placeholder="End Date"
            type="date"
            min={values.startDate}
            {...getFieldProps('endDate')}
          />
        </FormPart>
      </Fieldset>
      <FormPart
        label="Time Zone*"
        forId="timezone"
        errorMsg={(touched.timezone && errors.timezone) || ''}
      >
        <Autocomplete
          id="timezone"
          options={TimeZoneDropDownOptions}
          placeholder="Choose a time zone"
          setRecord={handleSetTimezone}
          value={timezoneName}
          name="timezone"
        />
      </FormPart>
      <div className={styles.buttons}>
        <Button
          emphasis="primary"
          disabled={isSubmitting}
          type="submit"
          theme="trainer"
        >
          Save
        </Button>
      </div>
    </form>
  )
}

export default ScheduleProgramForm
