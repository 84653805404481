import { InputProps } from '@campgladiator/cgui-core.atoms.input'
import { FormInputProps } from '../../components/form-ui/input'
import { InputType, useWithTextInput } from './useWithTextInput'

type UseWithFormInputArgs = {
  icon?: InputProps['icon']
  id: string
  errorMsg?: string
  pattern?: string
  placeholder: string
  title: string
  type?: InputType
}

export const useWithFormInput = ({
  errorMsg,
  icon,
  id,
  pattern,
  placeholder,
  title,
  type = 'text',
}: UseWithFormInputArgs): FormInputProps => {
  const binding = useWithTextInput(type)
  return {
    binding,
    icon,
    id,
    errorMsg,
    pattern,
    placeholder,
    title,
    value: binding.value,
  }
}
