import { Input } from '@campgladiator/cgui-core.atoms.input'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'

export type PaymentAmountInputProps = {
  amount: number | null
  error: string
  formLabel: string
  handleAmountChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  id: string
  name: string
}

const PaymentAmountInput = ({
  amount,
  formLabel,
  id,
  name,
  error,
  handleAmountChange,
}: PaymentAmountInputProps) => (
  <FormPart label={formLabel} forId={id} errorMsg={error}>
    <Input
      id={id}
      type="number"
      name={name}
      value={amount || ''}
      onChange={handleAmountChange}
      icon={{ type: 'monochrome', name: 'dollar-sign' }}
      invalid={!!error}
      step={0.01}
    />
  </FormPart>
)

export default PaymentAmountInput
