import classNames from 'classnames'
import { PaymentMethodDTO } from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import { useGrowlContext } from 'app/contexts/growl-context'
import { useFormik } from 'formik'
import { padStart } from 'lodash'
import moment from 'moment'
import { updatePaymentMethod } from 'services/api/payment'
import * as Yup from 'yup'
import { PaymentCardItem } from '../payment-card-item'
import styles from './update-payment-form.module.scss'

export type UpdatePaymentCardFormProps = {
  onSuccess: () => void
  paymentDetails: PaymentMethodDTO
  isDefaultPaymentCheckboxDisabled: boolean
}

const UpdatePaymentCardForm = ({
  paymentDetails,
  isDefaultPaymentCheckboxDisabled,
  onSuccess,
}: UpdatePaymentCardFormProps) => {
  const { showGrowl } = useGrowlContext()
  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setSubmitting,
    resetForm,
    isSubmitting,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      name: paymentDetails.name,
      expiration: `${padStart(
        paymentDetails.expMonth.toString(),
        2,
        '0',
      )}/${paymentDetails.expYear.toString().slice(-2)}`,
      default: paymentDetails?.default,
    },
    validationSchema: Yup.object({
      expiration: Yup.string()
        .typeError('Invalid expiration date')
        .test(
          'test-credit-card-expiration-date',
          'Invalid expiration date',
          (expirationDate) => {
            if (!expirationDate) {
              return false
            }

            const creditCardDate = moment(expirationDate, 'MM/YY')
            return (
              creditCardDate.isValid() &&
              moment() < creditCardDate.add(1, 'months')
            )
          },
        )
        .required('Expiration date is required'),
    }),
    onSubmit: async (values) => {
      try {
        if (!paymentDetails) return

        const { expiration, ...params } = values
        await updatePaymentMethod(paymentDetails.id, {
          ...params,
          expMonth: expiration.slice(0, 2),
          expYear: expiration.slice(-2),
        })
        resetForm()
        onSuccess()
      } catch (error: any) {
        console.log(error)
        showGrowl('FAILED', error)
      }

      setSubmitting(false)
    },
  })

  const handleExpirationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    const onlyNumbers = inputValue.replace(/^[^0-9]$/, '')

    const expDateFormatter =
      onlyNumbers.replace(/\//g, '').substring(0, 2) +
      (onlyNumbers.length > 2 ? '/' : '') +
      onlyNumbers.replace(/\//g, '').substring(2, 4)

    e.target.value = expDateFormatter

    setFieldValue('expiration', e.target.value)
  }

  return (
    <div className={styles.formContainer}>
      <form className={styles.billingCardForm} onSubmit={handleSubmit}>
        <div className={styles.cardToUpdate}>
          <PaymentCardItem
            card={paymentDetails}
            onEditButtonClick={() => {}}
            hideActionButtons={true}
          />
        </div>

        <div className={classNames(grid.row)}>
          <FormPart
            className={classNames(styles.formPart, grid.col12)}
            data-full-line
            forId="name"
            label="Name on Card"
          >
            <Input id="name" type="text" disabled {...getFieldProps('name')} />
          </FormPart>
        </div>
        <div className={classNames(grid.row)}>
          <FormPart
            className={classNames(styles.formPart, grid.col12)}
            data-full-line
            forId="cardNumber"
            label="Card Information"
          >
            <Input
              id="cardNumber"
              name="cardNumber"
              required
              type="text"
              disabled={true}
              value={'**** **** **** ' + paymentDetails?.last4}
            />
          </FormPart>
        </div>
        <div className={classNames(grid.row)}>
          <FormPart
            className={classNames(styles.formPart, grid.colLg3, grid.col6)}
            data-full-line
            forId="expiration"
            label="Expiration"
            errorMsg={(touched.expiration && errors.expiration) || ''}
          >
            <Input
              id="expiration"
              required
              type="text"
              placeholder="MM/YY"
              {...getFieldProps('expiration')}
              onChange={handleExpirationChange}
            />
          </FormPart>
          <FormPart
            className={classNames(styles.formPart, grid.colLg3, grid.col6)}
            data-full-line
            forId="cvc"
            label="CVC"
          >
            <Input id="cvc" name="cvc" value="****" disabled />
          </FormPart>
        </div>
        <div className={classNames(grid.row)}>
          <FormPart
            className={classNames(styles.formPart, grid.col6)}
            data-full-line
            forId="country"
            label="Country or Region"
          >
            <Input
              id="country"
              name="country"
              disabled
              type="text"
              value={paymentDetails.country}
            />
          </FormPart>
        </div>
        <div className={classNames(grid.col12, styles.checkbox)}>
          <input
            type="checkbox"
            checked={values.default}
            onChange={() => setFieldValue('default', !values.default)}
          />
          <Paragraph weight="book" size="small">
            Save this card as my default payment
          </Paragraph>
        </div>

        <div className={classNames(grid.row, styles.submitButton)}>
          <div className={classNames(grid.colLg4)}>
            <Button
              type="submit"
              emphasis="secondary"
              disabled={isSubmitting}
              theme="trainer"
            >
              update credit card
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UpdatePaymentCardForm
