const supportedPhotoTypes = ['image/jpeg', 'image/jpg', 'image/png']
const supportedVideoTypes = ['video/mp4']

export const deriveContentType = (selectedFile: File) => {
  const selectedFileType = selectedFile?.type as string
  if (supportedPhotoTypes.includes(selectedFileType)) {
    return 'PHOTO'
  } else if (supportedVideoTypes.includes(selectedFileType)) {
    return 'VIDEO'
  }
}

export const isPhotoOrVideo = (file: File) => {
  const contentType = deriveContentType(file)
  return contentType === 'PHOTO' || contentType === 'VIDEO'
}
