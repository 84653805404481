import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserDTO } from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import {
  Table,
  TData,
  THead,
  TRow,
} from '@campgladiator/cgui-core.organisms.table'
import { Dropdown } from 'components/form-ui/dropdown'
import { SearchInput } from '../../components/module/shared/search-input'
import {
  getUsersByEmail,
  getUsersByName,
  getUsersByPhoneNumber,
} from '../../services/api/user'
import styles from './users.module.scss'

const UsersPage = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchType, setSearchType] = useState<string>('Camper Name')
  const [users, setUsers] = useState<UserDTO[]>([])

  const [isGrowlVisible, setGrowlVisible] = useState<boolean>(false)
  const [growlContent, setGrowlContent] = useState<string>('')

  const showMessage = (message: string) => {
    setGrowlVisible(true)
    setGrowlContent(message)
  }

  const navigate = useNavigate()

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  const handleNewButtonClick = () => navigate('/users/create')

  const handleViewDetailsClick = (userId?: string) =>
    navigate(`/users/${userId || ''}`)

  const searchUsers = useCallback(
    async (signal: AbortSignal) => {
      setUsers([])

      const value = searchTerm
      const type = searchType.replace('Camper ', '')

      switch (type) {
        case 'Name':
          getUsersByName(value, signal)
            .then((result) => setUsers((u) => [...u, ...result]))
            .catch((err) => {
              if (!signal.aborted) {
                showMessage(err.message)
              }
            })
          break
        case 'Email':
          getUsersByEmail(value, signal)
            .then((result) => setUsers((u) => [...u, ...result]))
            .catch((err) => {
              if (err.statusCode !== 404 && !signal.aborted) {
                showMessage(err.message)
              }
            })
          break
        case 'Phone':
          getUsersByPhoneNumber(value, signal)
            .then((result) => setUsers((u) => [...u, ...result]))
            .catch((err) => {
              if (err.statusCode !== 404 && !signal.aborted) {
                showMessage(err.message)
              }
            })
          break
        default:
          getUsersByName(value, signal)
            .then((result) => setUsers((u) => [...u, ...result]))
            .catch((err) => {
              if (!signal.aborted) {
                showMessage(err.message)
              }
            })
      }
    },
    [searchTerm, searchType],
  )

  useEffect(() => {
    const abortController = new AbortController()

    if (!searchTerm) {
      setUsers([])
      return
    }
    if (searchTerm.length >= 2) {
      searchUsers(abortController.signal).catch(() => {})
    }

    return () => {
      abortController.abort()
    }
  }, [searchTerm, searchUsers])

  const searchOptions = ['Camper Name', 'Camper Email', 'Camper Phone']

  const SearchDropdownItems = searchOptions.map((searchOption) => ({
    value: searchOption,
    label: searchOption,
  }))

  const handleSearchTypeChange = (
    event: React.FormEvent<HTMLSelectElement>,
  ) => {
    setSearchType(event.currentTarget.value)
  }

  return (
    <div className={styles.page}>
      <GrowlMessage
        isVisible={isGrowlVisible}
        onClick={() => setGrowlVisible(false)}
      >
        {growlContent}
      </GrowlMessage>

      <Card className={styles.title}>
        <Heading type="h3" className={styles.pageHeader}>
          Users
        </Heading>
        <Button
          emphasis="primary"
          icon={{ name: 'icon-plus-circle', type: 'line' }}
          size="default"
          variation="solid"
          className={styles.submitButton}
          onClick={handleNewButtonClick}
          theme="trainer"
        >
          New User
        </Button>
      </Card>

      <div className={styles.searchLabelSection}>
        <div className={styles.searchText}>Search by</div>
        <div className={styles.searchText}>{searchType}</div>
      </div>
      <div className={styles.searchSection}>
        <Dropdown
          className={styles.searchType}
          id="gender-dropdown"
          options={SearchDropdownItems}
          onChange={handleSearchTypeChange}
        />
        <SearchInput
          numericSearch={
            searchType.replace('Camper ', '') === 'Phone' ? true : false
          }
          placeholder="Search for a camper"
          onSearch={handleSearch}
        />
      </div>

      <div className={styles.tableWrapper}>
        <Table.Manual>
          <thead>
            <TRow>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Name
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Email
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Phone
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Actions
                </Heading>
              </THead>
            </TRow>
          </thead>
          <tbody>
            {!users.length && (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    No users to display
                  </Paragraph>
                </TData>
              </TRow>
            )}

            {!!users.length &&
              users.map(({ id, firstName, lastName, email, phone }) => (
                <TRow key={id}>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {firstName} {lastName}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {email}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {phone}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      <Button
                        emphasis="secondary"
                        icon={{ name: 'icon-edit', type: 'solid' }}
                        size="large"
                        variation="text"
                        onClick={() => handleViewDetailsClick(id)}
                        theme="trainer"
                      />
                    </Paragraph>
                  </TData>
                </TRow>
              ))}
          </tbody>
        </Table.Manual>
      </div>
    </div>
  )
}

export default UsersPage
