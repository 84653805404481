import { useCallback, useEffect, useState } from 'react'
import {
  passwordValidation,
  emailValidationRFC2822,
} from '@campgladiator/cg-common.utility.validation'
import { Icon } from '@campgladiator/cgui-core.atoms.input/dist/input'
import { ListItemProps } from '@campgladiator/cgui-core.atoms.list-item'
import { useFetchTrainerByUserId } from 'app/hooks/useFetchTrainerByUserId'
import { useFormik } from 'formik'
import { getOrganization } from 'services/api/organization'
import { getUserById } from 'services/api/user'
import { stateList } from 'services/data/address'
import { UserFormProps } from 'types/user'
import { phoneValidation, zipCodeValidation } from 'utils/regex'
import * as Yup from 'yup'
import { IUserFormProps } from '../user-form'

type UseUserFormProps = Pick<
  IUserFormProps,
  'isEdit' | 'isSubmitting' | 'onSubmit' | 'user'
>

const emailRegex = new RegExp(emailValidationRFC2822)
const passwordRegex = new RegExp(passwordValidation)

const validationSchema = (isEdit: boolean) =>
  Yup.object({
    firstName: Yup.string().required('First Name is Required'),
    lastName: Yup.string().required('Last Name is Required'),
    email: Yup.string()
      .required('Email address is Required')
      .matches(emailRegex, 'Invalid email address'),
    password: !isEdit
      ? Yup.string()
          .required('Password is Required')
          .matches(
            passwordRegex,
            'Password must contain at least 8 characters, including upper/lowercase and numbers',
          )
      : Yup.string().notRequired(),
    confirmPassword: !isEdit
      ? Yup.string()
          .required('confirm Password is Required')
          .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      : Yup.string().notRequired(),
    phone: Yup.string()
      .required('Phone Number is Required')
      .matches(phoneValidation, 'Phone number is not valid'),
    zipCode: Yup.string()
      .notRequired()
      .matches(zipCodeValidation, 'Please match the requested format'),
  })

const getStateAbbr = (stateName: string | undefined) => {
  const [firstFound] = stateList.filter(
    (state) =>
      state.name.toLowerCase() === stateName?.toLowerCase() ||
      state.abbr.toLowerCase() === stateName?.toLowerCase(),
  )
  if (firstFound) return firstFound.abbr || ''
  return ''
}

const useUserFrom = ({
  isEdit,
  isSubmitting,
  user,
  onSubmit,
}: UseUserFormProps) => {
  const [organizationId, setOrganizationId] = useState<string>('')
  const [referralName, setReferralName] = useState<string>('')
  const [searchType, setSearchType] = useState<string>('Name')
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState<boolean>(false)
  const [orgName, setOrgName] = useState<string>('')
  const trainer = useFetchTrainerByUserId(user?.id!)
  const isUserATrainer = trainer && Object.keys(trainer).length > 0
  const isEmailInputDisabled = isUserATrainer

  const { handleSubmit, getFieldProps, setFieldValue, errors, touched } =
    useFormik({
      initialValues: {
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        email: user?.email || '',
        password: '',
        confirmPassword: '',
        phone: user?.phone || '',
        organizationId: user?.organizationId,
        referralId: user?.referralId,
        dateOfBirth: user?.dateOfBirth?.split('T')[0],
        gender: user?.gender,
        shirtSize: user?.shirtSize,
        role: user?.role,
        address1: user?.address1,
        address2: user?.address2,
        country: user?.country,
        city: user?.city,
        state: getStateAbbr(user?.state),
        zipCode: user?.zipCode,
        referralType: user?.referralType,
      },
      validationSchema: validationSchema(isEdit || false),
      enableReinitialize: true,
      onSubmit: (values: UserFormProps) => {
        onSubmit({
          ...values,
          organizationId,
          gender: values.gender || undefined,
          shirtSize: values.shirtSize || undefined,
          referralType: values.referralType || undefined,
          role: values.role || undefined,
          password: !isEdit ? values.password : undefined,
          dateOfBirth:
            values.dateOfBirth && new Date(values.dateOfBirth).toISOString(),
        })
      },
    })

  const fetchOrganizationName = useCallback(async (orgId: string) => {
    try {
      const org = await getOrganization(orgId)
      setOrgName(org.name)
    } catch (err) {
      console.error(err)
    }
  }, [])

  const handleSetOrganizationId = (record: ListItemProps) => {
    if (!record) {
      setOrgName('')
      setFieldValue('organizationId', '')
      setOrganizationId('')
      return
    }
    setOrgName(record.text || '')
    setFieldValue('organizationId', record.itemId || '')
    setOrganizationId(record.itemId || '')
  }

  const getUserDetails = async (userId: string) => {
    try {
      const response = await getUserById(userId!)
      setReferralName(`${response.firstName} ${response.lastName}`)
    } catch (error) {
      console.error(error)
    }
  }

  const getVisibilityIcon = (isPasswordVisible: boolean) => {
    const eyeIconVariant = isPasswordVisible
      ? { type: 'line', name: 'icon-eye' }
      : { type: 'line', name: 'icon-eye-slash' }
    return eyeIconVariant as Icon
  }

  const onPasswordClick = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const onConfirmPasswordClick = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
  }

  const getPasswordInputType = (isPasswordVisible: boolean) =>
    isPasswordVisible ? 'text' : 'password'

  useEffect(() => {
    if (user?.organizationId) {
      fetchOrganizationName(user?.organizationId)
    }
  }, [fetchOrganizationName, user])

  useEffect(() => {
    if (user?.referralId) {
      getUserDetails(user.referralId)
    }
  }, [user?.referralId])

  useEffect(() => {
    if (!isSubmitting) setSearchType('Name')
  }, [isSubmitting])

  return {
    errors,
    isConfirmPasswordVisible,
    isEmailInputDisabled,
    isPasswordVisible,
    orgName,
    referralName,
    searchType,
    touched,
    getFieldProps,
    getPasswordInputType,
    getUserDetails,
    getVisibilityIcon,
    handleSetOrganizationId,
    handleSubmit,
    onConfirmPasswordClick,
    onPasswordClick,
    setFieldValue,
    setSearchType,
  }
}

export default useUserFrom
