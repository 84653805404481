import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TrainerDTO } from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  Table,
  TData,
  THead,
  TRow,
} from '@campgladiator/cgui-core.organisms.table'
import { useGrowlContext } from 'app/contexts/growl-context'
import { Dropdown } from 'components/form-ui/dropdown'
import { SearchInput } from 'components/module/shared/search-input'
import { getTrainerByFilter } from 'services/api/trainer'
import TrainerSearchDropdownItems from 'services/data/trainer'
import { formatPhoneNumber } from 'utils/phone'
import styles from './view.module.scss'

const ViewTrainers = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchType, setSearchType] = useState<string>('Trainer Name')
  const [trainers, setTrainers] = useState<TrainerDTO[]>([])
  const { showGrowl } = useGrowlContext()
  const navigate = useNavigate()

  const searchTrainers = useCallback(
    async (signal: AbortSignal) => {
      setTrainers([])

      const value = searchTerm
      const type = searchType.replace('Trainer ', '')
      switch (type) {
        case 'Name':
          getTrainerByFilter('name', value, signal)
            .then((result) => setTrainers((u) => [...u, ...result]))
            .catch((err) => {
              showGrowl('FAILED', err.message)
            })
          break
        case 'Email':
          getTrainerByFilter('emailPrefix', value, signal)
            .then((result) => setTrainers((u) => [...u, ...result]))
            .catch((err) => {
              if (err.statusCode !== 404) {
                showGrowl('FAILED', err.message)
              }
            })
          break
        default:
          getTrainerByFilter('name', value, signal)
            .then((result) => setTrainers((u) => [...u, ...result]))
            .catch((err) => {
              showGrowl('FAILED', err.message)
            })
      }
    },
    //eslint-disable-next-line
    [searchTerm, searchType],
  )

  useEffect(() => {
    const abortController = new AbortController()
    if (!searchTerm) {
      setTrainers([])
      return
    }
    if (searchTerm.length >= 2)
      searchTrainers(abortController.signal).catch(() => {})

    return () => {
      abortController.abort()
    }
  }, [searchTerm, searchTrainers])

  return (
    <div className={styles.page}>
      <Card className={styles.title}>
        <Heading type="h3" className={styles.pageHeader}>
          Trainers
        </Heading>
      </Card>
      <div className={styles.searchLabelSection}>
        <div className={styles.searchText}>Search by</div>
        <div className={styles.searchText}>{searchType}</div>
      </div>
      <div className={styles.searchSection}>
        <Dropdown
          className={styles.searchType}
          id="search-dropdown"
          options={TrainerSearchDropdownItems}
          onChange={(e) => setSearchType(e.currentTarget.value)}
        />
        <SearchInput
          placeholder="Search for a Trainer"
          onSearch={(value) => setSearchTerm(value)}
          suffix={
            searchType.replace('Trainer ', '') === 'Email'
              ? '@campgladiator.com'
              : undefined
          }
        />
      </div>
      <div className={styles.tableWrapper}>
        <Table.Manual>
          <thead>
            <TRow>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Name
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Email
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Phone
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Actions
                </Heading>
              </THead>
            </TRow>
          </thead>
          <tbody>
            {!trainers.length && (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    No trainers to display
                  </Paragraph>
                </TData>
              </TRow>
            )}
            {!!trainers.length &&
              trainers.map(
                ({ id, firstName, lastName, email, contactNumber }) => (
                  <TRow key={id}>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {firstName} {lastName}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {email}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {formatPhoneNumber(contactNumber)}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        <Button
                          emphasis="secondary"
                          icon={{ name: 'icon-edit', type: 'solid' }}
                          size="large"
                          variation="text"
                          onClick={() => navigate(`/trainers/${id || ''}`)}
                          theme="trainer"
                        />
                      </Paragraph>
                    </TData>
                  </TRow>
                ),
              )}
          </tbody>
        </Table.Manual>
      </div>
    </div>
  )
}

export default ViewTrainers
