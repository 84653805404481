import { useEffect } from 'react'
import {
  OrganizationDTO,
  OrganizationSplitDTO,
} from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { useGrowlContext } from 'app/contexts/growl-context'
import { setActiveOrganization } from 'app/redux/features/active-organization/active-organization-reducer'
import { useAppDispatch } from 'app/redux/store'
import ApiAutocomplete from 'components/form-ui/api-auto-complete/api-auto-complete'
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik'
import { isEmpty, map } from 'lodash'
import { putOrganization } from 'services/api/organization'
import { getTrainers } from 'services/api/trainer'
import * as Yup from 'yup'
import styles from './org-trainer-split.module.scss'

export type OrgTrainerSplitProps = {
  orgDetails: OrganizationDTO
}

export const defaultAssignment = {
  id: '',
  orgId: '',
  created: '',
  updated: '',
  trainerId: '',
  percentage: '',
  trainerName: '',
}

type initialValuesType = {
  splits: OrganizationSplitDTO[]
}

const validationSchema = Yup.object({
  splits: Yup.array().of(
    Yup.object().shape(
      {
        trainerId: Yup.string().when('percentage', {
          is: (val: number | null) => (val || 0) > 0,
          then: (schema) => schema.required('Trainer is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
        percentage: Yup.number()
          .max(100, 'Split % must not be greater than 100')
          .min(0.1, 'Split % must be greater than 0')
          .when('trainerId', {
            is: (val: string | null) => val && val.length > 0,
            then: (schema) => schema.required('Split % is required'),
            otherwise: (schema) => schema.notRequired(),
          }),
      },
      [
        ['trainerId', 'percentage'],
        ['percentage', 'trainerId'],
      ],
    ),
  ),
  totalAssignedPercent: Yup.number().test(
    'is-percentage-100',
    'Total split % should be 100',
    (_, context) => {
      const totalCommission = context.parent.splits.reduce(
        (acc: number, curr: OrganizationSplitDTO) =>
          acc + Number(curr.percentage || 0),
        0,
      )

      return totalCommission === 100 || totalCommission === 0
    },
  ),
})

const OrgTrainerSplit = ({ orgDetails }: OrgTrainerSplitProps) => {
  const dispatch = useAppDispatch()
  const { showGrowl } = useGrowlContext()

  const formik = useFormik({
    initialValues: {
      splits: !isEmpty(orgDetails.splits)
        ? orgDetails.splits
        : [{ ...defaultAssignment }],
    } as initialValuesType & { totalAssignedPercent: number },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async ({ splits }) => {
      try {
        const updatedOrg = await putOrganization(orgDetails.id!, {
          ...orgDetails,
          splits: splits.filter(
            (it: OrganizationSplitDTO) => it.percentage && it.trainerId,
          ),
        })

        dispatch(setActiveOrganization(updatedOrg))
        showGrowl('', 'Split updated successfully')
      } catch (error: any) {
        showGrowl('FAILED', error)
      }
    },
  })

  const {
    getFieldProps,
    setFieldValue,
    isSubmitting,
    errors,
    touched,
    handleSubmit,
    values,
  } = formik

  useEffect(() => {
    if (!isEmpty(orgDetails.splits)) setFieldValue('splits', orgDetails.splits)

    //eslint-disable-next-line
  }, [orgDetails.id])

  return (
    <div className={styles.container}>
      <Heading className={styles.title} type="h4">
        Trainer Split
      </Heading>
      <form onSubmit={handleSubmit} className={styles.formSection}>
        <FormikProvider value={formik}>
          <FieldArray
            name="splits"
            render={(arrayHelpers) =>
              values.splits.map((split, index, arrAssignments) => (
                <Fieldset
                  key={`assignment_${index}`}
                  className={styles.fieldset}
                >
                  <FormPart
                    label="Trainer"
                    forId={`trainer_${index}`}
                    className={styles.fieldsetItem}
                    errorMsg={
                      (getIn(touched.splits?.[index], 'trainerId') &&
                        getIn(errors.splits?.[index], 'trainerId')) ||
                      ''
                    }
                  >
                    <ApiAutocomplete
                      id={`trainer_${index}`}
                      placeholder="Trainer"
                      getMethod={getTrainers}
                      searchKey="name"
                      initialSelectedItem={split?.trainerName || ''}
                      renderOptionLabel={(record) =>
                        `${record.firstName} ${record.lastName}`
                      }
                      onSelect={(record: any) => {
                        setFieldValue(
                          `splits[${index}].trainerId`,
                          record?.itemId || '',
                        )
                      }}
                      filterOption={(trainer: any) =>
                        !map(arrAssignments, 'trainerId').includes(trainer.id)
                      }
                      {...getFieldProps(`splits.${index}.trainerId`)}
                    />
                  </FormPart>
                  <FormPart
                    label="Split %"
                    forId={`commission_${index}`}
                    className={styles.fieldsetItem}
                    errorMsg={
                      (getIn(touched.splits?.[index], 'percentage') &&
                        getIn(errors.splits?.[index], 'percentage')) ||
                      ''
                    }
                  >
                    <Input
                      type="number"
                      id={`commission_${index}`}
                      {...getFieldProps(`splits.${index}.percentage`)}
                      min={0}
                    />
                  </FormPart>
                  <div className={styles.addRemoveButtons}>
                    {(arrAssignments.length > 1 || index !== 0) && (
                      <Icon.Line
                        name="icon-minus"
                        title="remove"
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    )}
                    {arrAssignments.length - 1 === index &&
                      arrAssignments.length < 5 && (
                        <Icon.Monochrome
                          name="plus"
                          title="add new"
                          onClick={() =>
                            arrayHelpers.push({
                              ...defaultAssignment,
                            })
                          }
                        />
                      )}
                  </div>
                </Fieldset>
              ))
            }
          />
        </FormikProvider>
        <Paragraph size="xsmall" weight="book" className={styles.errorMessage}>
          {errors.totalAssignedPercent}
        </Paragraph>
        <div className={styles.buttons}>
          <Button
            emphasis="primary"
            className={styles.submitButton}
            type="submit"
            disabled={isSubmitting}
            theme="trainer"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default OrgTrainerSplit
