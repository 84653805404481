import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { FreezeFeeForm } from './components/freeze-fee-form'
import { TrainerTipForm } from './components/trainer-tip-form'
import styles from './new-transaction.module.scss'
import useWithNewTransaction from './use-with-new-transaction'

export enum NewTransactionType {
  FreezeFee = 'Freeze Fee',
  TrainerTip = 'Trainer Tip',
}

const NewTransaction = () => {
  const {
    headingText,
    newTransactionType,
    newTransactionTypeButtons,
    handleBackClick,
  } = useWithNewTransaction()

  const formComponents = {
    [NewTransactionType.TrainerTip]: TrainerTipForm,
    [NewTransactionType.FreezeFee]: FreezeFeeForm,
  }
  let FormComponent = null

  if (newTransactionType) {
    FormComponent = formComponents[newTransactionType]
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Heading className={styles.title} type="h3">
          <span className={styles.carrot} onClick={handleBackClick}>
            <Icon.Line name={'icon-angle-left-b'} />
          </span>
          {headingText}
        </Heading>
      </header>

      <div className={styles.transactionHeader}>
        <Heading className={styles.title} type="h4">
          Select a transaction type
        </Heading>
      </div>

      <div className={styles.newTransactionTypeButtonsContainer}>
        {newTransactionTypeButtons.map((button, index) => (
          <Button key={index} {...button} />
        ))}
      </div>
      {FormComponent && <FormComponent transactionType={newTransactionType!} />}
    </div>
  )
}

export default NewTransaction
