import { AppThunk } from 'app/redux/store'
import { getTrainerById } from 'services/api/trainer'
import { clearActiveTrainer, setActiveTrainer } from './active-trainer-reducer'

export const loadTrainerDetails =
  (trainerId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await getTrainerById(trainerId)
      dispatch(setActiveTrainer(response))
    } catch (errorMessage) {
      dispatch(clearActiveTrainer())
    }
  }
