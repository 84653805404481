import moment from 'moment-timezone'

export const currentLocalDateInISO8601 = new Date().toISOString().split('T')[0]

export const displayDate = (date?: string | null) =>
  date ? moment(date).format('MM/DD/YYYY') : ''

export const displayTime = (date?: string | null) =>
  date ? moment(date).format('HH:mm:ss A') : ''

export const displayDateTime = (date?: string | null) =>
  date ? moment(date).format('MM/DD/YYYY hh:mm:ss A') : ''

export const displayUTCDateTime = (date?: string | null) =>
  date ? moment(date).utc().format('MM/DD/YYYY hh:mm:ss A') : ''

export const stringToDate = (date?: string | null) =>
  date ? moment(date).format('YYYY-MM-DD') : ''

export const currentDate = moment().format('YYYY-MM-DD')

export const tomorrowDate = moment().add(1, 'days').format('YYYY-MM-DD')

export const dateTimeStringToLocalDateSameZone = (dateTime?: string) => {
  if (!dateTime) return ''

  return moment.parseZone(dateTime).format('L')
}

export const dateTimeStringToLocalTime = (date?: string) => {
  if (!date) return ''
  return moment(date).format('LT')
}

export const dateTimeStringToDayOfWeek = (date?: string) => {
  if (!date) return ''
  return moment(date).format('ddd')
}

export const dateTimeStringToLocalDate = (date?: string) => {
  if (!date) return ''
  return moment(date).format('L')
}

export const dateTimeStringToLocalTimeWithZone = (
  date?: string,
  timezone?: string,
) => {
  if (!date || !timezone) return ''
  return moment.tz(date, timezone).format('LT')
}

export const isPastFromNow = (
  dateTime: string,
  timezone?: string,
): boolean | undefined => {
  if (!dateTime || !timezone) return undefined
  return moment.tz(dateTime, timezone).isBefore(moment()) ?? undefined
}

export const dateTimeStringToDayOfWeekWithZone = (
  date?: string,
  timezone?: string,
) => {
  if (!date || !timezone) return ''
  return moment.tz(date, timezone).format('ddd')
}

export const dateTimeStringToLocalDateWithZone = (
  date?: string,
  timezone?: string,
) => {
  if (!date || !timezone) return ''
  return moment.tz(date, timezone).format('L')
}

export const currentUtcDateTimeString = moment().utc().format()
export const currentDateTimeString = moment().format()
