import styles from './photo-attachment.module.scss'

type AttachmentPhotoProps = {
  attachment: File
}

const PhotoAttachment = ({ attachment }: AttachmentPhotoProps) => {
  const photoUrl = URL.createObjectURL(attachment)
  return <img src={photoUrl} alt="attachment" className={styles.photo} />
}

export default PhotoAttachment
