import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { FormInput } from 'components/form-ui/input'
import { NewTransactionType } from '../../new-transaction'
import { PaymentAmountInput } from '../components/payment-amount-input'
import { PaymentCard } from '../components/payment-card'
import { SubmitButton } from '../components/submit-button'
import styles from './freeze-fee-form.module.scss'
import { useFreezeFeeForm } from '.'

export type FreezeFeeFormProps = {
  transactionType: NewTransactionType
}

const FreezeFeeForm = ({ transactionType }: FreezeFeeFormProps) => {
  const {
    descriptionProps,
    descriptionFormId,
    descriptionFormLabel,
    paymentAmountInputProps,
    paymentMethodProps,
    submitButtonProps,
  } = useFreezeFeeForm({
    transactionType,
  })

  return (
    <form>
      <div className={styles.formSection}>
        <FormPart label={descriptionFormLabel} forId={descriptionFormId}>
          <FormInput {...descriptionProps} />
        </FormPart>

        <PaymentAmountInput {...paymentAmountInputProps} />

        <PaymentCard {...paymentMethodProps} />

        <SubmitButton {...submitButtonProps} />
      </div>
    </form>
  )
}

export default FreezeFeeForm
