import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchWorkouts } from '@campgladiator/cg-common.api.workouts'
import { ButtonProps } from '@campgladiator/cgui-core.atoms.button'
import { ApiAutocompleteProps } from 'components/form-ui/api-auto-complete/api-auto-complete'
import { getTrainers } from 'services/api/trainer'
import type { WorkoutDTO } from '@campgladiator/cg-common.types.types'

const useViewOnDemandVideos = () => {
  const [selectedTrainer, setSelectedTrainer] = useState<string>('')
  const [onDemandVideos, setOnDemandVideos] = useState<WorkoutDTO[]>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  const size = 20
  const isSearchApplied = selectedTrainer.trim().length > 0

  const searchOnDemandVideos = useCallback(
    async (page: number = 0) => {
      if (isSearchApplied) {
        setIsLoading(true)
        const { content: workouts } = await fetchWorkouts({
          trainerId: selectedTrainer,
          type: 'ONDEMAND',
          page,
          size,
        })

        if (page === 0) {
          setOnDemandVideos(workouts)
        } else {
          setOnDemandVideos((prevVideos) => [...prevVideos, ...workouts])
        }
        setHasMore(workouts.length > 0 && workouts.length === size)
        setIsLoading(false)
      } else {
        setOnDemandVideos([])
        setHasMore(false)
      }
    },
    [isSearchApplied, selectedTrainer],
  )

  const loadMoreWorkouts = () => {
    const nextPage = currentPage + 1
    setCurrentPage(nextPage)
    searchOnDemandVideos(nextPage)
  }

  const editButtonProps: ButtonProps = {
    emphasis: 'secondary',
    icon: { name: 'icon-edit', type: 'solid' },
    size: 'large',
    variation: 'text',
    onClick: () => {},
    theme: 'trainer',
  }

  const deleteButtonProps: ButtonProps = {
    emphasis: 'secondary',
    icon: { name: 'icon-trash', type: 'solid' },
    size: 'large',
    variation: 'text',
    onClick: () => {},
    theme: 'trainer',
  }

  const loadMoreButtonProps: ButtonProps = {
    children: isLoading ? 'Loading...' : 'Load More',
    disabled: isLoading || !hasMore,
    emphasis: 'secondary',
    size: 'large',
    theme: 'trainer',
    onClick: loadMoreWorkouts,
  }

  const pageTitleWithSearchInputAndNewButtonProps = {
    title: 'OnDemand Videos',
    placeholder: '',
    newButtonText: 'New OnDemand Video',
    showSearchInput: false,
    onSearch: () => {},
    onNewButtonClick: () => navigate('/programs/manage-ondemand-videos/create'),
  }

  const trainerSearchAutoCompleteProps: ApiAutocompleteProps = {
    id: 'trainer_search',
    name: 'trainer',
    placeholder: 'Search by Trainer Name',
    getMethod: getTrainers,
    searchKey: 'name',
    renderOptionLabel: (record) => `${record.firstName} ${record.lastName}`,
    onSelect: (record: any) => {
      setSelectedTrainer(record.itemId || '')
    },
    value: selectedTrainer,
  }

  useEffect(() => {
    setCurrentPage(0)
    searchOnDemandVideos(0)
  }, [selectedTrainer, searchOnDemandVideos])

  return {
    editButtonProps,
    deleteButtonProps,
    hasMore,
    loadMoreButtonProps,
    isLoading,
    onDemandVideos,
    pageTitleWithSearchInputAndNewButtonProps,
    trainerSearchAutoCompleteProps,
  }
}

export default useViewOnDemandVideos
