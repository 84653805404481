import { OrganizationDTO } from '@campgladiator/cg-common.types.types'
import { OrganizationForm } from '../organization-form'
import styles from './edit-organization.module.scss'
import useEditOrganization from './hooks/use-edit-organization'

export type EditOrganizationsProps = {
  organization: Partial<OrganizationDTO>
}

const EditOrganizations = ({ organization }: EditOrganizationsProps) => {
  const { couponCodeDropdownItems, isSubmitting, handleSubmit } =
    useEditOrganization({ organization })

  return (
    <div className={styles.container}>
      <OrganizationForm
        organization={organization}
        couponCodeDropdownItems={couponCodeDropdownItems}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default EditOrganizations
