import { UserDTO } from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import ApiAutoComplete from 'components/form-ui/api-auto-complete'
import { Autocomplete } from 'components/form-ui/auto-complete'
import { Dropdown } from 'components/form-ui/dropdown'
import { lowerCase } from 'lodash'
import { getUsersByFilter } from 'services/api/user'
import StateDropDownItems from 'services/data/address'
import GenderDropdownItems from 'services/data/gender'
import ReferralTypeDropdownItems from 'services/data/referralType'
import RoleDropdownItems from 'services/data/role'
import ShirtSizeDropdownItems from 'services/data/shirtSize'
import SearchDropdownItems from 'services/data/user'
import { UserFormProps } from 'types/user'
import { currentLocalDateInISO8601 as today } from 'utils/date'
import useUserFrom from './hooks/use-user-form'
import styles from './user-form.module.scss'

export interface IUserFormProps {
  isEdit?: boolean | false
  isSubmitting: boolean
  onSubmit: (values: UserFormProps) => void
  user?: Partial<UserDTO>
}

const UserForm = ({ isEdit, isSubmitting, user, onSubmit }: IUserFormProps) => {
  const {
    errors,
    isConfirmPasswordVisible,
    isEmailInputDisabled,
    isPasswordVisible,
    orgName,
    referralName,
    searchType,
    touched,
    getFieldProps,
    getPasswordInputType,
    getVisibilityIcon,
    handleSetOrganizationId,
    handleSubmit,
    onConfirmPasswordClick,
    onPasswordClick,
    setFieldValue,
    setSearchType,
  } = useUserFrom({ user, isEdit, isSubmitting, onSubmit })

  return (
    <div className={styles.formContainer}>
      <Heading className={styles.title} type="h4">
        Basic Information
      </Heading>
      <form className={styles.formSection} onSubmit={handleSubmit}>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="First name*"
            forId="firstName"
            className={styles.fieldsetItem}
            errorMsg={(touched.firstName && errors.firstName) || undefined}
          >
            <Input
              id="firstName"
              placeholder="First Name"
              {...getFieldProps('firstName')}
            />
          </FormPart>
          <FormPart
            label="Last name*"
            forId="lastName"
            className={styles.fieldsetItem}
            errorMsg={(touched.lastName && errors.lastName) || undefined}
          >
            <Input
              id="lastName"
              placeholder="Last Name"
              {...getFieldProps('lastName')}
            />
          </FormPart>
        </Fieldset>
        <FormPart
          label="Email*"
          forId="email"
          className={styles.fieldsetItem}
          errorMsg={(touched.email && errors.email) || undefined}
        >
          <Input
            id="email"
            placeholder="Email"
            {...getFieldProps('email')}
            disabled={isEmailInputDisabled}
          />
        </FormPart>
        {!isEdit && (
          <Fieldset className={styles.fieldset}>
            <FormPart
              label="Password*"
              forId="password"
              className={styles.fieldsetItem}
              errorMsg={(touched.password && errors.password) || undefined}
            >
              <Input
                id="password"
                type={getPasswordInputType(isPasswordVisible)}
                placeholder="Password"
                icon={getVisibilityIcon(isPasswordVisible)}
                onIconClick={onPasswordClick}
                {...getFieldProps('password')}
              />
            </FormPart>
            <FormPart
              label="Confirm Password*"
              forId="confirmPassword"
              className={styles.fieldsetItem}
              errorMsg={
                (touched.confirmPassword && errors.confirmPassword) || undefined
              }
            >
              <Input
                id="confirmPassword"
                type={getPasswordInputType(isConfirmPasswordVisible)}
                placeholder="Confirm Password"
                icon={getVisibilityIcon(isConfirmPasswordVisible)}
                onIconClick={onConfirmPasswordClick}
                {...getFieldProps('confirmPassword')}
              />
            </FormPart>
          </Fieldset>
        )}
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Phone Number*"
            forId="phone"
            className={styles.fieldsetItem}
            errorMsg={(touched.phone && errors.phone) || undefined}
          >
            <Input
              id="phone"
              type="tel"
              placeholder="+1 (555) 555-5555"
              {...getFieldProps('phone')}
            />
          </FormPart>
          <FormPart
            label="Date of Birth"
            forId="dateOfBirth"
            className={styles.fieldsetItem}
          >
            <Input
              id="dateOfBirth"
              type="date"
              max={today}
              placeholder="MM/DD/YYYY"
              {...getFieldProps('dateOfBirth')}
            />
          </FormPart>
        </Fieldset>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Gender"
            forId="gender"
            className={styles.fieldsetItem}
          >
            <Dropdown
              id="gender-dropdown"
              label="Choose Gender"
              options={GenderDropdownItems}
              {...getFieldProps('gender')}
            />
          </FormPart>
          <FormPart
            label="T-Shirt Size"
            forId="shirtSize"
            className={styles.fieldsetItem}
          >
            <Dropdown
              id="shirtSize-dropdown"
              label="Choose T-Shirt Size"
              options={ShirtSizeDropdownItems}
              {...getFieldProps('shirtSize')}
            />
          </FormPart>
        </Fieldset>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Referral Type"
            forId="referralType"
            className={styles.fieldsetItem}
          >
            <Dropdown
              id="referralType-dropdown"
              label="Choose Referral Type"
              options={ReferralTypeDropdownItems}
              {...getFieldProps('referralType')}
            />
          </FormPart>
          <FormPart label="Role" forId="role" className={styles.fieldsetItem}>
            <Dropdown
              id="role-dropdown"
              label="Choose Role"
              options={RoleDropdownItems}
              {...getFieldProps('role')}
            />
          </FormPart>
        </Fieldset>
        <FormPart
          label="Organization"
          forId="organizationId"
          className={styles.fieldsetItem}
        >
          <Autocomplete
            id="organizationId"
            placeholder="Organization"
            options={[]}
            setRecord={handleSetOrganizationId}
            value={orgName}
            name="organizationId"
            optionType="organization"
          />
        </FormPart>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Search for a referral by"
            forId="searchBy"
            className={styles.fieldsetItem}
          >
            <Dropdown
              className={styles.searchType}
              id="user-referral-dropdown"
              value={searchType}
              options={SearchDropdownItems}
              onChange={(e) => setSearchType(e.currentTarget.value)}
            />
          </FormPart>
          <FormPart
            label={searchType}
            forId="referralId"
            className={styles.fieldsetItem}
          >
            <ApiAutoComplete
              id="referralId"
              placeholder={searchType}
              initialSelectedItem={referralName}
              getMethod={getUsersByFilter}
              searchKey={
                searchType === 'Phone' ? 'phoneNumber' : lowerCase(searchType)
              }
              numericSearch={searchType === 'Phone' ? true : false}
              renderOptionLabel={(record) =>
                `${record.firstName} ${record.lastName}`
              }
              filterOption={(record: any) => record.id !== user?.id}
              onSelect={(record: any) =>
                setFieldValue('referralId', record?.itemId || '')
              }
              {...getFieldProps('referralId')}
            />
          </FormPart>
        </Fieldset>
        <Heading className={styles.locationTitle} type="h4">
          Location Information
        </Heading>
        <FormPart
          label="Address"
          forId="address1"
          className={styles.fieldsetItem}
        >
          <Input
            id="address1"
            placeholder="Address"
            {...getFieldProps('address1')}
          />
        </FormPart>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Apt, Suite, Etc"
            forId="address2"
            className={styles.fieldsetItem}
          >
            <Input
              id="address2"
              placeholder="Apt, Suite, Etc"
              {...getFieldProps('address2')}
            />
          </FormPart>
          <FormPart
            label="Country"
            forId="country"
            className={styles.fieldsetItem}
          >
            <Input
              id="country"
              placeholder="Country"
              {...getFieldProps('country')}
            />
          </FormPart>
        </Fieldset>
        <FormPart label="City" forId="city" className={styles.fieldsetItem}>
          <Input id="city" placeholder="City" {...getFieldProps('city')} />
        </FormPart>
        <Fieldset className={styles.fieldset}>
          <FormPart label="State" forId="state" className={styles.fieldsetItem}>
            <Dropdown
              label="Choose a state"
              options={StateDropDownItems}
              {...getFieldProps('state')}
            />
          </FormPart>
          <FormPart
            label="Zip Code"
            forId="zipCode"
            className={styles.fieldsetItem}
            errorMsg={(touched.zipCode && errors.zipCode) || undefined}
          >
            <Input
              id="zipCode"
              placeholder="99999"
              maxLength={5}
              minLength={5}
              type="tel"
              {...getFieldProps('zipCode')}
            />
          </FormPart>
        </Fieldset>
        <div className={styles.buttons}>
          <Button
            emphasis="primary"
            type="submit"
            disabled={isSubmitting}
            theme="trainer"
          >
            {!isEdit ? 'Submit new user account' : 'Save'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default UserForm
