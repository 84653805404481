import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import { Address } from 'types/address'

const baseUrl = configuration.apiGatewayUrl

export const postOutdoorAddress = async (data: Address | undefined) => {
  if (!data) return
  try {
    const endpoint = '/library/v1/addresses'
    const method = 'POST' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(data),
    })
    const result = await response.json()

    return response.ok ? (result as any) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-address-library',
      attributeName: 'post-address',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putOutdoorAddress = async (data: Address) => {
  try {
    const endpoint = `/library/v1/addresses/${data.id}`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(data),
    })
    const result = await response.json()

    return response.ok ? (result as any) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-address-library',
      attributeName: 'put-address',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
