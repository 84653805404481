import { TagDTO } from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { request } from 'app/networking/fetch'
import configuration from 'config/configuration'

const baseUrl = configuration.apiGatewayUrl

export const getTags = async (isDefault?: boolean): Promise<TagDTO[]> => {
  const endpoint = isDefault ? '/library/v1/tags/default' : '/library/v1/tags'
  const method = 'GET'
  try {
    const response = await request({
      baseUrl,
      endpoint,
      method,
    })

    const result = await response.json()

    return response.ok
      ? result.content
      : Promise.reject(new Error(result.message))
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-tag-by-type-error',
      attributeName: 'get-tag-by-type',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}
