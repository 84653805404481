import { ReactElement, useState } from 'react'
import classNames from 'classnames'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import styles from './radio-button.module.scss'

export type RadioButtonOptionItem = {
  label: string
  value: string
}

type RadioButtonProps = {
  initialChecked?: string | null
  className?: string
  icon?: ReactElement
  options: RadioButtonOptionItem[]
  onChange: (value: string) => void
}

const RadioButton = ({
  initialChecked,
  className,
  icon,
  options,
  onChange,
}: RadioButtonProps) => {
  const [checked, setChecked] = useState<string | null>(
    initialChecked ? initialChecked : null,
  )

  const onClick = (item: RadioButtonOptionItem) => {
    setChecked(item.value)
    onChange(item.value)
  }

  return (
    <>
      {options.map((option, index) => (
        <Card
          className={classNames(styles.radioCard, className)}
          onClick={() => onClick(option)}
        >
          <div className={styles.labelContent}>
            <Input
              className={styles.input}
              id={`radio-card-${index}`}
              name="radio-card"
              type="radio"
              value={option.value}
              checked={checked === option.value}
            />
            <div className={styles.labelContainer}>
              {icon}
              <div className={styles.label}>
                <Paragraph>{option.label}</Paragraph>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </>
  )
}

export default RadioButton
