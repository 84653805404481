import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  Table,
  TRow,
  TData,
  THead,
} from '@campgladiator/cgui-core.organisms.table'
import { PageTitleWithSearchInputAndNewButton } from 'components/module/shared/page-title-search-input-new-button'
import { debounce } from 'lodash'
import { getAllPrograms } from 'services/api/program'
import { ProgramDetails } from 'types/program.d'
import styles from './view.module.scss'

const ViewPrograms = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [programs, setPrograms] = useState<ProgramDetails[]>([])
  const navigate = useNavigate()
  const isSearchApplied = searchTerm.length > 2

  const searchPrograms = useCallback(async () => {
    if (isSearchApplied) {
      getAllPrograms(searchTerm, true).then((program) => {
        if (program) {
          setPrograms(program)
        }
      })
    } else {
      setPrograms([])
    }
  }, [isSearchApplied, searchTerm])

  const delayedSearchQuery = useMemo(
    () => debounce(searchPrograms, 50),
    [searchPrograms],
  )

  const getProgramTypeDescription = (program: ProgramDetails) => {
    const { subType } = program.productDetailSummary
    return subType === 'IN_PERSON' ? 'OUTDOOR' : 'ONLINE'
  }

  useEffect(() => {
    delayedSearchQuery()
    return delayedSearchQuery.cancel
  }, [searchTerm, delayedSearchQuery])

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  return (
    <div className={styles.page}>
      <PageTitleWithSearchInputAndNewButton
        title="Programs"
        placeholder="Search for a program"
        newButtonText="New Program"
        showSearchInput={true}
        onSearch={handleSearch}
        onNewButtonClick={() => navigate('/programs/manage-programs/create')}
      />

      <div className={styles.tableWrapper}>
        <Table.Manual>
          <thead>
            <TRow>
              <THead>
                <Heading type="h6">Trainer</Heading>
              </THead>
              <THead>
                <Heading type="h6">Product</Heading>
              </THead>
              <THead>
                <Heading type="h6">Type</Heading>
              </THead>
              <THead>
                <Heading type="h6">Start Date</Heading>
              </THead>
              <THead>
                <Heading type="h6">End Date</Heading>
              </THead>
              <THead>
                <Heading type="h6">Timezone</Heading>
              </THead>
              <THead>
                <Heading type="h6">Edit</Heading>
              </THead>
            </TRow>
          </thead>
          <tbody>
            {!programs.length && (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    No programs to display
                  </Paragraph>
                </TData>
              </TRow>
            )}

            {!!programs.length &&
              programs.map((program) => (
                <TRow key={program.id}>
                  <TData className={styles.tableData}>
                    <Paragraph size="small" weight="book">
                      {`${program.trainerSummary?.firstName} ${program.trainerSummary?.lastName}`}
                    </Paragraph>
                  </TData>
                  <TData className={styles.tableData}>
                    <Paragraph size="small" weight="book">
                      {program.productDetailSummary?.title || '-'}
                    </Paragraph>
                  </TData>
                  <TData className={styles.tableData}>
                    <Paragraph size="small" weight="book">
                      {getProgramTypeDescription(program)}
                    </Paragraph>
                  </TData>
                  <TData className={styles.tableData}>
                    <Paragraph size="small" weight="book">
                      {program.startDate}
                    </Paragraph>
                  </TData>
                  <TData className={styles.tableData}>
                    <Paragraph size="small" weight="book">
                      {program.endDate || '-'}
                    </Paragraph>
                  </TData>
                  <TData className={styles.tableData}>
                    <Paragraph size="small" weight="book">
                      {program.timezone}
                    </Paragraph>
                  </TData>
                  <TData className={styles.tableData}>
                    <Paragraph size="small" weight="book">
                      <Button
                        emphasis="secondary"
                        icon={{ name: 'icon-edit', type: 'solid' }}
                        size="large"
                        variation="text"
                        onClick={() =>
                          navigate(`/programs/manage-programs/${program.id!}`)
                        }
                        theme="trainer"
                      />
                    </Paragraph>
                  </TData>
                </TRow>
              ))}
          </tbody>
        </Table.Manual>
      </div>
    </div>
  )
}

export default ViewPrograms
