import { PaymentMethodDTO } from '@campgladiator/cg-common.types.types'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { RadioButton } from '@campgladiator/cgui-core.atoms.radio-button'
import { Label, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { capitalize } from 'lodash'
import styles from './payment-card-item.module.scss'

export type PaymentCardItemProps = {
  card: PaymentMethodDTO
  selected: boolean
  onSelected: (id: string) => void
}

const PaymentCardItem = ({
  card,
  selected,
  onSelected,
}: PaymentCardItemProps) => {
  const hasCardDetails = card?.brand && card?.last4

  return (
    <Card flat className={styles.item}>
      <div className={styles.content}>
        <RadioButton
          checked={selected}
          name="paymentCard"
          onValueChange={() => onSelected(card.id)}
          onChange={() => onSelected(card.id)}
          options={[{ value: card.id, label: '' }]}
        />

        <div className={styles.icon}>
          <div>
            <Icon.Line name="icon-credit-card" className={styles.cardIcon} />
          </div>
          {card?.default && (
            <Label size="small" className={styles.default}>
              DEFAULT
            </Label>
          )}
        </div>

        <div className={styles.detail}>
          {hasCardDetails && (
            <div className={styles.info}>
              <Paragraph className={styles.label}>
                {capitalize(card?.brand || '')} card ending {card?.last4 || ''}
              </Paragraph>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}
export default PaymentCardItem
