import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { Dropdown } from 'components/form-ui/dropdown'
import { FieldArray, FieldConfig, FieldInputProps } from 'formik'
import styles from '../trainer-bios.module.scss'

type ArrayFieldSectionProps<T> = {
  dataArray: T
  getFieldProps: (
    nameOrOptions: string | FieldConfig<any>,
  ) => FieldInputProps<any>
  label: string
  name: string
  drowpDownOptions: { value: string; label: string }[]
}

const ArrayDropDownFormPart = <T extends any[]>({
  dataArray,
  getFieldProps,
  label,
  name,
  drowpDownOptions,
}: ArrayFieldSectionProps<T>) => {
  const showRemoveIcon = (arrItems: any[], index: number) =>
    arrItems.length > 1 || index !== 0
  const showAddIcon = (arrItems: any[], index: number) =>
    arrItems.length - 1 === index && arrItems.length < drowpDownOptions.length

  return (
    <FormPart label={label} forId={name}>
      <FieldArray
        name={name}
        render={(arrayHelpers) =>
          dataArray?.map((_, index, arrItems) => (
            <div className={styles.row}>
              <Dropdown
                id={`${name}.${index}`}
                label={label}
                className={styles.dropDown}
                options={drowpDownOptions}
                {...getFieldProps(`${name}.${index}`)}
              />
              {showRemoveIcon(arrItems, index) && (
                <Icon.Line
                  name="icon-minus"
                  title="remove"
                  onClick={() => arrayHelpers.remove(index)}
                />
              )}
              {showAddIcon(arrItems, index) && (
                <Icon.Monochrome
                  name="plus"
                  title="add new"
                  onClick={() => arrayHelpers.push('')}
                />
              )}
            </div>
          ))
        }
      />
    </FormPart>
  )
}

export default ArrayDropDownFormPart
