import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { UserDTO } from '@campgladiator/cg-common.types.types'
import { Avatar } from '@campgladiator/cgui-core.atoms.avatar'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { useFetchTrainerByUserId } from 'app/hooks/useFetchTrainerByUserId'
import { isEmpty } from 'lodash'
import formatPhoneNumber from 'utils/format-phone-number'
import { UserMembershipInfo } from '../user-membership-info'
import styles from './user-detail-side-panel.module.scss'

export type UserDetailSidePanelProps = {
  totalCheckins?: number
  user: UserDTO
}

const UserDetailSidePanel = ({
  user,
  totalCheckins,
}: UserDetailSidePanelProps) => {
  const navigate = useNavigate()
  const [image, setUserImage] = useState<string | undefined>()
  const trainer = useFetchTrainerByUserId(user?.id!)
  const isTrainerButtonAllowed =
    !isEmpty(trainer) &&
    (trainer.archived == null || trainer.archived > new Date().toISOString())

  useEffect(() => {
    if (user) setUserImage(user.imageURL)
  }, [user])

  return (
    user && (
      <div className={styles.panelContainer}>
        <div className={styles.imgContainer}>
          {image ? (
            <Avatar
              alt="User's picture"
              size="170px"
              src={image}
              onError={() => setUserImage('')}
            />
          ) : (
            <Avatar size="170px" />
          )}
        </div>
        <div className={styles.userName}>
          <Paragraph weight="bold" size="xlarge">
            {user.firstName} {user.lastName}
          </Paragraph>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.infoItem}>
            <Icon.Monochrome
              className={styles.infoIcon}
              name={'envelope'}
              emphasis="gladiator"
            />
            <Paragraph weight="book" size="small">
              {user.email}
            </Paragraph>
          </div>
          <div className={styles.infoItem}>
            <Icon.Monochrome
              className={styles.infoIcon}
              name={'phone-volume'}
              emphasis="gladiator"
            />
            <Paragraph weight="book" size="small">
              {formatPhoneNumber(user.phone)}
            </Paragraph>
          </div>

          <div className={styles.infoItem}>
            <Icon.Solid
              className={classNames(styles.infoIcon, styles.iconCheck)}
              name="icon-check"
            />
            <Paragraph weight="book" size="small">
              {totalCheckins} Checkins
            </Paragraph>
          </div>

          <div className={styles.trainerButton}>
            {isTrainerButtonAllowed ? (
              <Button
                emphasis="primary"
                variation="solid"
                onClick={() =>
                  navigate(`/trainers/${trainer.id}`, { state: { trainer } })
                }
                theme="trainer"
              >
                Trainer
              </Button>
            ) : (
              <Button
                emphasis="primary"
                variation="solid"
                onClick={() =>
                  navigate('/trainers/create', { state: { user } })
                }
                theme="trainer"
              >
                Turn into trainer
              </Button>
            )}
          </div>
        </div>
        {user.id && <UserMembershipInfo userId={user.id} />}
      </div>
    )
  )
}

export default UserDetailSidePanel
