import { Input, InputProps } from '@campgladiator/cgui-core.atoms.input'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { TextInputBinding } from 'app/hooks/useWithTextInput'
import styles from './input.module.scss'

export type FormInputProps = {
  binding: TextInputBinding
  errorMsg?: string
  icon?: InputProps['icon']
  id: string
  pattern?: string
  placeholder: string
  title: string
  value: string
}
const FormInput = ({
  binding,
  icon,
  id,
  errorMsg,
  pattern,
  placeholder,
  title,
}: FormInputProps) => (
  <FormPart forId={id} errorMsg={errorMsg}>
    <Input
      className={styles.input}
      id={id}
      name={id}
      pattern={pattern}
      placeholder={placeholder}
      required
      title={title}
      invalid={!!errorMsg}
      icon={icon}
      onIconClick={icon?.onClick}
      {...binding}
    />
  </FormPart>
)

export default FormInput
