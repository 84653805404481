import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import useProgramType from 'app/hooks/useProgramType'
import { activeProgramSelector } from 'app/redux/features/active-program/active-program-reducer'
import { ScheduleForm } from 'components/module/schedule'
import { getSchedulesById } from 'services/api/schedule'
import { ProductTypes } from 'types/product.d'
import { ProgramScheduleDTO } from 'types/schedule'
import {
  ChangePageFunction,
  SchedulePages,
} from '../schedule-container/schedule-container'
import styles from './schedule-edit.module.scss'

export type ScheduleEditProps = {
  changePage: ChangePageFunction
  scheduleId: string
}

const ScheduleEdit = ({ changePage, scheduleId }: ScheduleEditProps) => {
  const [schedule, setSchedule] = useState({} as ProgramScheduleDTO)

  const fetchSchedule = async (id: string) => {
    if (!id) {
      return
    }
    const schedule = await getSchedulesById(id)

    setSchedule(schedule)
  }

  const programDetails = useSelector(activeProgramSelector)
  const programType = useProgramType(programDetails)

  const changePageToList = () => {
    changePage(SchedulePages.List)
  }

  const onSuccess = () => fetchSchedule(scheduleId)

  useEffect(() => {
    fetchSchedule(scheduleId)
  }, [scheduleId])

  return (
    <div className={styles.tabContainer}>
      <header className={styles.title}>
        <div
          className={styles.backIconWrapper}
          onClick={() => changePageToList()}
        >
          <Icon.Line className={styles.backIcon} name={'icon-angle-left-b'} />
        </div>
        <Heading type="h3">Edit schedule</Heading>
      </header>
      <ScheduleForm
        programId={programDetails?.id || ''}
        programType={programType}
        productType={
          programDetails?.productDetailSummary.type || ProductTypes.BOOTCAMP
        }
        scheduleData={schedule}
        onAfterSubmitForm={onSuccess}
      />
    </div>
  )
}

export default ScheduleEdit
