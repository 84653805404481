import { useParams } from 'react-router-dom'
import { TrainerBioDTO } from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { TextArea } from '@campgladiator/cgui-core.atoms.textarea'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { useGrowlContext } from 'app/contexts/growl-context'
import {
  activeTrainerBioSelector,
  setActiveTrainer,
} from 'app/redux/features/active-trainer/active-trainer-reducer'
import { useAppDispatch, useAppSelector } from 'app/redux/store'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { putUpdateTrainerBios } from 'services/api/trainer'
import {
  TrainerBioLanguageDropdownItems,
  TrainerBioSocialMediaDropdownItems,
} from 'services/data/trainer'
import * as Yup from 'yup'
import {
  ArrayDropDownFormPart,
  ArrayFieldFormPart,
  ArraySocialMediaFormPart,
} from './components'
import styles from './trainer-bios.module.scss'

const defaultSMValues = { type: '', link: '' }

const validationSchema = Yup.object({
  socialMedias: Yup.array().of(
    Yup.object().shape(
      {
        type: Yup.string().when('link', {
          is: (val: any) => !isEmpty(val),
          then: (schema) => schema.required('Type is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
        link: Yup.string().when('type', {
          is: (val: any) => !isEmpty(val),
          then: (schema) =>
            schema.required('Link is required').url('Invalid url'),
          otherwise: (schema) => schema.notRequired(),
        }),
      },
      [
        ['type', 'link'],
        ['link', 'type'],
      ],
    ),
  ),
})

const TrainerBios = () => {
  const { id } = useParams()
  const trainerBio = useAppSelector(activeTrainerBioSelector)
  const { showGrowl } = useGrowlContext()
  const dispatch = useAppDispatch()

  const initialValues = {
    aboutMe: trainerBio?.aboutMe || '',
    trainerAttributes:
      trainerBio?.trainerAttributes && !isEmpty(trainerBio?.trainerAttributes)
        ? trainerBio?.trainerAttributes
        : [''],
    fitnessBackgrounds:
      trainerBio?.fitnessBackgrounds && !isEmpty(trainerBio?.fitnessBackgrounds)
        ? trainerBio?.fitnessBackgrounds
        : [''],
    languages:
      trainerBio?.languages && !isEmpty(trainerBio?.languages)
        ? trainerBio?.languages
        : [],
    socialMedias:
      trainerBio?.socialMedias && !isEmpty(trainerBio?.socialMedias)
        ? trainerBio?.socialMedias
        : [{ ...defaultSMValues }],
  }

  const onSubmit = async (values: Partial<TrainerBioDTO>) => {
    try {
      const args = {
        ...values,
        trainerAttributes: values.trainerAttributes?.filter(
          (value) => !isEmpty(value),
        ),
        languages: values.languages?.filter((value) => !isEmpty(value)),
        fitnessBackgrounds: values.fitnessBackgrounds?.filter(
          (value) => !isEmpty(value),
        ),
        socialMedias: values.socialMedias?.filter(
          (value) => !isEmpty(value.type),
        ),
      }
      const updatedTrainerDetails = await putUpdateTrainerBios(id!, args)
      showGrowl('SUCCESS', 'Trainer Bio updated')
      dispatch(setActiveTrainer(updatedTrainerDetails))
    } catch (error: any) {
      showGrowl('FAILED', error)
      console.log(error)
    }

    setSubmitting(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  })

  const {
    handleSubmit,
    getFieldProps,
    setSubmitting,
    values,
    isSubmitting,
    errors,
    touched,
  } = formik

  return (
    <div className={styles.container}>
      <Heading className={styles.title} type="h4">
        Trainer Bio
      </Heading>
      <form className={styles.formSection} onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <FormPart label="About Me" forId="aboutMe">
            <TextArea
              rows={4}
              id="aboutMe"
              placeholder="About Me"
              className={styles.textArea}
              {...getFieldProps('aboutMe')}
            />
          </FormPart>
          <ArrayFieldFormPart<String[]>
            dataArray={values.fitnessBackgrounds || []}
            getFieldProps={getFieldProps}
            label="Fitness Backgrounds"
            name="fitnessBackgrounds"
          />
          <ArrayFieldFormPart<String[]>
            dataArray={values.trainerAttributes || []}
            getFieldProps={getFieldProps}
            label="Trainer Attributes"
            name="trainerAttributes"
          />
          <ArrayDropDownFormPart
            dataArray={values.languages || []}
            getFieldProps={getFieldProps}
            label="Other Languages"
            name="languages"
            drowpDownOptions={TrainerBioLanguageDropdownItems}
          />
          <ArraySocialMediaFormPart
            dataArray={values.socialMedias}
            dropDownOptions={TrainerBioSocialMediaDropdownItems}
            defaultValues={defaultSMValues}
            label="Social Media"
            name="socialMedias"
            getFieldProps={getFieldProps}
            touched={touched}
            errors={errors}
          />
          <div className={styles.buttons}>
            <Button
              emphasis="primary"
              className={styles.submitButton}
              disabled={isSubmitting}
              type="submit"
              theme="trainer"
            >
              Submit
            </Button>
          </div>
        </FormikProvider>
      </form>
    </div>
  )
}

export default TrainerBios
