import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TrainerDTO } from '@campgladiator/cg-common.types.types'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { TrainerForm } from 'components/module/trainer/trainer-form'
import { convertUserIntoTrainer } from 'services/api/trainer'
import { uploadUserImage } from 'services/api/user'
import styles from './create.module.scss'

const Create = () => {
  const [isGrowlVisible, setIsGrowlVisible] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [growlContent, setGrowlContent] = useState('')
  const navigate = useNavigate()

  const setGrowlView = (isVisible: boolean, growlContent: string) => {
    setIsGrowlVisible(isVisible)
    setGrowlContent(growlContent)
  }

  const clearError = () => {
    setIsGrowlVisible(false)
  }

  const handleSubmit = async (
    values: Partial<TrainerDTO>,
    selectedFile?: File,
  ) => {
    setSubmitting(true)

    if (selectedFile) {
      const formData = new FormData()
      formData.append('image', selectedFile)
      uploadUserImage(values.userId!, formData)
        .then((response) => {
          if (response) {
            const data = { ...values, photo: response.imageURL }
            submitTrainerData(data)
          }
        })
        .catch((e) => {
          setGrowlView(true, e.message)
          setSubmitting(false)
        })
    } else {
      submitTrainerData(values)
    }
  }

  const submitTrainerData = (data: Partial<TrainerDTO>) => {
    convertUserIntoTrainer(data)
      .then((response) => {
        if (response) {
          setGrowlView(true, 'User saved successfully')
          setTimeout(
            () =>
              navigate(`/trainers/${response.id}`, {
                state: { trainer: response },
              }),
            1000,
          )
        }
      })
      .catch((e) => {
        setGrowlView(true, e)
        setSubmitting(false)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Card flat className={styles.container}>
      <div>
        <GrowlMessage isVisible={isGrowlVisible} onClick={clearError}>
          {growlContent}
        </GrowlMessage>

        <TrainerForm
          isSubmitting={isSubmitting}
          onSubmit={(values: Partial<TrainerDTO>, selectedFile?: File) => {
            handleSubmit(values, selectedFile)
          }}
        />
      </div>
    </Card>
  )
}

export default Create
