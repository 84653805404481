import { RefObject, useEffect } from 'react'

export const useOuterClick = (
  ref: RefObject<HTMLElement>,
  callback: { (): void },
) => {
  const handleClick = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export default useOuterClick
