import {
  BaseEnumerableResponse,
  ProductDetailDTO,
} from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import { ProductDetailsParams } from 'types/product'
import { objectToQueryString } from 'utils/url'

const baseUrl = configuration.apiGatewayUrl

export const getProductDetails = async (
  params?: ProductDetailsParams,
): Promise<BaseEnumerableResponse<ProductDetailDTO>> => {
  try {
    const endpoint = '/library/v1/product-details'
    const method = 'GET' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      params: objectToQueryString(params),
    })

    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-trainers-library',
      attributeName: 'get-trainers',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
