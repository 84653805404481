import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import { LocalePageData, Locale, RegionGroup } from 'types/locale'

const baseUrl = configuration.apiGatewayUrl

export const getTeamsByRegion = async (regionId: string) => {
  try {
    const endpoint = `/trainer/v1/regions/${regionId}/teams`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'get-teams-by-region-id',
      attributeName: 'get-teams-by-region-id',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
export const getActiveRegions = async (): Promise<LocalePageData> => {
  try {
    const endpoint = '/trainer/v1/regions'
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok
      ? (result as LocalePageData)
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'get-active-regions',
      attributeName: 'get-active-regions',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getAllRegions = async (): Promise<LocalePageData> => {
  try {
    const endpoint = '/trainer/v1/regions?includeInactive=true'
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok
      ? (result as LocalePageData)
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'get-all-regions',
      attributeName: 'get-all-regions',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getActiveTeams = async () => {
  try {
    const endpoint = '/trainer/v1/teams'
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-active-team',
      attributeName: 'get-active-team',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getAllTeams = async () => {
  try {
    const endpoint = 'trainer/v1/teams?includeInactive=true'
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok
      ? (result as LocalePageData)
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-all-teams',
      attributeName: 'get-all-teams',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getTeamById = async (teamId: string) => {
  try {
    const endpoint = `/trainer/v1/teams/${teamId}`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok ? (result as Locale) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-team-by-id',
      attributeName: 'get-team-by-id',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getRegionById = async (regionId: string) => {
  try {
    const endpoint = `/trainer/v1/regions/${regionId}`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok ? (result as Locale) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-region-by-id',
      attributeName: 'get-region-by-region-id',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getActiveRegionGroups = async (): Promise<LocalePageData> => {
  try {
    const endpoint = '/trainer/v1/region-groups?size=100'
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok
      ? (result as LocalePageData)
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'get-active-region-groups',
      attributeName: 'get-active-region-groups',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const postRegionGroup = async (regionGroup: RegionGroup) => {
  try {
    const endpoint = '/trainer/v1/region-groups'
    const method = 'POST' as HttpMethod
    const body = JSON.stringify(regionGroup)

    const response = await request({ baseUrl, endpoint, method, body })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'post-region-group',
      attributeName: 'post-region-group',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putRegionGroup = async (
  regionGroup: RegionGroup,
  regionGroupId: string,
) => {
  try {
    const endpoint = `/trainer/v1/region-groups/${regionGroupId}`
    const method = 'PUT' as HttpMethod
    const body = JSON.stringify(regionGroup)

    const response = await request({ baseUrl, endpoint, method, body })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'put-region-group',
      attributeName: 'put-region-group',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getRegionGroupById = async (regionGroupId: string) => {
  try {
    const endpoint = `/trainer/v1/region-groups/${regionGroupId}`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok
      ? (result as RegionGroup)
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'get-region-group-by-id',
      attributeName: 'get-region-group-by-id',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const CreateNewLocale = async (
  locale: Locale,
  type: string = 'REGION',
): Promise<Locale> => {
  const endpoint =
    type === 'REGION' ? '/trainer/v1/regions' : '/trainer/v1/teams'
  const method = 'POST'
  const body = JSON.stringify(locale)
  try {
    const response = await request({
      baseUrl,
      body,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok ? result : Promise.reject(new Error(result.message))
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-create-locale-error',
      attributeName: 'post-create-locale',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const updateLocale = async (
  args: Locale,
  localeId: string,
): Promise<Locale> => {
  const endpoint = `/trainer/v1/regions/${localeId}`
  const method = 'PUT'
  const body = JSON.stringify(args)

  try {
    const response = await request({
      baseUrl,
      body,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok ? result : Promise.reject(new Error(result.message))
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-update-locale-error',
      attributeName: 'post-update-locale',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}
