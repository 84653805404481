import { NoteManagement } from '@campgladiator/cg-common.ui.note-management'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { PageTitleWithSearchInputAndNewButton } from 'components/module/shared/page-title-search-input-new-button'
import styles from './notes.module.scss'
import { useWithNotes } from '.'

const Notes = () => {
  const {
    error,
    pageTitleProps,
    successMessage,
    useWithNoteManagementProps,
    clearErrors,
  } = useWithNotes()

  return (
    <>
      <GrowlMessage
        isVisible={!!error || !!successMessage}
        onClick={clearErrors}
      >
        {error || successMessage}
      </GrowlMessage>
      <PageTitleWithSearchInputAndNewButton {...pageTitleProps} />
      <div className={styles.tableContainer}>
        <NoteManagement {...useWithNoteManagementProps} />
      </div>
    </>
  )
}

export default Notes
