import React, { ChangeEvent } from 'react'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import styles from './ScheduleReassignSelectEffectiveDate.module.scss'

export type ScheduleReassignSelectEffectiveDateProps = {
  effectiveDate?: string
  isEffectiveDateValid: boolean
  onEffectiveDateChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const ScheduleReassignSelectEffectiveDate = ({
  effectiveDate,
  onEffectiveDateChange,
  isEffectiveDateValid,
}: ScheduleReassignSelectEffectiveDateProps) => (
  <div className={styles.container}>
    <div>
      <FormPart
        forId="effectiveDate"
        label="Start Date*"
        errorMsg={
          !isEffectiveDateValid
            ? 'Invalid date. Should be higher than today'
            : undefined
        }
      >
        <Input
          type="date"
          id="effectiveDate"
          name="effectiveDate"
          value={effectiveDate}
          onChange={onEffectiveDateChange}
        />
      </FormPart>
    </div>
  </div>
)

export default ScheduleReassignSelectEffectiveDate
