import { AppThunk } from 'app/redux/store'
import { getProductDetails } from 'services/api/product'
import { setProductList } from './products-list-reducer'

export const loadProductList = (): AppThunk => async (dispatch) => {
  try {
    const response = await getProductDetails()
    dispatch(setProductList(response.content))
  } catch (errorMessage) {
    console.error(errorMessage)
  }
}
