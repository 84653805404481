import { TrainerDTO } from '@campgladiator/cg-common.types.types'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import ApiAutocomplete from 'components/form-ui/api-auto-complete/api-auto-complete'
import { FieldArray, FormikProps, FormikProvider, getIn } from 'formik'
import { getTrainers } from 'services/api/trainer'
import { LocaleAssignment, RegionType } from 'types/locale'
import { Locale } from 'types/locale'
import useRPAssignmentSection from './hooks/use-rp-assignment-section'
import styles from './rp-assignment-section.module.scss'

export const defaultAssignment = {
  trainerId: '',
  trainerName: '',
  commissionPercentage: '',
}

export interface AssignmentHoldingForm extends Locale {
  assignments: LocaleAssignment[]
  totalAssignedPercent: number
}

export type RPAssignmentSectionProps = {
  formik: FormikProps<AssignmentHoldingForm>
  type?: RegionType
}

const RPAssignmentSection = ({ formik, type }: RPAssignmentSectionProps) => {
  const {
    errors,
    formTypeLabel,
    touched,
    values,
    filterOption,
    getFieldProps,
    setFieldValue,
  } = useRPAssignmentSection({
    formik,
    type,
  })

  return (
    <>
      <FormikProvider value={formik}>
        <FieldArray
          name="assignments"
          render={(arrayHelpers) =>
            values.assignments.map(
              (
                assignment: LocaleAssignment,
                index: number,
                arrAssignments: LocaleAssignment[],
              ) => (
                <Fieldset
                  key={`assignment_${index}`}
                  className={styles.fieldset}
                >
                  <FormPart
                    label={formTypeLabel}
                    forId={`RVP_${index}`}
                    className={styles.fieldsetItem}
                    errorMsg={
                      (getIn(touched.assignments?.[index], 'trainerId') &&
                        getIn(errors.assignments?.[index], 'trainerId')) ||
                      ''
                    }
                  >
                    <ApiAutocomplete
                      id={`trainerId_${index}`}
                      placeholder="Trainer"
                      getMethod={getTrainers}
                      searchKey="name"
                      initialSelectedItem={assignment?.trainerName || ''}
                      renderOptionLabel={(record) =>
                        `${record.firstName} ${record.lastName}`
                      }
                      onSelect={(record: any) => {
                        setFieldValue(
                          `assignments[${index}].trainerId`,
                          record?.itemId || '',
                        )
                      }}
                      filterOption={(trainer: TrainerDTO) =>
                        filterOption(trainer, type!)
                      }
                      {...getFieldProps(`assignments.${index}.trainerId`)}
                    />
                  </FormPart>
                  <FormPart
                    label="Commission %"
                    forId={`commission_${index}`}
                    className={styles.fieldsetItem}
                    errorMsg={
                      (getIn(
                        touched.assignments?.[index],
                        'commissionPercentage',
                      ) &&
                        getIn(
                          errors.assignments?.[index],
                          'commissionPercentage',
                        )) ||
                      ''
                    }
                  >
                    <Input
                      type="number"
                      id={`commission_${index}`}
                      {...getFieldProps(
                        `assignments.${index}.commissionPercentage`,
                      )}
                      min={0}
                    />
                  </FormPart>
                  <div className={styles.addRemoveButtons}>
                    {(arrAssignments.length > 1 || index !== 0) && (
                      <Icon.Line
                        name="icon-minus"
                        title="remove"
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    )}
                    {arrAssignments.length - 1 === index &&
                      arrAssignments.length < 5 && (
                        <Icon.Monochrome
                          name="plus"
                          title="add new"
                          onClick={() =>
                            arrayHelpers.push({
                              ...defaultAssignment,
                            })
                          }
                        />
                      )}
                  </div>
                </Fieldset>
              ),
            )
          }
        />
      </FormikProvider>
      <Paragraph size="xsmall" weight="book" className={styles.errorMessage}>
        {errors.totalAssignedPercent}
      </Paragraph>
    </>
  )
}

export default RPAssignmentSection
