import { CombinedState } from '@reduxjs/toolkit'
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'
import { AuthState } from 'app/auth'
import { logger } from 'redux-logger'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import { ActiveOrganizationState } from './features/active-organization/active-organization-reducer'
import { ActiveProgramType } from './features/active-program/active-program-reducer'
import { ActiveTrainerType } from './features/active-trainer/active-trainer-reducer'
import { ActiveUserType } from './features/active-user/active-user-reducer'
import { ProductListType } from './features/products-list/products-list-reducer'
import { SubscriptionType } from './features/subscription/subscription-reducer'
import { TeamType } from './features/team/team-reducer'

type DefaultMiddlewareAlias = CurriedGetDefaultMiddleware<
  CombinedState<{
    authNew: AuthState
    activeProgram: ActiveProgramType
    activeOrganization: ActiveOrganizationState
    activeUser: ActiveUserType
    activeTrainer: ActiveTrainerType
    productList: ProductListType
    subscription: SubscriptionType
    team: TeamType
  }>
>

export const configureMiddleware = (
  getDefaultMiddleware: DefaultMiddlewareAlias,
): any => {
  const isProduction = process.env.NODE_ENV === 'production'
  return isProduction
    ? nonLoggingMiddleware(getDefaultMiddleware)
    : loggingMiddleware(getDefaultMiddleware)
}

const nonLoggingMiddleware = (
  getDefaultMiddleware: DefaultMiddlewareAlias,
): any => getDefaultMiddleware(defaultMiddlewareConfig)

const loggingMiddleware = (getDefaultMiddleware: DefaultMiddlewareAlias): any =>
  getDefaultMiddleware(defaultMiddlewareConfig).concat(logger)

const defaultMiddlewareConfig = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
}
