import { useCallback, useEffect, useState } from 'react'
import { ListItemProps } from '@campgladiator/cgui-core.atoms.list-item'
import { ListMenuProps } from '@campgladiator/cgui-core.molecules.list-menu'
import {
  assignmentSchema,
  totalAssignedPercentSchema,
} from 'components/module/trainer/rp-assignment-section/hooks/use-rp-assignment-section'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { getActiveRegionGroups, getRegionGroupById } from 'services/api/locale'
import { Locale } from 'types/locale'
import * as Yup from 'yup'
import { defaultAssignment } from '../../trainer/rp-assignment-section/rp-assignment-section'
import { ILocaleFormProps } from '../locale-form'

type UseLocaleFormProps = Pick<ILocaleFormProps, 'localeData' | 'onSubmit'>

const validationSchema = () =>
  Yup.object({
    name: Yup.string().required('Region name is required'),
    description: Yup.string().required('Description is required'),
    parentId: Yup.string().required('Region Group is required'),
    assignments: assignmentSchema,
    totalAssignedPercent: totalAssignedPercentSchema,
  })

const useLocaleForm = ({ localeData, onSubmit }: UseLocaleFormProps) => {
  const [regionGroups, setRegionGroups] = useState<ListMenuProps['items']>([])
  const [groupName, setGroupName] = useState<string>('')

  const fetchGroupName = async (teamId: string) => {
    try {
      const response = await getRegionGroupById(teamId)
      setGroupName(response.name)
    } catch (error) {
      console.error(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      type: 'REGION',
      name: localeData ? localeData.name : '',
      description: localeData ? localeData.description : '',
      deleted: false,
      assignments:
        localeData?.assignments && !isEmpty(localeData.assignments)
          ? localeData.assignments
          : [{ ...defaultAssignment }],
      active: localeData ? localeData.active : true,
      parentId: localeData ? localeData.parentId : '',
    } as Locale & { totalAssignedPercent: number },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit({
        ...values,
        assignments: values.assignments
          .filter((it) => it.trainerId)
          .map(({ trainerId, commissionPercentage }) => ({
            trainerId,
            commissionPercentage,
          })),
      })
    },
  })

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    errors,
    touched,
    values,
  } = formik

  const getRegionGroups = useCallback(async () => {
    try {
      const response = await getActiveRegionGroups()
      if (response) {
        const regionGroups = response.content
          .filter((regionGroup) => !regionGroup.deleted)
          .map(({ id, name }) => ({
            itemId: id!,
            text: name,
            type: 'item',
          })) as ListMenuProps['items']
        setRegionGroups(regionGroups)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const handleSetRegionGroup = (record: ListItemProps) => {
    if (!record) {
      setFieldValue('parentId', '')
      setGroupName('')
      return
    }
    setFieldValue('parentId', record?.itemId || '')
    setGroupName(record?.text || '')
  }

  useEffect(() => {
    getRegionGroups()
  }, [getRegionGroups])

  useEffect(() => {
    if (localeData?.assignments && !isEmpty(localeData.assignments)) {
      setFieldValue('assignments', localeData.assignments)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (localeData?.parentId) {
      fetchGroupName(localeData.parentId)
    }
  }, [localeData?.parentId])

  return {
    errors,
    touched,
    values,
    regionGroups,
    groupName,
    formik,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleSetRegionGroup,
  }
}

export default useLocaleForm
