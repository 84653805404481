import { WorkoutScheduleDTO } from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import { ProgramScheduleDTO, Schedule } from 'types/schedule'

const baseUrl = configuration.apiGatewayUrl

export const postNewSchedule = async (
  schedule: Schedule,
): Promise<Schedule> => {
  const endpoint = '/library/v1/program-schedules'
  const method = 'POST'
  const body = JSON.stringify(schedule)
  try {
    const response = await request({
      baseUrl,
      body,
      endpoint,
      method,
    })

    const result = await response.json()

    return response.ok ? result : Promise.reject(new Error(result.message))
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-create-schedule-error',
      attributeName: 'post-create-schedule',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getSchedulesByProgramId = async (
  programId: string,
): Promise<ProgramScheduleDTO[]> => {
  const endpoint = `/library/v1/program-schedules?programId=${programId}`
  const method = 'GET'
  try {
    const response = await request({
      baseUrl,
      endpoint,
      method,
    })

    const result = await response.json()

    return response.ok
      ? result.content
      : Promise.reject(new Error(result.message))
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-schedule-by-program-id-error',
      attributeName: 'get-schedule-by-program-id',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getSchedulesById = async (
  id: string,
): Promise<ProgramScheduleDTO> => {
  const endpoint = `/library/v1/program-schedules/${id}`
  const method = 'GET'
  try {
    const response = await request({
      baseUrl,
      endpoint,
      method,
    })

    const result = await response.json()

    return response.ok ? result : Promise.reject(new Error(result.message))
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-schedule-by-id-error',
      attributeName: 'get-schedule-by-id',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putSchedule = async (schedule: Schedule): Promise<Schedule> => {
  const endpoint = `/library/v1/program-schedules/${schedule.id}`
  const method = 'PUT'
  const body = JSON.stringify(schedule)
  try {
    const response = await request({
      baseUrl,
      body,
      endpoint,
      method,
    })

    const result = await response.json()

    return response.ok ? result : Promise.reject(new Error(result.message))
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-put-schedule-error',
      attributeName: 'put-create-schedule',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getSchedulesByTrainerId = async (
  id: string,
): Promise<WorkoutScheduleDTO> => {
  const endpoint = `/trainer/v1/trainers/${id}/schedule`
  const method = 'GET'
  try {
    const response = await request({
      baseUrl,
      endpoint,
      method,
    })

    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-schedule-by-trainerId-error',
      attributeName: 'get-schedule-by-trainerId',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}
