import { SocialMediaLink } from '@campgladiator/cg-common.types.types'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { Dropdown } from 'components/form-ui/dropdown'
import {
  FieldArray,
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
  getIn,
} from 'formik'
import styles from '../trainer-bios.module.scss'

type SelectType = {
  link: string
  type: string
}

type DropdownType = {
  label: string
  value: string
}

type SocialMediaFormPartType = {
  dataArray?: SocialMediaLink[]
  defaultValues: SelectType
  dropDownOptions: DropdownType[]
  errors: FormikErrors<any>
  label: string
  getFieldProps: (
    nameOrOptions: string | FieldConfig<any>,
  ) => FieldInputProps<any>
  name: string
  touched: FormikTouched<any>
}

const ArraySocialMediaFormPart = ({
  dataArray,
  dropDownOptions,
  name,
  label,
  getFieldProps,
  touched,
  defaultValues,
  errors,
}: SocialMediaFormPartType) => {
  const showRemoveIcon = (arrItems: any[], index: number) =>
    arrItems.length > 1 || index !== 0
  const showAddIcon = (arrItems: any[], index: number) =>
    arrItems.length - 1 === index && arrItems.length < dropDownOptions.length

  return (
    <FormPart label={label} forId={name}>
      <FieldArray
        name={name}
        render={(arrayHelpers) =>
          dataArray?.map((value, index, arrItems) => {
            const options = dropDownOptions.filter(
              (item) =>
                !dataArray
                  .filter((it) => it.type !== value.type)
                  .map((it) => it.type)
                  .includes(item.value),
            )

            return (
              <div className={styles.row}>
                <Dropdown
                  id={`${name}.${index}`}
                  label="Select"
                  className={styles.dropDown}
                  options={options}
                  {...getFieldProps(`${name}.${index}.type`)}
                />
                <FormPart
                  label=""
                  forId={`${name}.${index}.link`}
                  errorMsg={
                    (getIn(
                      touched as FormikTouched<any>,
                      `${name}.${index}.link`,
                    ) &&
                      getIn(
                        errors as FormikErrors<any>,
                        `${name}.${index}.link`,
                      )) ||
                    ''
                  }
                >
                  <Input
                    id={`${name}.${index}.link`}
                    placeholder="Link"
                    {...getFieldProps(`${name}.${index}.link`)}
                  />
                </FormPart>
                {showRemoveIcon(arrItems, index) && (
                  <Icon.Line
                    name="icon-minus"
                    title="remove"
                    onClick={() => arrayHelpers.remove(index)}
                  />
                )}
                {showAddIcon(arrItems, index) && (
                  <Icon.Monochrome
                    name="plus"
                    title="add new"
                    onClick={() =>
                      arrayHelpers.push({
                        ...defaultValues,
                      })
                    }
                  />
                )}
              </div>
            )
          })
        }
      />
    </FormPart>
  )
}

export default ArraySocialMediaFormPart
