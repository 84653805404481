import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { RegionGroupForm } from 'components/module/region-group/region-group-form'
import styles from './create.module.scss'
import useCreate from './hooks/use-create'

const Create = () => {
  const {
    growlContent,
    isGrowlVisible,
    isSubmitting,
    clearError,
    handleSubmit,
  } = useCreate()
  return (
    <Card flat className={styles.container}>
      <header className={styles.header}>
        <Heading className={styles.title} type="h3">
          Create Region Group
        </Heading>
      </header>

      <div>
        <GrowlMessage
          autoClose={false}
          isVisible={isGrowlVisible}
          onClick={clearError}
        >
          {growlContent}
        </GrowlMessage>
        <RegionGroupForm
          onSubmit={(value) => {
            handleSubmit(value)
          }}
          isSubmitting={isSubmitting}
        />
      </div>
    </Card>
  )
}

export default Create
