import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/redux/store'
import { getAuthorizationByToken } from 'services/api/authentication'
import { AuthToken } from 'types/authentication'
import { setAuthToken } from 'utils/auth-token-storage'
import { resetError, login, authSelector } from '.'
import { KeanuUserRole } from '.'

type UseAuth = {
  redirectPathOnLoginSuccess: string
}

const useAuth = ({ redirectPathOnLoginSuccess }: UseAuth) => {
  const [userEmail, setUserEmail] = useState<string>('')
  const [hasAccessError, setHasAccessError] = useState<boolean>(false)

  const { isLoggedIn, isLoading, error, authToken } =
    useAppSelector(authSelector)

  const dispatch = useAppDispatch()

  useEffect(
    () => {
      !isLoading &&
        isLoggedIn &&
        authToken &&
        userEmail &&
        executeAuthProcess(authToken)
    },
    //eslint-disable-next-line
    [!isLoading, isLoggedIn, authToken, userEmail],
  )

  const hasUnknownError = error === 'unknownError'
  const isGrowlVisible = hasUnknownError || hasAccessError
  const growlContent = hasAccessError
    ? 'User does not have access to this portal.'
    : 'Sorry! This operation cannot be completed. Check your connection and try again.'

  const executeAuthProcess = async (authToken: AuthToken) => {
    const claims = ['HQ', 'ADMIN'] as KeanuUserRole[]
    try {
      await getAuthorizationByToken(authToken.token, claims)
      setAuthToken(authToken)
      window.open(redirectPathOnLoginSuccess, '_self')
    } catch {
      setHasAccessError(true)
    }
  }

  const onSubmitPasswordForLogin = async (
    credential: string,
    password: string,
  ) => {
    const emailToLowercase = credential.toLocaleLowerCase()
    setUserEmail(emailToLowercase)
    await dispatch(login({ credential: emailToLowercase, password }))
  }

  const clearError = () => {
    dispatch(resetError())
    setHasAccessError(false)
  }

  const onBackToLogin = () => {
    dispatch(resetError())
  }

  return {
    isGrowlVisible,
    isLoading,
    error,
    growlContent,
    clearError,
    onSubmitPasswordForLogin,
    onBackToLogin,
  }
}

export { useAuth }
