import { FinderLauncherDTO } from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import { objectToQueryString } from 'utils/url'

const baseUrl = configuration.apiGatewayUrl

export const getFindersLaunchers = async (
  childId: string,
): Promise<FinderLauncherDTO[]> => {
  try {
    const endpoint = '/billing'
    const method = 'GET' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      params: objectToQueryString({ childId }),
    })

    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'billing-pay-ovverides',
      attributeName: 'get-payoverrides',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const postFindersLaunchers = async (
  data: Partial<FinderLauncherDTO>[],
): Promise<FinderLauncherDTO[]> => {
  const endpoint = '/billing/v1/finders-launchers'
  const method = 'POST'
  try {
    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(data),
    })

    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'billing-finders-launchers',
      attributeName: 'post-finders-launchers',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putFindersLaunchers = async (
  id: string,
  data: Partial<FinderLauncherDTO>[],
) => {
  const endpoint = `/billing/v1/finders-launchers/child/${id}`
  const method = 'PUT'
  try {
    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(data),
    })

    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'billing-finders-launchers',
      attributeName: 'put-finders-launchers',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
