import { upperCase } from 'lodash'

const roleList = ['Camper', 'Trainer', 'HQ', 'Admin']

const RoleDropdownItems = roleList.map((role) => ({
  value: upperCase(role),
  label: role,
}))

export default RoleDropdownItems
