import { useEffect, useState } from 'react'
import { ProgramType } from '@campgladiator/cg-common.types.types'
import { ActiveProgramType } from 'app/redux/features/active-program/active-program-reducer'

const useProgramType = (programDetails: ActiveProgramType) => {
  const [programType, setProgramType] = useState<ProgramType>()

  useEffect(() => {
    if (!programDetails) return
    const { subType } = programDetails.productDetailSummary
    const inferredProgramType: ProgramType =
      subType === 'IN_PERSON' ? 'OUTDOOR' : 'ONLINE'
    setProgramType(inferredProgramType)
  }, [programDetails])

  return programType
}

export default useProgramType
