const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return ''
  const cleanNum = phoneNumber.toString().replace(/\D/g, '')
  const match = cleanNum.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return cleanNum
}

export default formatPhoneNumber
