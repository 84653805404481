import { Toggle } from '@campgladiator/cgui-core.atoms.toggle'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { TData, TRow } from '@campgladiator/cgui-core.organisms.table'
import { ProductSubTypes } from 'types/product.d'
import { Workout } from 'types/workout'
import { displayAddress } from 'utils/address-helper'
import {
  dateTimeStringToDayOfWeekWithZone,
  dateTimeStringToLocalDateWithZone,
  dateTimeStringToLocalTimeWithZone,
  isPastFromNow,
} from 'utils/date'
import styles from './workouts-row.module.scss'

type WorkoutsRowProps = {
  handleStatusToggle: (status: boolean, workoutId: number) => void
  programTimezone: string
  programType: string
  workout: Workout
}

const WorkoutsRow = ({
  programTimezone,
  programType,
  workout,
  handleStatusToggle,
}: WorkoutsRowProps) => {
  const {
    id,
    address,
    archived,
    duration,
    link,
    startTime: workoutDateTimeString,
  } = workout

  const dayOfWeekWithZone = dateTimeStringToDayOfWeekWithZone(
    workoutDateTimeString,
    programTimezone,
  )
  const localDateWithZone = dateTimeStringToLocalDateWithZone(
    workoutDateTimeString,
    programTimezone,
  )
  const localTimeWithZone = dateTimeStringToLocalTimeWithZone(
    workoutDateTimeString,
    programTimezone,
  )
  const isPast = isPastFromNow(workoutDateTimeString, programTimezone)
  return (
    <TRow>
      <TData>
        <Paragraph size="small" weight="book">
          {dayOfWeekWithZone}
        </Paragraph>
      </TData>
      <TData>
        <Paragraph size="small" weight="book">
          {localDateWithZone}
        </Paragraph>
      </TData>
      <TData>
        <Paragraph size="small" weight="book">
          {localTimeWithZone}
        </Paragraph>
      </TData>
      <TData>
        <Paragraph size="small" weight="book">
          {duration} min.
        </Paragraph>
      </TData>
      <TData>
        <Paragraph size="small" weight="book">
          {programType === ProductSubTypes.IN_PERSON && displayAddress(address)}

          {programType === ProductSubTypes.ONLINE && (
            <a href={link}>Zoom link</a>
          )}
        </Paragraph>
      </TData>
      <TData>
        <div
          className={`${styles.toggleContainer} ${
            archived ? styles.inactive : styles.active
          }`}
        >
          <div className={styles.toggleLabel}>
            <Paragraph size="small">
              {archived ? 'Inactive' : 'Active'}
            </Paragraph>
          </div>
          <Toggle
            toggleSize="small"
            checked={!archived}
            disabled={isPast}
            onChange={(e) => handleStatusToggle(e.target.checked, id)}
          />
        </div>
      </TData>
    </TRow>
  )
}

export default WorkoutsRow
