import { SubscriptionStatus } from 'types/subscription.d'

export const subscriptionStatusOptions = [
  {
    value: SubscriptionStatus.ACTIVE,
    label: SubscriptionStatus.ACTIVE,
  },
  {
    value: SubscriptionStatus.CANCELED,
    label: SubscriptionStatus.CANCELED,
  },
  {
    value: SubscriptionStatus.FROZEN,
    label: SubscriptionStatus.FROZEN,
  },
  {
    value: SubscriptionStatus.TRIAL,
    label: SubscriptionStatus.TRIAL,
  },
]
