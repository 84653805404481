const ShirtSizeDropdownItems = [
  { label: 'X-Small', value: 'XSMALL' },
  { label: 'Small', value: 'SMALL' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Large', value: 'LARGE' },
  { label: 'X-Large', value: 'XLARGE' },
  { label: 'XX-Large', value: 'XXLARGE' },
  { label: 'XXX-Large', value: 'XXXLARGE' },
]

export default ShirtSizeDropdownItems
