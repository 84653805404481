import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import { Program, ProgramDetails, ProgramFullDetails } from 'types/program'

const baseUrl = configuration.apiGatewayUrl

export const getAllPrograms = async (
  trainerName: string,
  includeDeletedTrainers?: boolean,
): Promise<ProgramDetails[]> => {
  try {
    const params = new URLSearchParams({
      trainerName,
      ...(includeDeletedTrainers ? { includeDeleted: 'true' } : {}),
    })

    const endpoint = `/library/v1/programs/summaries?${params.toString()}`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok
      ? (result as ProgramDetails[])
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-all-programs-error',
      attributeName: 'get-all-programs',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const postPrograms = async (data: Program) => {
  try {
    const endpoint = '/library/v1/programs'
    const method = 'POST' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(data),
    })
    const result = await response.json()

    return response.ok ? (result as any) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-programs-library',
      attributeName: 'post-programs',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putProgram = async (data: Program) => {
  try {
    const { id, ...programData } = data
    const endpoint = `/library/v1/programs/${id}`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(programData),
    })
    const result = await response.json()

    return response.ok ? (result as any) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-programs-library',
      attributeName: 'put-programs',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getProgramDetails = async (id: string) => {
  try {
    const endpoint = `/library/v1/programs/${id}/summary`
    const method = 'GET' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok ? (result as any) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-programs-library',
      attributeName: 'put-programs',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getProgramByFilters = async (
  trainerId: string,
  productType: string,
  productSubType: string,
): Promise<ProgramFullDetails> => {
  try {
    const endpoint = `/library/v1/programs/full?trainerId=${trainerId}&productType=${productType}&productSubType=${productSubType}`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok
      ? (result as ProgramFullDetails)
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-program-by-type-subType-error',
      attributeName: 'get-program-by-type-subType',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
