import React, { useState } from 'react'
import classNames from 'classnames'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import styles from './tabs.module.scss'

interface TabsProps {
  selected?: number
  children?: React.ReactElement[]
}

const Tabs: React.FC<TabsProps> = ({ selected = 0, children }) => {
  const [selectedTab, setSelectedTab] = useState(selected)

  return (
    <>
      <ul className={styles.tabContainer}>
        {children?.map((elem: any, index: number) => {
          return (
            <li
              key={index}
              className={classNames(
                index === selectedTab ? styles.selected : undefined,
                styles.tabItem,
              )}
              onClick={() => setSelectedTab(index)}
            >
              <Paragraph
                weight={index === selectedTab ? 'normal' : 'book'}
                size="xsmall"
                className={styles.tabTitle}
              >
                {elem.props.title}
              </Paragraph>
            </li>
          )
        })}
      </ul>
      {children && <div className={styles.tab}>{children[selectedTab]}</div>}
    </>
  )
}

export default Tabs
