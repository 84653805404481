import { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Icon } from '@campgladiator/cgui-core.atoms.input/dist/input'
import { debounce } from 'lodash'
import styles from './search-input.module.scss'

export type SearchInputProps = {
  placeholder: string
  numericSearch?: boolean
  onSearch: (value: string) => void
  suffix?: string | undefined
}

const SearchInput = ({
  onSearch,
  placeholder,
  numericSearch,
  suffix,
}: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState<string>('')

  const searchBarIcon: Icon =
    searchValue.length === 0
      ? { type: 'solid', name: 'icon-search' }
      : { type: 'monochrome', name: 'times-circle' }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(debounce(onSearch, 2000), [onSearch])

  const handleOnInputChange = (value: string) => {
    if (numericSearch) {
      value = value.replace(/[^0-9]/g, '')
    }
    setSearchValue(value)
  }

  const clearSearchValue = () => {
    setSearchValue('')
  }

  useEffect(() => {
    delayedSearch(searchValue)
  }, [searchValue, delayedSearch])

  return (
    <div
      className={classNames({
        [styles.searchWrapperWithSuffix]: suffix,
        [styles.searchWrapper]: !suffix,
      })}
      data-suffix={suffix}
    >
      <Input
        className={styles.search}
        name="search"
        id="search"
        placeholder={placeholder}
        icon={!suffix ? searchBarIcon : undefined}
        onInputChange={handleOnInputChange}
        value={searchValue}
        onIconClick={() => clearSearchValue()}
      />
    </div>
  )
}

export default SearchInput
