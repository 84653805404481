import { Input, InputProps } from '@campgladiator/cgui-core.atoms.input'
import styles from './currency-input.module.scss'

const CurrencyInput = ({ onChange, ...props }: InputProps) => {
  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputValue = e.target.value as string
    if (inputValue.includes('.') && inputValue.split('.')?.[1]?.length > 2) {
      e.target.value = inputValue.slice(0, -1)
    }

    onChange?.(e)
  }

  return (
    <Input
      className={styles.currency}
      step={1}
      onChange={handleOnChange}
      {...props}
      icon={{ type: 'monochrome', name: 'dollar-sign' }}
    />
  )
}

export default CurrencyInput
