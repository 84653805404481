import {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
  useCallback,
  useMemo,
} from 'react'
import { Icon } from '@campgladiator/cgui-core.atoms.input/dist/input'
import { ListItemProps } from '@campgladiator/cgui-core.atoms.list-item'
import {
  ListMenu,
  ListMenuProps,
} from '@campgladiator/cgui-core.molecules.list-menu'
import { useOuterClick } from 'app/hooks'
import { debounce } from 'lodash'
import { getAllOrganizations } from 'services/api/organization'
import { AutocompleteProps, OptionType } from '../auto-complete'
import styles from '../auto-complete.module.scss'

type UseAutoCompleteProps = Pick<
  AutocompleteProps,
  'options' | 'value' | 'setRecord'
> & {
  optionType?: OptionType
}

const createNoResultsItem = (): ListItemProps & { type: 'item' } => ({
  type: 'item',
  itemId: '0',
  text: 'No results found',
  active: false,
  disablePointer: true,
})

const addNoResultsItemIfNeeded = (items: ListMenuProps['items']) => {
  if (items.length === 0) {
    items.push(createNoResultsItem())
  }
  return items
}

const filterOptions = (options: any[], userInput: string) =>
  options.filter(
    (option: any) =>
      option.text.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
  )

const useAutoComplete = ({
  options,
  optionType = 'other',
  value,
  setRecord,
}: UseAutoCompleteProps) => {
  const contentRef = useRef<HTMLInputElement>(null)
  const [activeOption, setActiveOption] = useState<number>(0)
  const [filteredOptions, setFilteredOptions] = useState<
    ListMenuProps['items']
  >([])
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [userInput, setUserInput] = useState<string>('')
  const [selectedRecordsIds, setSelectedRecordsIds] = useState<Set<string>>(
    new Set(),
  )

  const inputIcon: Icon =
    selectedRecordsIds.size === 0
      ? { type: 'solid', name: 'icon-search' }
      : { type: 'monochrome', name: 'times-circle' }

  useOuterClick(contentRef, () => {
    handleInputBlur()
  })

  const handleInputBlur = () => {
    setShowOptions(false)
  }

  const handleOnOrganizationChange = useCallback(async (userInput: string) => {
    const response = await getAllOrganizations(userInput)
    const organizations: ListMenuProps['items'] = response.map(
      ({ id, name }) => ({
        itemId: id!,
        text: name,
        type: 'item',
      }),
    )

    addNoResultsItemIfNeeded(organizations)

    setFilteredOptions(organizations)
    setShowOptions(true)
  }, [])

  const debouncedHandleOnOrganizationChange = useCallback(
    (userInput: string) => {
      const debounced = debounce(handleOnOrganizationChange, 500)
      debounced(userInput)
    },
    [handleOnOrganizationChange],
  )

  const handleClear = useCallback(() => {
    setUserInput('')
    setRecord({} as ListItemProps & { type: 'item' })
    setSelectedRecordsIds(new Set(undefined))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const memoizedFilteredOptions = useMemo(() => {
    if (optionType === 'organization') {
      return filteredOptions
    } else {
      const filtered = filterOptions(options, userInput)

      addNoResultsItemIfNeeded(filtered)

      return filtered
    }
  }, [options, optionType, userInput, filteredOptions])

  const handleOnChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const userInput = e.currentTarget.value
      setUserInput(userInput)
      setFilteredOptions([])

      switch (optionType) {
        case 'organization':
          if (userInput.length > 2) {
            debouncedHandleOnOrganizationChange(userInput)
          } else if (userInput.length === 0) {
            handleClear()
          }
          break
        default:
          const filteredOptions = filterOptions(options, userInput)

          addNoResultsItemIfNeeded(filteredOptions)

          if (userInput.length === 0) {
            handleClear()
          }

          setActiveOption(0)
          setFilteredOptions(filteredOptions)
          setShowOptions(true)
          break
      }
    },
    [optionType, debouncedHandleOnOrganizationChange, handleClear, options],
  )

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setActiveOption(0)
      setShowOptions(false)
      setUserInput(filteredOptions[activeOption].toString() || '')
    } else if (event.key === 'ArrowUp') {
      if (activeOption === 0) {
        return
      }
      setActiveOption(activeOption - 1)
    } else if (event.key === 'ArrowDown') {
      if (activeOption === filteredOptions.length - 1) {
        return
      }
      setActiveOption(activeOption + 1)
    }
  }

  const formatClickableMenuItem = (
    record: ListItemProps & { type: 'item' },
  ) => {
    return {
      ...record,
      handleClick: () => {
        setActiveOption(0)
        setFilteredOptions([])
        setShowOptions(false)
        setUserInput(record.text?.toString() || '')
        setSelectedRecordsIds(new Set(record.itemId))
        setRecord(record)
      },
    }
  }

  useEffect(() => {
    if (value) {
      setUserInput(value)
      setSelectedRecordsIds(new Set(value))
    }
  }, [value])

  const optionList =
    showOptions && userInput ? (
      <ListMenu
        selection="single"
        initialActiveIds={selectedRecordsIds}
        className={styles.menu}
        style={{ height: 'initial' }}
        items={
          memoizedFilteredOptions
            ? memoizedFilteredOptions.map((record) =>
                formatClickableMenuItem(record),
              )
            : []
        }
      />
    ) : null

  return {
    contentRef,
    inputIcon,
    optionList,
    userInput,
    handleClear,
    handleOnChange,
    onKeyDown,
  }
}

export default useAutoComplete
