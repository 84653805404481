import { useLayoutEffect, useState } from 'react'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { Modal } from '@campgladiator/cgui-core.molecules.modal'
import { CurrencyInput } from 'components/form-ui/currency-input'
import { useFormik } from 'formik'
import { putRefundTransaction } from 'services/api/transactions'
import { dollarToCents } from 'utils/currency'
import * as Yup from 'yup'
import styles from './refund-action.module.scss'

export type RefundActionProps = {
  id: string
  allowedAmount: number
  onSucces: () => void
  onFailure: (error: any) => void
}

const RefundAction = ({
  id,
  allowedAmount,
  onSucces,
  onFailure,
}: RefundActionProps) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false)

  const {
    handleSubmit,
    getFieldProps,
    resetForm,
    setSubmitting,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      id,
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .notRequired()
        .min(0.01, 'Refund amount must be greater than 0')
        .max(
          allowedAmount,
          `Refund amount must not be more than ${allowedAmount}`,
        ),
    }),
    onSubmit: async ({ id, amount }) => {
      try {
        const refundAmount = amount ? dollarToCents(Number(amount)) : undefined
        await putRefundTransaction(id, refundAmount)

        setTimeout(() => {
          setToggleModal(false)
          onSucces()
        }, 3000)
      } catch (error: any) {
        console.log(error)
        onFailure(error)
      }
      setSubmitting(false)
    },
  })

  useLayoutEffect(() => {
    resetForm()

    //eslint-disable-next-line
  }, [toggleModal])

  return (
    <>
      <Paragraph size="small" weight="book">
        <Button
          name="refund"
          emphasis="primary"
          variation="outline"
          onClick={() => setToggleModal(true)}
          theme="trainer"
        >
          Refund
        </Button>
      </Paragraph>
      <Modal
        isVisible={toggleModal}
        title="Are you sure you want to refund this transaction?"
        onClose={() => setToggleModal(false)}
        className={styles.container}
        buttonPrimary={{
          children: isSubmitting ? <Loader size="xsmall" /> : 'Yes',
          type: 'button',
          name: 'submit',
          disabled: isSubmitting,
          onClick: () => handleSubmit(),
        }}
        buttonSecondary={{
          children: 'No',
          onClick: () => setToggleModal(false),
        }}
        theme="trainer"
      >
        <form>
          <FormPart
            forId="amount"
            errorMsg={(touched.amount && errors.amount) || ''}
          >
            <CurrencyInput
              id="amount"
              placeholder={`Input amount if this is a partial refund. (max $${allowedAmount})`}
              type="number"
              max={allowedAmount}
              min={1}
              {...getFieldProps('amount')}
            />
          </FormPart>
        </form>
      </Modal>
    </>
  )
}

export default RefundAction
