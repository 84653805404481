import { useState } from 'react'
import { useGrowlContext } from 'app/contexts/growl-context'
import { setLastRefreshTime } from 'app/redux/features/team/team-reducer'
import { useAppDispatch } from 'app/redux/store'
import PageTitle from 'components/layouts/page-title/page-title'
import { postCreateTeam } from 'services/api/team'
import { Locale } from 'types/locale'
import { TeamForm } from '../team-form'

const CreateTeam = () => {
  const dispatch = useAppDispatch()
  const [isSubmitting, setSubmitting] = useState(false)
  const { showGrowl } = useGrowlContext()

  const handleSubmit = async (values: Locale) => {
    setSubmitting(true)
    try {
      await postCreateTeam(values)
      dispatch(setLastRefreshTime(Date.now()))
      showGrowl('SUCCESS', 'Team created successfully')
    } catch (error: any) {
      console.error(error)
      showGrowl('FAILED', error)
    }

    setSubmitting(false)
  }

  return (
    <div>
      <PageTitle>Create New Team</PageTitle>
      <TeamForm
        onSubmit={(value: Locale) => {
          handleSubmit(value)
        }}
        isSubmitting={isSubmitting}
      />
    </div>
  )
}

export default CreateTeam
