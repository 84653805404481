import { useState } from 'react'
import { CreateTeam } from '../create-team'
import { EditTeam } from '../edit-team'
import { TeamsList } from '../teams-list'
import styles from './teams-container.module.scss'

export enum TeamPages {
  Edit,
  List,
}

export type ChangePageFunction = (page: TeamPages, data?: any) => void

const TeamsContainer = () => {
  const [currentPage, setCurrentPage] = useState<TeamPages>(TeamPages.List)
  const [data, setData] = useState<any>({})

  const changePage: ChangePageFunction = (page: TeamPages, data = {}) => {
    setCurrentPage(page)
    setData(data)
  }

  return (
    <div className={styles.container}>
      {currentPage === TeamPages.Edit ? (
        <EditTeam changePage={changePage} {...data} />
      ) : (
        <>
          <CreateTeam />
          <div className={styles.spacer}></div>
          <TeamsList changePage={changePage} {...data} />
        </>
      )}
    </div>
  )
}

export default TeamsContainer
