import React from 'react'
import ApiAutocomplete from 'components/form-ui/api-auto-complete'
import { getTrainers } from 'services/api/trainer'
import { ProductSubTypes, ProductTypes } from 'types/product.d'

export type TrainerAutoCompleteProps = {
  id: string
  name: string
  productType: ProductTypes
  productSubType: ProductSubTypes
  initialSelectedItem?: string
  onSelect: (record: any) => void
}

const TrainerAutoComplete = ({
  productType,
  productSubType,
  ...restProps
}: TrainerAutoCompleteProps) => (
  <ApiAutocomplete
    placeholder="Find a trainer"
    getMethod={getTrainers}
    searchKey="name"
    renderOptionLabel={(record) => `${record.firstName} ${record.lastName}`}
    queryParams={{
      type: productType,
      subType: productSubType,
    }}
    {...restProps}
  />
)
export default TrainerAutoComplete
