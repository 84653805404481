import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import ApiAutocomplete from 'components/form-ui/api-auto-complete/api-auto-complete'
import { getTrainers } from 'services/api/trainer'
import { NewTransactionType } from '../../new-transaction'
import { PaymentAmountInput } from '../components/payment-amount-input'
import { PaymentCard } from '../components/payment-card'
import { SubmitButton } from '../components/submit-button'
import styles from './trainer-tip-form.module.scss'
import useTrainerTipForm from './use-trainer-tip-form'

export type TrainerTipFormProps = {
  transactionType: NewTransactionType
}

const TrainerTipForm = ({ transactionType }: TrainerTipFormProps) => {
  const {
    paymentAmountInputProps,
    paymentMethodProps,
    selectedTrainerId,
    submitButtonProps,
    handleTrainerSelect,
  } = useTrainerTipForm({ transactionType })

  return (
    <form>
      <div className={styles.formSection}>
        <FormPart label="Trainer" forId="trainerId">
          <ApiAutocomplete
            id="trainerId"
            placeholder="Trainer Name"
            getMethod={getTrainers}
            searchKey="name"
            name="trainerId"
            renderOptionLabel={(record) =>
              `${record.firstName} ${record.lastName}`
            }
            onSelect={(record: any) => {
              handleTrainerSelect(record)
            }}
            value={selectedTrainerId}
          />
        </FormPart>

        <PaymentAmountInput {...paymentAmountInputProps} />

        <PaymentCard {...paymentMethodProps} />

        <SubmitButton {...submitButtonProps} />
      </div>
    </form>
  )
}

export default TrainerTipForm
