import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { UserDTO } from '@campgladiator/cg-common.types.types'
import { loadTrainerDetails } from 'app/redux/features/active-trainer/active-trainer-actions'
import { activeTrainerSelector } from 'app/redux/features/active-trainer/active-trainer-reducer'
import { useAppDispatch } from 'app/redux/store'
import { MainLayout } from 'components/layouts/main-layout'
import { TabPanel, Tabs } from 'components/layouts/tabs'
import { EditTrainer } from 'components/module/trainer/edit-trainer'
import { PayConfigurations } from 'components/module/trainer/pay-configurations'
import { TrainerBios } from 'components/module/trainer/trainer-bios'
import TrainerSidePanel from 'components/module/trainer/trainer-side-panel'
import { getFindersLaunchers } from 'services/api/finderLauncher'
import { getTrainerById } from 'services/api/trainer'
import { PayConfigResponse } from 'types/pay-config.d'

const getTrainerDetails = async (id: string | null) => {
  if (!id) return 'Unknown'

  try {
    const { firstName, lastName } = await getTrainerById(id)
    return `${firstName} ${lastName}`
  } catch (error) {
    return 'Unknown'
  }
}

const TrainerDetails = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const trainer = useSelector(activeTrainerSelector)
  const [updatedTrainerImage, setUpdatedTrainerImage] = useState<UserDTO>()
  const [childId, setChildId] = useState<string | null>(null)
  const [payConfig, setPayConfig] = useState<PayConfigResponse | null>(null)

  const fetchFindersLaunchers = async () => {
    if (!id) return

    try {
      const response = await getFindersLaunchers(id)
      setChildId(response?.at(0)?.childId || null)

      const result = await Promise.all(
        response.map(async (it) => ({
          type: it.type,
          trainerId: it.parentId,
          percentage: it.percent,
          trainerName: await getTrainerDetails(it.parentId),
        })),
      )

      setPayConfig({
        finders: result.filter((it) => it.type === 'FINDER'),
        launchers: result.filter((it) => it.type === 'LAUNCHER'),
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSaveSuccess = () => {
    fetchFindersLaunchers()
  }

  useEffect(() => {
    if (id) {
      dispatch(loadTrainerDetails(id))
      fetchFindersLaunchers()
    }

    //eslint-disable-next-line
  }, [dispatch, id])

  return (
    <MainLayout
      sidePanel={
        <TrainerSidePanel onAfterTrainerImageUpdate={setUpdatedTrainerImage} />
      }
    >
      <div>
        <Tabs>
          <TabPanel title="Basic Info">
            {trainer ? (
              <EditTrainer
                trainer={
                  updatedTrainerImage
                    ? { ...trainer, photo: updatedTrainerImage.imageURL }
                    : trainer
                }
              />
            ) : (
              <div />
            )}
          </TabPanel>
          <TabPanel title="Biography">
            <TrainerBios />
          </TabPanel>
          <TabPanel title="Pay Configurations">
            <PayConfigurations
              childId={childId}
              payConfig={payConfig}
              onSuccess={handleSaveSuccess}
            />
          </TabPanel>
        </Tabs>
      </div>
    </MainLayout>
  )
}

export default TrainerDetails
