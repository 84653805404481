import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getRegionGroupById, putRegionGroup } from 'services/api/locale'
import { RegionGroup } from 'types/locale'

const useRegionGroupOverview = () => {
  const [regionGroupData, setRegionGroupData] = useState<RegionGroup>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isGrowlVisible, setIsGrowlVisible] = useState<boolean>(false)
  const [growlContent, setGrowlContent] = useState('')

  const { id } = useParams()
  const { state } = useLocation()

  const setGrowlView = (isVisible: boolean, growlContent: string) => {
    setIsGrowlVisible(isVisible)
    setGrowlContent(growlContent)
  }

  const clearError = () => {
    setIsGrowlVisible(false)
  }

  const handleSubmit = async (values: RegionGroup) => {
    setIsSubmitting(true)

    try {
      const response = await putRegionGroup(values, id!)
      if (response) {
        setGrowlView(true, 'Region group saved successfully')
      }
    } catch (error: any) {
      setGrowlView(true, error.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  const getRegionGroup = useCallback(async () => {
    try {
      const response = await getRegionGroupById(state.id)
      if (response) {
        setRegionGroupData(response)
      }
    } catch (error: any) {
      setGrowlView(true, error)
    }
  }, [state.id])

  useEffect(() => {
    getRegionGroup()
  }, [getRegionGroup])

  return {
    growlContent,
    isGrowlVisible,
    isSubmitting,
    regionGroupData,
    clearError,
    handleSubmit,
  }
}

export default useRegionGroupOverview
