import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TrainerDTO } from '@campgladiator/cg-common.types.types'
import { useGrowlContext } from 'app/contexts/growl-context'
import { setActiveTrainer } from 'app/redux/features/active-trainer/active-trainer-reducer'
import { useAppDispatch } from 'app/redux/store'
import { updateTrainer } from 'services/api/trainer'
import { TrainerForm } from '../trainer-form'
import styles from './edit-trainer.module.scss'

interface IEditTrainerProps {
  trainer?: Partial<TrainerDTO>
}

const EditTrainer = ({ trainer }: IEditTrainerProps) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { showGrowl } = useGrowlContext()

  const [isSubmitting, setSubmitting] = useState(false)
  const [lastTrainerImage, setLastTrainerImage] = useState<string>('')

  const handleSubmit = async (values: Partial<TrainerDTO>) => {
    setSubmitting(true)
    updateTrainer(values, id!)
      .then((response) => {
        if (response) {
          showGrowl('SUCCESS', 'Trainer saved successfully')
          dispatch(setActiveTrainer(response))
        }
      })
      .catch((e) => {
        showGrowl('FAILED', e.message)
      })
      .finally(() => setSubmitting(false))
  }

  useEffect(() => {
    const wasUpdated =
      lastTrainerImage !== trainer?.photo && lastTrainerImage !== ''
    setLastTrainerImage(trainer?.photo || '')
    if (wasUpdated) handleSubmit(trainer!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainer])

  return (
    <div className={styles.formContainer}>
      <TrainerForm
        isEdit
        trainer={trainer}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default EditTrainer
