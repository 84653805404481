import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  TRow,
  Table,
  THead,
  TData,
} from '@campgladiator/cgui-core.organisms.table'
import { ActiveProgramType } from 'app/redux/features/active-program/active-program-reducer'
import { Dropdown } from 'components/form-ui/dropdown'
import { ProductSubTypes } from 'types/product.d'
import { WorkoutsRow } from './components/workouts-row'
import useWorkoutsList from './use-workouts-list'
import styles from './workouts-list.module.scss'

const WorkoutsList = ({
  programDetails,
}: {
  programDetails: ActiveProgramType
}) => {
  const {
    currentPage,
    isLoading,
    programTimezone,
    programType,
    scheduleDropdownOptions,
    scheduleId,
    totalPages,
    workouts,
    handlePageChange,
    handleScheduleIdChange,
    handleStatusToggle,
  } = useWorkoutsList({
    programDetails,
  })

  return (
    <div className={styles.tabContainer}>
      <div className={styles.filterContainer}>
        <Heading type="h2" className={styles.title}>
          Workouts
        </Heading>

        <Paragraph size="xsmall" className={styles.filterLabel}>
          Select a schedule:*
        </Paragraph>

        <Dropdown
          className={styles.filterDropdown}
          label="Select a schedule"
          options={scheduleDropdownOptions}
          value={scheduleId}
          onChange={(e) => handleScheduleIdChange(e.target.value)}
        />
      </div>

      <Table.Manual
        showFooter
        currentPage={currentPage}
        totalPageCount={totalPages}
        onPageChange={(pageNumber: number) => handlePageChange(pageNumber)}
      >
        <thead>
          <TRow>
            <THead>
              <Heading type="h6">Day</Heading>
            </THead>
            <THead>
              <Heading type="h6">Date</Heading>
            </THead>
            <THead>
              <Heading type="h6">Start time</Heading>
            </THead>
            <THead>
              <Heading type="h6">Duration</Heading>
            </THead>
            <THead>
              <Heading type="h6">
                {programType === ProductSubTypes.ONLINE && 'Link'}
                {programType === ProductSubTypes.IN_PERSON && 'Address'}
              </Heading>
            </THead>
            <THead>
              <Heading type="h6">Status</Heading>
            </THead>
          </TRow>
        </thead>
        <tbody>
          {isLoading ? (
            <TRow>
              <TData>
                <Loader />
              </TData>
            </TRow>
          ) : !workouts.length ? (
            <TRow>
              <TData>
                <Paragraph size="small" weight="book">
                  No workouts to display
                </Paragraph>
              </TData>
            </TRow>
          ) : (
            workouts.map((workout) => (
              <WorkoutsRow
                workout={workout}
                programType={programType}
                programTimezone={programTimezone!}
                handleStatusToggle={handleStatusToggle}
              />
            ))
          )}
        </tbody>
      </Table.Manual>
    </div>
  )
}

export default WorkoutsList
