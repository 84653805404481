import { TrainerDTO } from '@campgladiator/cg-common.types.types'
import { Avatar } from '@campgladiator/cgui-core.atoms.avatar'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Toggle } from '@campgladiator/cgui-core.atoms.toggle'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { Autocomplete } from 'components/form-ui/auto-complete'
import { Dropdown } from 'components/form-ui/dropdown'
import planTypeDropdownItems from 'services/data/planType'
import { trainerTypeDropdownItems } from 'services/data/trainer'
import { currentLocalDateInISO8601 as today } from 'utils/date'
import useTrainerForm from './hooks/use-trainer-form'
import styles from './trainer-form.module.scss'

export interface ITrainerFormProps {
  isEdit?: boolean | false
  trainer?: Partial<TrainerDTO>
  onSubmit: (values: Partial<TrainerDTO>, selectedFile?: File) => void
  isSubmitting: boolean
}

const TrainerForm = ({
  onSubmit,
  isSubmitting,
  isEdit,
  trainer,
}: ITrainerFormProps) => {
  const {
    errors,
    growlContent,
    image,
    isGrowlVisible,
    selectedTeam,
    state,
    teams,
    touched,
    values,
    clearError,
    getFieldProps,
    handleClick,
    handleOnChange,
    handleSetTeam,
    handleSubmit,
    onImageChange,
    setFieldValue,
    setUserImage,
  } = useTrainerForm({
    isEdit,
    trainer,
    onSubmit,
  })

  return (
    <>
      <GrowlMessage isVisible={isGrowlVisible} onClick={clearError}>
        {growlContent}
      </GrowlMessage>
      <div className={styles.formContainer}>
        <Heading className={styles.title} type="h4">
          {isEdit ? 'Basic Information' : 'New Trainer'}
        </Heading>
        {!isEdit && (
          <Card className={styles.profileContainer}>
            <div className={styles.imageContainer}>
              <div className={styles.editImage}>
                <Icon.Solid
                  name={'icon-camera'}
                  className={styles.cameraIcon}
                />
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={onImageChange}
                  onClick={handleClick}
                />
              </div>
              {!image ? (
                <Avatar size="80px" className={styles.avatar} />
              ) : (
                <Avatar
                  alt="Trainer's picture"
                  size="80px"
                  className={styles.avatar}
                  src={image}
                  onError={() => setUserImage('')}
                />
              )}
            </div>
            <div>
              <Paragraph className={styles.name}>
                {state?.user?.firstName} {state?.user?.lastName}
              </Paragraph>
            </div>
          </Card>
        )}

        <form className={styles.formSection} onSubmit={handleSubmit}>
          <FormPart
            label="Is Outdoor Approved?"
            forId="outdoorApproved"
            className={styles.fieldsetItem}
          >
            <Toggle
              checked={values.approvedPrograms?.outdoorApproved}
              onChange={(e) =>
                setFieldValue(
                  'approvedPrograms.outdoorApproved',
                  e.target.checked,
                )
              }
            />
          </FormPart>

          <FormPart
            label="Is Online Approved?"
            forId="onlineApproved"
            className={styles.fieldsetItem}
          >
            <Toggle
              checked={values.approvedPrograms?.onlineApproved}
              onChange={(e) =>
                setFieldValue(
                  'approvedPrograms.onlineApproved',
                  e.target.checked,
                )
              }
            />
          </FormPart>

          <FormPart
            label="Is Nutrition Approved?"
            forId="nutritionApproved"
            className={styles.fieldsetItem}
          >
            <Toggle
              checked={values.approvedPrograms?.nutritionApproved}
              onChange={(e) =>
                setFieldValue(
                  'approvedPrograms.nutritionApproved',
                  e.target.checked,
                )
              }
            />
          </FormPart>

          <FormPart
            label="Is Strength Approved?"
            forId="strengthApproved"
            className={styles.fieldsetItem}
          >
            <Toggle
              checked={values.approvedPrograms?.strengthApproved}
              onChange={(e) =>
                setFieldValue(
                  'approvedPrograms.strengthApproved',
                  e.target.checked,
                )
              }
            />
          </FormPart>

          <Fieldset className={styles.fieldset}>
            <FormPart
              label="First name*"
              forId="firstName"
              className={styles.fieldsetItem}
              errorMsg={(touched.firstName && errors.firstName) || undefined}
            >
              <Input
                id="firstName"
                placeholder="First Name"
                {...getFieldProps('firstName')}
              />
            </FormPart>
            <FormPart
              label="Last name*"
              forId="lastName"
              className={styles.fieldsetItem}
              errorMsg={(touched.lastName && errors.lastName) || undefined}
            >
              <Input
                id="lastName"
                placeholder="Last Name"
                {...getFieldProps('lastName')}
              />
            </FormPart>
          </Fieldset>

          <FormPart
            label="Payroll Alias"
            forId="payrollId"
            className={styles.fieldsetItem}
          >
            <Input
              id="payrollId"
              placeholder="555"
              {...getFieldProps('payrollId')}
            />
          </FormPart>

          <Fieldset className={styles.fieldset}>
            <FormPart
              label="Trainer start date*"
              forId="startDate"
              className={styles.fieldsetItem}
              errorMsg={(touched.startDate && errors.startDate) || ''}
            >
              <Input
                id="startDate"
                type="date"
                min={isEdit ? undefined : today}
                placeholder="MM/DD/YYYY"
                {...getFieldProps('startDate')}
              />
            </FormPart>

            {isEdit && (
              <FormPart
                label="Trainer end date"
                forId="archived"
                className={styles.fieldsetItem}
                errorMsg={(touched.archived && errors.archived) || ''}
              >
                <Input
                  id="archived"
                  type="date"
                  placeholder="MM/DD/YYYY"
                  min={values.startDate}
                  {...getFieldProps('archived')}
                />
              </FormPart>
            )}
          </Fieldset>

          <Fieldset className={styles.fieldset}>
            <FormPart
              label="Email*"
              forId="email"
              className={styles.fieldsetItem}
              errorMsg={(touched.email && errors.email) || ''}
            >
              <Input
                id="email"
                placeholder="Email"
                {...getFieldProps('email')}
              />
            </FormPart>
            <FormPart
              label="Contact number*"
              forId="contactNumber"
              className={styles.fieldsetItem}
              errorMsg={(touched.contactNumber && errors.contactNumber) || ''}
            >
              <Input
                id="contactNumber"
                name="contactNumber"
                type="tel"
                placeholder="(555) 555-5555"
                value={values.contactNumber}
                onChange={handleOnChange}
              />
            </FormPart>
          </Fieldset>

          <FormPart
            label="Team*"
            forId="localeId"
            className={styles.fieldsetItem}
            errorMsg={(touched.localeId && errors.localeId) || ''}
          >
            <Autocomplete
              id="localeId"
              placeholder="Team"
              options={teams}
              value={selectedTeam}
              name={'localeId'}
              setRecord={handleSetTeam}
            />
          </FormPart>

          <FormPart
            label="Pay Plan"
            forId="planType"
            className={styles.fieldsetItem}
          >
            <Dropdown
              id="planType"
              options={planTypeDropdownItems}
              {...getFieldProps('planType')}
            />
          </FormPart>

          <FormPart
            label="Type*"
            forId="trainerType"
            className={styles.fieldsetItem}
            errorMsg={(touched.type && errors.type) || ''}
          >
            <Dropdown
              id="trainerType"
              options={trainerTypeDropdownItems}
              {...getFieldProps('type')}
            />
          </FormPart>

          <div className={styles.buttons}>
            <Button
              emphasis="primary"
              type="submit"
              disabled={isSubmitting}
              theme="trainer"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default TrainerForm
