import { useState } from 'react'
import classNames from 'classnames'
import { PaymentMethodDTO } from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Divider } from '@campgladiator/cgui-core.atoms.divider'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import PaymentCardItem from './payment-card-item/payment-card-item'
import styles from './payment-card.module.scss'
import UpdatePaymentForm from './update-payment-form/update-payment-form'

export type PaymentCardProps = {
  onAddNewCardClick: () => void
  onUpdateCardComplete: () => void
  cards: PaymentMethodDTO[]
  loading: boolean
}

const PaymentCard = ({
  onAddNewCardClick,
  onUpdateCardComplete,
  cards,
  loading,
}: PaymentCardProps) => {
  const [viewMode, setViewMode] = useState<'LIST' | 'EDIT'>('LIST')
  const [paymentDetails, setPaymentDetails] = useState<PaymentMethodDTO | null>(
    null,
  )

  const handleEditPayment = (cardDetails: PaymentMethodDTO | null = null) => {
    setPaymentDetails(cardDetails)
    setViewMode('EDIT')
  }

  const handleUpdateSuccess = () => {
    setViewMode('LIST')
    onUpdateCardComplete()
  }

  const handleCancel = () => {
    setViewMode('LIST')
  }

  return (
    <div className={styles.formContainer}>
      <Heading className={styles.title} type="h4">
        Credit Card Information
      </Heading>
      <Card flat className={styles.cardContainer}>
        <div className={styles.paymentContainer}>
          <Paragraph size="small" className={styles.lblPaymentMethods}>
            Payment Methods
          </Paragraph>

          <div className={styles.btnContainer}>
            {viewMode === 'LIST' ? (
              <Button
                type="button"
                emphasis="secondary"
                onClick={onAddNewCardClick}
                theme="trainer"
              >
                Add a new card
              </Button>
            ) : (
              <Button
                type="button"
                emphasis="primary"
                variation="outline"
                onClick={handleCancel}
                theme="trainer"
              >
                Cancel
              </Button>
            )}
          </div>
        </div>

        <Divider />

        {viewMode === 'LIST' ? (
          <div className={classNames(grid.row, styles.cardList)}>
            {loading ? (
              <Loader className={styles.loader} />
            ) : cards && cards.length > 0 ? (
              cards.map((card, i) => (
                <div key={i} className={classNames(grid.col12, grid.colLg6)}>
                  <PaymentCardItem
                    card={card}
                    buttonTitle="Edit"
                    onRemoveButtonClick={() => {}}
                    onEditButtonClick={handleEditPayment}
                  />
                </div>
              ))
            ) : (
              <Paragraph
                className={styles.emptyList}
                size="small"
                weight="book"
              >
                You don't currently have any credit cards saved
              </Paragraph>
            )}
          </div>
        ) : (
          paymentDetails && (
            <UpdatePaymentForm
              paymentDetails={paymentDetails}
              onSuccess={handleUpdateSuccess}
              isDefaultPaymentCheckboxDisabled={cards.length <= 1}
            />
          )
        )}
      </Card>
    </div>
  )
}

export default PaymentCard
