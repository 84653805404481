import {
  assignmentSchema,
  totalAssignedPercentSchema,
} from 'components/module/trainer/rp-assignment-section/hooks/use-rp-assignment-section'
import {
  AssignmentHoldingForm,
  defaultAssignment,
} from 'components/module/trainer/rp-assignment-section/rp-assignment-section'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { RegionGroup } from 'types/locale'
import * as Yup from 'yup'
import { IRegionGroupProps } from '..'

type UseRegionGroupFormArgs = Pick<
  IRegionGroupProps,
  'isSubmitting' | 'regionGroupData' | 'onSubmit'
>

const validationSchema = () =>
  Yup.object({
    name: Yup.string().required('Region name is required'),
    description: Yup.string().required('Description is required'),
    assignments: assignmentSchema,
    totalAssignedPercent: totalAssignedPercentSchema,
  })

const useRegionGroupForm = ({
  isSubmitting,
  regionGroupData,
  onSubmit,
}: UseRegionGroupFormArgs) => {
  const formik = useFormik({
    initialValues: {
      type: 'REGION_GROUP',
      name: regionGroupData ? regionGroupData.name : '',
      description: regionGroupData ? regionGroupData.description : '',
      deleted: false,
      active: regionGroupData ? regionGroupData.active : true,
      assignments:
        regionGroupData?.assignments && !isEmpty(regionGroupData.assignments)
          ? regionGroupData.assignments
          : [{ ...defaultAssignment }],
    } as RegionGroup & { totalAssignedPercent: number },

    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: AssignmentHoldingForm) => {
      onSubmit({
        ...values,
        assignments: values.assignments
          .filter((it) => it.trainerId)
          .map(({ trainerId, commissionPercentage }) => ({
            trainerId,
            commissionPercentage,
          })),
      })
    },
  })

  const {
    errors,
    touched,
    values,
    getFieldProps,
    handleSubmit,
    setFieldValue,
  } = formik

  const isButtonDisabled = () => {
    const { name, description, assignments } = values
    const isInvalidAssignment = assignments.some(
      (it) =>
        (it.trainerId && !it.commissionPercentage) ||
        (!it.trainerId && it.commissionPercentage) ||
        errors.assignments ||
        errors.totalAssignedPercent,
    )
    const isInvalid =
      name === '' || description === '' || isInvalidAssignment || isSubmitting

    return isInvalid
  }

  return {
    errors,
    formik,
    touched,
    values,
    getFieldProps,
    handleSubmit,
    isButtonDisabled,
    setFieldValue,
  }
}

export default useRegionGroupForm
