import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TransactionDTO } from '@campgladiator/cg-common.types.types'
import { useGrowlContext } from 'app/contexts/growl-context'
import { activeUserSelector } from 'app/redux/features/active-user/active-user-reducer'
import {
  getUserTransactions,
  retryInvoicePayment,
} from 'services/api/transactions'
import setStatusFailedOnlyToTheLastInvoiceId from 'utils/filterTransactions'

const UseTransactions = () => {
  const [transactionList, setTransactionsList] = useState<TransactionDTO[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { showGrowl } = useGrowlContext()
  const user = useSelector(activeUserSelector)
  const navigate = useNavigate()

  const handleRetryPayment = (invoiceId: string) => {
    retryInvoicePayment(invoiceId)
      .then(() => {
        showGrowl('SUCCESS', 'Payment attempt was successful', false)
        fetchTransactions()
      })
      .catch((error) => {
        showGrowl('FAILED', `Payment attempt failed: ${error}`, false)
      })
  }

  const fetchTransactions = useCallback(async () => {
    const customerId = user?.stripeCustomerId

    if (!customerId) return

    setLoading(true)

    try {
      const response = await getUserTransactions(customerId)
      const res = response.sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
      )
      setTransactionsList(setStatusFailedOnlyToTheLastInvoiceId(res))
    } catch (error) {
      console.error(error)
      showGrowl('FAILED', 'Error geting transactions', false)
    }

    setLoading(false)
  }, [showGrowl, user?.stripeCustomerId])

  const handleSuccess = () => {
    showGrowl(
      'SUCCESS',
      'The refund was successful. The amount shown may take a minute to update to the final amount',
      false,
    )
    fetchTransactions()
  }

  const handleFailure = (error: any) => {
    showGrowl('FAILED', error)
  }

  const handleAddNewTransaction = () => {
    navigate('new-transaction')
  }

  const pageTitleProps = {
    title: '',
    placeholder: '',
    newButtonText: 'Add Transaction',
    showSearchInput: false,
    onSearch: () => {},
    onNewButtonClick: handleAddNewTransaction,
  }

  useEffect(() => {
    fetchTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.stripeCustomerId])

  return {
    loading,
    pageTitleProps,
    transactionList,
    handleFailure,
    handleRetryPayment,
    handleSuccess,
  }
}

export default UseTransactions
