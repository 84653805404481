import React from 'react'
import { Divider } from '@campgladiator/cgui-core.atoms.divider'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { TextArea } from '@campgladiator/cgui-core.atoms.textarea'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { Dropdown } from 'components/form-ui/dropdown'
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
  getIn,
} from 'formik'
import StateDropDownItems from 'services/data/address'
import { Schedule } from 'types/schedule'
import styles from './../schedule-form.module.scss'

export type ScheduleAddressProps = {
  touched: FormikTouched<Schedule>
  errors: FormikErrors<Schedule>
  getFieldProps: (
    nameOrOptions: string | FieldConfig<any>,
  ) => FieldInputProps<any>
  disableAddress?: boolean
  isEditing: string | undefined
  disableLatandLong?: boolean
  fieldName: 'address' | 'alternativeAddress'
}

const ScheduleAddress = ({
  errors,
  touched,
  getFieldProps,
  disableAddress,
  isEditing,
  disableLatandLong,
  fieldName,
}: ScheduleAddressProps) => {
  return (
    <>
      <FormPart
        label="Name"
        forId="name"
        className={styles.fieldsetItem}
        errorMsg={
          (getIn(touched[fieldName], 'name') &&
            getIn(errors[fieldName], 'name')) ||
          ''
        }
      >
        <Input
          id="name"
          placeholder="Name"
          {...getFieldProps(`${fieldName}.name`)}
        />
      </FormPart>
      <FormPart
        label="Address 1"
        forId="address1"
        className={styles.fieldsetItem}
        errorMsg={
          (getIn(touched[fieldName], 'address1') &&
            getIn(errors[fieldName], 'address1')) ||
          ''
        }
      >
        <Input
          id="address1"
          placeholder="Address 1"
          {...getFieldProps(`${fieldName}.address1`)}
          disabled={disableAddress}
        />
      </FormPart>
      <FormPart
        label="Address 2"
        forId="address2"
        className={styles.fieldsetItem}
      >
        <Input
          id="address2"
          placeholder="Address 2"
          className={styles.fieldsetItem}
          {...getFieldProps(`${fieldName}.address2`)}
        />
      </FormPart>
      <FormPart
        label="City"
        forId="city"
        className={styles.fieldsetItem}
        errorMsg={
          (getIn(touched[fieldName], 'city') &&
            getIn(errors[fieldName], 'city')) ||
          ''
        }
      >
        <Input
          id="city"
          placeholder="City"
          {...getFieldProps(`${fieldName}.city`)}
        />
      </FormPart>
      <Fieldset className={styles.fieldset}>
        <FormPart
          label="State"
          forId="state"
          className={styles.fieldsetItem}
          errorMsg={
            (getIn(touched[fieldName], 'state') &&
              getIn(errors[fieldName], 'state')) ||
            ''
          }
        >
          <Dropdown
            id="state"
            label="Choose a state"
            options={StateDropDownItems}
            {...getFieldProps(`${fieldName}.state`)}
          />
        </FormPart>
        <FormPart
          label="Zip code"
          forId="zip"
          className={styles.fieldsetItem}
          errorMsg={
            (getIn(touched[fieldName], 'zip') &&
              getIn(errors[fieldName], 'zip')) ||
            ''
          }
        >
          <Input
            id="zip"
            placeholder="Zip code"
            type="number"
            {...getFieldProps(`${fieldName}.zip`)}
          />
        </FormPart>
      </Fieldset>
      {isEditing && (
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Latitude"
            forId="latitude"
            className={styles.fieldsetItem}
            errorMsg={
              (getIn(touched[fieldName], 'latitude') &&
                getIn(errors[fieldName], 'latitude')) ||
              ''
            }
          >
            <Input
              id="latitude"
              placeholder="Latitude"
              type="number"
              {...getFieldProps(`${fieldName}.latitude`)}
              disabled={disableLatandLong}
            />
          </FormPart>
          <FormPart
            label="Longitude"
            forId="longitude"
            className={styles.fieldsetItem}
            errorMsg={
              (getIn(touched[fieldName], 'longitude') &&
                getIn(errors[fieldName], 'longitude')) ||
              ''
            }
          >
            <Input
              id="longitude"
              placeholder="Longitude"
              type="number"
              {...getFieldProps(`${fieldName}.longitude`)}
              disabled={disableLatandLong}
            />
          </FormPart>
        </Fieldset>
      )}
      <Divider />
      <FormPart label="Directions" forId="directions">
        <TextArea
          rows={4}
          id="directions"
          placeholder="Directions"
          className={styles.textArea}
          {...getFieldProps(`${fieldName}.directions`)}
        />
      </FormPart>
    </>
  )
}

export default ScheduleAddress
