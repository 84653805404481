import { useParams } from 'react-router-dom'
import { useWithNoteManagement } from '@campgladiator/cg-common.ui.note-management'
import { useAppAccess } from 'app/contexts/app-access'
import styles from './notes.module.scss'

const useWithNotes = () => {
  const { id: userId } = useParams()
  const { loggedInAdmin, authToken } = useAppAccess()

  const useWithNoteManagementProps = useWithNoteManagement({
    adminId: loggedInAdmin?.id || '',
    userId: userId || '',
    token: authToken?.token || '',
  })

  const { clearErrors, error, successMessage, handleAddNewNote } =
    useWithNoteManagementProps

  const pageTitleProps = {
    className: styles.headerView,
    title: 'All notes',
    placeholder: '',
    newButtonText: 'Create a note',
    showSearchInput: false,
    onSearch: () => {},
    onNewButtonClick: handleAddNewNote,
  }

  return {
    error,
    pageTitleProps,
    successMessage,
    useWithNoteManagementProps,
    clearErrors,
  }
}

export default useWithNotes
