import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createFreezeFee } from '@campgladiator/cg-common.api.payment'
import { FreezeRequestDTO } from '@campgladiator/cg-common.types.types'
import { useAppAccess } from 'app/contexts/app-access'
import { useGrowlContext } from 'app/contexts/growl-context'
import { useWithFormInput } from 'app/hooks/useWithFormInput'
import { useWithPaymentAmountInput } from '../components/payment-amount-input'
import { useWithPaymentCard } from '../components/payment-card'
import { SubmitButtonProps } from '../components/submit-button'
import { FreezeFeeFormProps } from './freeze-fee-form'

type UseFreezeFeeFormArgs = FreezeFeeFormProps

const useFreezeFeeForm = ({ transactionType }: UseFreezeFeeFormArgs) => {
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const { showGrowl } = useGrowlContext()
  const navigate = useNavigate()
  const { id: userId } = useParams()
  const { authToken } = useAppAccess()

  const freezeAmountId = 'freezeAmount'
  const descriptionFormId = 'freezeFormDescription'
  const descriptionFormLabel = 'Description'
  const freezeError = 'Failed to create freeze fee'

  const descriptionProps = useWithFormInput({
    id: 'credential',
    placeholder: 'Description',
    title: 'Please enter a valid email address',
  })

  const { value } = descriptionProps

  const paymentAmountInputProps = useWithPaymentAmountInput({
    transactionType,
    formLabel: 'Freeze Amount',
    id: freezeAmountId,
    name: freezeAmountId,
  })

  const paymentMethodProps = useWithPaymentCard({
    formLabel: 'Payment Methods',
    id: 'paymentMethods',
  })

  const { amount } = paymentAmountInputProps
  const { selectedCardId } = paymentMethodProps
  const isButtonDisabled = !amount || !value || isSaving || !selectedCardId

  const handleSubmitButtonClick = async (event: {
    preventDefault: () => void
  }): Promise<boolean> => {
    event.preventDefault()
    setIsSaving(true)

    try {
      const stripeAmount = amount ? amount * 100 : 0
      const args: FreezeRequestDTO = {
        amount: stripeAmount,
        userId: userId ?? '',
        description: value,
        stripePaymentMethodId: selectedCardId ?? '',
      }
      await createFreezeFee(args, authToken?.token ?? '')
      showGrowl('SUCCESS', 'Freeze fee created successfully')
      setTimeout(() => {
        setIsSaving(false)
        navigate(-1)
      }, 2000)
      return Promise.resolve(true)
    } catch (error) {
      console.error(`${freezeError}:`, error)
      showGrowl('FAILED', freezeError)
      setIsSaving(false)
      return Promise.resolve(false)
    }
  }

  const submitButtonProps: SubmitButtonProps = {
    disabled: isButtonDisabled,
    handleSubmitButtonClick,
    isSaving,
  }

  return {
    descriptionProps,
    descriptionFormLabel,
    descriptionFormId,
    paymentAmountInputProps,
    paymentMethodProps,
    submitButtonProps,
  }
}

export default useFreezeFeeForm
