export enum ProductTypes {
  BOOTCAMP = 'BOOTCAMP',
  NUTRITION = 'NUTRITION',
  STRENGTH = 'STRENGTH',
}

export enum ProductSubTypes {
  ONLINE = 'ONLINE',
  IN_PERSON = 'IN_PERSON',
}

export type ProductDetailsParams = {
  type?: ProductTypes
  subType?: ProductSubTypes
}

export type ProductDetails = {
  id: string
  type: ProductTypes
  subType: ProductSubTypes
  title: string
  sellingPoints: Array<string>
  overview: string
  image: string
  miniatureImage: string
  approvalRequired: string
  lowestPrice: number | null
  termLength: number | null
  programIds: number | null
  products?: Product[]
}

export type Product = {
  id: string
  stripeProductId: string
  stripeProductName: string
  stripeProductDescription: string
  active: boolean
  created: string
  lastUpdated: string
  deleted: boolean
  termLength: number
}

export type ProductPrice = {
  id: string
  productId: string
  stripeProductId: string
  stripePriceId: string
  name: string
  description: string
  amount: number
  currency: string
  type: string
  termLength: number
  term: string
  active: boolean
  created: string
  lastUpdated: string
  deleted: boolean
}
