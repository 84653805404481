import { Button } from '@campgladiator/cgui-core.atoms.button'
import styles from './submit-button.module.scss'

export type SubmitButtonProps = {
  disabled: boolean
  handleSubmitButtonClick: (event: {
    preventDefault: () => void
  }) => Promise<boolean>
  isSaving: boolean
}

const SubmitButton = ({
  disabled,
  handleSubmitButtonClick,
  isSaving,
}: SubmitButtonProps) => (
  <div className={styles.buttons}>
    <Button
      disabled={disabled}
      theme="trainer"
      type="submit"
      emphasis="primary"
      children={isSaving ? 'Saving...' : 'Save'}
      onClick={(event) => handleSubmitButtonClick(event)}
    />
  </div>
)

export default SubmitButton
