const { REACT_APP_BUILD_ENVIRONMENT } = process.env

type Config = { readonly [key: string]: ConfigEnvironment }

type ConfigEnvironment = {
  apiGatewayUrl: string
  cgTrainerEmail: string
  cgTrainerName: string
  cookieKeys: CookieKeyType
  documentsUrl: string
  isCancellationReasonFeatureVisible: boolean
  stripePublishableKey: string
  websiteLoginUrl: string
}

type CookieKeyType = {
  authCookieKey: string
}

const cookieKeys: CookieKeyType = {
  authCookieKey: 'authCookieKey',
}

const config: Config = {
  development: {
    apiGatewayUrl: 'https://staging.api-gateway.campgladiator.com',
    documentsUrl: 'https://staging-web.campgladiator.com/api/retrieve-contract',
    cookieKeys,
    stripePublishableKey:
      'pk_test_51Kpj4SJWDZbdRfZo1KPCBSsqNkUpt9JphFjpd7ytzqlgOLVMlyvagzHEboDUgt1qDlyq84urthYbZRLvlei8kO2d00R52CQ8Kj',
    websiteLoginUrl: 'http://localhost:3003/login',
    cgTrainerEmail: 'careers123@campgladiator.com',
    isCancellationReasonFeatureVisible: true,
    cgTrainerName: 'Camp Gladiator',
  },
  staging: {
    apiGatewayUrl: 'https://staging.api-gateway.campgladiator.com',
    documentsUrl: 'https://staging-web.campgladiator.com/api/retrieve-contract',
    cookieKeys,
    stripePublishableKey:
      'pk_test_51Kpj4SJWDZbdRfZo1KPCBSsqNkUpt9JphFjpd7ytzqlgOLVMlyvagzHEboDUgt1qDlyq84urthYbZRLvlei8kO2d00R52CQ8Kj',
    websiteLoginUrl: 'https://staging-admin-web.campgladiator.com/login',
    cgTrainerEmail: 'careers123@campgladiator.com',
    isCancellationReasonFeatureVisible: true,
    cgTrainerName: 'Camp Gladiator',
  },
  production: {
    apiGatewayUrl: 'https://gateway.campgladiator.com',
    documentsUrl: 'https://campgladiator.com/api/retrieve-contract',
    cookieKeys,
    stripePublishableKey:
      'pk_live_51Kpj4SJWDZbdRfZoXKv6czog5ujKioxFHwRozEzAdwHlbtwIofZRnmOXCodoPvxuHVoRpiypQue2FmXk2K2CXPmm004RwwctUF',
    websiteLoginUrl: 'https://admin-web.campgladiator.com/login',
    cgTrainerEmail: 'careers123@campgladiator.com',
    isCancellationReasonFeatureVisible: true,
    cgTrainerName: 'Camp Gladiator',
  },
}

const configuration = { ...config[REACT_APP_BUILD_ENVIRONMENT as string] }

export default configuration
