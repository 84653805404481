import { useState } from 'react'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { useGrowlContext } from 'app/contexts/growl-context'
import { setLastRefreshTime } from 'app/redux/features/team/team-reducer'
import { useAppDispatch } from 'app/redux/store'
import { putTeam } from 'services/api/team'
import { Locale } from 'types/locale'
import { TeamForm } from '../team-form'
import {
  ChangePageFunction,
  TeamPages,
} from '../teams-container/teams-container'
import styles from './edit-team.module.scss'

export type TeamEditProps = {
  changePage: ChangePageFunction
  team: Locale
}

const EditTeam = ({ changePage, team }: TeamEditProps) => {
  const dispatch = useAppDispatch()
  const [isSubmitting, setSubmitting] = useState(false)
  const { showGrowl } = useGrowlContext()

  const changePageToList = () => {
    changePage(TeamPages.List)
  }

  const handleSubmit = async (values: Locale) => {
    setSubmitting(true)
    try {
      await putTeam(team.id!, values)
      dispatch(setLastRefreshTime(Date.now()))
      showGrowl('SUCCESS', 'Team updated successfully')
      setTimeout(changePageToList, 1000)
    } catch (error: any) {
      showGrowl('FAILED', error)
    }

    setSubmitting(false)
  }

  return (
    <div>
      <header className={styles.headerContainer}>
        <div
          className={styles.backIconWrapper}
          onClick={() => changePageToList()}
        >
          <Icon.Line className={styles.backIcon} name={'icon-angle-left-b'} />
        </div>
        <Heading type="h3">Edit Team</Heading>
      </header>

      <TeamForm
        isEdit
        localeData={team}
        onSubmit={(value: Locale) => {
          handleSubmit(value)
        }}
        isSubmitting={isSubmitting}
      />
    </div>
  )
}

export default EditTeam
