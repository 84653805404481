import {
  CheckinDTO,
  TotalCheckinsDTO,
} from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { request } from 'app/networking/fetch'
import {
  BaseEnumerableResponse,
  SortingDirection,
} from 'app/networking/types/network'
import configuration from 'config/configuration'
import { AuthToken } from 'types/authentication'

const baseUrl = configuration.apiGatewayUrl

export const postCheckIn = async ({
  checkIn,
  invalidateUserOnUnauthorizedResponse,
  updateUser,
}: {
  checkIn: Partial<CheckinDTO>
  authToken?: AuthToken
  invalidateUserOnUnauthorizedResponse?: (error: string) => void
  updateUser?: (authToken: AuthToken) => void
}): Promise<CheckinDTO> => {
  const endpoint = '/engagement/v1/checkins'
  const method = 'POST'
  try {
    const response = await request({
      baseUrl,
      body: JSON.stringify(checkIn),
      endpoint,
      method,
      invalidateUserAccessCallback: invalidateUserOnUnauthorizedResponse,
      updateUser,
    })

    const result = await response.json()
    return response.ok ? result : Promise.reject(result.message)
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-add-checkIn',
      attributeName: 'post-checkIn',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getCheckinsCountByUserId = async ({
  userId,
  invalidateUserOnUnauthorizedResponse,
  updateUser,
}: {
  userId: string
  invalidateUserOnUnauthorizedResponse?: (error: string) => void
  updateUser?: (authToken: AuthToken) => void
}): Promise<TotalCheckinsDTO> => {
  const endpoint = `/engagement/v1/checkins/users/${userId}/count`
  const method = 'GET'
  try {
    const response = await request({
      baseUrl,
      endpoint,
      method,
      invalidateUserAccessCallback: invalidateUserOnUnauthorizedResponse,
      updateUser,
    })

    const result = await response.json()
    return response.ok ? result : Promise.reject(result.message)
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    throw new Error(errorMessage as string)
  }
}

export const fetchCheckins = async ({
  updateUser,
  userId,
  page,
  size,
  sort,
  invalidateUserOnUnauthorizedResponse,
}: {
  invalidateUserOnUnauthorizedResponse?: (error: string) => void
  page: number
  size: number
  sort: SortingDirection
  userId: string
  updateUser?: (authToken: AuthToken) => void
}): Promise<BaseEnumerableResponse<CheckinDTO>> => {
  const endpoint = '/engagement/v1/checkins'
  const params = `?userId=${userId}&page=${page}&size=${size}&sort=${sort}`
  const method = 'GET'
  try {
    const response = await request({
      baseUrl,
      endpoint,
      method,
      params,
      invalidateUserAccessCallback: invalidateUserOnUnauthorizedResponse,
      updateUser,
    })

    const result = await response.json()
    return response.ok ? result : Promise.reject(result.message)
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-checkIns',
      attributeName: 'get-checkIns',
      value: new Error(error as string),
    })
    throw new Error(errorMessage as string)
  }
}
