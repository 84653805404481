import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { OnDemandVideoForm } from 'components/module/ondemand-video/ondemand-video-form'
import styles from './create.module.scss'

const CreateOnDemandVideo = () => (
  <Card flat className={styles.container}>
    <header className={styles.header}>
      <Heading className={styles.title} type="h3">
        Upload OnDemand Video
      </Heading>
    </header>

    <OnDemandVideoForm />
  </Card>
)

export default CreateOnDemandVideo
