import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Toggle } from '@campgladiator/cgui-core.atoms.toggle'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { RPAssignmentSection } from 'components/module/trainer/rp-assignment-section'
import {
  assignmentSchema,
  totalAssignedPercentSchema,
} from 'components/module/trainer/rp-assignment-section/hooks/use-rp-assignment-section'
import {
  AssignmentHoldingForm,
  defaultAssignment,
} from 'components/module/trainer/rp-assignment-section/rp-assignment-section'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { Locale } from 'types/locale'
import * as Yup from 'yup'
import styles from './team-form.module.scss'

interface ILocaleFormProps {
  isEdit?: boolean
  onSubmit: (values: Locale) => void
  isSubmitting?: boolean
  localeData?: Locale | undefined
}

const validationSchema = () =>
  Yup.object({
    name: Yup.string().required('Team name is required'),
    assignments: assignmentSchema,
    totalAssignedPercent: totalAssignedPercentSchema,
  })

const TeamForm = ({
  isEdit,
  onSubmit,
  isSubmitting,
  localeData,
}: ILocaleFormProps) => {
  const { id } = useParams()

  const formik = useFormik({
    initialValues: {
      type: 'TEAM',
      name: localeData ? localeData.name : '',
      deleted: false,
      assignments:
        localeData?.assignments && !isEmpty(localeData.assignments)
          ? localeData.assignments
          : [{ ...defaultAssignment }],
      active: localeData ? localeData.active : true,
      parentId: id,
    } as Locale & { totalAssignedPercent: number },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: AssignmentHoldingForm) => {
      onSubmit({
        ...values,
        assignments: values.assignments
          .filter(
            (it: { trainerId: string; commissionPercentage: number | null }) =>
              it.trainerId,
          )
          .map(({ trainerId, commissionPercentage }) => ({
            trainerId,
            commissionPercentage,
          })),
      })
    },
  })

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
  } = formik

  useEffect(() => {
    if (!isSubmitting && !isEdit) resetForm()
    //eslint-disable-next-line
  }, [isSubmitting, isEdit])

  useEffect(() => {
    if (localeData?.assignments && !isEmpty(localeData.assignments)) {
      setFieldValue('assignments', localeData.assignments)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit} className={styles.formSection}>
        <FormPart
          label="Team Name*"
          forId="name"
          errorMsg={(touched.name && errors.name) || undefined}
        >
          <Input id="name" placeholder="Name" {...getFieldProps('name')} />
        </FormPart>

        {isEdit && (
          <>
            <FormPart label="Parent Region" forId="parentRegionName">
              <Input
                id="parentRegionName"
                name="parentRegionName"
                placeholder="Region"
                disabled
                value={localeData ? localeData.parentRegionName : undefined}
              />
            </FormPart>

            <FormPart label="Active" forId="active">
              <Toggle
                checked={values.active}
                onChange={(e) => setFieldValue('active', e.target.checked)}
              />
            </FormPart>
          </>
        )}
        <RPAssignmentSection formik={formik} type="TEAM" />
        <div className={styles.buttons}>
          <Button
            emphasis="primary"
            className={styles.submitButton}
            type="submit"
            disabled={isSubmitting}
            theme="trainer"
          >
            {!isEdit ? 'Create' : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default TeamForm
