import { TrainerDTO } from '@campgladiator/cg-common.types.types'
import { map } from 'lodash'
import { LocaleAssignment } from 'types/locale'
import * as Yup from 'yup'
import { RPAssignmentSectionProps } from '../rp-assignment-section'

export const assignmentSchema = Yup.array().of(
  Yup.object().shape(
    {
      trainerId: Yup.string().when('commissionPercentage', {
        is: (val: number | null) => (val || 0) > 0,
        then: (schema) => schema.required('Trainer is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
      commissionPercentage: Yup.number()
        .max(100, 'Commission % must not be greater than 100')
        .min(0.1, 'Commission % must be greater than 0')
        .transform((value) => (value === 0 ? null : value))
        .when('trainerId', {
          is: (val: string | null) => val && val.length > 0,
          then: (schema) => schema.required('Commission % is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
    },
    [
      ['trainerId', 'commissionPercentage'],
      ['commissionPercentage', 'trainerId'],
    ],
  ),
)

export const totalAssignedPercentSchema = Yup.number().test(
  'is-percentage-100',
  'Total commission % should be 100',
  (_, context) => {
    const totalCommission = context.parent.assignments.reduce(
      (acc: number, curr: LocaleAssignment) =>
        acc + Number(curr.commissionPercentage || 0),
      0,
    )

    return totalCommission === 100 || totalCommission === 0
  },
)

type UseRPAssignmentSectionArgs = Pick<
  RPAssignmentSectionProps,
  'formik' | 'type'
>

const useRPAssignmentSection = ({
  formik,
  type,
}: UseRPAssignmentSectionArgs) => {
  const { getFieldProps, setFieldValue, errors, touched, values } = formik

  const formTypeLabel =
    type === 'REGION_GROUP' ? 'RVP' : type === 'TEAM' ? 'RVP/FGD/TL' : 'RVP/FGD'

  const filterOption = (trainer: TrainerDTO, type: string) => {
    const trainerTypes = [
      'REGION_VICE_PRESIDENT',
      'FIELD_GROWTH_DIRECTOR',
      type === 'TEAM' && 'TEAM_LEAD',
      'FIELD_GROWTH_DIRECTOR',
      'REGION_VICE_PRESIDENT',
    ]
    const regionVP = ['REGION_VICE_PRESIDENT']

    const filteredTrainerTypes =
      type === 'REGION_GROUP' ? regionVP : trainerTypes

    const filteredResults =
      filteredTrainerTypes.includes(trainer.type!) &&
      !map(values.assignments, 'trainerId').includes(trainer.id!)

    return filteredResults
  }

  return {
    errors,
    formTypeLabel,
    touched,
    values,
    filterOption,
    getFieldProps,
    setFieldValue,
  }
}
export default useRPAssignmentSection
