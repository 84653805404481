import {
  BaseEnumerableResponse,
  TrainerBioDTO,
  TrainerDTO,
} from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import { objectToQueryString } from 'utils/url'

const baseUrl = configuration.apiGatewayUrl
let controller: any = null

export const getTrainers = async (
  params?: any,
): Promise<TrainerDTO | BaseEnumerableResponse<TrainerDTO>> => {
  try {
    const endpoint = '/trainer/v1/trainers'
    const method = 'GET' as HttpMethod

    if (controller) {
      controller.abort()
    }

    controller = new AbortController()

    const response = await request({
      baseUrl,
      endpoint,
      method,
      params: objectToQueryString(params),
      signal: controller.signal,
    })

    const result = await response.json()
    const parsedResult = 'content' in result ? result.content : [result]
    return response.ok ? parsedResult : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-trainers-library',
      attributeName: 'get-trainers',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const convertUserIntoTrainer = async (
  args: Partial<TrainerDTO>,
): Promise<TrainerDTO> => {
  const endpoint = '/trainer/v1/trainers'
  const method = 'POST'
  const body = JSON.stringify(args)

  try {
    const response = await request({
      baseUrl,
      body,
      endpoint,
      method,
    })
    const result = await response.json()
    return response.ok ? result : Promise.reject(result.message || result.error)
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-create-trainer-error',
      attributeName: 'post-create-trainer',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const updateTrainer = async (
  args: Partial<TrainerDTO>,
  userId: string,
): Promise<TrainerDTO> => {
  const endpoint = `/trainer/v1/trainers/${userId}`
  const method = 'PUT'
  const body = JSON.stringify(args)

  try {
    const response = await request({
      baseUrl,
      body,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok ? result : Promise.reject(new Error(result.message))
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-update-trainer-error',
      attributeName: 'post-update-trainer',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getTrainerByUserId = async (
  userId: string,
): Promise<TrainerDTO> => {
  try {
    const endpoint = `/trainer/v1/trainers?userId=${userId}`
    const method = 'GET' as HttpMethod
    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()
    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-trainer-by-userId-error',
      attributeName: 'get-trainer-by-userId',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getTrainerById = async (id: string): Promise<TrainerDTO> => {
  try {
    const endpoint = `/trainer/v1/trainers/${id}?includeDeleted=true`
    const method = 'GET' as HttpMethod
    const response = await request({
      baseUrl,
      endpoint,
      method,
    })
    const result = await response.json()
    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-trainers-library',
      attributeName: 'get-trainer-by-id',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getTrainerByFilter = async (
  searchType: string,
  name: string,
  signal: AbortSignal,
): Promise<TrainerDTO[]> => {
  try {
    const endpoint = `/trainer/v1/trainers?${searchType}=${name}&includeDeleted=true`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method, signal })
    const result = await response.json()
    const parsedResult = 'content' in result ? result.content : [result]

    return response.ok
      ? parsedResult
      : Promise.reject({ statusCode: response.status, message: result.message })
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: `keanu-get-trainer-by-${searchType}-error`,
      attributeName: `get-trainer-by-${searchType}`,
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putUpdateTrainerBios = async (
  id: string,
  args: Partial<TrainerBioDTO>,
): Promise<TrainerDTO> => {
  const endpoint = `/trainer/v1/trainers/${id}/bios`
  const method = 'PUT'
  const body = JSON.stringify(args)

  try {
    const response = await request({
      baseUrl,
      body,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok ? result : Promise.reject(new Error(result.message))
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-update-trainer-bio',
      attributeName: 'put-update-trainer-bio',
      value: new Error(error as string),
    })

    throw new Error(errorMessage as string)
  }
}
