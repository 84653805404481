import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PaymentMethodDTO } from '@campgladiator/cg-common.types.types'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { useGrowlContext } from 'app/contexts/growl-context'
import { activeUserSelector } from 'app/redux/features/active-user/active-user-reducer'
import { getAllPaymentMethods } from 'services/api/payment'
import styles from './billing.module.scss'
import { MembershipPayment } from './membership-payment'
import { PaymentCard } from './payment-card'
import { NewPaymentCardModal } from './payment-card/new-payment-card-modal'

const Billing = () => {
  const [isAddCardModalVisible, setAddCardModalVisible] =
    useState<boolean>(false)

  const { showGrowl } = useGrowlContext()
  const user = useSelector(activeUserSelector)
  const [cards, setPaymentCards] = useState<PaymentMethodDTO[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const getPaymentMethods = async (stripeCustomerId: string) => {
    setLoading(true)
    try {
      const response = await getAllPaymentMethods(stripeCustomerId)
      setLoading(false)
      if (response) {
        setPaymentCards(response)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user?.stripeCustomerId) {
      getPaymentMethods(user.stripeCustomerId)
    }
  }, [user?.stripeCustomerId])

  const handleAfterIsCardSaved = async (paymentMethodId?: string) => {
    if (user?.stripeCustomerId) {
      await getPaymentMethods(user.stripeCustomerId)
    }
  }

  const handleUpdateCardSuccess = () => {
    showGrowl('SUCCESS', 'Payment method updated successfully')
    handleAfterIsCardSaved()
  }

  return (
    <div className={styles.formContainer}>
      {user?.stripeCustomerId ? (
        <>
          <NewPaymentCardModal
            user={user}
            isAddCardModalVisible={isAddCardModalVisible}
            setAddCardModalVisible={setAddCardModalVisible}
            onAfterCardIsSaved={handleAfterIsCardSaved}
          />
          <PaymentCard
            onAddNewCardClick={() => setAddCardModalVisible(true)}
            onUpdateCardComplete={handleUpdateCardSuccess}
            cards={cards}
            loading={loading}
          />
          <MembershipPayment />
        </>
      ) : (
        <Paragraph>
          This user does not have a Stripe customer ID. No billing information
          can be displayed.
        </Paragraph>
      )}
    </div>
  )
}

export default Billing
