import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { loadProgramDetails } from 'app/redux/features/active-program/active-program-actions'
import { activeProgramSelector } from 'app/redux/features/active-program/active-program-reducer'
import { useAppDispatch } from 'app/redux/store'
import { MainLayout } from 'components/layouts/main-layout'
import { TabPanel, Tabs } from 'components/layouts/tabs'
import EditProgram from 'components/module/program/edit-program'
import ProgramSidePanel from 'components/module/program/program-side-panel'
import { ScheduleContainer } from 'components/module/program/schedule/schedule-container'
import { WorkoutsList } from 'components/module/program/workouts/workouts-list'
import styles from './details.module.scss'

const ProgramDetails = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const programDetails = useSelector(activeProgramSelector)

  useEffect(() => {
    if (id) dispatch(loadProgramDetails(id))
  }, [id, dispatch])

  return (
    <MainLayout
      sidePanel={<ProgramSidePanel programDetails={programDetails} />}
    >
      <>
        <div className={styles.tabViewContainer}>
          <Tabs>
            <TabPanel title="Basic info">
              <EditProgram programDetails={programDetails} />
            </TabPanel>
            <TabPanel title="Schedule">
              <ScheduleContainer />
            </TabPanel>
            <TabPanel title="Workouts">
              <WorkoutsList programDetails={programDetails} />
            </TabPanel>
          </Tabs>
        </div>
      </>
    </MainLayout>
  )
}

export default ProgramDetails
