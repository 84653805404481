import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  Table,
  TRow,
  TData,
  THead,
} from '@campgladiator/cgui-core.organisms.table'
import ApiAutocomplete from 'components/form-ui/api-auto-complete'
import { PageTitleWithSearchInputAndNewButton } from 'components/module/shared/page-title-search-input-new-button'
import styles from './view.module.scss'
import { useViewOnDemandVideos } from '.'

const ViewOnDemandVideos = () => {
  const {
    editButtonProps,
    deleteButtonProps,
    hasMore,
    loadMoreButtonProps,
    isLoading,
    onDemandVideos,
    pageTitleWithSearchInputAndNewButtonProps,
    trainerSearchAutoCompleteProps,
  } = useViewOnDemandVideos()
  return (
    <div className={styles.page}>
      <PageTitleWithSearchInputAndNewButton
        {...pageTitleWithSearchInputAndNewButtonProps}
      />

      <div className={styles.searchSection}>
        <ApiAutocomplete {...trainerSearchAutoCompleteProps} />
      </div>

      <div className={styles.tableWrapper}>
        <Table.Manual>
          <thead>
            <TRow>
              <THead>
                <Heading type="h6">Trainer</Heading>
              </THead>
              <THead>
                <Heading type="h6">Title</Heading>
              </THead>
              <THead>
                <Heading type="h6">Date Added</Heading>
              </THead>
              <THead>
                <Heading type="h6">Edit</Heading>
              </THead>
            </TRow>
          </thead>
          <tbody>
            {!onDemandVideos.length && !isLoading ? (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    No videos to display
                  </Paragraph>
                </TData>
              </TRow>
            ) : (
              onDemandVideos.map((workout) => (
                <TRow key={workout.id}>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {`${workout.trainer.firstName} ${workout.trainer.lastName}`}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {workout.title}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {new Date(workout.created).toLocaleDateString()}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Button {...editButtonProps}>
                      {editButtonProps.children}
                    </Button>
                    <Button {...deleteButtonProps}>
                      {deleteButtonProps.children}
                    </Button>
                  </TData>
                </TRow>
              ))
            )}
            {isLoading && (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    <Loader />
                  </Paragraph>
                </TData>
              </TRow>
            )}
          </tbody>
        </Table.Manual>
        {hasMore && (
          <div className={styles.buttonWrapper}>
            <Button {...loadMoreButtonProps}>
              {loadMoreButtonProps.children}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ViewOnDemandVideos
