import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppAccess } from 'app/contexts/app-access'
import { activeUserSelector } from 'app/redux/features/active-user/active-user-reducer'
import { fetchCheckins } from 'services/api/checkIn'
import { getWorkoutsById } from 'services/api/workout'
import { CheckInWithWorkout } from 'types/checkIn'

const useWithCheckInsList = () => {
  const [checkins, setCheckins] = useState<CheckInWithWorkout[]>([])
  const [isLoading, setIsLoading] = useState<boolean>()
  const size = 10
  const [page, setPage] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)

  const user = useSelector(activeUserSelector)
  const { authToken, invalidateUserOnUnauthorizedResponse, updateUser } =
    useAppAccess()

  useEffect(() => {
    if (user?.id && authToken) {
      setIsLoading(true)
      fetchCheckins({
        userId: user?.id,
        page,
        size,
        sort: 'DESC',
        invalidateUserOnUnauthorizedResponse,
        updateUser,
      })
        .then(async ({ content, totalPages }) => {
          setTotalPages(totalPages)
          const checkInsWithWorkout = await Promise.all(
            content.map(async (checkIn) => {
              const checkinWithWorkout = checkIn as CheckInWithWorkout
              const workout = await getWorkoutsById(checkIn.workoutId)
              checkinWithWorkout.workout = workout
              return checkinWithWorkout
            }),
          )
          setCheckins(checkInsWithWorkout)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page])

  return { checkins, isLoading, setPage, size, page, totalPages }
}

export default useWithCheckInsList
