import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OrganizationDTO } from '@campgladiator/cg-common.types.types'
import { ButtonProps } from '@campgladiator/cgui-core.atoms.button'
import { debounce } from 'lodash'
import { getAllOrganizations } from 'services/api/organization'

const useView = () => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchedOrganizations, setSearchedOrganizations] = useState<
    OrganizationDTO[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [isLastPage, setIsLastPage] = useState<boolean>(false)

  const size = 40

  const isSearchApplied = searchTerm.length > 2
  const organizationsList = searchedOrganizations

  const pageTitleProps = {
    title: 'Partnerships',
    placeholder: 'Search for an organization',
    showSearchInput: true,
    onSearch: (value: string) => setSearchTerm(value),
    newButtonText: 'Add Organization',
    onNewButtonClick: () => navigate('/partnerships/create'),
  }

  const searchOrganizations = useCallback(async () => {
    setIsLoading(true)
    try {
      const searchQuery = isSearchApplied ? searchTerm : undefined
      const page = isSearchApplied ? 0 : currentPage
      const pageSize = isSearchApplied ? 100 : size
      const organizations = await getAllOrganizations(
        searchQuery,
        page,
        pageSize,
      )
      if (organizations) {
        setSearchedOrganizations(organizations)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchApplied, searchTerm])

  const loadMore = useCallback(async () => {
    if (isSearchApplied || isLoading) return

    setIsLoading(true)
    try {
      const nextPage = currentPage + 1
      const moreOrganizations = await getAllOrganizations(
        undefined,
        nextPage,
        size,
      )
      if (moreOrganizations) {
        setSearchedOrganizations((prevOrganizations) => [
          ...prevOrganizations,
          ...moreOrganizations,
        ])
        setCurrentPage(nextPage)
        if (moreOrganizations.length < size) {
          setIsLastPage(true)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [isSearchApplied, isLoading, currentPage, size])

  const loadAllPagesUpToCurrent = useCallback(async () => {
    if (searchTerm !== '') return

    setIsLoading(true)
    try {
      let allOrganizations: any = []
      for (let i = 0; i <= currentPage; i++) {
        const organizations = await getAllOrganizations(undefined, i, size)
        allOrganizations = [...allOrganizations, ...organizations]
      }

      setSearchedOrganizations(allOrganizations)
      if (allOrganizations.length % size !== 0) {
        setIsLastPage(true)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [searchTerm, currentPage, size])

  useEffect(() => {
    loadAllPagesUpToCurrent()
  }, [loadAllPagesUpToCurrent])

  const buttonProps: ButtonProps = {
    children: isLoading ? 'Loading...' : 'Load More',
    disabled: isLoading || isLastPage,
    emphasis: 'secondary',
    size: 'large',
    theme: 'trainer',
    onClick: loadMore,
  }

  const delayedSearchQuery = useMemo(
    () => debounce(searchOrganizations, 300),
    [searchOrganizations],
  )

  useEffect(() => {
    delayedSearchQuery()
    return delayedSearchQuery.cancel
  }, [searchTerm, delayedSearchQuery])

  useEffect(() => {
    if (searchTerm !== '' || searchTerm === '') {
      setSearchedOrganizations([])
    }
  }, [searchTerm])

  return {
    buttonProps,
    isSearchApplied,
    isLoading,
    organizationsList,
    pageTitleProps,
    navigate,
  }
}

export default useView
