import { useState } from 'react'
import { CreateSchedule } from 'pages/schedules/create'
import { ScheduleEdit } from '../schedule-edit'
import { ScheduleList } from '../schedule-list'

export enum SchedulePages {
  List,
  Create,
  Edit,
}

export type ChangePageFunction = (page: SchedulePages, data?: any) => void

const ScheduleContainer = () => {
  const [currentPage, setCurrentPage] = useState<SchedulePages>(
    SchedulePages.List,
  )
  const [data, setData] = useState<any>({})

  const changePage: ChangePageFunction = (page: SchedulePages, data = {}) => {
    setCurrentPage(page)
    setData(data)
  }

  return (
    <>
      {currentPage === SchedulePages.Create && (
        <CreateSchedule changePage={changePage} {...data} />
      )}
      {currentPage === SchedulePages.Edit && (
        <ScheduleEdit changePage={changePage} {...data} />
      )}
      {currentPage === SchedulePages.List && (
        <ScheduleList changePage={changePage} {...data} />
      )}
    </>
  )
}

export default ScheduleContainer
