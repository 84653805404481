import { UserDTO } from '@campgladiator/cg-common.types.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/redux/store'
import { SubscriptionSummary } from 'types/subscription'

export type ActiveUserType = {
  userDetails: UserDTO | null
  membershipSummary: SubscriptionSummary[] | null
}

export const initialState = {
  userDetails: null,
  membershipSummary: null,
} as ActiveUserType

const activeUserReducer = createSlice({
  name: 'activeUser',
  initialState,
  reducers: {
    setActiveUser: (state: ActiveUserType, action: PayloadAction<UserDTO>) => {
      state.userDetails = action.payload
    },
    clearActiveUser: () => initialState,
    setMembershipSummary: (
      state: ActiveUserType,
      action: PayloadAction<SubscriptionSummary[]>,
    ) => {
      state.membershipSummary = action.payload
    },
    clearMembershipSummary: (state: ActiveUserType) => {
      state.membershipSummary = null
    },
  },
})

export const {
  setActiveUser,
  clearActiveUser,
  setMembershipSummary,
  clearMembershipSummary,
} = activeUserReducer.actions

export const activeUserSelector = (state: RootState) =>
  state.activeUser.userDetails

export const activeUserMembershipSummarySelector = (state: RootState) =>
  state.activeUser.membershipSummary

export default activeUserReducer.reducer
