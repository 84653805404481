import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/redux/store'
import { AuthState } from '.'

export const initialState: AuthState = {
  isLoading: false,
  isLoggedIn: false,
  error: null,
  authToken: null,
}

export const authSlice = createSlice({
  name: 'authNew',
  initialState,
  reducers: {
    setClearError: (state: AuthState) => {
      state.error = initialState.error
    },
    setLoginPending: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setLoginSuccess: (
      state: AuthState,
      action: PayloadAction<AuthState['authToken']>,
    ) => {
      state.isLoading = initialState.isLoading
      state.authToken = action.payload
      state.isLoggedIn = true
      state.error = initialState.error
    },
    setLoginFailure: (
      state: AuthState,
      action: PayloadAction<AuthState['error']>,
    ) => {
      state.isLoading = initialState.isLoading
      state.isLoggedIn = initialState.isLoggedIn
      state.error = action.payload
      state.authToken = initialState.authToken
    },
    setLogoutSuccess: () => initialState,
  },
})

export const {
  setClearError,
  setLoginFailure,
  setLoginPending,
  setLoginSuccess,
  setLogoutSuccess,
} = authSlice.actions
export const authSelector = (state: RootState) => state.authNew
export default authSlice.reducer
