import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { postRegionGroup } from 'services/api/locale'
import { RegionGroup } from 'types/locale'

const useCreate = () => {
  const [isGrowlVisible, setIsGrowlVisible] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [growlContent, setGrowlContent] = useState('')
  const navigate = useNavigate()

  const handleSubmit = async (value: RegionGroup) => {
    setSubmitting(true)

    try {
      const response = await postRegionGroup(value)
      if (response) {
        setGrowlView(true, 'Region group saved successfully')
        const id = response.id
        setTimeout(
          () => navigate(`/region-groups/${response.id}`, { state: { id } }),
          1000,
        )
      }
    } catch (error: any) {
      setGrowlView(true, error.message)
    } finally {
      setSubmitting(false)
    }
  }

  const setGrowlView = (isVisible: boolean, growlContent: string) => {
    setIsGrowlVisible(isVisible)
    setGrowlContent(growlContent)
  }

  const clearError = () => {
    setIsGrowlVisible(false)
  }

  return {
    growlContent,
    isGrowlVisible,
    isSubmitting,
    clearError,
    handleSubmit,
  }
}

export default useCreate
