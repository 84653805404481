import classNames from 'classnames'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import Auth from 'app/auth/auth'
import styles from './login.module.scss'

function Login() {
  return (
    <div className={classNames(styles.container, grid.container)}>
      <Card flat>
        <Auth redirectPathOnLoginSuccess={'/users'} />
      </Card>
    </div>
  )
}

export default Login
