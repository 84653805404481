import { useCallback, useEffect, useState } from 'react'
import { fetchCoupons } from '@campgladiator/cg-common.api.coupons'
import {
  OrganizationDTO,
  CouponDTO,
} from '@campgladiator/cg-common.types.types'

export type OrganizationCouponCodeDropdownItem = {
  value: OrganizationDTO['couponCode']
  label: string
}

const convertCouponCodeToDropdownItem = (
  coupon: CouponDTO,
): OrganizationCouponCodeDropdownItem => ({
  value: coupon.stripeCouponId,
  label: coupon.stripeCouponName,
})

const useOrganizationCoupon = () => {
  const [couponCodeDropdownItems, setCouponCodeDropdownItems] = useState<
    OrganizationCouponCodeDropdownItem[]
  >([])
  const [couponError, setCouponError] = useState<string | null>(null)

  const getOrgSpecificCouponCodes = useCallback(async () => {
    try {
      const coupons = await fetchCoupons({ includeAdminOnly: true })
      const orgSpecificCoupons = coupons.filter(({ stripeCouponId }) =>
        stripeCouponId.includes('CORPORATE_WELLNESS_'),
      )
      const orgCouponsToDropdownItems = orgSpecificCoupons.map(
        convertCouponCodeToDropdownItem,
      )
      if (!orgCouponsToDropdownItems.length)
        throw new Error(
          'No organization specific coupons found. Please ensure a coupon exists with a stripeCouponId that includes the prefix CORPORATE_WELLNESS_',
        )
      return orgCouponsToDropdownItems
    } catch (error: any) {
      const defaultError =
        'Unable to fetch coupons. Please refresh the page to try again.'
      const couponError = error instanceof Error ? error.message : defaultError
      setCouponError(couponError)
      return []
    }
  }, [])

  const setCoupons = useCallback(async () => {
    const coupons = await getOrgSpecificCouponCodes()
    setCouponCodeDropdownItems(coupons)
  }, [getOrgSpecificCouponCodes])

  useEffect(() => {
    setCoupons()
  }, [setCoupons])

  return { couponCodeDropdownItems, couponError }
}

export default useOrganizationCoupon
