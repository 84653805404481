import styles from './attachment-field.module.scss'
import { AttachmentWrapper } from './components/attachment-wrapper'
import { PhotoAttachment } from './components/photo-attachment'
import { deriveContentType, isPhotoOrVideo } from './logic'

type AttachmentFieldProps = {
  attachment: File
  handleRemoveAttachment?: (attachment: File) => void
}

const AttachmentField = ({
  attachment,
  handleRemoveAttachment,
}: AttachmentFieldProps) => (
  <div className={styles.wrapper}>
    <div className={styles.attachmentField}>
      {isPhotoOrVideo(attachment) && (
        <AttachmentWrapper
          handleRemoveAttachment={() =>
            handleRemoveAttachment && handleRemoveAttachment(attachment)
          }
        >
          {deriveContentType(attachment) === 'PHOTO' && (
            <PhotoAttachment attachment={attachment} />
          )}
        </AttachmentWrapper>
      )}
    </div>
  </div>
)

export default AttachmentField
