import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonProps } from '@campgladiator/cgui-core.atoms.button'
import { NewTransactionType } from './new-transaction'

const useWithNewTransaction = () => {
  const [newTransactionType, setNewTransactionType] =
    useState<NewTransactionType | null>(NewTransactionType.FreezeFee)

  const navigate = useNavigate()

  const newTransactionTypeButtonEmphasis = 'secondary'
  const freezeFeeButtonVariation =
    newTransactionType === NewTransactionType.FreezeFee ? 'solid' : 'outline'
  const trainerTipButtonVariation =
    newTransactionType === NewTransactionType.TrainerTip ? 'solid' : 'outline'
  const headingText = 'New Transaction'

  const handleNewTransactionTypeButtonClick = (type: NewTransactionType) => {
    setNewTransactionType(type)
  }

  const newTransactionTypeButtonsText: ButtonProps[] = [
    {
      children: NewTransactionType.FreezeFee,
      onClick: () =>
        handleNewTransactionTypeButtonClick(NewTransactionType.FreezeFee),
      emphasis: newTransactionTypeButtonEmphasis,
      variation: freezeFeeButtonVariation,
    },
    {
      children: NewTransactionType.TrainerTip,
      onClick: () =>
        handleNewTransactionTypeButtonClick(NewTransactionType.TrainerTip),
      emphasis: newTransactionTypeButtonEmphasis,
      variation: trainerTipButtonVariation,
    },
  ]

  const newTransactionTypeButtons: ButtonProps[] =
    newTransactionTypeButtonsText.map(
      ({ children, emphasis, variation, onClick }) => ({
        children,
        size: 'small',
        variation,
        emphasis,
        buttonType: 'button',
        onClick,
      }),
    )

  const handleBackClick = () => navigate(-1)

  return {
    headingText,
    newTransactionType,
    newTransactionTypeButtons,
    handleBackClick,
  }
}

export default useWithNewTransaction
