import { useState } from 'react'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { ScheduleProgramForm } from 'components/module/program/schedule-program-form'
import { postPrograms } from 'services/api/program'
import { Program } from 'types/program'
import styles from './create.module.scss'

const CreateProgram = () => {
  const [alert, setAlert] = useState({
    open: false,
    title: '',
    text: '',
  })

  const handleAlertClose = () => {
    setAlert({
      open: false,
      title: '',
      text: '',
    })
  }

  const handleSaveProgram = (formData: Program) => {
    return new Promise(async (resolve, reject) => {
      try {
        await postPrograms(formData)
        setAlert({
          open: true,
          title: 'SUCCESS',
          text: 'Program saved successfully',
        })

        resolve('done')
      } catch (error: any) {
        setAlert({
          open: true,
          title: 'FAILED',
          text: error,
        })

        reject('failed')
      }
    })
  }

  return (
    <Card flat className={styles.container}>
      <header className={styles.header}>
        <Heading className={styles.title} type="h3">
          Create Program
        </Heading>
      </header>
      <div>
        <GrowlMessage
          isVisible={alert.open}
          onClick={handleAlertClose}
          title={alert.title}
        >
          {alert.text}
        </GrowlMessage>
        <ScheduleProgramForm onSubmitForm={handleSaveProgram} />
      </div>
    </Card>
  )
}

export default CreateProgram
