import classNames from 'classnames'
import { PaymentMethodDTO } from '@campgladiator/cg-common.types.types'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Divider } from '@campgladiator/cgui-core.atoms.divider'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import PaymentCardItem from './payment-card-item/payment-card-item'
import styles from './payment-card.module.scss'

export type PaymentCardProps = {
  cards: PaymentMethodDTO[]
  formLabel: string
  id: string
  loading: boolean
  selectedCardId: string | null
  setSelectedCardId: (id: string) => void
}

const PaymentCard = ({
  cards,
  formLabel,
  id,
  loading,
  selectedCardId,
  setSelectedCardId,
}: PaymentCardProps) => (
  <FormPart label={formLabel} forId={id}>
    <Card flat className={styles.cardContainer}>
      <div className={styles.paymentContainer}>
        <Paragraph size="small" className={styles.lblPaymentMethods}>
          Payment Methods
        </Paragraph>
      </div>

      <Divider />

      <div className={classNames(grid.row, styles.cardRow, styles.cardList)}>
        {loading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : cards && cards.length > 0 ? (
          cards.map((card, i) => (
            <div key={i} className={classNames(grid.col12, styles.cardItem)}>
              <PaymentCardItem
                card={card}
                selected={card.id === selectedCardId}
                onSelected={setSelectedCardId}
              />
            </div>
          ))
        ) : (
          <Paragraph className={styles.emptyList} size="small" weight="book">
            The user doesn't currently have any credit cards saved
          </Paragraph>
        )}
      </div>
    </Card>
  </FormPart>
)

export default PaymentCard
