import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useCookies } from 'react-cookie'
import { KeanuUserRole, UserDTO } from '@campgladiator/cg-common.types.types'
import { configuration } from 'config'
import {
  fetchKeanuUserById,
  getAuthorizationByToken,
} from 'services/api/authentication'
import { AppAccessAuthToken, AuthToken } from 'types/authentication'

type AuthState = {
  authToken: AppAccessAuthToken
  invalidateUserAccess: (reason: any) => void
  invalidateUserOnUnauthorizedResponse: (error: any) => void
  isLoggedIn: boolean
  loggedInAdmin: Partial<UserDTO>
  updateUser: (authToken: AuthToken) => void
  validateUserAccess: () => void
}

type AuthProviderProps = { children: ReactNode }

const authorizedClaims: KeanuUserRole[] = ['ADMIN', 'HQ']

const initialState: AuthState = {
  authToken: null,
  invalidateUserAccess: () => {},
  invalidateUserOnUnauthorizedResponse: () => {},
  isLoggedIn: false,
  loggedInAdmin: {},
  updateUser: () => {},
  validateUserAccess: () => {},
}

const AuthContext = createContext(initialState)
const {
  cookieKeys: { authCookieKey },
  websiteLoginUrl,
} = configuration

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [cookies] = useCookies()
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [authToken, setAuthToken] = useState<AppAccessAuthToken>(null)
  const [loggedInAdmin, setLoggedInAdmin] = useState<Partial<UserDTO>>({})

  const validateAuthCookie = (cookies: { [x: string]: any }) => {
    const authToken: AppAccessAuthToken = cookies.authCookieKey

    if (authToken) return authToken
    throw new Error(
      `Auth cookie is missing: ${JSON.stringify(cookies.authCookieKey)}`,
    )
  }

  const redirectToLogin = (reason = 'unknown') => {
    console.error(`UNAUTHORIZED: ${reason}`)
    window.location.replace(websiteLoginUrl)
  }

  const updateUser = (authToken: AuthToken) => {
    if (!authToken) return
    setAuthToken(authToken)
  }

  const invalidateUserAccess = useCallback((reason: any) => {
    document.cookie = `${authCookieKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    setIsLoggedIn(false)
    redirectToLogin(reason)
    setLoggedInAdmin({})
  }, [])

  const validateUserAccess = useMemo(
    () => async () => {
      try {
        const authToken = validateAuthCookie(cookies)
        const { id: keanuUserId } = await getAuthorizationByToken(
          authToken.token,
          authorizedClaims,
        )
        const User = await fetchKeanuUserById(keanuUserId)
        setIsLoggedIn(true)
        setAuthToken(authToken)
        setLoggedInAdmin(User)
      } catch (error) {
        console.error('Login error:', error)
        invalidateUserAccess(error)
      }
    },
    [cookies, invalidateUserAccess],
  )

  const invalidateUserOnUnauthorizedResponse = (error: any) => {
    const safeErrorMessage: string = error.message || ''
    if (safeErrorMessage.includes('Please make a new sign-in request')) {
      invalidateUserAccess(safeErrorMessage)
    }
  }

  const context = useMemo(
    () => ({
      authToken,
      isLoggedIn,
      loggedInAdmin,
      updateUser,
      validateUserAccess,
      invalidateUserAccess,
      invalidateUserOnUnauthorizedResponse,
    }),
    //eslint-disable-next-line
    [authToken, isLoggedIn],
  )

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export const useAppAccess = () => {
  return useContext(AuthContext)
}
