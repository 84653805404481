import { Avatar } from '@campgladiator/cgui-core.atoms.avatar'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Pagination } from '@campgladiator/cgui-core.atoms.pagination'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  TData,
  THead,
  TRow,
  Table,
} from '@campgladiator/cgui-core.organisms.table'
import { PageTitleWithSearchInputAndNewButton } from 'components/module/shared/page-title-search-input-new-button'
import { displayDate, displayTime } from 'utils/date'
import {
  ChangePageFunction,
  CheckInsPages,
} from '../checkIns-container/checkIns-container'
import styles from './checkIns-list.module.scss'
import useWithCheckInsList from './useWithCheckInsList'

export type CheckInsListProps = {
  changePage: ChangePageFunction
}

const CheckInsList = ({ changePage }: CheckInsListProps) => {
  const handleNewButtonClick = () => {
    changePage(CheckInsPages.Create)
  }
  const { checkins, isLoading, setPage, page, totalPages } =
    useWithCheckInsList()

  return (
    <>
      <PageTitleWithSearchInputAndNewButton
        title="Checkins"
        placeholder=""
        newButtonText="New Checkin"
        showSearchInput={false}
        onSearch={() => {}}
        onNewButtonClick={() => handleNewButtonClick()}
      />
      <div className={styles.tableContainer}>
        <Table.Manual>
          <thead>
            <TRow>
              <THead>
                <Heading type="h6">Date Added</Heading>
              </THead>
              <THead>
                <Heading type="h6">Time</Heading>
              </THead>
              <THead>
                <Heading type="h6">Workout Date</Heading>
              </THead>
              <THead>
                <Heading type="h6">Type</Heading>
              </THead>
              <THead>
                <Heading type="h6">Trainer</Heading>
              </THead>
            </TRow>
          </thead>
          <tbody>
            {!checkins.length || isLoading ? (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    {isLoading ? <Loader /> : 'No records found'}
                  </Paragraph>
                </TData>
              </TRow>
            ) : (
              checkins.map((checkIn, index) => {
                const trainerName = `${checkIn?.workout?.trainer?.firstName} ${checkIn?.workout?.trainer?.lastName}`
                const workoutStartTimeDate =
                  checkIn.workout && checkIn.workout.startTime
                    ? displayDate(checkIn.workout.startTime)
                    : '-'
                return (
                  <TRow key={index}>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {displayDate(checkIn.created)}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {displayTime(checkIn.created)}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {workoutStartTimeDate}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {checkIn.type}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph
                        size="small"
                        weight="book"
                        className={styles.trainerColumn}
                      >
                        <div>
                          <Avatar
                            src={checkIn?.workout?.trainer?.photo}
                            size="30px"
                          />
                        </div>
                        <div>{trainerName}</div>
                      </Paragraph>
                    </TData>
                  </TRow>
                )
              })
            )}
          </tbody>
        </Table.Manual>
        {checkins.length > 0 && (
          <div className={styles.pagination}>
            <Pagination
              onPageChange={(page) => {
                setPage(page - 1)
              }}
              totalCount={totalPages}
              currentPage={page + 1}
              size="default"
            />
          </div>
        )}
      </div>
    </>
  )
}

export default CheckInsList
