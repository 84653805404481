import React from 'react'
import { Provider } from 'react-redux'
import { AuthProvider } from 'app/contexts/app-access'
import { GrowlProvider } from 'app/contexts/growl-context'
import reportWebVitals from 'app/libs/web-vitals'
import { store } from 'app/redux/store'
import { createRoot } from 'react-dom/client'
import App from './App'
import './styles/global.scss'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <GrowlProvider>
          <App />
        </GrowlProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
