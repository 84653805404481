import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Toggle } from '@campgladiator/cgui-core.atoms.toggle'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { RPAssignmentSection } from 'components/module/trainer/rp-assignment-section'
import { RegionGroup } from 'types/locale'
import useRegionGroupForm from './hooks/use-region-group-form'
import styles from './region-group-form.module.scss'

export interface IRegionGroupProps {
  isSubmitting?: boolean
  onSubmit: (values: RegionGroup) => void
  regionGroupData?: RegionGroup
}

const RegionGroupForm = ({
  isSubmitting,
  regionGroupData,
  onSubmit,
}: IRegionGroupProps) => {
  const {
    errors,
    formik,
    touched,
    values,
    getFieldProps,
    handleSubmit,
    isButtonDisabled,
    setFieldValue,
  } = useRegionGroupForm({
    isSubmitting,
    regionGroupData,
    onSubmit,
  })

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit} className={styles.formSection}>
        <FormPart
          label="Region Group Name*"
          forId="name"
          errorMsg={(touched.name && errors.name) || undefined}
        >
          <Input
            id="name"
            placeholder="Region Group Name"
            {...getFieldProps('name')}
          />
        </FormPart>

        <FormPart
          label="Region Group Description*"
          forId="description"
          errorMsg={(touched.description && errors.description) || undefined}
        >
          <Input
            id="description"
            placeholder="Region Group Description"
            {...getFieldProps('description')}
          />
        </FormPart>

        <FormPart label="Active" forId="active">
          <Toggle
            checked={values.active}
            onChange={(e) => setFieldValue('active', e.target.checked)}
          />
        </FormPart>

        <RPAssignmentSection formik={formik} type="REGION_GROUP" />

        <div className={styles.buttons}>
          <Button
            emphasis="primary"
            className={styles.submitButton}
            type="submit"
            disabled={isButtonDisabled()}
            theme="trainer"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default RegionGroupForm
