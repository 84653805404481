import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Checkbox } from '@campgladiator/cgui-core.atoms.checkbox'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Link } from '@campgladiator/cgui-core.atoms.link'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import ApiAutoComplete from 'components/form-ui/api-auto-complete'
import AutoComplete from 'components/form-ui/auto-complete/auto-complete'
import { Dropdown } from 'components/form-ui/dropdown'
import { RadioButton } from 'components/form-ui/radio-button'
import { getTrainers } from 'services/api/trainer'
import { ProductTypes } from 'types/product.d'
import { NewPaymentCardModal } from '../../billing/payment-card/new-payment-card-modal'
import { ChangePageFunction } from '../membership-container/membership-container'
import styles from './add-membership.module.scss'
import useWithAddMembership from './hooks/useWithAddMembership'

export type AddMembershipProps = {
  changePage: ChangePageFunction
}

const AddMembership = ({ changePage }: AddMembershipProps) => {
  const {
    autoAssignChecked,
    couponName,
    errors,
    isAddCardModalVisible,
    isSubmitting,
    membershipOptions,
    paymentMethodOptions,
    schedules,
    selectedProgram,
    touched,
    user,
    values,
    changePageToList,
    getCoupons,
    getFieldProps,
    handleSetCoupon,
    handleSubmit,
    setAddCardModalVisible,
    setAutoAssignChecked,
    setFieldValue,
    setPaymentMethodSaved,
  } = useWithAddMembership({ changePage })

  return (
    <>
      <div className={styles.membershipContainer}>
        <header className={styles.headerContainer}>
          <div
            className={styles.backIconWrapper}
            onClick={() => changePageToList()}
          >
            <Icon.Line className={styles.backIcon} name={'icon-angle-left-b'} />
          </div>
          <Heading type="h3">Add a Membership</Heading>
        </header>

        <form className={styles.formSection} onSubmit={handleSubmit}>
          <Heading className={styles.title} type="h5">
            Product
          </Heading>
          <FormPart
            forId="productId"
            label="Product"
            className={styles.fieldsetItem}
            errorMsg={(touched.productId && errors.productId) || ''}
          >
            <Dropdown
              id="productId"
              label="Select a Product"
              options={membershipOptions || []}
              {...getFieldProps('productId')}
            />
          </FormPart>
          <FormPart
            label="Trial Days"
            forId="trialPeriodDays"
            className={styles.fieldsetItem}
            errorMsg={
              (touched.trialPeriodDays && errors.trialPeriodDays) || undefined
            }
          >
            <Input
              id="trialPeriodDays"
              placeholder="Amount of trial days"
              {...getFieldProps('trialPeriodDays')}
            />
          </FormPart>
          <Heading className={styles.title} type="h5">
            Trainer
          </Heading>
          {selectedProgram?.type === ProductTypes.BOOTCAMP && (
            <Checkbox
              onStateChange={(checked) => setAutoAssignChecked(checked)}
            >
              <Paragraph size="small" weight="book">
                Auto Assign
              </Paragraph>
            </Checkbox>
          )}
          <FormPart
            label="Search for a trainer"
            forId="servicingTrainer"
            errorMsg={
              (touched.servicingTrainer && errors.servicingTrainer) || ''
            }
          >
            <ApiAutoComplete
              id="servicingTrainer"
              placeholder="Trainer's name"
              disabled={autoAssignChecked}
              getMethod={getTrainers}
              searchKey="name"
              renderOptionLabel={(record) =>
                `${record.firstName} ${record.lastName}`
              }
              onSelect={(record: any) =>
                setFieldValue('servicingTrainer', record?.itemId || undefined)
              }
              {...getFieldProps('servicingTrainer')}
            />
          </FormPart>
          <FormPart
            label="Select a schedule"
            forId="programScheduleId"
            errorMsg={
              (touched.programScheduleId && errors.programScheduleId) || ''
            }
          >
            <Dropdown
              id="programScheduleId"
              label="Select a schedule"
              options={schedules || []}
              disabled={autoAssignChecked}
              {...getFieldProps('programScheduleId')}
            />
          </FormPart>
          <Heading className={styles.title} type="h5">
            Payment
          </Heading>
          {paymentMethodOptions.length > 0 ? (
            <FormPart
              label=""
              forId="paymentMethodId"
              errorMsg={
                (touched.paymentMethodId && errors.paymentMethodId) || ''
              }
            >
              <RadioButton
                icon={<Icon.Line name="icon-credit-card" />}
                onChange={(value) => setFieldValue('paymentMethodId', value)}
                options={paymentMethodOptions}
                className={styles.radioContainer}
              />
            </FormPart>
          ) : (
            <div className={styles.paymentLinkContainer}>
              <FormPart
                label=""
                forId="paymentMethodId"
                errorMsg={
                  (touched.paymentMethodId && errors.paymentMethodId) || ''
                }
              >
                <Paragraph size="small" weight="book">
                  You don't currently have any credit cards saved. To Add a new
                  card
                  <Link
                    href="#"
                    size="small"
                    hideArrow
                    className={styles.link}
                    onClick={() => setAddCardModalVisible(true)}
                  >
                    click here
                  </Link>
                </Paragraph>
              </FormPart>
            </div>
          )}
          <Heading className={styles.title} type="h5">
            Discounts
          </Heading>
          <FormPart
            label="Search for a coupon"
            forId="couponCode"
            errorMsg={(touched.couponCode && errors.couponCode) || ''}
          >
            <AutoComplete
              id="couponCode"
              placeholder="Coupon's name"
              disabled={!values.productId}
              options={getCoupons().map((coupon) => ({
                itemId: coupon.stripeCouponId,
                text: coupon.stripeCouponName,
                type: 'item',
              }))}
              name="couponCode"
              setRecord={handleSetCoupon}
              value={couponName}
            />
          </FormPart>
          <div className={styles.buttons}>
            <Button
              emphasis="primary"
              type="submit"
              disabled={isSubmitting}
              theme="trainer"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
      <NewPaymentCardModal
        user={user}
        isAddCardModalVisible={isAddCardModalVisible}
        setAddCardModalVisible={() => setAddCardModalVisible(false)}
        onAfterCardIsSaved={() => {
          setPaymentMethodSaved(true)
          setAddCardModalVisible(false)
        }}
      />
    </>
  )
}

export default AddMembership
