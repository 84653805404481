import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type TeamType = {
  lastRefreshTime: number | null
}

const initialState: TeamType = {
  lastRefreshTime: null,
} as TeamType

const teamReducer = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setLastRefreshTime: (state, action: PayloadAction<number>) => {
      state.lastRefreshTime = action.payload
    },
    clear: () => initialState,
  },
})

export const { setLastRefreshTime, clear } = teamReducer.actions

export default teamReducer.reducer
