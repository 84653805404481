import { Tag } from '@campgladiator/cgui-core.atoms.tag'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { capitalize } from 'lodash'
import styles from './org-side-panel.module.scss'

type OrgSidePanelProps = {
  orgName: string
}

const OrgSidePanel = ({ orgName = '' }: OrgSidePanelProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <Paragraph weight="bold" size="xlarge" className={styles.title}>
          {capitalize(orgName)}
        </Paragraph>
        <Tag size="small" emphasis="secondary" variation="alternative">
          Organization
        </Tag>
      </div>
    </div>
  )
}

export default OrgSidePanel
