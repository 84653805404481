import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  Table,
  TData,
  THead,
  TRow,
} from '@campgladiator/cgui-core.organisms.table'
import { SearchInput } from 'components/module/shared/search-input'
import useRegionGroups from './hooks/use-region-groups'
import styles from './region-groups.module.scss'

const RegionGroups = () => {
  const { filteredRegionGroups, navigate, handleSearch } = useRegionGroups()

  return (
    <div className={styles.page}>
      <Card className={styles.title}>
        <Heading className={styles.pageHeader} type="h3">
          Region Groups
        </Heading>
        <Button
          className={styles.createButton}
          emphasis="primary"
          icon={{ name: 'icon-plus-circle', type: 'line' }}
          size="default"
          variation="solid"
          theme="trainer"
          onClick={() => navigate('/region-groups/create')}
        >
          New Region Group
        </Button>
      </Card>
      <SearchInput
        placeholder="Search a region group name or description"
        onSearch={handleSearch}
      />
      <div className={styles.wrapper}>
        <Table.Manual showFooter>
          <thead>
            <TRow>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Region Group Name
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Region Group Description
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Status
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Edit
                </Heading>
              </THead>
            </TRow>
          </thead>
          <tbody>
            {filteredRegionGroups.map(({ id, name, description, active }) => (
              <TRow key={id}>
                <TData>
                  <Paragraph size="small" weight="book">
                    {name}
                  </Paragraph>
                </TData>
                <TData>
                  <Paragraph size="small" weight="book">
                    {description}
                  </Paragraph>
                </TData>
                <TData>
                  <Paragraph size="small" weight="book">
                    {active ? 'Active' : 'Inactive'}
                  </Paragraph>
                </TData>
                <TData>
                  <Paragraph size="small" weight="book">
                    <Button
                      emphasis="secondary"
                      icon={{ name: 'icon-edit', type: 'solid' }}
                      size="large"
                      variation="text"
                      onClick={() =>
                        navigate(`/region-groups/${id}`, {
                          state: { id, name },
                        })
                      }
                      theme="trainer"
                    ></Button>
                  </Paragraph>
                </TData>
              </TRow>
            ))}
          </tbody>
        </Table.Manual>
      </div>
    </div>
  )
}
export default RegionGroups
