import classNames from 'classnames'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { SearchInput } from '../search-input'
import { SearchInputProps } from '../search-input/search-input'
import styles from './page-title-search-input-new-button.module.scss'

type PageTitleWithSearchInputProps = SearchInputProps & {
  title: string
  newButtonText?: string
  onNewButtonClick?: () => void
  showSearchInput: boolean
  className?: string
}

const PageTitleWithSearchInputAndNewButton = ({
  title,
  newButtonText,
  onNewButtonClick,
  showSearchInput,
  className,
  ...props
}: PageTitleWithSearchInputProps) => {
  return (
    <div>
      <Card className={classNames(styles.title, className)}>
        <Heading type="h3" className={styles.pageHeader}>
          {title}
        </Heading>
        {newButtonText && (
          <Button
            emphasis="primary"
            icon={{ name: 'icon-plus-circle', type: 'line' }}
            size="default"
            variation="solid"
            className={styles.submitButton}
            onClick={onNewButtonClick}
            theme="trainer"
          >
            {newButtonText}
          </Button>
        )}
      </Card>
      {showSearchInput && (
        <div className={styles.searchWrapper}>
          <SearchInput
            placeholder={props.placeholder}
            onSearch={props.onSearch}
          />
        </div>
      )}
    </div>
  )
}

export default PageTitleWithSearchInputAndNewButton
