import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type SubscriptionType = {
  lastRefreshTime: number | null
}

const initialState: SubscriptionType = {
  lastRefreshTime: null,
} as SubscriptionType

const subscriptionReducer = createSlice({
  name: 'subscriptionReducer',
  initialState,
  reducers: {
    setLastRefreshTime: (state, action: PayloadAction<number>) => {
      state.lastRefreshTime = action.payload
    },
    clear: () => initialState,
  },
})

export const { setLastRefreshTime, clear } = subscriptionReducer.actions

export default subscriptionReducer.reducer
