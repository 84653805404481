import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchCoupons as getKeanuCoupons } from '@campgladiator/cg-common.api.coupons'
import {
  CouponDTO,
  ProductDetailDTO,
  TrainerDTO,
} from '@campgladiator/cg-common.types.types'
import { ListItemProps } from '@campgladiator/cgui-core.atoms.list-item'
import { useGrowlContext } from 'app/contexts/growl-context'
import { activeUserSelector } from 'app/redux/features/active-user/active-user-reducer'
import { productListSelector } from 'app/redux/features/products-list/products-list-reducer'
import { setLastRefreshTime } from 'app/redux/features/subscription/subscription-reducer'
import { useAppDispatch, useAppSelector } from 'app/redux/store'
import { configuration } from 'config'
import { useFormik } from 'formik'
import { capitalize } from 'lodash'
import { getAllPaymentMethods } from 'services/api/payment'
import { getProgramByFilters } from 'services/api/program'
import { postSubscription } from 'services/api/subscriptions'
import { getTrainerById } from 'services/api/trainer'
import { ProductTypes } from 'types/product.d'
import { ProgramFullDetails } from 'types/program'
import { Schedule } from 'types/schedule'
import { currentDate } from 'utils/date'
import * as Yup from 'yup'
import {
  ChangePageFunction,
  MembershipPages,
} from '../../membership-container/membership-container'

const useWithAddMembership = ({
  changePage,
}: {
  changePage: ChangePageFunction
}) => {
  const user = useSelector(activeUserSelector)
  const dispatch = useAppDispatch()
  const { showGrowl } = useGrowlContext()
  const [schedules, setSchedules] = useState<any>([])
  const [isAddCardModalVisible, setAddCardModalVisible] =
    useState<boolean>(false)
  const [isPaymentMethodSaved, setPaymentMethodSaved] = useState<boolean>(false)
  const [autoAssignChecked, setAutoAssignChecked] = useState<boolean>(false)
  const [paymentMethodOptions, setPaymentMethodsOptions] = useState([])
  const [selectedProgram, setSelectedProgram] = useState<ProductDetailDTO>()
  const [selectedTrainer, setSelectedTrainer] = useState<TrainerDTO>()
  const [coupons, setCoupons] = useState<CouponDTO[]>([])
  const [couponName, setCouponName] = useState<string>('')

  const productList = useAppSelector(productListSelector)

  const membershipOptions = productList
    ?.filter((product) => product.type !== 'STRENGTH')
    ?.flatMap((it) => it.products)
    .map(({ stripeProductId, stripeProductName, prices }) => ({
      value: stripeProductId,
      label: stripeProductName,
      prices: prices,
    }))

  const {
    handleSubmit,
    setSubmitting,
    getFieldProps,
    setFieldValue,
    values,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      couponCode: undefined,
      paymentMethodId: '',
      productId: '',
      programScheduleId: undefined,
      servicingTrainer: undefined,
      trialPeriodDays: 0,
    },
    validationSchema: Yup.object({
      couponCode: Yup.string(),
      productId: Yup.string().required('Product is required'),
      trialPeriodDays: Yup.number()
        .min(0, 'Value must be 0 or greater than 0')
        .max(30, 'Value must not be greater than 30'),
      paymentMethodId: Yup.string().required('Payment method is required'),
      servicingTrainer: Yup.string().when('productId', (_, schema) => {
        if (
          !autoAssignChecked ||
          selectedProgram?.type === ProductTypes.NUTRITION ||
          selectedProgram?.type === ProductTypes.STRENGTH
        ) {
          return schema.required('Trainer name is required')
        } else {
          return schema
        }
      }),
      programScheduleId: Yup.string().when('servicingTrainer', (_, schema) => {
        if (
          !autoAssignChecked &&
          selectedProgram?.type === ProductTypes.BOOTCAMP &&
          selectedTrainer?.email !== configuration.cgTrainerEmail
        ) {
          return schema.required('Schedule is required')
        } else {
          return schema
        }
      }),
    }),
    onSubmit: async (values) => {
      try {
        setSubmitting(true)

        const selectedProgram = membershipOptions?.find(
          (product) => product.value === values.productId,
        )
        const selectedSchedule = schedules?.find(
          (schedule: any) => schedule.value === values.programScheduleId,
        )
        const response = await postSubscription({
          ...values,
          couponCode: values.couponCode,
          userId: user?.id || '',
          stripeCustomerId: user?.stripeCustomerId || '',
          productPrice: selectedProgram?.prices[0].stripePriceId || '',
          programId: selectedSchedule?.programId || undefined,
          periodStartDate: currentDate,
          billingDate: currentDate,
          servicingTrainer: !autoAssignChecked
            ? values.servicingTrainer
            : undefined,
          programScheduleId: !autoAssignChecked
            ? values.programScheduleId
            : undefined,
        })
        setSubmitting(false)
        if (response) {
          showGrowl('SUCCESS', 'Membership added successfully.')
          setTimeout(() => {
            dispatch(setLastRefreshTime(Date.now()))
            changePageToList()
          }, 1000)
        }
      } catch (error: any) {
        showGrowl('FAILED', error)
        setSubmitting(false)
      }
    },
    enableReinitialize: true,
  })

  const getPaymentMethods = async (stripeCustomerId: string) => {
    try {
      const response = await getAllPaymentMethods(stripeCustomerId)
      if (response) {
        const paymentMethodItems: any = response.map((card) => ({
          value: card.id,
          label: `${capitalize(card?.brand || '')} card ending ${
            card?.last4 || ''
          }`,
        }))
        setPaymentMethodsOptions(paymentMethodItems)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getProductByStripeProductId = (stripeProductId: string) =>
    selectedProgram?.products?.find(
      (it) => it.stripeProductId === stripeProductId,
    )

  const getCoupons = () =>
    coupons.filter(
      (coupon) =>
        coupon.valid &&
        coupon.applicableProductIds?.some(
          (applicableProductIds) =>
            applicableProductIds ===
            getProductByStripeProductId(values.productId)?.id,
        ),
    )

  const handleSetCoupon = (record: ListItemProps) => {
    if (!record) {
      setCouponName('')
      setFieldValue('couponCode', '')
      return
    }
    setFieldValue('couponCode', record ? record.itemId : '')
    const couponName = record ? record.text : ''
    setCouponName(couponName!)
  }

  useEffect(() => {
    if (user?.stripeCustomerId) {
      getPaymentMethods(user.stripeCustomerId)
    }
  }, [user?.stripeCustomerId, isPaymentMethodSaved])

  useEffect(() => {
    const selectedProgram = productList?.find((it) =>
      it.products.find((it) => it.stripeProductId === values.productId),
    )
    setSelectedProgram(selectedProgram)
    setFieldValue('couponCode', '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.productId, productList])

  useEffect(() => {
    if (selectedProgram?.type === ProductTypes.NUTRITION)
      setAutoAssignChecked(false)
  }, [selectedProgram])

  const getSelectedTrainer = async (servicingTrainer: string) => {
    try {
      const response: TrainerDTO = await getTrainerById(servicingTrainer)
      setSelectedTrainer(response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (values.servicingTrainer) {
      getSelectedTrainer(values.servicingTrainer)
    }
    //eslint-disable-next-line
  }, [values.servicingTrainer])

  const getProgramSchedule = async () => {
    try {
      if (selectedProgram) {
        let response: ProgramFullDetails = await getProgramByFilters(
          values.servicingTrainer!,
          selectedProgram.type,
          selectedProgram.subType,
        )
        if (response.programs.length !== 0) {
          const programSchedules = response.programs?.flatMap(
            (program) => program.programSchedules,
          )
          if (programSchedules) schedulesList(programSchedules)
        } else {
          response = await getProgramByFilters(
            values.servicingTrainer!,
            selectedProgram.type,
            selectedProgram.subType === 'ONLINE' ? 'IN_PERSON' : 'ONLINE',
          )
          const programSchedules = response.programs?.flatMap(
            (program) => program.programSchedules,
          )
          if (programSchedules) schedulesList(programSchedules)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const schedulesList = (programSchedules: any) => {
    const scheduleDropdownOptions = programSchedules.map(
      (schedule: Schedule) => {
        const daysOfWeek = schedule?.daysOfTheWeek
          .split(',')
          .map((dow) => capitalize(dow.substring(0, 3)))
          .join(', ')

        const startTime = schedule?.startTime.substring(0, 5)

        return {
          label: `${daysOfWeek} - ${startTime}`,
          value: schedule?.id || '',
          programId: schedule?.programId || '',
        }
      },
    )
    setSchedules(scheduleDropdownOptions)
  }

  useEffect(() => {
    if (values.productId && values.servicingTrainer) {
      getProgramSchedule()
    }
    //eslint-disable-next-line
  }, [values.productId, values.servicingTrainer])

  useEffect(() => {
    getKeanuCoupons().then(setCoupons)
  }, [user])

  const changePageToList = () => {
    changePage(MembershipPages.List)
  }

  return {
    autoAssignChecked,
    couponName,
    errors,
    isAddCardModalVisible,
    isSubmitting,
    membershipOptions,
    paymentMethodOptions,
    schedules,
    selectedProgram,
    selectedTrainer,
    touched,
    user,
    values,
    changePageToList,
    getCoupons,
    getFieldProps,
    handleSetCoupon,
    handleSubmit,
    setAddCardModalVisible,
    setAutoAssignChecked,
    setFieldValue,
    setPaymentMethodSaved,
  }
}

export default useWithAddMembership
