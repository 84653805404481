import { useState, useEffect } from 'react'
import { OrganizationDTO } from '@campgladiator/cg-common.types.types'
import { useGrowlContext } from 'app/contexts/growl-context'
import { setActiveOrganization } from 'app/redux/features/active-organization/active-organization-reducer'
import { useAppDispatch } from 'app/redux/store'
import useOrganizationCoupon from 'pages/partnerships/create/hooks/use-org-coupon'
import { putOrganization } from 'services/api/organization'
import { EditOrganizationsProps } from '../edit-organization'

const useEditOrganization = ({ organization }: EditOrganizationsProps) => {
  const dispatch = useAppDispatch()
  const { showGrowl } = useGrowlContext()
  const [isSubmitting, setSubmitting] = useState(false)
  const { couponCodeDropdownItems, couponError } = useOrganizationCoupon()

  const handleSubmit = async (values: Partial<OrganizationDTO>) => {
    setSubmitting(true)
    try {
      const updatedOrg = await putOrganization(organization.id!, values)
      dispatch(setActiveOrganization(updatedOrg))
      showGrowl('', 'Organization updated successfully')
    } catch (error: any) {
      showGrowl('FAILED', error)
    }

    setSubmitting(false)
  }

  useEffect(() => {
    if (couponError) {
      showGrowl('ERROR', couponError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponError])

  return {
    isSubmitting,
    couponCodeDropdownItems,
    handleSubmit,
  }
}

export default useEditOrganization
