import { ButtonProps } from '@campgladiator/cgui-core.atoms.button'
import { Modal } from '@campgladiator/cgui-core.molecules.modal'
import ScheduleReassignSelectEffectiveDate, {
  ScheduleReassignSelectEffectiveDateProps,
} from './components/ScheduleReassignSelectEffectiveDate'
import ScheduleReassignSelectSchedule, {
  ScheduleReassignSelectScheduleProps,
} from './components/ScheduleReassignSelectSchedule'
import ScheduleReassignSelectTrainer, {
  ScheduleReassignSelectTrainerProps,
} from './components/ScheduleReassignSelectTrainer'
import styles from './ScheduleReassign.module.scss'

export type ScheduleReassignSteps =
  | 'SelectTrainer'
  | 'SelectSchedule'
  | 'SelectEffectiveDate'

export type ScheduleReassignProps = {
  buttons: ButtonProps[]
  isVisible: boolean
  onClose: (value: boolean) => void
  scheduleReassignSelectEffectiveDateProps: ScheduleReassignSelectEffectiveDateProps
  scheduleReassignSelectScheduleProps: ScheduleReassignSelectScheduleProps
  scheduleReassignSelectTrainerProps: ScheduleReassignSelectTrainerProps
  step: ScheduleReassignSteps
  title: string
}

const ScheduleReassign = ({
  isVisible,
  onClose,
  step,
  buttons,
  title,
  scheduleReassignSelectTrainerProps,
  scheduleReassignSelectScheduleProps,
  scheduleReassignSelectEffectiveDateProps,
}: ScheduleReassignProps) => {
  const { campersCount } = scheduleReassignSelectTrainerProps
  return campersCount > 0 ? (
    <Modal
      isVisible={isVisible}
      title={title}
      onClose={() => onClose(false)}
      buttons={buttons}
      theme="trainer"
      className={styles.modal}
    >
      {step === 'SelectTrainer' && (
        <ScheduleReassignSelectTrainer
          {...scheduleReassignSelectTrainerProps}
        />
      )}
      {step === 'SelectSchedule' && (
        <ScheduleReassignSelectSchedule
          {...scheduleReassignSelectScheduleProps}
        />
      )}
      {step === 'SelectEffectiveDate' && (
        <ScheduleReassignSelectEffectiveDate
          {...scheduleReassignSelectEffectiveDateProps}
        />
      )}
    </Modal>
  ) : null
}

export default ScheduleReassign
