import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import ApiAutocomplete from 'components/form-ui/api-auto-complete/api-auto-complete'
import { FieldArray, FormikProps, FormikProvider, getIn } from 'formik'
import { getTrainers } from 'services/api/trainer'
import { TrainerSplitAssignment } from 'types/pay-config'
import * as Yup from 'yup'
import styles from './trainer-split-allocation.module.scss'

export const defaultAssignment = {
  trainerId: '',
  trainerName: '',
  percentage: '',
}

interface AssignmentHoldingForm {
  [key: string]: TrainerSplitAssignment[]
}

type TrainerSplitAllocationProps = {
  formik: FormikProps<AssignmentHoldingForm>
  splitKey: string
  selectedTrainers: string[]
}

export const assignmentSchema = Yup.array().of(
  Yup.object().shape(
    {
      trainerId: Yup.string().when('percentage', {
        is: (val: number | null) => (val || 0) > 0,
        then: (schema) => schema.required('Trainer is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
      percentage: Yup.number()
        .max(100, 'Trainer’s % must not be greater than 100')
        .min(0.1, 'Trainer’s % must be greater than 0')
        .when('trainerId', {
          is: (val: string | null) => val && val.length > 0,
          then: (schema) => schema.required('Trainer’s % is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
    },
    [
      ['trainerId', 'percentage'],
      ['percentage', 'trainerId'],
    ],
  ),
)

export const totalAssignedPercentSchema = (splitKey: string) =>
  Yup.number().test(
    'is-percentage-100',
    'Total trainer’s % should be 100',
    (_, context) => {
      const totalCommission = context.parent[splitKey].reduce(
        (acc: number, curr: TrainerSplitAssignment) =>
          acc + Number(curr.percentage || 0),
        0,
      )

      return totalCommission === 100 || totalCommission === 0
    },
  )

const TrainerSplitAllocation = ({
  formik,
  splitKey,
  selectedTrainers,
}: TrainerSplitAllocationProps) => {
  const { getFieldProps, setFieldValue, errors, touched, values } = formik

  return (
    <>
      <FormikProvider value={formik}>
        <FieldArray
          name={splitKey}
          render={(arrayHelpers) =>
            values[splitKey].map(
              (
                assignment: TrainerSplitAssignment,
                index: number,
                arrAssignments: TrainerSplitAssignment[],
              ) => (
                <Fieldset
                  key={`${splitKey}_${index}`}
                  className={styles.fieldset}
                >
                  <FormPart
                    label="Trainer"
                    forId={`${splitKey}_trainer_${index}`}
                    className={styles.fieldsetItem}
                    errorMsg={
                      (getIn(touched[splitKey]?.[index], 'trainerId') &&
                        getIn(errors[splitKey]?.[index], 'trainerId')) ||
                      ''
                    }
                  >
                    <ApiAutocomplete
                      id={`${splitKey}_trainer_${index}`}
                      placeholder="Trainer"
                      getMethod={getTrainers}
                      searchKey="name"
                      initialSelectedItem={assignment?.trainerName || ''}
                      renderOptionLabel={(record) =>
                        `${record.firstName} ${record.lastName}`
                      }
                      onSelect={(record: any) => {
                        setFieldValue(
                          `${splitKey}.${index}.trainerId`,
                          record?.itemId || '',
                        )
                      }}
                      filterOption={(trainer: any) =>
                        !selectedTrainers.includes(trainer.id)
                      }
                      {...getFieldProps(`${splitKey}.${index}.trainerId`)}
                    />
                  </FormPart>
                  <FormPart
                    label="Trainer’s %"
                    forId={`${splitKey}_percent_${index}`}
                    className={styles.fieldsetItem}
                    errorMsg={
                      (getIn(touched[splitKey]?.[index], 'percentage') &&
                        getIn(errors[splitKey]?.[index], 'percentage')) ||
                      ''
                    }
                  >
                    <Input
                      type="number"
                      id={`${splitKey}_percent_${index}`}
                      {...getFieldProps(`${splitKey}.${index}.percentage`)}
                      min={0}
                    />
                  </FormPart>
                  <div className={styles.addRemoveButtons}>
                    {(arrAssignments.length > 1 || index !== 0) && (
                      <Icon.Line
                        name="icon-minus"
                        title="remove"
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    )}
                    {arrAssignments.length - 1 === index &&
                      arrAssignments.length < 5 && (
                        <Icon.Monochrome
                          name="plus"
                          title="add new"
                          onClick={() =>
                            arrayHelpers.push({
                              ...defaultAssignment,
                            })
                          }
                        />
                      )}
                  </div>
                </Fieldset>
              ),
            )
          }
        />
      </FormikProvider>
      <Paragraph size="xsmall" weight="book" className={styles.errorMessage}>
        {getIn(errors, `${splitKey}TotalPercent`)}
      </Paragraph>
    </>
  )
}

export default TrainerSplitAllocation
