import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Toggle } from '@campgladiator/cgui-core.atoms.toggle'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { Autocomplete } from 'components/form-ui/auto-complete'
import { Locale } from 'types/locale'
import { RPAssignmentSection } from '../trainer/rp-assignment-section'
import useLocaleForm from './hooks/use-locale-form'
import styles from './locale-form.module.scss'

export interface ILocaleFormProps {
  onSubmit: (values: Locale) => void
  isSubmitting?: boolean
  localeData?: Locale | undefined
}

const LocaleForm = ({
  onSubmit,
  isSubmitting,
  localeData,
}: ILocaleFormProps) => {
  const {
    errors,
    formik,
    groupName,
    regionGroups,
    touched,
    values,
    getFieldProps,
    handleSetRegionGroup,
    handleSubmit,
    setFieldValue,
  } = useLocaleForm({
    localeData,
    onSubmit,
  })

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit} className={styles.formSection}>
        <FormPart
          label="Region Name*"
          forId="name"
          errorMsg={(touched.name && errors.name) || undefined}
        >
          <Input id="name" placeholder="Name" {...getFieldProps('name')} />
        </FormPart>

        <FormPart
          label="Region Description*"
          forId="description"
          errorMsg={(touched.description && errors.description) || undefined}
        >
          <Input
            id="description"
            placeholder="Description"
            {...getFieldProps('description')}
          />
        </FormPart>
        <FormPart
          label="Region Group*"
          forId="parentId"
          className={styles.fieldsetItem}
          errorMsg={(touched.parentId && errors.parentId) || undefined}
        >
          <Autocomplete
            id="parentId"
            placeholder="RegionGroup"
            options={regionGroups}
            value={groupName}
            name="parentId"
            setRecord={handleSetRegionGroup}
          />
        </FormPart>
        <FormPart label="Active" forId="active">
          <Toggle
            checked={values.active}
            onChange={(e) => setFieldValue('active', e.target.checked)}
          />
        </FormPart>
        <RPAssignmentSection formik={formik} type="REGION" />
        <div className={styles.buttons}>
          <Button
            emphasis="primary"
            className={styles.submitButton}
            type="submit"
            disabled={isSubmitting}
            theme="trainer"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default LocaleForm
