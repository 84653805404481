import { Tag } from '@campgladiator/cgui-core.atoms.tag'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { ActiveProgramType } from 'app/redux/features/active-program/active-program-reducer'
import { capitalize } from 'lodash'
import moment from 'moment'
import { TimeZoneDropDownOptions } from 'services/data/timezone'
import { DisplayItem } from './components/display-item'
import styles from './program-side-panel.module.scss'

const ProgramSidePanel = ({
  programDetails,
}: {
  programDetails: ActiveProgramType
}) =>
  programDetails ? (
    <div>
      <div className={styles.topSection}>
        <Paragraph weight="bold" size="xlarge" className={styles.title}>
          {capitalize(programDetails.productDetailSummary.type)}
        </Paragraph>
        <Tag size="small" emphasis="secondary" variation="alternative">
          Programs
        </Tag>
      </div>
      <DisplayItem
        label="Trainer"
        value={`${programDetails?.trainerSummary?.firstName} ${programDetails?.trainerSummary?.lastName}`}
      />
      <DisplayItem
        label="Start Date"
        value={
          programDetails.startDate &&
          moment(programDetails.startDate).format('MM/DD/YYYY')
        }
      />
      <DisplayItem
        label="End Date"
        value={
          programDetails.endDate &&
          moment(programDetails.endDate).format('MM/DD/YYYY')
        }
      />
      <DisplayItem
        label="Time Zone"
        value={
          TimeZoneDropDownOptions.find(
            (it: any) => it.itemId === programDetails.timezone,
          )?.text
        }
      />
    </div>
  ) : null

export default ProgramSidePanel
