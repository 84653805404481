import { TransactionDTO } from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import { objectToQueryString } from 'utils/url'

const baseUrl = configuration.apiGatewayUrl

export const getUserTransactions = async (
  customerId: string,
): Promise<TransactionDTO[]> => {
  try {
    const endpoint = `/billing/v1/transactions?customerId=${customerId}`
    const method = 'GET' as HttpMethod
    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-user-transaction',
      attributeName: 'get-user-transaction-by-customerid',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const retryInvoicePayment = async (
  invoiceId: string,
): Promise<boolean> => {
  try {
    const endpoint = `/billing/v1/transactions/invoices/${invoiceId}/pay`
    const method: HttpMethod = 'PUT'

    const response = await request({ baseUrl, endpoint, method })

    if (response.ok) return true

    const result = await response.json()
    return Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-retry-invoice-payment',
      attributeName: 'retry-invoice-payment',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putRefundTransaction = async (
  id: string,
  amount?: number,
): Promise<boolean> => {
  try {
    const endpoint = `/billing/v1/transactions/${id}/refund`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      params: amount ? objectToQueryString({ amount }) : undefined,
    })

    if (response.ok) return true

    const result = await response.json()
    return Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-refund-transaction',
      attributeName: 'put-refund-transaction',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
