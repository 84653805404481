import { useState } from 'react'
import { AddMembership } from '../add-membership'
import Memberships from '../memberships'
import styles from './membership-container.module.scss'

export enum MembershipPages {
  List,
  Add,
}

export type ChangePageFunction = (page: MembershipPages, data?: any) => void

const MembershipContainer = () => {
  const [currentPage, setCurrentPage] = useState<MembershipPages>(
    MembershipPages.List,
  )

  const changePage: ChangePageFunction = (page: MembershipPages) => {
    setCurrentPage(page)
  }

  return (
    <div className={styles.container}>
      {currentPage === MembershipPages.Add ? (
        <AddMembership changePage={changePage} />
      ) : (
        <Memberships changePage={changePage} />
      )}
    </div>
  )
}

export default MembershipContainer
