import { Button } from '@campgladiator/cgui-core.atoms.button'
import styles from './file-uploader.module.scss'

export type MediaType = 'PHOTO' | 'VIDEO'

export type FileUploaderProps = {
  disabled: boolean
  fileInputRef: React.Ref<HTMLInputElement> | undefined
  mediaType: MediaType
  supportedAttachmentTypes: string
  text: string
  onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void
  onClick: (event?: React.MouseEvent<HTMLElement>) => void
}

const FileUploader = ({
  disabled,
  fileInputRef,
  mediaType,
  supportedAttachmentTypes,
  text,
  onClick,
  onChange,
}: FileUploaderProps) => (
  <div className={styles.fileUploaderWrapper}>
    <input
      accept={supportedAttachmentTypes}
      type="file"
      id={`select-file-${mediaType}`}
      className={styles.input}
      disabled={disabled}
      ref={fileInputRef}
      onChange={onChange}
    />

    <label
      htmlFor={`select-file-${mediaType}`}
      className={styles.fileUploaderLabel}
    >
      <Button
        emphasis="secondary"
        variation="outline"
        theme="trainer"
        onClick={onClick}
        icon={{
          name: 'icon-times',
          type: 'line',
        }}
        className={styles.fileUploader}
      >
        {text}
      </Button>
    </label>
  </div>
)

export default FileUploader
