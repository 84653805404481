import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import { getActiveRegionGroups } from 'services/api/locale'
import { RegionGroup } from 'types/locale'

const filterRegionGroups = (regions: RegionGroup[], query: string) =>
  regions.filter((region) => {
    const queryableName = `${region.name?.toLowerCase()}`
    const queryableDescription = `${region.description?.toLowerCase()}`
    const isMatchingName = queryableName.includes(query.toLowerCase())
    const isMatchingDescription = queryableDescription.includes(
      query.toLowerCase(),
    )
    return isMatchingName || isMatchingDescription
  })

const useRegionGroups = () => {
  const [regionGroups, setRegionGroups] = useState<RegionGroup[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredRegionGroups, setFilteredRegionGroups] =
    useState<RegionGroup[]>(regionGroups)

  const navigate = useNavigate()

  const isSearchApplied = searchValue.length > 2

  const fetchRegionGroups = async () => {
    try {
      const response = await getActiveRegionGroups()
      setRegionGroups(response.content)
    } catch (error: any) {
      console.error(error)
    }
  }

  const searchQuery = useCallback(() => {
    if (isSearchApplied) {
      const filteredRegions = filterRegionGroups(regionGroups, searchValue)
      setFilteredRegionGroups(filteredRegions)
    } else {
      setFilteredRegionGroups(regionGroups)
    }
  }, [regionGroups, isSearchApplied, searchValue])

  const delayedSearchQuery = useMemo(
    () => debounce(searchQuery, 500),
    [searchQuery],
  )

  const handleSearch = (value: string) => setSearchValue(value)

  useEffect(() => {
    fetchRegionGroups()
  }, [])

  useEffect(() => {
    delayedSearchQuery()
    return delayedSearchQuery.cancel
  }, [searchValue, delayedSearchQuery])

  return {
    filteredRegionGroups,
    navigate,
    handleSearch,
  }
}

export default useRegionGroups
