import moment from 'moment'

export const isMembershipCancelled = (archivedAt: string | null) => {
  if (archivedAt === null) return false

  const archivedAtLocal = moment.utc(archivedAt).local()
  const now = new Date()
  const archived = new Date(archivedAtLocal.toString() || '')
  return archived < now
}
