import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  Table,
  TRow,
  TData,
  THead,
} from '@campgladiator/cgui-core.organisms.table'
import { PageTitleWithSearchInputAndNewButton } from 'components/module/shared/page-title-search-input-new-button'
import useView from './hooks/use-view'
import styles from './view.module.scss'

const ViewOrganizations = () => {
  const {
    buttonProps,
    isLoading,
    isSearchApplied,
    organizationsList,
    pageTitleProps,
    navigate,
  } = useView()

  return (
    <div className={styles.page}>
      <PageTitleWithSearchInputAndNewButton {...pageTitleProps} />

      <div className={styles.tableWrapper}>
        <Table.Manual>
          <thead>
            <TRow>
              <THead>
                <Heading type="h6">Name</Heading>
              </THead>
              <THead>
                <Heading type="h6">Description</Heading>
              </THead>
              <THead>
                <Heading type="h6">Edit</Heading>
              </THead>
            </TRow>
          </thead>
          <tbody>
            {organizationsList.length === 0 && !isLoading ? (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    {'No organizations to display'}
                  </Paragraph>
                </TData>
              </TRow>
            ) : (
              !!organizationsList.length &&
              organizationsList.map((organization) => (
                <TRow key={organization.id}>
                  <TData className={styles.tableData}>
                    <Paragraph size="small" weight="book">
                      {organization.name}
                    </Paragraph>
                  </TData>
                  <TData className={styles.tableData}>
                    <Paragraph size="small" weight="book">
                      {organization.description || '-'}
                    </Paragraph>
                  </TData>
                  <TData className={styles.tableData}>
                    <Paragraph size="small" weight="book">
                      <Button
                        emphasis="secondary"
                        icon={{ name: 'icon-edit', type: 'solid' }}
                        size="large"
                        variation="text"
                        onClick={() =>
                          navigate(`/partnerships/${organization.id!}`)
                        }
                        theme="trainer"
                      />
                    </Paragraph>
                  </TData>
                </TRow>
              ))
            )}
            {isLoading && (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    <Loader />
                  </Paragraph>
                </TData>
              </TRow>
            )}
          </tbody>
        </Table.Manual>
        {!isSearchApplied && (
          <div className={styles.buttonWrapper}>
            <Button {...buttonProps}>{buttonProps.children}</Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ViewOrganizations
