import { Tabs, TabPanel } from 'components/layouts/tabs'
import { RegionOverview } from 'components/module/region/region-overview'
import { TeamsContainer } from 'components/module/teams/teams-container'
import styles from './view.module.scss'

const RegionView = () => {
  return (
    <div className={styles.container}>
      <Tabs>
        <TabPanel title="Overview">
          <RegionOverview />
        </TabPanel>
        <TabPanel title="Teams">
          <div className={styles.teamContainer}>
            <TeamsContainer />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default RegionView
