import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { TextArea } from '@campgladiator/cgui-core.atoms.textarea'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { FieldArray, FieldConfig, FieldInputProps } from 'formik'
import styles from '../trainer-bios.module.scss'

type ArrayFieldSectionProps<T> = {
  dataArray: T
  getFieldProps: (
    nameOrOptions: string | FieldConfig<any>,
  ) => FieldInputProps<any>
  label: string
  name: string
}

const ArrayFieldFormPart = <T extends any[]>({
  dataArray,
  getFieldProps,
  label,
  name,
}: ArrayFieldSectionProps<T>) => {
  const showRemoveIcon = (value: any[], index: number) =>
    value.length > 1 || index !== 0
  const showAddIcon = (value: any[], index: number) =>
    value.length - 1 === index && value.length < 3 && value.length < 10

  return (
    <FormPart label={label} forId={name}>
      <FieldArray
        name={name}
        render={(arrayHelpers) =>
          dataArray?.map((_, index, value) => (
            <div className={styles.row}>
              <TextArea
                id={`${name}.${index}`}
                className={styles.input}
                placeholder={label}
                {...getFieldProps(`${name}.${index}`)}
              />
              {showRemoveIcon(value, index) && (
                <Icon.Line
                  name="icon-minus"
                  title="remove"
                  onClick={() => arrayHelpers.remove(index)}
                />
              )}
              {showAddIcon(value, index) && (
                <Icon.Monochrome
                  name="plus"
                  title="add new"
                  onClick={() => arrayHelpers.push('')}
                />
              )}
            </div>
          ))
        }
      />
    </FormPart>
  )
}

export default ArrayFieldFormPart
