import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  TData,
  THead,
  TRow,
  Table,
} from '@campgladiator/cgui-core.organisms.table'
import { PageTitleWithSearchInputAndNewButton } from 'components/module/shared/page-title-search-input-new-button'
import { centsToDollar, displayPrices } from 'utils/currency'
import { displayDateTime } from 'utils/date'
import { RefundAction } from './refund-action'
import styles from './transactions.module.scss'
import UseTransactions from './use-transactions'

const TransactionColumns = [
  'Product',
  'Description',
  'Status',
  'Date',
  'Amount',
  'Refunded amount',
  'Invoice#',
  'Processor',
  'Payment Actions',
  'Chargebacked',
]

const Transactions = () => {
  const {
    loading,
    pageTitleProps,
    transactionList,
    handleFailure,
    handleRetryPayment,
    handleSuccess,
  } = UseTransactions()

  return (
    <>
      <PageTitleWithSearchInputAndNewButton {...pageTitleProps} />
      <div className={styles.container}>
        <Table.Manual>
          <thead>
            <TRow>
              {TransactionColumns.map((column) => (
                <THead>
                  <Heading type="h6">{column}</Heading>
                </THead>
              ))}
            </TRow>
          </thead>
          <tbody>
            {(transactionList.length === 0 || loading) && (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    {loading ? <Loader /> : 'No data to display'}
                  </Paragraph>
                </TData>
              </TRow>
            )}

            {transactionList.map(
              ({
                id,
                description,
                lineItemDescription,
                created,
                status,
                outcome,
                amount,
                amountRefunded,
                invoiceId,
                refunded,
                disputed,
              }) => (
                <TRow key={id}>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {lineItemDescription}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {description}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph
                      size="small"
                      weight="book"
                    >{`${status} ${outcome}`}</Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {displayDateTime(created)}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {displayPrices(amount)}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {displayPrices(amountRefunded)}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {invoiceId}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      Stripe
                    </Paragraph>
                  </TData>
                  <TData>
                    {!refunded && status === 'SUCCEEDED' && (
                      <RefundAction
                        id={id}
                        allowedAmount={centsToDollar(amount - amountRefunded)}
                        onSucces={handleSuccess}
                        onFailure={handleFailure}
                      />
                    )}
                    {!refunded && status === 'FAILED' && (
                      <Button onClick={() => handleRetryPayment(invoiceId)}>
                        Retry
                      </Button>
                    )}
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      <Icon.Solid
                        className={styles.chargebackFlag}
                        name={disputed ? 'icon-check' : 'icon-multiply'}
                      />
                    </Paragraph>
                  </TData>
                </TRow>
              ),
            )}
          </tbody>
        </Table.Manual>
      </div>
    </>
  )
}

export default Transactions
