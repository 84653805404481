import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { OrganizationForm } from 'components/module/organization/organization-form'
import styles from './create.module.scss'
import useCreate from './hooks/use-create'

const Create = () => {
  const {
    growlContent,
    couponCodeDropdownItems,
    isGrowlVisible,
    isSubmitting,
    pageTitle,
    clearError,
    handleSubmit,
  } = useCreate()

  return (
    <Card flat className={styles.container}>
      <header className={styles.header}>
        <Heading className={styles.title} type="h3">
          {pageTitle}
        </Heading>
      </header>
      <div>
        <GrowlMessage
          autoClose={false}
          isVisible={isGrowlVisible}
          onClick={clearError}
        >
          {growlContent}
        </GrowlMessage>
        <OrganizationForm
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          couponCodeDropdownItems={couponCodeDropdownItems}
        />
      </div>
    </Card>
  )
}

export default Create
