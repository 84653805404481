import { ProgramType } from '@campgladiator/cg-common.types.types'
import moment from 'moment'
import { ProductTypes } from 'types/product.d'
import * as Yup from 'yup'

export const createValidationSchema = ({
  productType,
  programType,
  isAlternativeAddressVisible,
  isEditing,
}: {
  productType: ProductTypes
  programType?: ProgramType
  isAlternativeAddressVisible?: boolean
  isEditing?: string
}) => {
  const isOutdoor = programType === 'OUTDOOR'

  return Yup.object({
    startDate: Yup.string().required('Start Date is Required'),
    endDate: Yup.string().notRequired(),
    tagId: Yup.number().required('Schedule Type is Required'),
    daysOfTheWeek:
      productType !== ProductTypes.NUTRITION
        ? Yup.string().required('You must select at least one day of the week')
        : Yup.string().notRequired(),
    startTime:
      productType !== ProductTypes.NUTRITION
        ? Yup.string().required('Start Time is Required')
        : Yup.string().notRequired(),
    durationInMinutes:
      productType !== ProductTypes.NUTRITION
        ? Yup.string().required('Duration is Required')
        : Yup.string().notRequired(),
    capacity:
      productType === ProductTypes.STRENGTH
        ? Yup.string()
            .required('Capacity is Required')
            .min(1, 'Capacity has to be greater or equal to 1')
        : Yup.string().notRequired(),
    link:
      productType === ProductTypes.BOOTCAMP && !isOutdoor
        ? Yup.string().required('Link is required')
        : Yup.string().notRequired(),
    effectiveDate: isEditing
      ? Yup.string()
          .required('Effective Date is Required')
          .test(
            'is-greater-or-equal',
            'Effective date should be today or in the future',
            function (value) {
              const { path, createError } = this
              const today = moment().startOf('day')
              const inputDate = moment(value).startOf('day')
              return (
                inputDate.isSameOrAfter(today) ||
                createError({
                  path,
                  message: 'Effective date should be today or in the future',
                })
              )
            },
          )
      : Yup.string().notRequired(),
    address:
      (productType === ProductTypes.BOOTCAMP && isOutdoor) ||
      productType === ProductTypes.STRENGTH
        ? Yup.object({
            name: Yup.string().required('Name is Required'),
            address1: Yup.string().required('Address 1 is Required'),
            state: Yup.string().required('State is Required'),
            city: Yup.string().trim().required('City is required'),
            zip: Yup.string()
              .required('Zip code is required')
              .min(5, 'Invalid zip')
              .max(5, 'Invalid zip'),
          })
        : Yup.object().notRequired(),
    alternativeAddress: isAlternativeAddressVisible
      ? Yup.object({
          name: Yup.string().required('Name is Required'),
          address1: Yup.string().required('Address 1 is Required'),
          state: Yup.string().required('State is Required'),
          city: Yup.string().trim().required('City is required'),
          zip: Yup.string()
            .required('Zip code is required')
            .min(5, 'Invalid zip')
            .max(5, 'Invalid zip'),
        })
      : Yup.object().notRequired(),
    newPrice:
      productType === ProductTypes.STRENGTH
        ? Yup.object({
            name: Yup.string().notRequired(),
            description: Yup.string().notRequired(),
            amount: Yup.number().required('Amount is Required'),
          })
        : Yup.object().notRequired(),
  })
}
