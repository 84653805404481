import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { Modal } from '@campgladiator/cgui-core.molecules.modal'
import { activeUserSelector } from 'app/redux/features/active-user/active-user-reducer'
import {
  RadioButton,
  RadioButtonOptionItem,
} from 'components/form-ui/radio-button'
import { capitalize } from 'lodash'
import { getAllPaymentMethods } from 'services/api/payment'
import styles from './payment-method.module.scss'

export type PaymentMethodProps = {
  productName: string
  defaultSelectedId: string
  submitting: boolean
  isVisible: boolean
  setToggleModal: (isVisible: boolean) => void
  onConfirm: (stripePaymentId: string) => void
}

const PaymentMethod = ({
  productName,
  defaultSelectedId,
  submitting,
  isVisible,
  setToggleModal,
  onConfirm,
}: PaymentMethodProps) => {
  const user = useSelector(activeUserSelector)
  const [stripePaymentId, setStripePaymentId] = useState(defaultSelectedId)
  const [options, setRadioButtonOptions] = useState<RadioButtonOptionItem[]>([])

  const getPaymentMethods = async (stripeCustomerId: string) => {
    try {
      const response = await getAllPaymentMethods(stripeCustomerId)
      if (response) {
        const cardRadioButtonItems = response.map((card) => ({
          value: card.id,
          label: `${capitalize(card?.brand || '')} card ending ${
            card?.last4 || ''
          }`,
        }))
        setRadioButtonOptions(cardRadioButtonItems)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (user?.stripeCustomerId) {
      getPaymentMethods(user.stripeCustomerId)
    }
  }, [user?.stripeCustomerId])

  return (
    <Modal
      isVisible={isVisible}
      title="Change Payment Method"
      onClose={() => setToggleModal(false)}
      className={styles.modalContainer}
      buttonPrimary={{
        children: submitting ? <Loader size="xsmall" /> : 'Confirm',
        type: 'button',
        name: 'Confirm',
        disabled: submitting,
        onClick: () => onConfirm(stripePaymentId),
      }}
      buttonSecondary={{
        children: 'Cancel',
        onClick: () => setToggleModal(false),
      }}
      theme="trainer"
    >
      <>
        <Paragraph size="small" weight="book" className={styles.modalSubTitle}>
          {productName}
        </Paragraph>

        <RadioButton
          icon={
            <Icon.Line name="icon-credit-card" className={styles.cardIcon} />
          }
          initialChecked={defaultSelectedId}
          onChange={(value) => setStripePaymentId(value)}
          options={options}
        />
      </>
    </Modal>
  )
}

export default PaymentMethod
