import classNames from 'classnames'
import styles from './dropdown.module.scss'

export type DropdownItem = {
  label: string
  value: string
  disabled?: boolean
}

export type DropdownProps = {
  className?: string
  options: DropdownItem[]
  name?: string
  id?: string
  value?: string | undefined
  disabled?: boolean
  label?: string
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void
}

const Dropdown = ({
  className,
  options,
  value,
  disabled,
  name,
  id,
  label,
  onChange,
  onBlur,
}: DropdownProps) => (
  <div className={classNames(styles.dropdownContainer, className)}>
    <select
      className={styles.dropdown}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      name={name}
      id={id}
    >
      {label && <option value="">{label}</option>}
      {options.map(({ value, label, disabled }, index) => (
        <option
          key={`${index}-${value}`}
          value={value}
          label={label}
          disabled={disabled}
        >
          {label}
        </option>
      ))}
    </select>
  </div>
)

export default Dropdown
