import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OrganizationDTO } from '@campgladiator/cg-common.types.types'
import { postOrganization } from 'services/api/organization'
import useOrganizationCoupon from './use-org-coupon'

const useCreate = () => {
  const [isGrowlVisible, setIsGrowlVisible] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [growlContent, setGrowlContent] = useState('')
  const navigate = useNavigate()
  const { couponCodeDropdownItems, couponError } = useOrganizationCoupon()

  const pageTitle = 'Create Organization'

  const setGrowlView = (isVisible: boolean, growlContent: string) => {
    setIsGrowlVisible(isVisible)
    setGrowlContent(growlContent)
  }

  const handleSubmit = async (value: Partial<OrganizationDTO>) => {
    setSubmitting(true)

    try {
      const response = await postOrganization(value)
      if (response) {
        setGrowlView(true, 'Organization saved successfully')
        const id = response.id
        setTimeout(
          () => navigate(`/partnerships/${response.id}`, { state: { id } }),
          1000,
        )
      }
    } catch (error: any) {
      setGrowlView(true, error.message)
    } finally {
      setSubmitting(false)
    }
  }

  const clearError = () => {
    setIsGrowlVisible(false)
  }

  useEffect(() => {
    if (!couponError) return
    setGrowlView(true, couponError)
  }, [couponError])

  return {
    growlContent,
    couponCodeDropdownItems,
    isGrowlVisible,
    isSubmitting,
    pageTitle,
    clearError,
    handleSubmit,
  }
}

export default useCreate
