import {
  OrganizationDTO,
  OrganizationParnershipType as PartnershipType,
} from '@campgladiator/cg-common.types.types'
import { emailValidationRFC2822 } from '@campgladiator/cg-common.utility.validation'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { Dropdown } from 'components/form-ui/dropdown'
import { useFormik } from 'formik'
import { OrganizationCouponCodeDropdownItem } from 'pages/partnerships/create/hooks/use-org-coupon'
import { stateList } from 'services/data/address'
import StateDropDownItems from 'services/data/address'
import { partnershipTypeDropdownItems } from 'services/data/partnershipType'
import { phoneValidation } from 'utils/regex'
import * as Yup from 'yup'
import styles from './organization-form.module.scss'

interface IOrganizationFormProps {
  organization?: Partial<OrganizationDTO>
  onSubmit: (values: Partial<OrganizationDTO>) => void
  isSubmitting: boolean
  couponCodeDropdownItems: OrganizationCouponCodeDropdownItem[]
}

const emailRegex = new RegExp(emailValidationRFC2822)

const validationSchema = () =>
  Yup.object({
    name: Yup.string().required('Name is required'),
    partnershipType: Yup.string().required('Partnership Type is required'),
    startDate: Yup.date().required('Start Date is required'),
    email: Yup.string()
      .required('Email address is required')
      .matches(emailRegex, 'Invalid email address'),
    phone: Yup.string()
      .required('Phone number is required')
      .matches(phoneValidation, 'Phone number is not valid'),
    discountPercentage: Yup.number()
      .required('Discount Percentage is required')
      .min(1, 'Discount Percentage must be greater than or equal to 1')
      .max(100, 'Discount Percentage must not be greater than 100'),
    couponCode: Yup.string().required('Coupon Code is required'),
  })

const getStateAbbr = (stateName: string | undefined) => {
  const [firstFound] = stateList.filter(
    (state) =>
      state.name.toLowerCase() === stateName?.toLowerCase() ||
      state.abbr.toLowerCase() === stateName?.toLowerCase(),
  )
  if (firstFound) return firstFound.abbr || ''
  return ''
}

const OrganizationForm = ({
  organization,
  isSubmitting,
  onSubmit,
  couponCodeDropdownItems,
}: IOrganizationFormProps) => {
  const { handleSubmit, getFieldProps, errors, touched } = useFormik({
    initialValues: {
      name: organization?.name || '',
      description: organization?.description || '',
      email: organization?.email || '',
      phone: organization?.phone || '',
      primaryContactName: organization?.primaryContactName || '',
      primaryContactTitle: organization?.primaryContactTitle || '',
      address: organization?.address || '',
      address2: organization?.address2 || '',
      city: organization?.city || '',
      state: getStateAbbr(organization?.state) || '',
      zipCode: organization?.zipCode || '',
      partnershipType: (organization?.partnershipType as PartnershipType) || '',
      startDate: organization?.startDate || '',
      discountPercentage: organization?.discountPercentage || 0,
      splits: organization?.splits || [],
      couponCode: organization?.couponCode || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: Partial<OrganizationDTO>) => {
      onSubmit({
        ...values,
      })
    },
  })

  return (
    <div className={styles.formContainer}>
      <form className={styles.formSection} onSubmit={handleSubmit}>
        <FormPart
          label="Name*"
          forId="name"
          className={styles.fieldsetItem}
          errorMsg={(touched.name && errors.name) || ''}
        >
          <Input id="name" placeholder="Name" {...getFieldProps('name')} />
        </FormPart>
        <FormPart
          label="Description"
          forId="description"
          className={styles.fieldsetItem}
          errorMsg={(touched.description && errors.description) || ''}
        >
          <Input
            id="description"
            placeholder="Description"
            {...getFieldProps('description')}
          />
        </FormPart>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Email*"
            forId="email"
            className={styles.fieldsetItem}
            errorMsg={(touched.email && errors.email) || ''}
          >
            <Input id="email" placeholder="Email" {...getFieldProps('email')} />
          </FormPart>
          <FormPart
            label="Phone Number*"
            forId="phone"
            className={styles.fieldsetItem}
            errorMsg={(touched.phone && errors.phone) || undefined}
          >
            <Input
              id="phone"
              type="tel"
              placeholder="+1 (555) 555-5555"
              {...getFieldProps('phone')}
            />
          </FormPart>
        </Fieldset>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Primary Contact Name"
            forId="primaryContactName"
            className={styles.fieldsetItem}
            errorMsg={
              (touched.primaryContactName && errors.primaryContactName) || ''
            }
          >
            <Input
              id="primaryContactName"
              placeholder="Primary Contact Name"
              {...getFieldProps('primaryContactName')}
            />
          </FormPart>
          <FormPart
            label="Primary Contact Title"
            forId="primaryContactTitle"
            className={styles.fieldsetItem}
            errorMsg={
              (touched.primaryContactTitle && errors.primaryContactTitle) || ''
            }
          >
            <Input
              id="primaryContactTitle"
              placeholder="Primary Contact Title"
              {...getFieldProps('primaryContactTitle')}
            />
          </FormPart>
        </Fieldset>
        <FormPart
          label="Partnership Type*"
          forId="partnershipType"
          errorMsg={(touched.partnershipType && errors.partnershipType) || ''}
        >
          <Dropdown
            id="type"
            label="Choose Partnership Type"
            options={partnershipTypeDropdownItems}
            {...getFieldProps('partnershipType')}
          />
        </FormPart>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Discount Percentage (%)*"
            forId="discountPercentage"
            className={styles.fieldsetItem}
            errorMsg={
              (touched.discountPercentage && errors.discountPercentage) || ''
            }
          >
            <Input
              type="number"
              id="discountPercentage"
              {...getFieldProps('discountPercentage')}
            />
          </FormPart>

          <FormPart
            label="Start Date*"
            forId="startDate"
            className={styles.fieldsetItem}
            errorMsg={(touched.startDate && errors.startDate) || ''}
          >
            <Input
              id="startDate"
              type="date"
              placeholder="MM/DD/YYYY"
              {...getFieldProps('startDate')}
            />
          </FormPart>
        </Fieldset>
        <FormPart
          label="Coupon Code*"
          forId="couponCode"
          errorMsg={(touched.couponCode && errors.couponCode) || ''}
        >
          <Dropdown
            id="type"
            label="Choose Coupon Code"
            options={couponCodeDropdownItems}
            {...getFieldProps('couponCode')}
          />
        </FormPart>
        <Heading className={styles.locationTitle} type="h4">
          Location Information
        </Heading>
        <FormPart
          label="Address 1"
          forId="address"
          errorMsg={(touched.address && errors.address) || ''}
        >
          <Input
            id="address"
            placeholder="Address 1"
            {...getFieldProps('address')}
          />
        </FormPart>
        <FormPart label="Address 2" forId="address2">
          <Input
            id="address2"
            placeholder="Address 2"
            {...getFieldProps('address2')}
          />
        </FormPart>
        <FormPart
          label="City"
          forId="city"
          errorMsg={(touched.city && errors.city) || ''}
        >
          <Input id="city" placeholder="City" {...getFieldProps('city')} />
        </FormPart>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="State"
            forId="state"
            className={styles.fieldsetItem}
            errorMsg={(touched.state && errors.state) || ''}
          >
            <Dropdown
              id="state"
              label="Choose a state"
              options={StateDropDownItems}
              {...getFieldProps('state')}
            />
          </FormPart>
          <FormPart
            label="Zip code"
            forId="zipCode"
            className={styles.fieldsetItem}
            errorMsg={(touched.zipCode && errors.zipCode) || ''}
          >
            <Input
              id="zipCode"
              placeholder="Zip code"
              maxLength={5}
              minLength={5}
              type="tel"
              {...getFieldProps('zipCode')}
            />
          </FormPart>
        </Fieldset>
        <div className={styles.buttons}>
          <Button
            emphasis="primary"
            type="submit"
            disabled={isSubmitting}
            theme="trainer"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default OrganizationForm
