import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createTip } from '@campgladiator/cg-common.api.payment'
import { TipRequestDTO } from '@campgladiator/cg-common.types.types'
import { useAppAccess } from 'app/contexts/app-access'
import { useGrowlContext } from 'app/contexts/growl-context'
import { useWithPaymentAmountInput } from '../components/payment-amount-input'
import { useWithPaymentCard } from '../components/payment-card'
import { SubmitButtonProps } from '../components/submit-button'
import { TrainerTipFormProps } from './trainer-tip-form'

type UseTrainerTipFormArgs = TrainerTipFormProps

const useTrainerTipForm = ({ transactionType }: UseTrainerTipFormArgs) => {
  const [selectedTrainerId, setSelectedTrainerId] = useState<string | null>(
    null,
  )
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const { authToken } = useAppAccess()
  const navigate = useNavigate()
  const { showGrowl } = useGrowlContext()
  const { id: userId } = useParams()

  const tipAmountInputId = 'tipAmount'
  const tipError = 'Failed to create tip'

  const handleTrainerSelect = (selectedTrainer: any) => {
    setSelectedTrainerId(selectedTrainer.itemId)
  }

  const paymentAmountInputProps = useWithPaymentAmountInput({
    transactionType,
    formLabel: 'Tip Amount',
    id: tipAmountInputId,
    name: tipAmountInputId,
  })

  const paymentMethodProps = useWithPaymentCard({
    formLabel: 'Payment Methods',
    id: 'paymentMethods',
  })
  const { amount, error } = paymentAmountInputProps
  const { selectedCardId } = paymentMethodProps

  const isButtonDisabled =
    !amount || !selectedTrainerId || !selectedCardId || isSaving || !!error

  const handleSubmitButtonClick = async (event: {
    preventDefault: () => void
  }): Promise<boolean> => {
    event.preventDefault()
    setIsSaving(true)

    try {
      const stripeAmount = amount ? amount * 100 : 0
      const args: TipRequestDTO = {
        amount: stripeAmount,
        trainerId: selectedTrainerId ?? '',
        stripePaymentMethodId: selectedCardId ?? '',
        userId: userId ?? '',
      }
      await createTip(args, authToken?.token ?? '')
      showGrowl('SUCCESS', 'Tip created successfully')
      setTimeout(() => {
        setIsSaving(false)
        navigate(-1)
      }, 2000)
      return Promise.resolve(true)
    } catch (error) {
      console.error(`${tipError}:`, error)
      showGrowl('FAILED', tipError)
      setIsSaving(false)
      return Promise.resolve(false)
    }
  }

  const submitButtonProps: SubmitButtonProps = {
    disabled: isButtonDisabled,
    handleSubmitButtonClick,
    isSaving,
  }

  return {
    isButtonDisabled,
    paymentAmountInputProps,
    paymentMethodProps,
    selectedTrainerId,
    submitButtonProps,
    handleTrainerSelect,
  }
}

export default useTrainerTipForm
