import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Action, combineReducers, configureStore } from '@reduxjs/toolkit'
import { authReducer } from 'app/auth'
import { ThunkAction } from 'redux-thunk'
import activeOrganizationReducer from './features/active-organization/active-organization-reducer'
import activeProgramReducer from './features/active-program/active-program-reducer'
import activeTrainerReducer from './features/active-trainer/active-trainer-reducer'
import activeUserReducer from './features/active-user/active-user-reducer'
import productsListReducer from './features/products-list/products-list-reducer'
import subscriptionReducer from './features/subscription/subscription-reducer'
import teamReducer from './features/team/team-reducer'
import { configureMiddleware } from './middleware'

const combinedReducer = combineReducers({
  authNew: authReducer,
  activeProgram: activeProgramReducer,
  activeOrganization: activeOrganizationReducer,
  activeUser: activeUserReducer,
  activeTrainer: activeTrainerReducer,
  team: teamReducer,
  productList: productsListReducer,
  subscription: subscriptionReducer,
})

export const store = configureStore({
  reducer: combinedReducer,
  middleware: configureMiddleware,
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  {},
  null,
  Action<string>
>
export const useAppDispatch = () => useDispatch<any>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
