import { useRef, useState } from 'react'
import { UserDTO } from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Toggle } from '@campgladiator/cgui-core.atoms.toggle'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripePaymentElementOptions } from '@stripe/stripe-js'
import { updatePaymentMethodAsDefault } from 'services/api/payment'
import styles from './new-payment-card-form.module.scss'

export type NewPaymentCardFormProps = {
  onAfterFormSubmit?: (paymentMethodId?: string) => void
  onSubmitError?: (error: any) => void
  user: UserDTO | null
}

const NewPaymentCardForm = ({
  user,
  onAfterFormSubmit,
  onSubmitError,
}: NewPaymentCardFormProps) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isDefaultPaymentMethod, setDefaultPaymentMethod] =
    useState<boolean>(false)

  const stripe = useStripe()
  const elements = useElements()

  const formRef = useRef<HTMLFormElement>(null)

  const submitButtonDisabled = isLoading || !stripe

  const paymentElementOptions = {
    fields: {
      billingDetails: {
        name: 'auto',
        email: 'never',
      },
    },
    terms: { card: 'never' },
  } as StripePaymentElementOptions

  const handleIsDefaultToggleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDefaultPaymentMethod(e.target.checked)
  }

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    try {
      setLoading(true)

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: '',
          payment_method_data: {
            billing_details: {
              email: user?.email,
            },
          },
        },
      })

      if (error) {
        throw error
      }

      if (setupIntent.payment_method && isDefaultPaymentMethod) {
        const paymentMethodId = setupIntent.payment_method?.toString()
        if (paymentMethodId) {
          await updatePaymentMethodAsDefault(paymentMethodId)
        }
      }

      if (onAfterFormSubmit)
        onAfterFormSubmit(setupIntent.payment_method?.toString())
    } catch (err) {
      if (onSubmitError) onSubmitError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <form id="new-payment-card-form" ref={formRef} onSubmit={handleFormSubmit}>
      <PaymentElement
        id="stripe-payment-element"
        options={paymentElementOptions}
      />
      <Fieldset>
        <FormPart forId="isDefault">
          <div className={styles.isDefaultToggleContainer}>
            <Toggle
              toggleSize="xsmall"
              className={styles.isDefaultToggleInput}
              id="isDefault"
              checked={isDefaultPaymentMethod}
              onChange={handleIsDefaultToggleChange}
            />
            <Paragraph size="xsmall" weight="book">
              Save as default payment card
            </Paragraph>
          </div>
        </FormPart>
      </Fieldset>
      <div className={styles.submitButtonContainer}>
        <Button
          emphasis="primary"
          className={styles.submitButton}
          disabled={submitButtonDisabled}
          type="submit"
          theme="trainer"
        >
          Save card
        </Button>
      </div>
    </form>
  )
}

export default NewPaymentCardForm
