import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { RegionGroup } from 'types/locale'
import { RegionGroupForm } from '../region-group-form'
import useRegionGroupOverview from './hooks/use-region-group-overview'
import styles from './region-group-overview.module.scss'

const RegionGroupOverview = () => {
  const {
    growlContent,
    isGrowlVisible,
    isSubmitting,
    regionGroupData,
    clearError,
    handleSubmit,
  } = useRegionGroupOverview()

  return (
    <div className={styles.container}>
      <GrowlMessage isVisible={isGrowlVisible} onClick={clearError}>
        {growlContent}
      </GrowlMessage>

      {regionGroupData && (
        <>
          <header className={styles.header}>
            <Heading type="h3">Overview</Heading>
          </header>

          <RegionGroupForm
            regionGroupData={regionGroupData}
            onSubmit={(value: RegionGroup) => {
              handleSubmit(value)
            }}
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </div>
  )
}

export default RegionGroupOverview
