import { upperCase } from 'lodash'

const referralTypeList = ['Trainer', 'Spouse', 'User']

const ReferralTypeDropdownItems = referralTypeList.map((referralType) => ({
  value: upperCase(referralType),
  label: referralType,
}))

export default ReferralTypeDropdownItems
