import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserDTO } from '@campgladiator/cg-common.types.types'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { useGrowlContext } from 'app/contexts/growl-context'
import { UserForm } from 'components/module/user/user-form'
import { UpdateUser } from 'services/api/user'
import styles from './create.module.scss'

const Create = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const { showGrowl } = useGrowlContext()

  const handleSubmit = async (values: Partial<UserDTO>) => {
    setSubmitting(true)
    UpdateUser('Add', values)
      .then((response) => {
        if (response) {
          showGrowl('', 'User saved successfully', false)
          setTimeout(() => navigate(`/users/${response.id}`), 1000)
        }
      })
      .catch((e) => {
        showGrowl('', e.message, false)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Card flat className={styles.container}>
      <header className={styles.header}>
        <Heading className={styles.title} type="h3">
          Create New User Account
        </Heading>
      </header>

      <UserForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />
    </Card>
  )
}

export default Create
