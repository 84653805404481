import { useMemo } from 'react'
import type { FileUploaderProps, MediaType } from './file-uploader'

type FileUploaderArgs = {
  disabled: boolean
  fileInputRef: React.Ref<HTMLInputElement> | undefined
  mediaType: MediaType
  text: string
  onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void
  onClick: (event?: React.MouseEvent<HTMLElement>) => void
}

const useWithFileUploader = ({
  disabled,
  fileInputRef,
  mediaType,
  text,
  onChange,
  onClick,
}: FileUploaderArgs): FileUploaderProps => {
  const supportedAttachmentTypes = useMemo(() => {
    switch (mediaType) {
      case 'PHOTO':
        return 'image/jpeg, image/jpg, image/png'
      case 'VIDEO':
        return 'video/mp4'
      default:
        return '*/*'
    }
  }, [mediaType])

  return {
    disabled,
    fileInputRef,
    mediaType,
    supportedAttachmentTypes,
    text,
    onChange,
    onClick,
  }
}

export { useWithFileUploader }
