import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { ProductDetailDTO } from '@campgladiator/cg-common.types.types'
import { ListItemProps } from '@campgladiator/cgui-core.atoms.list-item'
import { useFormik } from 'formik'
import moment from 'moment'
import { getProductDetails } from 'services/api/product'
import { ProductTypes } from 'types/product.d'
import * as Yup from 'yup'
import { ScheduleProgramFormProps } from '..'

type UseScheduleProgramFormProps = Pick<
  ScheduleProgramFormProps,
  'initialData' | 'onSubmitForm'
>

type ProductOptions = {
  value: string
  label: string
}

const approvalAttrMapping = {
  OUTDOOR: 'outdoorApproved',
  ONLINE: 'onlineApproved',
  STRENGTH: 'strengthApproved',
} as {
  [key: string]: string
}

const defaultValues = {
  type: '',
  productDetailsId: '',
  trainerId: '',
  startDate: '',
  endDate: '',
  timezone: '',
}

const currentDate = moment().format('YYYY-MM-DD')

export const programValidationSchema = Yup.object({
  type: Yup.string().required(),
  productDetailsId: Yup.string().required('Product is required'),
  trainerId: Yup.string().required('Trainer is required'),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .notRequired()
    .min(Yup.ref('startDate'), "End date can't be before start date"),
  timezone: Yup.string().required('Time zone is required'),
})

const useScheduleProgramForm = ({
  initialData,
  onSubmitForm,
}: UseScheduleProgramFormProps) => {
  const [productList, setProductList] = useState<ProductDetailDTO[]>([])
  const [productOptions, setProductOptions] = useState<ProductOptions[]>([])
  const [trainersFilterParams, setTrainersFilterParams] = useState<any>({})
  const [timezoneName, setTimezoneName] = useState<string>('')

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setSubmitting,
    resetForm,
    isSubmitting,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: initialData || defaultValues,
    validationSchema: programValidationSchema,
    onSubmit: async (values) => {
      try {
        onSubmitForm(values)
        resetForm()
      } catch (error: any) {
        console.log(error)
      }

      setSubmitting(false)
    },
    enableReinitialize: true,
  })

  const handleSetTimezone = (record: ListItemProps) => {
    if (!record) {
      setTimezoneName('')
      setFieldValue('timezone', '')
      return
    }
    setFieldValue('timezone', record ? record.itemId : '')
    const timezoneName = record ? record.text : ''
    setTimezoneName(timezoneName!)
  }

  const triggerProductChange = useCallback(
    (productDetailsId: string) => {
      const productDetails = productList.find(
        ({ id }) => id === productDetailsId,
      )
      const type =
        productDetails?.approvalRequired === 'STRENGTH'
          ? 'OUTDOOR'
          : productDetails?.approvalRequired

      setFieldValue('productDetailsId', productDetailsId)
      setFieldValue('type', type)
      setTrainersFilterParams({
        approvedProgram: productDetails?.approvalRequired,
      })
    },
    [productList, setFieldValue, setTrainersFilterParams],
  )

  const handleProductChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const productDetailsId = e.target.value
    triggerProductChange(productDetailsId)
  }

  const fetchProductDetails = async () => {
    try {
      const response = await getProductDetails()
      const selectedProducts = response.content?.filter(
        (product: any) => product.type !== ProductTypes.NUTRITION,
      )
      setProductList(selectedProducts)
      setProductOptions(
        selectedProducts.map((it) => ({
          value: it.id,
          label: it.title,
        })),
      )
    } catch (error) {
      console.log(error)
    }
  }

  const getMinDate = () => {
    if (!initialData?.startDate) return currentDate

    return moment().isAfter(initialData?.startDate)
      ? initialData?.startDate
      : currentDate
  }

  useEffect(() => {
    fetchProductDetails()
  }, [])

  useEffect(() => {
    if (initialData?.timezone) {
      setTimezoneName(initialData.timezone)
    }
  }, [initialData?.timezone])

  useEffect(() => {
    if (initialData?.id) {
      triggerProductChange(initialData.productDetailsId)
      setTrainersFilterParams({
        approvedProgram: initialData.type,
      })
    }
  }, [
    initialData?.id,
    initialData?.productDetailsId,
    initialData?.type,
    triggerProductChange,
  ])

  return {
    errors,
    isSubmitting,
    productOptions,
    timezoneName,
    touched,
    trainersFilterParams,
    values,
    approvalAttrMapping,
    getFieldProps,
    getMinDate,
    handleProductChange,
    handleSetTimezone,
    handleSubmit,
    setFieldValue,
  }
}

export default useScheduleProgramForm
