import React from 'react'

type ITabPanelProps = {
  title?: string
  children?: React.ReactElement
}

export const TabPanel = ({ children }: ITabPanelProps) => {
  return <div>{children}</div>
}
