import { ChangeEvent, useState } from 'react'
import { NewTransactionType } from '../../../new-transaction'
import { PaymentAmountInputProps } from './payment-amount-input'

type UseWithPaymentAmountInputArgs = {
  transactionType: NewTransactionType
} & Pick<PaymentAmountInputProps, 'formLabel' | 'id' | 'name'>

const useWithPaymentAmountInput = ({
  formLabel,
  id,
  name,
  transactionType,
}: UseWithPaymentAmountInputArgs) => {
  const [amount, setAmount] = useState<number | null>(null)
  const [error, setError] = useState<string>('')

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = parseFloat(event.target.value)

    if (!Number.isNaN(value)) {
      const decimalPlaces = (value.toString().split('.')[1] || []).length
      if (decimalPlaces > 2) {
        value = parseFloat(value.toFixed(2))
      }
    }

    if (
      transactionType === NewTransactionType.TrainerTip &&
      (value < 1 || value > 99.99)
    ) {
      setError('Value can only be between 1 and 99.99')
    } else {
      setAmount(value)
      setError('')
    }
  }

  return {
    amount,
    error,
    formLabel,
    id,
    name,
    handleAmountChange,
  }
}

export default useWithPaymentAmountInput
