import { TransactionDTO } from '@campgladiator/cg-common.types.types'

const setStatusFailedOnlyToTheLastInvoiceId = (
  transactions: TransactionDTO[],
): TransactionDTO[] => {
  const invoiceIdToTransactionMap = getOldestFailedTransactionsMap(transactions)
  const result: TransactionDTO[] = []

  for (const transaction of transactions) {
    result.push(updateTransactionStatus(transaction, invoiceIdToTransactionMap))
  }

  return result
}

const getOldestFailedTransactionsMap = (
  transactions: TransactionDTO[],
): Map<string, TransactionDTO> => {
  const invoiceIdToTransactionMap = new Map<string, TransactionDTO>()

  for (const transaction of transactions) {
    if (transaction.status === 'FAILED') {
      const existingTransaction = invoiceIdToTransactionMap.get(
        transaction.invoiceId,
      )

      if (
        !existingTransaction ||
        new Date(transaction.created) < new Date(existingTransaction.created)
      ) {
        invoiceIdToTransactionMap.set(transaction.invoiceId, transaction)
      }
    }
  }

  return invoiceIdToTransactionMap
}

const updateTransactionStatus = (
  transaction: TransactionDTO,
  invoiceIdToTransactionMap: Map<string, TransactionDTO>,
): TransactionDTO => {
  if (
    transaction.status !== 'FAILED' ||
    transaction === invoiceIdToTransactionMap.get(transaction.invoiceId)
  ) {
    return transaction
  } else {
    return { ...transaction, status: null as any }
  }
}

export default setStatusFailedOnlyToTheLastInvoiceId
