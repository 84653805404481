import { TeamRequestDTO } from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import { Locale } from 'types/locale'

const baseUrl = configuration.apiGatewayUrl

export const postCreateTeam = async (data: Locale): Promise<TeamRequestDTO> => {
  try {
    const endpoint = '/trainer/v1/teams'
    const method = 'POST' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(data),
    })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-team-library',
      attributeName: 'post-teams',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putTeam = async (
  teamId: string,
  data: Locale,
): Promise<TeamRequestDTO> => {
  const endpoint = `/trainer/v1/teams/${teamId}`
  const method = 'PUT'
  try {
    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(data),
    })

    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-put-teams',
      attributeName: 'put-teams',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
