import { fetchCoupons } from '@campgladiator/cg-common.api.coupons'
import { CouponDTO } from '@campgladiator/cg-common.types.types'

let coupons: CouponDTO[] = []

const getCoupons = () => {
  if (coupons.length) return coupons
  fetchCoupons().then((response) => (coupons = response))
  return coupons
}

export default getCoupons
