import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { LocaleForm } from 'components/module/locale'
import { getRegionById } from 'services/api/locale'
import { updateLocale } from 'services/api/locale'
import { Locale } from 'types/locale'
import styles from './region-overview.module.scss'

const RegionOverview = () => {
  const { id } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [regionData, setRegionData] = useState<Locale>()
  const [isGrowlVisible, setIsGrowlVisible] = useState(false)
  const [growlContent, setGrowlContent] = useState('')
  const { state } = useLocation()

  const getRegion = useCallback(async () => {
    getRegionById(state.id)
      .then((response: Locale) => {
        if (response) {
          setRegionData(response)
        }
      })
      .catch((e) => {
        setGrowlView(true, e)
      })
  }, [state.id])

  useEffect(() => {
    getRegion()
  }, [getRegion])

  const setGrowlView = (isVisible: boolean, growlContent: string) => {
    setIsGrowlVisible(isVisible)
    setGrowlContent(growlContent)
  }

  const clearError = () => {
    setIsGrowlVisible(false)
  }

  const handleSubmit = async (value: Locale) => {
    setSubmitting(true)
    updateLocale(value, id!)
      .then((response: Locale) => {
        if (response) {
          setGrowlView(true, 'Region saved successfully')
        }
      })
      .catch((e) => {
        setGrowlView(true, e.message)
        setSubmitting(false)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <div className={styles.container}>
      <GrowlMessage isVisible={isGrowlVisible} onClick={clearError}>
        {growlContent}
      </GrowlMessage>

      {regionData && (
        <>
          <header className={styles.header}>
            <Heading className={styles.title} type="h3">
              Overview
            </Heading>
          </header>

          <LocaleForm
            localeData={regionData}
            onSubmit={(value: Locale) => {
              handleSubmit(value)
            }}
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </div>
  )
}

export default RegionOverview
