import { TrainerPlanType } from '@campgladiator/cg-common.types.types'

type PlanTypeDropdownItem = {
  value: TrainerPlanType
  label: string
}

export const planTypeDropdownItems: PlanTypeDropdownItem[] = [
  {
    value: 'BASIC',
    label: 'Basic',
  },
  {
    value: 'APPLICANT',
    label: 'Applicant',
  },
  {
    value: 'AFFILIATE_MARKETER',
    label: 'Affiliate Marketer',
  },
  {
    value: 'PRO',
    label: 'Pro',
  },
]

export default planTypeDropdownItems
