import { useCallback, useEffect, useState } from 'react'
import {
  getProgramScheduleChangeRequest,
  createProgramScheduleChangeRequest,
  updateProgramScheduleChangeRequest,
} from '@campgladiator/cg-common.api.programs'
import {
  BaseEnumerableResponse,
  ProgramScheduleChangeDTO,
  ProgramScheduleChangeRequestDTO,
} from '@campgladiator/cg-common.types.types'
import { DropdownProps } from 'components/form-ui/dropdown/dropdown'
import { useFormik } from 'formik'
import moment from 'moment'
import { postOutdoorAddress, putOutdoorAddress } from 'services/api/address'
import { putSchedule, postNewSchedule } from 'services/api/schedule'
import { getTags } from 'services/api/tag'
import { Address, AddressTypes } from 'types/address.d'
import { ProductTypes } from 'types/product.d'
import { Schedule } from 'types/schedule.d'
import { useAppAccess } from '../../../../app/contexts/app-access'
import { createValidationSchema } from '../logic/form/createValidationSchema'
import { convertTagsToOptions, filterTagsByType } from '../logic/tags'
import { IScheduleFormProps } from '../schedule-form'
import useWithScheduleReassign from './use-with-schedule-reassign'

const useScheduleForm = ({
  programId,
  programType,
  productType,
  scheduleData,
  onAfterSubmitForm,
}: IScheduleFormProps) => {
  const [isGrowlVisible, setGrowlVisible] = useState<boolean>(false)
  const [growlContent, setGrowlContent] = useState<string>('')
  const [scheduleTypes, setScheduleTypes] = useState<DropdownProps['options']>(
    [],
  )
  const [isReassignModalVisible, setIsReassignModalVisible] =
    useState<boolean>(false)
  const isEditing = scheduleData && scheduleData.id
  const [isAlternativeAddressVisible, setIsAlternativeAddressVisible] =
    useState<boolean>(false)
  const [defaultEffectiveDate, setDefaultEffectiveDate] = useState<string>(
    moment(scheduleData?.endDate ? scheduleData.endDate : undefined).format(
      'YYYY-MM-DD',
    ),
  )
  type ScheduleDateCaseType = 'FUTURE' | 'PRESENT'
  type AddressType = 'ADDRESS' | 'ALTERNATIVE_ADDRESS'

  const showMessage = (message: string) => {
    setGrowlContent(message)
    setGrowlVisible(true)
  }

  const { scheduleReassignProps } = useWithScheduleReassign({
    scheduleData: scheduleData,
    showMessage,
    defaultEffectiveDate,
    isReassignModalVisible,
    onClose: () => setIsReassignModalVisible(false),
  })

  const { authToken } = useAppAccess()

  const displayError = (error: any) => {
    if (typeof error === 'string') {
      showMessage(error)
    } else if (error.message) {
      showMessage(error.message)
    } else {
      showMessage('An unknown error has occurred, please try again')
    }
  }

  const createChangePayload = (
    schedule: Schedule,
  ): ProgramScheduleChangeRequestDTO => {
    return {
      addressId: schedule.addressId ?? '',
      alternativeAddressId: schedule.alternativeAddressId,
      daysOfTheWeek: schedule.daysOfTheWeek,
      description: schedule.description,
      durationInMinutes: schedule.durationInMinutes
        ? Number(schedule.durationInMinutes)
        : 0,
      programScheduleId: schedule.id ?? '',
      startTime: schedule.startTime,
      applyChangeOn: schedule.effectiveDate ?? '',
    }
  }

  const checkIfChangeExists = (
    programScheduleChanges: BaseEnumerableResponse<ProgramScheduleChangeDTO>,
    changePayload: ProgramScheduleChangeRequestDTO,
  ): ProgramScheduleChangeDTO | undefined => {
    for (const change of programScheduleChanges.content) {
      const date = change.applyChangeOn
      if (date === changePayload.applyChangeOn) {
        return change
      }
    }
    return undefined
  }

  const handleFutureDateCase = async (schedule: Schedule) => {
    const changePayload = createChangePayload(schedule)

    const programScheduleChange = await getProgramScheduleChangeRequest(
      {
        programScheduleId: schedule.id,
      },
      authToken?.token ?? '',
    )

    const change = checkIfChangeExists(programScheduleChange, changePayload)

    const response = !change
      ? await createProgramScheduleChangeRequest(
          changePayload,
          authToken?.token ?? '',
        )
      : await updateProgramScheduleChangeRequest(
          change.id,
          changePayload,
          authToken?.token ?? '',
        )
    return response
  }

  const handlePresentDateCase = async (schedule: Schedule) => {
    const payload = {
      ...schedule,
      addressId: schedule.addressId,
      alternativeAddressId: schedule.alternativeAddressId,
    }

    return await putSchedule(payload)
  }

  const handleAddress = async (
    schedule: Schedule,
    address: Address,
    addressType: AddressType,
    addressFunction: (address: Address) => Promise<Address>,
  ) => {
    const addressResponse = await addressFunction(address)

    switch (addressType) {
      case 'ADDRESS':
        schedule.addressId = addressResponse.id
        schedule.address = addressResponse
        break
      case 'ALTERNATIVE_ADDRESS':
        schedule.alternativeAddressId = addressResponse.id
        schedule.alternativeAddress = addressResponse
        break
    }
  }

  const handleScheduleChangeRequest = async (
    dateCase: ScheduleDateCaseType,
    schedule: Schedule,
  ) => {
    const allowedProgramType = ['OUTDOOR', 'ONDEMAND']
    if (programType && allowedProgramType.includes(programType)) {
      const addressFunction =
        dateCase === 'FUTURE' ? postOutdoorAddress : putOutdoorAddress
      await handleAddress(
        schedule,
        schedule.address!,
        'ADDRESS',
        addressFunction,
      )

      if (isAlternativeAddressVisible) {
        const alternativeAddressFunction =
          dateCase === 'FUTURE'
            ? postOutdoorAddress
            : !schedule.alternativeAddressId
            ? postOutdoorAddress
            : putOutdoorAddress
        await handleAddress(
          schedule,
          schedule.alternativeAddress!,
          'ALTERNATIVE_ADDRESS',
          alternativeAddressFunction,
        )
      }
    }
  }

  const updateScheduleAndAddress = async (schedule: Schedule) => {
    const date = new Date(schedule.effectiveDate!)
    const currentDate = new Date()
    const dateCase = date > currentDate ? 'FUTURE' : 'PRESENT'

    await handleScheduleChangeRequest(dateCase, schedule)

    const response =
      dateCase === 'FUTURE'
        ? await handleFutureDateCase(schedule)
        : await handlePresentDateCase(schedule)

    return {
      ...response,
      addressId: schedule.addressId,
      alternativeAddressId: schedule.alternativeAddressId,
    }
  }

  const postNewOutdoorAddress = async (address?: Address) => {
    const newAddress = await postOutdoorAddress(address)

    if (!newAddress) {
      throw new Error(
        'An error has occurred while saving the address, please try again',
      )
    }

    return newAddress.id
  }

  const createScheduleAndAddress = async (schedule: Schedule) => {
    if (programType === 'OUTDOOR' || programType === 'ONDEMAND') {
      schedule.addressId = await postNewOutdoorAddress(schedule.address)

      if (isAlternativeAddressVisible) {
        schedule.alternativeAddressId = await postNewOutdoorAddress(
          schedule.alternativeAddress,
        )
      }
    }

    return await postNewSchedule(schedule)
  }

  const handleEditing = async (schedule: Schedule) => {
    try {
      const {
        daysOfTheWeek,
        startTime,
        durationInMinutes,
        description,
        ...allowedFieldsForChanges
      } = schedule

      const response = await updateScheduleAndAddress({
        ...(schedule as Schedule),
        ...allowedFieldsForChanges,
      })
      return response
    } catch (error: any) {
      displayError(error)
    }
  }

  const handleCreation = async (schedule: Schedule) => {
    try {
      const response = await createScheduleAndAddress(schedule)
      return response
    } catch (error: any) {
      displayError(error)
    }
  }

  const upsertScheduleData = async (schedule: Schedule) => {
    try {
      let response
      const action = isEditing ? 'updated' : 'created'

      if (isEditing) {
        response = await handleEditing(schedule)
      } else {
        response = await handleCreation(schedule)
      }

      showMessage(`Schedule ${action} successfully`)
      return response
    } catch (error: any) {
      displayError(error)
    }
  }

  const getScheduleTypeTags = useCallback(async () => {
    const tags = await getTags()
    const styleTags = filterTagsByType({ tags, type: 'style' })
    const tagsAsOptions = convertTagsToOptions(styleTags)
    setScheduleTypes(tagsAsOptions)
  }, [])

  const price =
    scheduleData?.prices && scheduleData.prices.length > 0
      ? scheduleData.prices[scheduleData.prices.length - 1]
      : undefined

  const handleOnSubmit = async (schedule: Schedule) => {
    try {
      await upsertScheduleData({
        ...schedule,
        capacity: schedule.capacity ? schedule.capacity : undefined,
        newPrice:
          !isEditing && productType === ProductTypes.STRENGTH
            ? schedule?.newPrice
            : undefined,
      })
    } catch (error: any) {
      displayError(error)
    } finally {
      if (onAfterSubmitForm) {
        onAfterSubmitForm()
        if (values.endDate) setIsReassignModalVisible(true)
      }

      setSubmitting(false)
    }
  }

  const {
    errors,
    isSubmitting,
    touched,
    values,
    getFieldProps,
    handleSubmit,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues: {
      id: scheduleData?.id,
      programId: programId ?? '',
      startDate: scheduleData?.startDate || '',
      endDate: scheduleData?.endDate || '',
      tagId: scheduleData?.tag?.id || undefined,
      type: programType,
      daysOfTheWeek: scheduleData?.daysOfTheWeek || '',
      startTime: scheduleData?.startTime || '',
      durationInMinutes: scheduleData?.durationInMinutes || '',
      capacity: scheduleData?.capacity || undefined,
      description: scheduleData?.description || '',
      link: scheduleData?.link,
      effectiveDate: undefined,
      addressId: scheduleData?.address?.id,
      address: {
        address1: scheduleData?.address?.address1 || '',
        address2: scheduleData?.address?.address2,
        city: scheduleData?.address?.city || '',
        directions: scheduleData?.address?.directions,
        id: scheduleData?.address?.id,
        latitude: scheduleData?.address?.latitude,
        longitude: scheduleData?.address?.longitude,
        name: scheduleData?.address?.name || '',
        state: scheduleData?.address?.state || '',
        type: AddressTypes.OUTDOOR,
        zip: scheduleData?.address?.zip || '',
      },
      alternativeAddressId: scheduleData?.alternativeAddress?.id,
      alternativeAddress: {
        address1: scheduleData?.alternativeAddress?.address1 || '',
        address2: scheduleData?.alternativeAddress?.address2,
        city: scheduleData?.alternativeAddress?.city || '',
        directions: scheduleData?.alternativeAddress?.directions,
        id: scheduleData?.alternativeAddress?.id,
        latitude: scheduleData?.alternativeAddress?.latitude,
        longitude: scheduleData?.alternativeAddress?.longitude,
        name: scheduleData?.alternativeAddress?.name || '',
        state: scheduleData?.alternativeAddress?.state || '',
        type: AddressTypes.OUTDOOR,
        zip: scheduleData?.alternativeAddress?.zip || '',
      },
      priceIds: scheduleData?.prices?.map((p) => p.id || ''),
      newPrice: {
        name: price?.name || '',
        description: price?.description || '',
        amount: price?.amount || 0,
      },
    },
    validationSchema: createValidationSchema({
      productType,
      programType,
      isAlternativeAddressVisible,
      isEditing,
    }),
    enableReinitialize: true,
    onSubmit: handleOnSubmit,
  })

  const disableLatandLong = Boolean(
    isEditing && values.address?.address1 !== scheduleData.address?.address1,
  )

  const disableAddress1 = Boolean(
    isEditing &&
      (values.address?.latitude !== scheduleData.address?.latitude ||
        values.address?.longitude !== scheduleData.address?.longitude),
  )

  const loadSchedulePreviousChangeRequested = useCallback(async () => {
    if (scheduleData?.id) {
      getProgramScheduleChangeRequest(
        {
          programScheduleId: scheduleData.id,
        },
        authToken?.token ?? '',
      ).then((response) => {
        if (response?.content && response.content[0]) {
          setFieldValue('effectiveDate', response.content[0].applyChangeOn)
        }
      })
    }
  }, [scheduleData?.id, authToken?.token, setFieldValue])

  useEffect(() => {
    getScheduleTypeTags()
  }, [getScheduleTypeTags])

  useEffect(() => {
    const hasAlternativeAddress = scheduleData?.alternativeAddress?.id
      ? true
      : false
    if (hasAlternativeAddress)
      setIsAlternativeAddressVisible(hasAlternativeAddress)
  }, [scheduleData?.alternativeAddress])

  useEffect(() => {
    loadSchedulePreviousChangeRequested()
  }, [loadSchedulePreviousChangeRequested])

  useEffect(() => {
    if (values.endDate) {
      const reassignDefaultDate = moment(values.endDate).add(1, 'days')
      setDefaultEffectiveDate(reassignDefaultDate.format('YYYY-MM-DD'))
    }
  }, [values.endDate])

  return {
    disableAddress1,
    disableLatandLong,
    errors,
    growlContent,
    isAlternativeAddressVisible,
    isEditing,
    isGrowlVisible,
    isSubmitting,
    scheduleReassignProps,
    scheduleTypes,
    touched,
    getFieldProps,
    handleSubmit,
    setGrowlVisible,
    setIsAlternativeAddressVisible,
  }
}

export default useScheduleForm
