import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import styles from './page-title.module.scss'

export type PageTitleProps = {
  children: string
}

const PageTitle = ({ children }: PageTitleProps) => {
  return (
    <header className={styles.header}>
      <Heading className={styles.title} type="h3">
        {children}
      </Heading>
    </header>
  )
}

export default PageTitle
