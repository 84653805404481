import { CancellationReason } from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import { configuration } from 'config'
import {
  Subscription,
  SubscriptionSummary,
  SubscriptionCreation,
} from 'types/subscription'
import { objectToQueryString } from 'utils/url'

const baseUrl = configuration.apiGatewayUrl

export const getUserSubscriptions = async (
  userId: string,
  page: number = 0,
  size: number = 100,
): Promise<Subscription[]> => {
  try {
    const endpoint = `/billing/v1/users/${userId}/subscriptions`
    const method = 'GET' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      params: objectToQueryString({
        page,
        size,
      }),
    })
    const result = await response.json()

    return response.ok
      ? (result.content as Subscription[])
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-user-subscriptions-error',
      attributeName: 'get-user-subscriptions-by-id',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putSubscriptions = async (data: any) => {
  try {
    const { id, ...programData } = data
    const endpoint = `/billing/v1/subscriptions/${id}`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(programData),
    })
    const result = await response.json()

    return response.ok ? (result as any) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-billing-library',
      attributeName: 'put-subscriptions',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putCancelSubscriptions = async (
  id: string,
  cancelAt: string,
  reason?: CancellationReason,
): Promise<boolean | string> => {
  try {
    const endpoint = `/billing/v1/subscriptions/${id}/cancel`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      params: objectToQueryString({ cancelAt, reason: reason ?? '' }),
    })

    if (response.ok) {
      return true
    }

    const result = await response.json()
    return result ? Promise.reject(result.message) : 'Unknown error'
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-subscriptions-library',
      attributeName: 'put-subscriptions-cancel',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putUpgradeSubscriptions = async (
  id: string,
  productId: string,
  productPrice: string,
  couponCode: string,
) => {
  try {
    const endpoint = `/billing/v1/subscriptions/${id}/upgrade`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify({ productId, productPrice, couponCode }),
    })

    if (response.ok) {
      return true
    }

    const result = await response.json()
    return result ? Promise.reject(result.message) : 'Unknown error'
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-subscriptions-library',
      attributeName: 'put-subscriptions-upgrade',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getSubscriptionSummary = async (
  userId: string,
): Promise<SubscriptionSummary[]> => {
  try {
    const endpoint = `/billing/v1/users/${userId}/subscriptions/summaries`
    const method = 'GET' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok
      ? (result as SubscriptionSummary[])
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-user-subscriptions-summary-error',
      attributeName: 'get-user-subscriptions-summary-by-id',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const postSubscription = async (data: SubscriptionCreation) => {
  try {
    const endpoint = '/billing/v1/subscriptions'
    const method = 'POST' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(data),
    })
    const result = await response.json()

    return response.ok ? (result as any) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-subscriptions-library',
      attributeName: 'post-subscriptions',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
