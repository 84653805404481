import { MouseEventHandler, ReactNode } from 'react'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import styles from './attachment-wrapper.module.scss'

type AttachmentWrapperProps = {
  children?: ReactNode
  handleRemoveAttachment?: MouseEventHandler<HTMLElement>
}

const AttachmentWrapper = ({
  handleRemoveAttachment,
  children,
}: AttachmentWrapperProps) => (
  <div className={styles.wrapper}>
    <Button
      emphasis="secondary"
      data-testid="close"
      className={styles.button}
      variation="outline"
      size="xsmall"
      icon={{ name: 'icon-times', type: 'solid' }}
      onClick={handleRemoveAttachment}
      theme="trainer"
    />
    {children}
  </div>
)

export default AttachmentWrapper
