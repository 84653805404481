import { useLocation } from 'react-router-dom'
import {
  Header as HeaderCore,
  HeaderProps as HeaderPropsCore,
} from '@campgladiator/cgui-core.organisms.header'
import { useAppAccess } from 'app/contexts/app-access'

export type HeaderProps = {
  loginUrl: string
}
const Header = ({ loginUrl }: HeaderProps) => {
  const { pathname } = useLocation()
  const { invalidateUserAccess } = useAppAccess()

  const headerLinks: HeaderPropsCore['headerLinks'] = [
    {
      label: 'Users',
      link: '/users',
      isActiveRoute: pathname === '/users',
      newTab: false,
    },
    {
      label: 'Trainers',
      link: '/trainers',
      isActiveRoute: pathname === '/trainers',
      newTab: false,
    },
    {
      label: 'Programs',
      link: null as unknown as string,
      isActiveRoute: pathname.includes('/programs'),
      newTab: false,
      items: [
        {
          text: 'Manage Programs',
          link: { url: '/programs/manage-programs', newTab: false },
          type: 'item',
          itemId: '0',
          active: pathname.includes('/programs/manage-programs'),
        },
        {
          text: 'Manage OnDemand Videos',
          link: { url: '/programs/manage-ondemand-videos', newTab: false },
          type: 'item',
          itemId: '1',
          active: pathname.includes('/programs/manage-ondemand-videos'),
        },
      ],
    },
    {
      label: 'Regions',
      link: '/regions',
      isActiveRoute: pathname === '/regions',
      newTab: false,
    },
    {
      label: 'Region Groups',
      link: '/region-groups',
      isActiveRoute: pathname === '/region-groups',
      newTab: false,
    },
    {
      label: 'Partnerships',
      link: '/partnerships',
      isActiveRoute: pathname === '/partnerships',
      newTab: false,
    },
  ]

  const userButtonPropsWithAvatar: HeaderPropsCore['userButton'] = {
    items: [],
  }

  return (
    <HeaderCore
      theme="trainer"
      userButton={userButtonPropsWithAvatar}
      isLoggedIn={true}
      headerLinks={headerLinks}
      loginUrl={loginUrl}
      onLogoutClick={() => {
        invalidateUserAccess('User Initiated Header Logout')
      }}
    />
  )
}

export default Header
