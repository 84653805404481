import { ProductDetailDTO } from '@campgladiator/cg-common.types.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/redux/store'

export type ProductListType = ProductDetailDTO[] | null
export const initialState = null as ProductListType

const productListReducer = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    setProductList: (
      state: ProductListType,
      action: PayloadAction<ProductDetailDTO[]>,
    ) => action.payload,
    clearProductList: () => initialState,
  },
})

export const { setProductList, clearProductList } = productListReducer.actions

export const productListSelector = (state: RootState) => state.productList

export default productListReducer.reducer
