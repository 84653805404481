export enum AddressTypes {
  TRAINER_HOME = 'TRAINER_HOME',
  CLIENT_HOME = 'CLIENT_HOME',
  TRAINING_FACILITY = 'TRAINING_FACILITY',
  OUTDOOR = 'OUTDOOR',
  FLEX = 'FLEX',
  UNKNOWN = 'UNKNOWN',
}

export type Address = {
  id?: string
  type: AddressTypes
  name: string
  address1: string
  address2?: string
  state: string
  city: string
  zip: string
  latitude?: number
  longitude?: number
  directions?: string
}
