import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { FormInput } from '../../../form-ui/input'
import styles from './LoginForm.module.scss'
import { useLoginForm } from './useLoginForm'

export type LoginStep = 'loginStep'

type LoginProps = {
  error?: string | null
  isLoading: boolean
  onSubmitPasswordForLogin: (credential: string, password: string) => void
}

const LoginForm = ({
  error,
  isLoading,
  onSubmitPasswordForLogin,
}: LoginProps) => {
  const {
    email,
    FORM_ID,
    formRef,
    password,
    passwordErrorMsg,
    shouldDisableButton,
    onFormSubmit,
  } = useLoginForm({ error, isLoading, onSubmitPasswordForLogin })

  return (
    <div className={styles.login}>
      <div className={styles.pageHeader}>
        <Heading type="h2" className={styles.heading}>
          Log In
        </Heading>
        <Paragraph className={styles.paragraph} weight="book">
          Enter your info to access the CG admin dashboard.
        </Paragraph>
      </div>
      <form
        id={FORM_ID}
        className={styles.form}
        ref={formRef}
        onSubmit={onFormSubmit}
      >
        <FormInput {...email} />
        <FormInput {...password} errorMsg={passwordErrorMsg} />
      </form>
      <div className={styles.proceed}>
        <Button
          className={styles.button}
          disabled={shouldDisableButton}
          form={FORM_ID}
          type="submit"
          theme="trainer"
        >
          Login
        </Button>
      </div>
    </div>
  )
}

export default LoginForm
