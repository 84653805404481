import { configuration } from 'config'
import {
  authEndpointBasePath,
  getRefreshToken,
} from 'services/api/authentication'
import { AuthToken } from 'types/authentication'
import { data, FetchData } from './fetch'

type InvalidateUserAccessCallback = (message: string) => void

type UpdateAuthTokenCallback = (authToken: AuthToken) => void

type GatewayAuthErrors = {
  authToken?: AuthToken
  fetchData: FetchData
  invalidateUserAccessCallback?: InvalidateUserAccessCallback
  response: Response
  updateAuthTokenCallback?: UpdateAuthTokenCallback
}

type RefreshAuthTokenAndRetryFetch = {
  authToken: AuthToken
  fetchData: FetchData
  requestUrl: string
  response: Response
  updateAuthTokenCallback: UpdateAuthTokenCallback
}

const { apiGatewayUrl } = configuration

export const handleGatewayAuthErrors = async ({
  response,
  fetchData,
  authToken,
  invalidateUserAccessCallback = () => {},
  updateAuthTokenCallback = () => {},
}: GatewayAuthErrors) => {
  const isAuthError = response.status === 401
  const isClientError = response.status >= 400 && response.status < 500
  const isGatewayRequest =
    typeof response.url === 'string' && response.url.includes(apiGatewayUrl)
  const isAuthEndpoint = response.url.includes(authEndpointBasePath)

  if (isGatewayRequest && isClientError && isAuthEndpoint) {
    invalidateUserAccessCallback('Session expired')
    return Promise.reject(response)
  } else if (isGatewayRequest && isAuthError && !isAuthEndpoint && authToken) {
    try {
      return await refreshAuthTokenAndRetryFetch({
        response,
        authToken,
        requestUrl: response.url,
        fetchData,
        updateAuthTokenCallback,
      })
    } catch {
      invalidateUserAccessCallback('Session expired')
      return Promise.reject(response)
    }
  } else {
    return Promise.resolve(response)
  }
}

const refreshAuthTokenAndRetryFetch = ({
  response,
  authToken,
  fetchData,
  requestUrl,
  updateAuthTokenCallback,
}: RefreshAuthTokenAndRetryFetch) =>
  getRefreshToken(authToken?.refreshToken || '')
    .then((newAuthToken) =>
      retryResponseWithNewAuthToken({
        response,
        authToken: newAuthToken,
        fetchData,
        updateAuthTokenCallback,
        requestUrl,
      }),
    )
    .catch((error) => {
      return Promise.reject(error)
    })

const retryResponseWithNewAuthToken = async ({
  authToken,
  fetchData,
  requestUrl,
  updateAuthTokenCallback,
}: RefreshAuthTokenAndRetryFetch) => {
  updateAuthTokenCallback(authToken)
  const newFetchData = data({
    ...fetchData,
    baseUrl: requestUrl,
    endpoint: '',
    authToken,
  })
  const retryFetch = await fetch(requestUrl, newFetchData).then(
    (retryResponse) => retryResponse,
  )
  return retryFetch
}
