import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { useGrowlContext } from 'app/contexts/growl-context'
import { loadOrganizationDetails } from 'app/redux/features/active-organization/active-organization-actions'
import { activeOrganizationSelector } from 'app/redux/features/active-organization/active-organization-reducer'
import { useAppDispatch, useAppSelector } from 'app/redux/store'
import { MainLayout } from 'components/layouts/main-layout'
import { TabPanel, Tabs } from 'components/layouts/tabs'
import { EditOrganization } from 'components/module/organization/edit-organization'
import { OrgSidePanel } from 'components/module/organization/org-side-panel'
import { OrgTrainerSplit } from 'components/module/organization/org-trainer-split'
import styles from './details.module.scss'

const OrgDetailsPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { showGrowl } = useGrowlContext()
  const { isLoading, error, organization } = useAppSelector(
    activeOrganizationSelector,
  )

  useEffect(() => {
    if (id) dispatch(loadOrganizationDetails(id))
  }, [id, dispatch])

  useEffect(() => {
    if (error) showGrowl('FAILED', error)
    //eslint-disable-next-line
  }, [error])

  return organization ? (
    <MainLayout sidePanel={<OrgSidePanel orgName={organization?.name || ''} />}>
      <Tabs>
        <TabPanel title="Overview">
          <EditOrganization organization={organization} />
        </TabPanel>
        <TabPanel title="Trainer Split">
          <OrgTrainerSplit orgDetails={organization} />
        </TabPanel>
      </Tabs>
    </MainLayout>
  ) : (
    <div className={styles.messageContainer}>{isLoading && <Loader />}</div>
  )
}

export default OrgDetailsPage
