import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import styles from '../../program-side-panel.module.scss'

const DisplayItem = ({ label, value }: { label: string; value: string }) => (
  <div className={styles.row}>
    <Paragraph weight="bold" size="small" className={styles.label}>
      {label}:
    </Paragraph>
    <Paragraph weight="book" size="xsmall">
      {value}
    </Paragraph>
  </div>
)

export default DisplayItem
