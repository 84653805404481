import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import {
  createSubscriptionReassignForBootcamp,
  fetchSubscriptionsByParameters,
} from '@campgladiator/cg-common.api.subscriptions'
import { BootcampSubscriptionReassignRequestDTO } from '@campgladiator/cg-common.types.types'
import { ButtonProps } from '@campgladiator/cgui-core.atoms.button'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import moment from 'moment'
import { useAppAccess } from '../../../../app/contexts/app-access'
import { configuration } from '../../../../config'
import { getSchedulesByTrainerId } from '../../../../services/api/schedule'
import { getTrainerById } from '../../../../services/api/trainer'
import { ProductSubTypes, ProductTypes } from '../../../../types/product.d'
import { ProgramScheduleDTO } from '../../../../types/schedule'
import { ScheduleReassignSelectEffectiveDateProps } from '../components/ScheduleReassign/components/ScheduleReassignSelectEffectiveDate'
import { ScheduleReassignSelectScheduleProps } from '../components/ScheduleReassign/components/ScheduleReassignSelectSchedule'
import { ScheduleReassignSelectTrainerProps } from '../components/ScheduleReassign/components/ScheduleReassignSelectTrainer'
import {
  ScheduleReassignProps,
  ScheduleReassignSteps,
} from '../components/ScheduleReassign/ScheduleReassign'

export type UseWithScheduleReassignProps = {
  defaultEffectiveDate: string
  isReassignModalVisible: boolean
  onClose: () => void
  scheduleData: ProgramScheduleDTO | undefined
  showMessage: (message: string) => void
}

export type TrainerSchedule = {
  trainerName: string
  trainerId: string
  trainerPhoto: string
}

const useWithScheduleReassign = ({
  scheduleData,
  showMessage,
  defaultEffectiveDate,
  isReassignModalVisible,
  onClose,
}: UseWithScheduleReassignProps) => {
  const [step, setStep] = useState<ScheduleReassignSteps>('SelectTrainer')
  const [campersCount, setCampersCount] = useState<number>(0)
  const [trainerSchedule, setTrainerSchedule] = useState<
    TrainerSchedule | undefined
  >()
  const [trainerSchedules, setTrainerSchedules] = useState<
    ProgramScheduleDTO[]
  >([])
  const [selectedSchedule, setSelectedSchedule] = useState<
    ProgramScheduleDTO | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [effectiveDate, setEffectiveDate] = useState<string | undefined>()
  const { authToken } = useAppAccess()

  const onEffectiveDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEffectiveDate(e.target.value)
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const payload: BootcampSubscriptionReassignRequestDTO = {
        currentProgramId: scheduleData?.programId,
        currentScheduleId: scheduleData?.id,
        newScheduleId: selectedSchedule?.id,
        newProgramId: selectedSchedule?.programId,
        applyChangeOn: effectiveDate
          ? moment(effectiveDate).toISOString()
          : undefined,
        newServicingTrainerId: trainerSchedule?.trainerId,
      }

      await createSubscriptionReassignForBootcamp(
        payload,
        authToken?.token ?? '',
      )
      showMessage('Schedule reassigned successfully')
    } catch (error) {
      showMessage('Error reassigning schedule: ' + error)
    } finally {
      setIsLoading(false)
      onCloseModal()
    }
  }

  const getModalTitle = () => {
    switch (step) {
      case 'SelectTrainer':
        return 'Transfer Users'
      case 'SelectSchedule':
        return 'Select Schedule'
      case 'SelectEffectiveDate':
        return 'Effective date'
      default:
        return ''
    }
  }

  const handleNextStep = () => {
    switch (step) {
      case 'SelectTrainer':
        if (trainerSchedule?.trainerName === configuration.cgTrainerName) {
          setStep('SelectEffectiveDate')
        } else {
          setStep('SelectSchedule')
        }
        break
      case 'SelectSchedule':
        setStep('SelectEffectiveDate')
        break
      case 'SelectEffectiveDate':
        handleSubmit()
        break
    }
  }

  const getPrimaryButtonLabel = () => {
    switch (step) {
      case 'SelectTrainer':
        return 'Search'
      case 'SelectSchedule':
        return 'Confirm'
      case 'SelectEffectiveDate':
        return 'Confirm'
      default:
        return ''
    }
  }

  const onCloseModal = useCallback(() => {
    onClose()
    setStep('SelectTrainer')
  }, [onClose])

  const isValidEffectiveDate = () => {
    if (!effectiveDate) {
      return false
    }
    return moment(effectiveDate).isAfter(moment())
  }

  const isPrimaryButtonDisabled = () => {
    switch (step) {
      case 'SelectTrainer':
        return isLoading || !trainerSchedule?.trainerId
      case 'SelectSchedule':
        return isLoading || !trainerSchedule?.trainerId || !selectedSchedule
      case 'SelectEffectiveDate':
        return isLoading || !isValidEffectiveDate()
      default:
        return true
    }
  }

  const buttonPrimary: ButtonProps = {
    children: isLoading ? <Loader size="xsmall" /> : getPrimaryButtonLabel(),
    name: 'submit',
    disabled: isPrimaryButtonDisabled(),
    onClick: handleNextStep,
  }

  const handleCancel = () => {
    if (step === 'SelectTrainer') {
      onCloseModal()
    } else {
      setStep('SelectTrainer')
    }
  }

  const buttonSecondary: ButtonProps = {
    children: 'Cancel',
    variation: 'text',
    onClick: handleCancel,
  }

  const buttons: ButtonProps[] = [buttonSecondary, buttonPrimary]

  const onTrainerSelect = async (item: any) => {
    if (!!item) {
      setIsLoading(true)
      try {
        const trainer = await getTrainerById(item.itemId)
        setTrainerSchedule({
          trainerName: item.text,
          trainerId: item.itemId,
          trainerPhoto: trainer.photo,
        })
        const scheduleResponse = await loadSchedulesByTrainer(item.itemId)

        if (scheduleResponse) {
          const { online_programs, outdoor_programs } = scheduleResponse

          const schedulesFiltered = (
            scheduleData?.type === 'ONLINE' ? online_programs : outdoor_programs
          ).map((c) => c.programSchedules as ProgramScheduleDTO[])

          const allProgramSchedulesIntoASingleArray = schedulesFiltered.flat()

          setTrainerSchedules(allProgramSchedulesIntoASingleArray)
        }
      } catch {
        loadSchedulesByTrainer(undefined)
      } finally {
        setIsLoading(false)
      }
    } else {
      setTrainerSchedule(undefined)
    }
  }

  const onScheduleSelect = (schedule: ProgramScheduleDTO) => {
    if (schedule.id === selectedSchedule?.id) {
      setSelectedSchedule(undefined)
    } else {
      setSelectedSchedule(schedule)
    }
  }

  const scheduleReassignSelectTrainerProps: ScheduleReassignSelectTrainerProps =
    {
      campersCount: campersCount,
      productType: ProductTypes.BOOTCAMP,
      productSubType:
        scheduleData?.type === 'ONLINE'
          ? ProductSubTypes.ONLINE
          : ProductSubTypes.IN_PERSON,
      onTrainerSelect: onTrainerSelect,
    }

  const scheduleReassignSelectScheduleProps: ScheduleReassignSelectScheduleProps =
    {
      schedules: trainerSchedules,
      trainer: trainerSchedule,
      onScheduleSelect,
      selectedSchedule,
    }

  const scheduleReassignSelectEffectiveDateProps: ScheduleReassignSelectEffectiveDateProps =
    {
      effectiveDate,
      onEffectiveDateChange,
      isEffectiveDateValid: isValidEffectiveDate(),
    }

  const scheduleReassignProps: ScheduleReassignProps = {
    isVisible: isReassignModalVisible,
    onClose: () => onCloseModal(),
    step,
    buttons,
    title: getModalTitle(),
    scheduleReassignSelectTrainerProps,
    scheduleReassignSelectScheduleProps,
    scheduleReassignSelectEffectiveDateProps,
  }

  const loadSchedulesByTrainer = async (id?: string) => {
    if (id) {
      return await getSchedulesByTrainerId(id)
    }
  }

  const loadCampersByProgramScheduleId = useCallback(async () => {
    if (scheduleData?.id)
      fetchSubscriptionsByParameters({
        programScheduleId: scheduleData?.id,
      }).then((response) => {
        setCampersCount(response.totalElements)

        if (response.totalElements === 0) {
          onCloseModal()
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData?.id])

  useEffect(() => {
    loadCampersByProgramScheduleId()
  }, [loadCampersByProgramScheduleId])

  useEffect(() => {
    setEffectiveDate(defaultEffectiveDate)
  }, [defaultEffectiveDate])

  return {
    scheduleReassignProps,
  }
}

export default useWithScheduleReassign
