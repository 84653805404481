import React from 'react'
import classNames from 'classnames'
import { WeekdayLetter } from '@campgladiator/cg-common.types.types'
import {
  getAbbreviatedWeekdayFromLetter,
  getFormattedWeekDays,
  militaryTimeToMeridianTime,
} from '@campgladiator/cg-common.utility.dates'
import { Avatar } from '@campgladiator/cgui-core.atoms.avatar'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { ProgramScheduleDTO } from '../../../../../../types/schedule'
import { TrainerSchedule } from '../../../hooks/use-with-schedule-reassign'
import styles from './ScheduleReassignSelectSchedule.module.scss'

export type ScheduleReassignSelectScheduleProps = {
  onScheduleSelect: (schedule: ProgramScheduleDTO) => void
  schedules: ProgramScheduleDTO[]
  selectedSchedule?: ProgramScheduleDTO
  trainer?: TrainerSchedule
}
const ScheduleReassignSelectSchedule = ({
  trainer,
  schedules,
  onScheduleSelect,
  selectedSchedule,
}: ScheduleReassignSelectScheduleProps) => {
  const getDayOfWeek = (daysOfTheWeek: string) =>
    daysOfTheWeek
      .split(',')
      .map((day) => {
        return getAbbreviatedWeekdayFromLetter(
          getFormattedWeekDays({
            daysOfTheWeek: day,
            abbreviation: true,
          }) as WeekdayLetter,
        )
      })
      .join(', ')
      .toUpperCase()

  const getTrainerAddress = (schedule: ProgramScheduleDTO) => {
    const { address } = schedule
    if (schedule.type === 'OUTDOOR' && address?.address1) {
      return `${address?.address1}, ${address?.city}, ${address?.state}`
    }
    return 'Online'
  }

  const isSelected = (schedule: ProgramScheduleDTO) =>
    selectedSchedule?.id === schedule.id

  return (
    <div className={styles.container}>
      {schedules.map((schedule) => {
        return (
          <Card
            key={schedule.id}
            flat
            className={classNames(
              styles.card,
              isSelected(schedule) ? styles.selected : undefined,
            )}
            onClick={() => onScheduleSelect(schedule)}
          >
            <div className={styles.trainer}>
              <Avatar src={trainer?.trainerPhoto} />
              <div className={styles.trainerData}>
                <Paragraph weight="book">{trainer?.trainerName}</Paragraph>
                <div className={styles.location}>
                  <Icon.Line name="icon-map-marker" className={styles.icon} />
                  <Paragraph weight="book" size="small">
                    {getTrainerAddress(schedule)}
                  </Paragraph>
                </div>
              </div>
            </div>
            <div className={styles.workout}>
              <Paragraph weight="book">
                {getDayOfWeek(schedule.daysOfTheWeek)} &nbsp;
                {militaryTimeToMeridianTime(schedule.startTime)}
              </Paragraph>
            </div>
          </Card>
        )
      })}
    </div>
  )
}

export default ScheduleReassignSelectSchedule
