import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { UserDTO } from '@campgladiator/cg-common.types.types'
import { useGrowlContext } from 'app/contexts/growl-context'
import { useFetchUser } from 'app/hooks/useFetchUser'
import { UpdateUser } from 'services/api/user'
import { UserForm } from '../user-form'
import styles from './edit-user.module.scss'

interface IEditUserProps {
  onAfterUserUpdate: (user: UserDTO) => void
}

const EditUser = ({ onAfterUserUpdate }: IEditUserProps) => {
  const { id } = useParams()
  const user = useFetchUser(id || '')
  const { showGrowl } = useGrowlContext()

  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = async (values: Partial<UserDTO>) => {
    setSubmitting(true)
    UpdateUser('Edit', values, id!)
      .then((response) => {
        if (response) {
          showGrowl('', 'User updated successfully')
          onAfterUserUpdate(response)
        }
      })
      .catch((e) => {
        showGrowl('', e.message)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <div className={styles.formContainer}>
      <>
        <UserForm
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          user={user}
          isEdit
        />
      </>
    </div>
  )
}

export default EditUser
