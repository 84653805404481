import { ReactElement, ReactNode } from 'react'
import styles from './main-layout.module.scss'

type MainLayoutProps = {
  children: ReactNode
  sidePanel?: ReactElement
}

const MainLayout = ({ children, sidePanel }: MainLayoutProps) => {
  return (
    <div className={styles.container}>
      {sidePanel ? (
        <div className={styles.sidePanelContainer}>{sidePanel}</div>
      ) : (
        <div className={styles.spacer}></div>
      )}
      <div className={styles.mainSection}>{children}</div>
    </div>
  )
}

export default MainLayout
