import { PaymentMethodDTO } from '@campgladiator/cg-common.types.types'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Link } from '@campgladiator/cgui-core.atoms.link'
import { Label, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { capitalize } from 'lodash'
import styles from './payment-card-item.module.scss'

export type PaymentCardItemProps = {
  card: PaymentMethodDTO
  buttonTitle?: string
  onRemoveButtonClick?: (currentCard?: PaymentMethodDTO) => void
  onEditButtonClick: (currentCard?: PaymentMethodDTO) => void
  hideActionButtons?: boolean
}

const PaymentCardItem = ({
  card,
  buttonTitle,
  hideActionButtons = false,
  onRemoveButtonClick,
  onEditButtonClick,
}: PaymentCardItemProps) => {
  const hasCardDetails = card?.brand && card?.last4

  return (
    <Card flat className={styles.item}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <div>
            <Icon.Line name="icon-credit-card" className={styles.cardIcon} />
          </div>
          {card?.default && (
            <Label size="small" className={styles.default}>
              DEFAULT
            </Label>
          )}
        </div>

        <div className={styles.detail}>
          {hasCardDetails && (
            <div className={styles.info}>
              <Paragraph className={styles.label}>
                {capitalize(card?.brand || '')} card ending {card?.last4 || ''}
              </Paragraph>
            </div>
          )}
          {!hideActionButtons && (
            <div className={styles.links}>
              <Link
                href="#"
                size="small"
                hideArrow
                className={styles.link}
                onClick={() => onEditButtonClick(card)}
              >
                {buttonTitle}
              </Link>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}
export default PaymentCardItem
