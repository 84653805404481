import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import moment from 'moment-timezone'
import { AuthToken } from 'types/authentication'
import { PageRequest } from 'types/page'
import { PagedWorkouts, Workout } from 'types/workout'
import { currentDateTimeString } from 'utils/date'

const baseUrl = configuration.apiGatewayUrl

export const getWorkoutsByScheduleId = async (
  scheduleId: string,
  page: PageRequest = { pageNumber: 0, pageSize: 1000 },
  invalidateUserOnUnauthorizedResponse?: (error: string) => void,
  updateUser?: (authToken: AuthToken) => void,
): Promise<PagedWorkouts> => {
  try {
    const startTimeToFilter = moment(currentDateTimeString)
      .startOf('day')
      .utc()
      .format()

    const endTimeToFilter = moment(currentDateTimeString)
      .endOf('day')
      .utc()
      .add('120', 'days')
      .format()

    const endpoint = `/library/v1/workouts?includeDeleted=true&startTime=${startTimeToFilter}&endTime=${endTimeToFilter}&programScheduleId=${scheduleId}&page=${page.pageNumber}&size=${page.pageSize}`
    const method = 'GET' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      invalidateUserAccessCallback: invalidateUserOnUnauthorizedResponse,
      updateUser,
    })

    const result = await response.json()

    return response.ok
      ? (result as PagedWorkouts)
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-workouts-by-scheduleId',
      attributeName: 'get-workouts-by-scheduleId',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putWorkout = async (
  workout: Workout,
  invalidateUserOnUnauthorizedResponse?: (error: string) => void,
  updateUser?: (authToken: AuthToken) => void,
): Promise<Workout> => {
  try {
    const endpoint = `/library/v1/workouts/${workout.id}`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(workout),
      invalidateUserAccessCallback: invalidateUserOnUnauthorizedResponse,
      updateUser,
    })

    const result = await response.json()

    return response.ok ? (result as Workout) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-workouts-by-scheduleId',
      attributeName: 'get-workouts-by-scheduleId',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getWorkoutsByTypeAndTrainer = async (
  type: string,
  trainerId: string,
  date: string,
  invalidateUserOnUnauthorizedResponse?: (error: string) => void,
  updateUser?: (authToken: AuthToken) => void,
) => {
  try {
    const startTimeToFilter = moment(date).startOf('day').utc().format()
    const endTimeToFilter = moment(date).endOf('day').utc().format()

    const endpoint = `/library/v1/workouts?startTime=${startTimeToFilter}&endTime=${endTimeToFilter}&type=${type}&trainerId=${trainerId}`
    const method = 'GET' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      invalidateUserAccessCallback: invalidateUserOnUnauthorizedResponse,
      updateUser,
    })

    const result = await response.json()

    return response.ok ? (result as any) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-workouts-by-type-and-trainerId',
      attributeName: 'get-workouts-by-type-and-trainerId',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getWorkoutsById = async (
  workoutId: number,
  invalidateUserOnUnauthorizedResponse?: (error: string) => void,
  updateUser?: (authToken: AuthToken) => void,
) => {
  try {
    const endpoint = `/library/v1/workouts/${workoutId}`
    const method = 'GET' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      invalidateUserAccessCallback: invalidateUserOnUnauthorizedResponse,
      updateUser,
    })

    const result = await response.json()

    return response.ok ? (result as Workout) : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-workouts-by-id',
      attributeName: 'get-workouts-by-id',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
