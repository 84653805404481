import { useCallback, useEffect, useRef, useState } from 'react'
import { Popover } from 'react-tiny-popover'
import {
  BaseEnumerableResponse,
  TrainerDTO,
} from '@campgladiator/cg-common.types.types'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Icon } from '@campgladiator/cgui-core.atoms.input/dist/input'
import { ListItemProps } from '@campgladiator/cgui-core.atoms.list-item'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { ListMenu } from '@campgladiator/cgui-core.molecules.list-menu'
import { useOuterClick } from 'app/hooks'
import { debounce } from 'lodash'
import styles from './api-auto-complete.module.scss'

export type ApiAutocompleteProps = {
  id: string
  placeholder: string
  name: string
  getMethod: (param: any) => Promise<any>
  searchKey: string
  queryParams?: any
  renderOptionLabel: (record: any) => void
  filterOption?: (record: any) => void
  initialSelectedItem?: string
  value?: any
  disabled?: boolean
  onInputChange?: (query: string) => void
  onSelect: (record: any) => void
  numericSearch?: boolean
}

type ListMenuType = ListItemProps & {
  active: boolean
  disablePointer: boolean
  item: any
  itemId: string
  text: void
  type: 'item'
}

const ApiAutocomplete = ({
  id,
  placeholder,
  name,
  value,
  initialSelectedItem = '',
  onInputChange,
  onSelect,
  getMethod,
  searchKey,
  queryParams,
  renderOptionLabel,
  filterOption = () => true,
  disabled = false,
  numericSearch,
}: ApiAutocompleteProps) => {
  const contentRef = useRef<HTMLInputElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [activeOption, setActiveOption] = useState(0)
  const [filteredOptions, setFilteredOptions] = useState<any>([])
  const [showOptions, setShowOptions] = useState(false)
  const [userInput, setUserInput] = useState('')
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [initialSelected, setInitialSelected] =
    useState<string>(initialSelectedItem)

  const selectedItems = new Set((value as string) || '')
  const itemSelected = useRef<boolean>(false)

  const inputIcon: Icon =
    userInput.length === 0
      ? { type: 'solid', name: 'icon-search' }
      : { type: 'monochrome', name: 'times-circle' }

  useOuterClick(contentRef, () => {
    handleInputBlur()
  })

  const handleInputBlur = () => {
    if (!value) {
      handleClear()
    }
    setShowOptions(false)
  }

  const fetchDataList = async (query: string) =>
    getMethod({
      ...queryParams,
      [searchKey]: query,
    })

  const handleInputChange = useCallback(
    async (userInput: string) => {
      if (itemSelected.current) {
        itemSelected.current = false
        return
      }

      setFilteredOptions([
        {
          itemId: 'loader',
          text: (
            <div className={styles.loader}>
              <Loader />
            </div>
          ),
          type: 'item',
        },
      ])

      try {
        const response = await fetchDataList(userInput)

        const filteredOptions = getOptions(response, queryParams)
          .filter(filterOption)
          .map((it) => ({
            itemId: it.id!,
            text: renderOptionLabel(it),
            item: it,
            type: 'item',
          }))

        if (filteredOptions.length === 0) {
          const noRecordsFoundItem = [
            {
              type: 'item',
              itemId: '0',
              text: 'No results found',
              active: false,
              disablePointer: true,
              item: null,
            },
          ]
          filteredOptions.push(noRecordsFoundItem[0] as unknown as ListMenuType)
        }

        setActiveOption(0)
        setFilteredOptions(filteredOptions)

        setShowOptions(true)
      } catch (error) {
        console.error(error)
      }
    },
    //eslint-disable-next-line
    [queryParams, filterOption],
  )

  const getQueryResult = debounce(handleInputChange, 400)

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      setActiveOption(0)
      setShowOptions(false)
      setUserInput(filteredOptions[activeOption])
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return
      }
      setActiveOption(activeOption - 1)
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        return
      }
      setActiveOption(activeOption + 1)
    }
  }

  const formatClickableMenuItem = (
    record: ListItemProps & { type: 'item' },
  ) => {
    return {
      ...record,
      handleClick: () => {
        setActiveOption(0)
        setFilteredOptions([])
        setShowOptions(false)
        itemSelected.current = true
        setReadOnly(true)
        setUserInput(record.text?.toString() || '')
        onSelect(record)
      },
    }
  }

  const handleClear = () => {
    setUserInput('')
    onSelect('')
    setReadOnly(false)
    itemSelected.current = false
    setInitialSelected('')
  }

  useEffect(() => {
    if (userInput && userInput.length > 0) {
      if (!itemSelected.current) getQueryResult(userInput)
    } else {
      setFilteredOptions([])
      setShowOptions(false)
    }
    //eslint-disable-next-line
  }, [userInput])

  useEffect(() => {
    if (value === '') {
      setActiveOption(0)
      setFilteredOptions([])
      setShowOptions(false)
      itemSelected.current = false
      setUserInput('')
      setReadOnly(false)
    }
  }, [value])

  useEffect(() => {
    if (initialSelected) {
      itemSelected.current = true
      setUserInput(initialSelected)
      setReadOnly(true)
    }
  }, [initialSelected])

  useEffect(() => {
    setInitialSelected(initialSelectedItem)
  }, [initialSelectedItem])

  const getOptions = (
    response: BaseEnumerableResponse<TrainerDTO> | TrainerDTO[],
    queryParams?: {
      type: string
      subType: string
    },
  ): TrainerDTO[] => {
    const options = 'content' in response ? response.content : response
    const hasParams = queryParams && Object.keys(queryParams).length > 0
    const isBootCamp = hasParams && queryParams.type === 'BOOTCAMP'
    const isNurition = hasParams && queryParams.type === 'NUTRITION'

    const filteredResults = hasParams
      ? Object.values(options).filter((option) =>
          isBootCamp
            ? option.approvedPrograms?.outdoorApproved ||
              option.approvedPrograms?.onlineApproved
            : isNurition && option.approvedPrograms?.nutritionApproved,
        )
      : options

    return filteredResults
  }

  const handleOnInputChange = (value: string) => {
    if (numericSearch) {
      value = value.replace(/[^0-9]/g, '')
    }
    setUserInput(value)
  }

  return (
    <div ref={contentRef} className={styles.autocompleteContainer}>
      <Popover
        containerClassName={styles.popover}
        positions={['bottom']}
        isOpen={
          showOptions && userInput.length > 0 && filteredOptions.length > 0
        }
        content={
          <ListMenu
            style={{
              zIndex: 20,
              marginTop:
                (filteredOptions.length > 4 ? 4.25 : filteredOptions.length) *
                  40 +
                60,
            }}
            selection="single"
            initialActiveIds={selectedItems}
            className={styles.menu}
            items={filteredOptions.map((record: any) =>
              formatClickableMenuItem(record),
            )}
          />
        }
      >
        <Input
          id={id}
          name={name}
          placeholder={placeholder}
          type="text"
          value={userInput}
          onChange={(e) => handleOnInputChange(e.target.value)}
          onKeyDown={onKeyDown}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete="off"
          icon={inputIcon}
          onIconClick={() => handleClear()}
          ref={inputRef}
        />
      </Popover>
    </div>
  )
}

export default ApiAutocomplete
