import { CancellationReason } from '@campgladiator/cg-common.types.types'
import { ProductTypes, ProductSubTypes } from './product.d'

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  FROZEN = 'FROZEN',
  TRIAL = 'TRIAL',
  PENDING = 'PENDING',
}

export interface Subscription {
  acceptedTermsAndConditions: string | null
  archivedAt: string | null
  billingDate: string
  cancellationReason: CancellationReason | null
  contractStatistics?: ContractStatistics
  couponCode: string | null
  id: string
  periodEndDate: string
  periodStartDate: string
  productId: string
  productName: string
  productPrice: number
  productSubType: ProductSubTypes
  productType: ProductTypes
  programId: string
  programScheduleId: string
  programSchedules?: ProgramScheduleDropdownItem[]
  promoCode: string | null
  purchaseDate: string | null
  purchasePrice: number
  resumesAt: string | null
  salesRep: string
  salesRepName?: string
  servicingTrainer: string
  servicingTrainerName: string
  servicingTrainerDeleted?: boolean | null
  status: SubscriptionStatus
  stripeCustomerId: string
  stripeSubscriptionId: string
  termLength: number
  trialPeriodDays: string | null
  userId: string
}

export type ContractStatistics = {
  inContract: boolean
  paymentCount: number
  paymentsRemaining: number
}

export type SubscriptionRequest = {
  archivedAt: string
  cancellationReason?: CancellationReason
  couponCode: string
  programScheduleId?: string
  resumesAt: string
  salesRep: string
  servicingTrainer: string
  servicingTrainerDeleted: boolean
  status: SubscriptionStatus
}

export type SubscriptionSummary = {
  id: string
  stripeProductId: string
  description: string
  type: ProductTypes
  subType: ProductSubTypes
  status: SubscriptionStatus
  periodStartDate: string
  periodEndDate: string
  programId: string
  programScheduleId: string
}

export type SubscriptionCreation = {
  userId: string
  stripeCustomerId: string
  productId: string
  productPrice: string
  salesRep?: string
  servicingTrainer?: string
  couponCode?: string
  promoCode?: string
  programId?: string
  programScheduleId?: string
  periodStartDate: string
  billingDate?: string
  paymentMethodId?: string
  trialPeriodDays?: number
  paymentProcessorProvider?: string
}

export type ProgramScheduleDropdownItem = {
  label: string
  value: string
  programId: string
}
