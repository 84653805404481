import React, { createContext, useContext, useState, ReactNode } from 'react'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'

interface GrowlContextProps {
  showGrowl: (title: string, text: string, autoClose?: boolean) => void
  closeGrowl: () => void
}

interface GrowlProviderProps {
  children: ReactNode
}

const GrowlContext = createContext<GrowlContextProps | undefined>(undefined)

export const GrowlProvider: React.FC<GrowlProviderProps> = ({ children }) => {
  const [isGrowlVisible, setIsGrowlVisible] = useState<boolean>(false)
  const [growlContent, setGrowlContent] = useState({
    title: '',
    text: '',
    autoClose: true,
  })

  const showGrowl = (title: string, text: string, autoClose = true) => {
    setGrowlContent({ title, text, autoClose })
    setIsGrowlVisible(true)
  }

  const closeGrowl = () => {
    setIsGrowlVisible(false)
  }

  return (
    <GrowlContext.Provider value={{ showGrowl, closeGrowl }}>
      {children}
      <GrowlMessage
        isVisible={isGrowlVisible}
        onClick={closeGrowl}
        title={growlContent.title}
        autoClose={growlContent.autoClose}
      >
        {growlContent.text}
      </GrowlMessage>
    </GrowlContext.Provider>
  )
}

export const useGrowlContext = () => {
  const context = useContext(GrowlContext)
  if (!context) {
    throw new Error('useGrowl must be used within a GrowlProvider')
  }
  return context
}
