import { TagDTO } from '@campgladiator/cg-common.types.types'
import { DropdownProps } from 'components/form-ui/dropdown/dropdown'

export const filterTagsByType = ({
  tags,
  type,
}: {
  tags: TagDTO[]
  type: string
}) => tags.filter(({ tagType }) => tagType === type)

export const convertTagsToOptions = (
  tags: TagDTO[],
): DropdownProps['options'] =>
  tags.map(({ title, id }) => ({
    value: id.toString(),
    label: title,
  }))
