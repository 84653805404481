import { useCallback, useEffect, useState } from 'react'
import { CouponDTO } from '@campgladiator/cg-common.types.types'
import { useGrowlContext } from 'app/contexts/growl-context'
import { loadUserMembershipSummaryDetails } from 'app/redux/features/active-user/active-user-actions'
import { useAppDispatch } from 'app/redux/store'
import { configuration } from 'config'
import { useFormik } from 'formik'
import { capitalize, pick } from 'lodash'
import moment from 'moment'
import { getSchedulesByTrainerId } from 'services/api/schedule'
import {
  putCancelSubscriptions,
  putSubscriptions,
} from 'services/api/subscriptions'
import { ProductTypes } from 'types/product.d'
import {
  Subscription,
  SubscriptionRequest,
  SubscriptionStatus,
} from 'types/subscription.d'
import { currentDate, stringToDate, tomorrowDate } from 'utils/date'
import * as Yup from 'yup'
import { getProgramByFilters } from '../../../../../services/api/program'
import getCoupons from '../logic/getCoupons'
import { DropdownItem } from './../../../../form-ui/dropdown/dropdown'
import { isMembershipCancelled } from './logic/memberships'

type useWithMembershipItemProps = {
  data: Subscription
  onUpgradeSuccess: () => void
  cancellationReasonOptions: DropdownItem[]
  coupons: CouponDTO[]
}

export type CouponCodeDropdownItem = {
  value: string
  label: string
}

type ScheduleDropdownItem = {
  label: string
  value: string
  programId: string
}

const useWithMembershipItem = ({
  data,
  onUpgradeSuccess,
  cancellationReasonOptions,
  coupons,
}: useWithMembershipItemProps) => {
  const dispatch = useAppDispatch()
  const [scheduleOptions, setScheduleOptions] = useState<
    ScheduleDropdownItem[]
  >([])
  const [couponCodeDropdownItems, setCouponCodeDropdownItems] = useState<
    CouponCodeDropdownItem[]
  >([])
  const { showGrowl } = useGrowlContext()
  const [
    isCancellationReasonOptionsVisible,
    setIsCancellationReasonOptionsVisible,
  ] = useState<boolean>(false)

  const { isCancellationReasonFeatureVisible } = configuration

  const onSubmit = async (values: SubscriptionRequest) => {
    setSubmitting(true)
    try {
      if (values.status === SubscriptionStatus.CANCELED) {
        await cancelMembership(values)
      } else {
        await updateMembership(values)
      }

      dispatch(loadUserMembershipSummaryDetails(data.userId))
    } catch (error: any) {
      console.log(error)
    } finally {
      setSubmitting(false)
    }
  }

  const {
    handleSubmit,
    setFieldValue,
    getFieldProps,
    setSubmitting,
    isSubmitting,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      status: data.status,
      salesRep: '',
      servicingTrainer: data.servicingTrainer,
      servicingTrainerDeleted: data.servicingTrainerDeleted,
      programScheduleId: data.programScheduleId,
      resumesAt: stringToDate(data.resumesAt),
      archivedAt: stringToDate(data.archivedAt),
      cancellationReason: data.cancellationReason,
      couponCode: data.couponCode,
    } as SubscriptionRequest,
    validationSchema: Yup.object({
      status: Yup.string().required(),
      resumesAt: Yup.date().when('status', {
        is: SubscriptionStatus.FROZEN,
        then: (schema) =>
          schema
            .required('Date is required')
            .min(tomorrowDate, 'Date should be a future date'),
        otherwise: (schema) => schema.notRequired(),
      }),
      servicingTrainerDeleted: Yup.boolean().nullable(),
      servicingTrainer: Yup.string()
        .nullable()
        .required('Trainer is required')
        .when('servicingTrainerDeleted', {
          is: true,
          then: (schema) =>
            schema.test(
              'is-trainer-deleted',
              'The servicing trainer is not active',
              () => false,
            ),
          otherwise: (schema) =>
            schema.test('is-trainer-deleted', '', () => true),
        }),
      couponCode: Yup.string().nullable(),
      archivedAt: Yup.date().when('status', {
        is: SubscriptionStatus.CANCELED,
        then: (schema) =>
          schema
            .required('Date is required')
            .min(currentDate, 'Date cannot be past date'),
        otherwise: (schema) => schema.notRequired(),
      }),
      cancellationReason: Yup.string().when('status', {
        is: (status: SubscriptionStatus) =>
          status === SubscriptionStatus.CANCELED &&
          isCancellationReasonFeatureVisible &&
          data.status !== SubscriptionStatus.CANCELED,
        then: (schema) => schema.required('Reason is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit,
    enableReinitialize: true,
  })

  const handleDeletedTrainer = (archived: string | null) => {
    setFieldValue('servicingTrainerDeleted', isMembershipCancelled(archived))
  }

  const isRecommitEligible = (subscription: Subscription) => {
    return (
      moment(subscription.purchaseDate).isSameOrBefore(moment('2023-06-01')) &&
      subscription.productType === ProductTypes.BOOTCAMP
    )
  }

  const getCouponNameByCode = (code: string | null) =>
    getCoupons()?.find((c) => c.stripeCouponId === code)?.stripeCouponName ??
    code

  const cancelMembership = async (values: SubscriptionRequest) => {
    try {
      await putCancelSubscriptions(
        data.id,
        moment(
          values.archivedAt + ' ' + moment().endOf('day').format('HH:mm:ss'),
        )
          .seconds(0)
          .utcOffset(0, true)
          .format(),
        isCancellationReasonFeatureVisible
          ? values.cancellationReason!
          : undefined,
      )

      showGrowl('SUCCESS', 'Membership cancelation date updated')
      onUpgradeSuccess()
    } catch (error: any) {
      showGrowl('FAILED', error)
    }
  }

  const updateMembership = async (values: SubscriptionRequest) => {
    try {
      const params = pick(data, [
        'id',
        'stripeSubscriptionId',
        'salesRep',
        'servicingTrainer',
        'resumesAt',
        'couponCode',
      ])

      const {
        status,
        salesRep,
        servicingTrainer,
        resumesAt,
        programScheduleId,
        couponCode,
      } = values

      const selectedSchedule = data.programSchedules?.find(
        (schedule: any) => schedule.value === values.programScheduleId,
      )

      await putSubscriptions({
        ...params,
        status,
        salesRep,
        servicingTrainer,
        programScheduleId,
        couponCode,
        programId: selectedSchedule?.programId || '',
        resumesAt:
          status === SubscriptionStatus.FROZEN
            ? moment(
                resumesAt + ' ' + moment().startOf('day').format('HH:mm:ss'),
              )
                .add('minutes', 1)
                .utcOffset(0, true)
                .format()
            : undefined,
      })

      showGrowl('SUCCESS', 'Membership updated successfully')
      onUpgradeSuccess()
    } catch (error: any) {
      showGrowl('FAILED', error)
    }
  }

  useEffect(() => {
    setFieldValue('salesRep', data.salesRep)
    setFieldValue('servicingTrainer', data.servicingTrainer)
    //eslint-disable-next-line
  }, [data.salesRep, data.servicingTrainer])

  const getCancellationReasonLabel = (reason: string | null) =>
    (
      cancellationReasonOptions.find(
        (r: { label: string; value: string }) => r.value === reason,
      ) as DropdownItem | undefined
    )?.label || reason

  const getProgramSchedulesByTrainerId = useCallback(
    async (id: string) => {
      try {
        const response = await getSchedulesByTrainerId(id)

        if (response) {
          const schedules = [
            ...response.outdoor_programs,
            ...response.online_programs,
          ]
          if (schedules.length > 0) {
            const allProgramSchedulesFromSchedules = schedules.map(
              (schedule) => schedule.programSchedules,
            )
            const scheduleDropdownOptions = allProgramSchedulesFromSchedules
              .filter((schedules) => schedules.length > 0)
              .flat(Infinity)
              .map((schedule) => {
                const daysOfWeek = schedule.daysOfTheWeek
                  ?.split(',')
                  ?.map((dow: string) => capitalize(dow.substring(0, 3)))
                  ?.join(', ')

                const startTime = schedule?.startTime?.substring(0, 5)

                return {
                  label: `${daysOfWeek} - ${startTime}`,
                  value: schedule?.id ?? '',
                  programId: schedule?.programId ?? '',
                }
              })
            setScheduleOptions(scheduleDropdownOptions)
            setFieldValue('programScheduleId', data.programScheduleId)
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    //eslint-disable-next-line
    [setFieldValue],
  )

  useEffect(() => {
    if (data.servicingTrainer !== null) {
      getProgramSchedulesByTrainerId(data.servicingTrainer)
    }
  }, [data.servicingTrainer, getProgramSchedulesByTrainerId])

  useEffect(() => {
    if (values.servicingTrainer === '') {
      setFieldValue('programScheduleId', '')
      setScheduleOptions([])
    } else {
      getProgramSchedulesByTrainerId(values.servicingTrainer)
    }
    //eslint-disable-next-line
  }, [values.servicingTrainer])

  const setCoupons = useCallback(async () => {
    let productId = ''

    try {
      const product = await getProgramByFilters(
        data.servicingTrainer,
        data.productType,
        data.productSubType,
      )

      productId =
        product?.productDetails?.products?.find(
          (c) => c.active && c.termLength === data.termLength,
        )?.id ?? ''
    } catch (error) {
      console.error(error)
    }

    setCouponCodeDropdownItems(
      coupons
        .filter((c) => c.applicableProductIds?.includes(productId))
        .map((c) => ({
          value: c.stripeCouponId,
          label: c.stripeCouponName,
        })),
    )
  }, [
    coupons,
    data.productSubType,
    data.productType,
    data.servicingTrainer,
    data.termLength,
  ])

  useEffect(() => {
    setCoupons()
  }, [setCoupons])

  return {
    errors,
    getCancellationReasonLabel,
    getCouponNameByCode,
    getFieldProps,
    handleSubmit,
    isCancellationReasonOptionsVisible,
    setIsCancellationReasonOptionsVisible,
    isRecommitEligible,
    isSubmitting,
    scheduleOptions,
    setFieldValue,
    touched,
    values,
    isCancellationReasonFeatureVisible,
    couponCodeDropdownItems,
    handleDeletedTrainer,
  }
}

export default useWithMembershipItem
