import { useState, useCallback, useEffect } from 'react'
import { UserDTO } from '@campgladiator/cg-common.types.types'
import { getUserById } from 'services/api/user'

export const useFetchUser = (id: string): UserDTO => {
  const [user, setUser] = useState<UserDTO>({} as UserDTO)

  const fetchUser = useCallback(async () => {
    const userId = id || ''
    const retrievedUser = await getUserById(userId)
    setUser(retrievedUser)
  }, [id])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return user
}
