import { UserDTO } from '@campgladiator/cg-common.types.types'
import { Modal } from '@campgladiator/cgui-core.molecules.modal'
import { useGrowlContext } from 'app/contexts/growl-context'
import { StripeElementsProvider } from 'app/contexts/stripe-elements-provider'
import { NewPaymentCardForm } from '../new-payment-card-form'
import styles from './new-payment-card-modal.module.scss'

export type NewPaymentCardModalProps = {
  isAddCardModalVisible: boolean
  setAddCardModalVisible: (isVisible: boolean) => void
  onAfterCardIsSaved?: (paymentMethodId?: string) => void
  user: UserDTO | null
}

const NewPaymentCardModal = ({
  isAddCardModalVisible,
  setAddCardModalVisible,
  onAfterCardIsSaved,
  user,
}: NewPaymentCardModalProps) => {
  const { showGrowl } = useGrowlContext()

  const handleAfterFormSubmit = (paymentMethodId?: string) => {
    showGrowl('', 'New card saved successfully', true)
    setAddCardModalVisible(false)
    if (onAfterCardIsSaved) onAfterCardIsSaved(paymentMethodId)
  }

  const handleError = (error: any) => {
    const errorMessage =
      typeof error === 'string'
        ? error
        : error.message
        ? error.message
        : 'An unknown error happened. Please try again later'
    showGrowl('Error', errorMessage, false)
  }

  return (
    <>
      <Modal
        isVisible={isAddCardModalVisible}
        title="Add new card"
        onClose={() => setAddCardModalVisible(false)}
        className={styles.modalContainer}
        theme="trainer"
      >
        <div className={styles.modalBody}>
          <StripeElementsProvider
            stripeCustomerId={user?.stripeCustomerId || ''}
          >
            <NewPaymentCardForm
              user={user}
              onAfterFormSubmit={handleAfterFormSubmit}
              onSubmitError={handleError}
            />
          </StripeElementsProvider>
        </div>
      </Modal>
    </>
  )
}

export default NewPaymentCardModal
