import { useCallback, useEffect, useState } from 'react'
import { TrainerDTO } from '@campgladiator/cg-common.types.types'
import { getTrainerByUserId } from 'services/api/trainer'

export const useFetchTrainerByUserId = (userId: string): TrainerDTO => {
  const [trainer, setTrainer] = useState<TrainerDTO>({} as TrainerDTO)

  const fetchTrainerByUserId = useCallback(async () => {
    if (userId) {
      try {
        const retrievedUser = await getTrainerByUserId(userId)
        setTrainer(retrievedUser)
      } catch (error: any) {
        console.error(error)
      }
    }
  }, [userId])

  useEffect(() => {
    fetchTrainerByUserId()
  }, [fetchTrainerByUserId])

  return trainer
}
