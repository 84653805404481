import React from 'react'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  ProductSubTypes,
  ProductTypes,
} from '../../../../../../types/product.d'
import { TrainerAutoComplete } from '../../../../user/memberships/membership-item/components'
import styles from './ScheduleReassignSelectTrainer.module.scss'

export type ScheduleReassignSelectTrainerProps = {
  campersCount: number
  onTrainerSelect: (item: any) => void
  productSubType: ProductSubTypes
  productType: ProductTypes
}

const ScheduleReassignSelectTrainer = ({
  campersCount = 0,
  productType,
  productSubType,
  onTrainerSelect,
}: ScheduleReassignSelectTrainerProps) => (
  <div className={styles.container}>
    <Paragraph>
      All {campersCount} Campers will be moved to the new schedule.
    </Paragraph>
    <TrainerAutoComplete
      id="trainer"
      name="trainer"
      onSelect={onTrainerSelect}
      productType={productType}
      productSubType={productSubType}
    />
  </div>
)

export default ScheduleReassignSelectTrainer
