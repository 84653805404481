import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import styles from './tabs.module.scss'

interface Tab {
  title: string
  path: string
}

interface TabsProps {
  tabs: Tab[]
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const location = useLocation()

  return (
    <ul className={styles.tabContainer}>
      {tabs.map((tab, index) => (
        <li
          key={index}
          className={classNames(styles.tabItem, {
            [styles.selected]: location.pathname.includes(tab.path),
          })}
        >
          <Link className={styles.link} to={tab.path}>
            <Paragraph
              weight={location.pathname.includes(tab.path) ? 'normal' : 'book'}
              size="xsmall"
              className={styles.tabTitle}
            >
              {tab.title}
            </Paragraph>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default Tabs
