import { configuration } from 'config'
import { AuthToken } from 'types/authentication'
import { getAuthToken } from 'utils/auth-token-storage'
import { handleGatewayAuthErrors } from './interceptors'

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export type RequestArgsType = {
  baseUrl: string
  endpoint: string
  method: HttpMethod
  params?: string
  authToken?: AuthToken
  body?: string | null | FormData
  urlEncoded?: boolean
  signal?: any
  fileUpload?: boolean
  invalidateUserAccessCallback?: (error: string) => void
  updateUser?: (authToken: AuthToken) => void
}

export type FetchData = {
  signal?: any
  body?: string | FormData
  method: HttpMethod
  headers?: { 'Content-Type': string; Authorization?: string }
}

const { apiGatewayUrl } = configuration

export const url = (args: RequestArgsType): string =>
  `${args.baseUrl + args.endpoint + (args.params || '')}`

export const data = (args: RequestArgsType): FetchData => {
  const body = args.body ? { body: args.body } : {}
  const { urlEncoded, fileUpload } = args
  const authToken = args.baseUrl.includes(apiGatewayUrl)
    ? getAuthToken()
    : undefined
  return {
    method: args.method,
    headers: createApiRequestHeaders({
      authToken: authToken?.token,
      urlEncoded,
      fileUpload,
    }),
    ...body,
    signal: args?.signal || undefined,
  }
}

export const createApiRequestHeaders = ({
  authToken,
  urlEncoded,
  fileUpload,
}: {
  authToken?: string
  urlEncoded?: boolean
  fileUpload?: boolean
}) => {
  if (fileUpload) return

  const basicHeader = urlEncoded
    ? { 'Content-Type': 'application/x-www-form-urlencoded' }
    : { 'Content-Type': 'application/json' }

  if (!authToken) {
    return basicHeader
  }

  const authHeader = { ...basicHeader, Authorization: `Bearer ${authToken}` }
  return authHeader
}

export const request = async ({
  invalidateUserAccessCallback = () => {},
  updateUser = () => {},
  ...args
}: RequestArgsType): Promise<Response> => {
  const requestData = data(args)
  const response = await fetch(url(args), requestData)
  const authToken = getAuthToken()

  return await handleGatewayAuthErrors({
    response,
    fetchData: requestData,
    authToken,
    invalidateUserAccessCallback,
    updateAuthTokenCallback: updateUser,
  })
}
