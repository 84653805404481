import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchCancellationReasons } from '@campgladiator/cg-common.api.cancellation-reason'
import { fetchCoupons } from '@campgladiator/cg-common.api.coupons'
import {
  CouponDTO,
  SubscriptionDTO,
} from '@campgladiator/cg-common.types.types'
import { MembershipManagementUpgradeProps } from '@campgladiator/cg-common.ui.membership-management'
import { useGrowlContext } from 'app/contexts/growl-context'
import { useAppSelector } from 'app/redux/store'
import { memoize } from 'lodash'
import { getUserSubscriptions } from 'services/api/subscriptions'
import { getTrainerById } from 'services/api/trainer'
import { Subscription } from 'types/subscription.d'
import { useAppAccess } from '../../../../app/contexts/app-access'
import { DropdownItem } from './../../../form-ui/dropdown/dropdown'
import getCoupons from './logic/getCoupons'
import { isMembershipCancelled } from './membership-item/logic/memberships'

const tableColumns = [
  'Product',
  'Remaining Months',
  'Purchase Date',
  'Billing Date',
  'Terms & Conditions Acceptance Date',
  'Purchase Price',
  'Discount',
  'Selling Trainer',
  'Servicing Trainer',
  'Schedule',
  'Status',
  'Resumes At',
  'Canceled At',
  'Cancellation Reason',
  '',
]

const getTrainerDetails = memoize(async (id: string | null) => {
  if (!id) return ''

  try {
    const { firstName, lastName, archived } = await getTrainerById(id)
    return {
      firstName,
      lastName,
      archived: isMembershipCancelled(archived),
    }
  } catch (error) {
    return undefined
  }
})

const useWithMemberships = () => {
  const { id } = useParams()
  const [coupons, setCoupons] = useState<CouponDTO[]>([])
  const { lastRefreshTime } = useAppSelector((state) => state.subscription)
  const [subscriptionData, setSubscriptionData] = useState<Subscription[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { showGrowl, closeGrowl } = useGrowlContext()
  const [
    membershipManagementUpgradeProps,
    setMembershipManagementUpgradeProps,
  ] = useState<MembershipManagementUpgradeProps | undefined>()
  const { authToken } = useAppAccess()

  const [cancellationReasonOptions, setCancellationReasonOptions] = useState<
    DropdownItem[]
  >([])

  const fetchSubscriptionData = async (id: string) => {
    setLoading(true)

    try {
      getCoupons()
      const response = await getUserSubscriptions(id)

      setSubscriptionData(
        await Promise.all(
          response.map(async (it) => {
            const servicingTrainer = await getTrainerDetails(
              it.servicingTrainer,
            )
            const sellingTrainer = await getTrainerDetails(it.salesRep)
            return {
              ...it,
              servicingTrainerName: servicingTrainer
                ? `${servicingTrainer?.firstName} ${servicingTrainer?.lastName}`
                : '',
              servicingTrainerDeleted:
                servicingTrainer && servicingTrainer !== undefined
                  ? servicingTrainer.archived
                  : false,
              salesRepName: sellingTrainer
                ? `${sellingTrainer?.firstName} ${sellingTrainer?.lastName}`
                : '',
            }
          }),
        ),
      )
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const handleUpgradeSuccess = () => {
    showGrowl(
      'SUCCESS',
      'Membership upgraded successfully. The new membership will take effect after the current plan end date.',
      false,
    )

    if (id) {
      fetchSubscriptionData(id)
    }
  }

  const getCancellationReasonOptions = useCallback(async () => {
    const reasons = await fetchCancellationReasons()
    const options = Object.entries(reasons).map(([key, value]) => {
      return { label: value, value: key }
    })
    options.unshift({ label: '', value: '' })
    setCancellationReasonOptions(options as [])
  }, [])

  const handleMembershipManagementClick = (subscription: Subscription) => {
    setMembershipManagementUpgradeProps({
      role: 'ADMIN',
      isCouponStepVisible: true,
      token: authToken?.token || '',
      subscription: subscription as unknown as SubscriptionDTO,
      theme: 'trainer',
      onClose: (success?: boolean) => {
        setMembershipManagementUpgradeProps(undefined)
        if (success) {
          handleUpgradeSuccess()
        } else if (success === false) {
          showGrowl(
            'ERROR',
            'An error occurred while upgrading the membership. Please try again.',
            false,
          )
        }
      },
    })
  }

  useEffect(() => {
    if (id) {
      fetchSubscriptionData(id)
      if (!cancellationReasonOptions.length) getCancellationReasonOptions()
    }
  }, [
    cancellationReasonOptions.length,
    getCancellationReasonOptions,
    id,
    lastRefreshTime,
  ])

  const loadCoupons = useCallback(async () => {
    const coupons = await fetchCoupons({ includeAdminOnly: true })
    setCoupons(coupons)
  }, [])

  useEffect(() => {
    loadCoupons()
  }, [loadCoupons])

  return {
    tableColumns,
    subscriptionData,
    loading,
    handleUpgradeSuccess,
    closeGrowl,
    cancellationReasonOptions,
    coupons,
    membershipManagementUpgradeProps,
    handleMembershipManagementClick,
  }
}

export default useWithMemberships
