import { useSelector } from 'react-redux'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import useProgramType from 'app/hooks/useProgramType'
import { activeProgramSelector } from 'app/redux/features/active-program/active-program-reducer'
import { SchedulePages } from 'components/module/program/schedule/schedule-container/schedule-container'
import { ScheduleForm } from 'components/module/schedule'
import { ProductTypes } from 'types/product.d'
import styles from './create.module.scss'

const Create = ({ changePage }: any) => {
  const programDetails = useSelector(activeProgramSelector)
  const programType = useProgramType(programDetails)

  const changePageToList = () => {
    changePage(SchedulePages.List)
  }

  return (
    <Card flat className={styles.container}>
      <header className={styles.header}>
        <Heading className={styles.title} type="h3">
          <span className={styles.carrot} onClick={() => changePageToList()}>
            <Icon.Line name={'icon-angle-left-b'} />
          </span>
          Create A Schedule
        </Heading>
      </header>

      <ScheduleForm
        programId={programDetails?.id || ''}
        programType={programType}
        productType={
          programDetails?.productDetailSummary.type || ProductTypes.BOOTCAMP
        }
        onAfterSubmitForm={() => {
          setTimeout(changePageToList, 1000)
        }}
      />
    </Card>
  )
}

export default Create
