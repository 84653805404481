import { OrganizationParnershipType } from '@campgladiator/cg-common.types.types'

type PartnershipTypeDropdownItem = {
  value: OrganizationParnershipType
  label: string
}

export const partnershipTypeDropdownItems: PartnershipTypeDropdownItem[] = [
  {
    value: 'GLOBAL',
    label: 'Global',
  },
  {
    value: 'NATIONAL',
    label: 'National',
  },
  {
    value: 'REGIONAL',
    label: 'Regional',
  },
  {
    value: 'LOCAL',
    label: 'Local',
  },
]
