import { useState } from 'react'
import { CheckinDTO } from '@campgladiator/cg-common.types.types'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { useAppAccess } from 'app/contexts/app-access'
import { useGrowlContext } from 'app/contexts/growl-context'
import { postCheckIn } from 'services/api/checkIn'
import { CheckInForm } from '../checkIn-form'
import {
  ChangePageFunction,
  CheckInsPages,
} from '../checkIns-container/checkIns-container'
import styles from './add-checkIn.module.scss'

export type AddCheckInProps = {
  changePage: ChangePageFunction
  onAfterCheckInUpdate: (checkIn: CheckinDTO) => void
}

const AddCheckIn = ({ changePage, onAfterCheckInUpdate }: AddCheckInProps) => {
  const { showGrowl } = useGrowlContext()
  const { invalidateUserOnUnauthorizedResponse, updateUser, authToken } =
    useAppAccess()
  const [isSubmitting, setSubmitting] = useState(false)
  const [resetForm, setResetForm] = useState(false)

  const changePageToList = () => {
    changePage(CheckInsPages.List)
  }

  const handleSubmit = async (values: Partial<CheckinDTO>) => {
    setSubmitting(true)
    postCheckIn({
      checkIn: values,
      authToken: authToken!,
      invalidateUserOnUnauthorizedResponse,
      updateUser,
    })
      .then((response) => {
        if (response) {
          showGrowl(
            'SUCCESS',
            `${response.id} - Checked In successfully.`,
            false,
          )
          setResetForm(true)
          onAfterCheckInUpdate(response)
        }
      })
      .catch((error) => {
        showGrowl('FAILED', error)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Heading className={styles.title} type="h3">
          <span className={styles.carrot} onClick={() => changePageToList()}>
            <Icon.Line name={'icon-angle-left-b'} />
          </span>
          New Checkin
        </Heading>
      </header>

      <CheckInForm
        resetData={resetForm}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default AddCheckIn
