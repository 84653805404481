import { AppThunk } from 'app/redux/store'
import { getProgramDetails } from 'services/api/program'
import { clearActiveProgram, setActiveProgram } from './active-program-reducer'

export const loadProgramDetails =
  (programId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await getProgramDetails(programId)
      dispatch(setActiveProgram(response))
    } catch (errorMessage) {
      dispatch(clearActiveProgram())
    }
  }
