import { RegionGroupOverview } from 'components/module/region-group/region-group-overview'
import styles from './view.module.scss'

const RegionGroupView = () => (
  <div className={styles.container}>
    <RegionGroupOverview />
  </div>
)

export default RegionGroupView
