import { ChangeEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CheckinDTO, ProgramType } from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { useAppAccess } from 'app/contexts/app-access'
import ApiAutoComplete from 'components/form-ui/api-auto-complete'
import { Dropdown } from 'components/form-ui/dropdown'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { getAllPrograms } from 'services/api/program'
import { getTrainers } from 'services/api/trainer'
import { getWorkoutsByTypeAndTrainer } from 'services/api/workout'
import {
  programTypeDropdownItems,
  approvalAttrMapping,
} from 'services/data/program'
import { Address } from 'types/address'
import { displayAddress } from 'utils/address-helper'
import { currentDate, dateTimeStringToLocalTimeWithZone } from 'utils/date'
import * as Yup from 'yup'
import styles from './checkIn-form.module.scss'

type CheckInFormProps = {
  resetData?: boolean
  isSubmitting: boolean
  onSubmit: (params: Partial<CheckinDTO>) => void
}

export const checkInValidationSchema = Yup.object({
  type: Yup.string().required('Type is required'),
  trainerId: Yup.string().required('Trainer is required'),
  date: Yup.date().required('Date is required'),
  workoutId: Yup.number().required('Workout is required'),
})

const CheckInForm = ({
  resetData,
  isSubmitting,
  onSubmit,
}: CheckInFormProps) => {
  const { invalidateUserOnUnauthorizedResponse, updateUser } = useAppAccess()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [timezone, setTimeZone] = useState('')
  const [trainerName, setTrainerName] = useState('')
  const [workoutOptions, setWorkoutOptions] = useState([])
  const [trainersFilterParams, setTrainersFilterParams] = useState<any>({})
  const {
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      type: '',
      trainerId: '',
      date: '',
      workoutId: undefined,
    },
    validationSchema: checkInValidationSchema,
    onSubmit: async (values) => {
      onSubmit({
        workoutId: values.workoutId,
        userId: id,
        type: values.type as ProgramType,
      })
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    if (resetData) resetForm()
    //eslint-disable-next-line
  }, [resetData])

  useEffect(() => {
    if (timezone && values.trainerId && values.date && values.type) {
      fetchWorkoutsByFilters(values.trainerId, values.date)
    }
    //eslint-disable-next-line
  }, [timezone && values.type, values.date, values.trainerId])

  useEffect(() => {
    if (trainerName) {
      fetchPrograms(trainerName)
    }
    //eslint-disable-next-line
  }, [trainerName])

  const fetchPrograms = async (trainerName: string) => {
    try {
      setLoading(true)
      getAllPrograms(trainerName).then((programs) => {
        setLoading(false)
        if (programs.length > 0) {
          const filteredProgram = programs.filter((program) => {
            const isSelectedWorkoutTypeofOnline = values.type === 'ONLINE'
            return (
              (isSelectedWorkoutTypeofOnline
                ? program.productDetailSummary.subType === 'ONLINE'
                : program.productDetailSummary.subType === 'IN_PERSON') &&
              program.productDetailSummary.type === 'BOOTCAMP'
            )
          })
          if (filteredProgram.length > 0) {
            setTimeZone(filteredProgram[0].timezone)
          }
        }
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value
    setFieldValue('type', type)
    setTrainersFilterParams({
      approvedProgram: type,
    })
  }

  const setWorkoutLabel = (startTime: string, address: Address) => {
    const lblAddress = address ? `- ${displayAddress(address)}` : ''
    return `${dateTimeStringToLocalTimeWithZone(
      startTime,
      timezone,
    )} ${lblAddress}`
  }

  const fetchWorkoutsByFilters = async (trainerId: string, date: string) => {
    try {
      setLoading(true)
      const response = await getWorkoutsByTypeAndTrainer(
        values.type,
        trainerId,
        date,
        invalidateUserOnUnauthorizedResponse,
        updateUser,
      )
      setWorkoutOptions(
        response.content.map((it: any) => ({
          value: it.id,
          label: setWorkoutLabel(it.startTime, it.address),
        })),
      )
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <form className={styles.formSection} onSubmit={handleSubmit}>
      <FormPart
        label="Type"
        forId="type"
        errorMsg={(touched.type && errors.type) || ''}
      >
        <Dropdown
          id="type"
          label="Type"
          options={programTypeDropdownItems}
          {...getFieldProps('type')}
          onChange={handleTypeChange}
        />
      </FormPart>
      <FormPart
        label="Trainer"
        forId="trainerId"
        errorMsg={(touched.trainerId && errors.trainerId) || ''}
      >
        <ApiAutoComplete
          id="trainerId"
          placeholder="Trainer"
          getMethod={getTrainers}
          searchKey="name"
          disabled={isEmpty(values.type)}
          renderOptionLabel={(record) =>
            `${record.firstName} ${record.lastName}`
          }
          filterOption={(record: any) =>
            record.approvedPrograms[
              approvalAttrMapping[trainersFilterParams.approvedProgram]
            ] === true
          }
          onSelect={(record: any) => {
            setTrainerName(record.text)
            setFieldValue('trainerId', record?.itemId || '')
            setFieldValue('date', currentDate)
          }}
          {...getFieldProps('trainerId')}
        />
      </FormPart>
      <FormPart
        label="Date"
        forId="date"
        errorMsg={(touched.date && errors.date) || ''}
      >
        <Input
          id="date"
          placeholder="Date"
          type="date"
          max={currentDate}
          disabled={isEmpty(values.type) || isEmpty(values.trainerId)}
          {...getFieldProps('date')}
          onChange={(e) => setFieldValue('date', e.target.value)}
        />
      </FormPart>
      <FormPart
        label="Workout"
        forId="workoutId"
        errorMsg={(touched.workoutId && errors.workoutId) || ''}
      >
        <Dropdown
          id="workoutId"
          label="Workout"
          options={workoutOptions}
          disabled={
            isEmpty(values.type) ||
            isEmpty(values.trainerId) ||
            isEmpty(values.date) ||
            loading
          }
          {...getFieldProps('workoutId')}
        />
      </FormPart>
      <div className={styles.buttons}>
        <Button
          emphasis="primary"
          disabled={isSubmitting}
          type="submit"
          theme="trainer"
        >
          Save
        </Button>
      </div>
    </form>
  )
}

export default CheckInForm
