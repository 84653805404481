import { ProgramType } from '@campgladiator/cg-common.types.types'

type ProgramTypeDropdownItem = {
  value: ProgramType
  label: string
}

export const programTypeDropdownItems: ProgramTypeDropdownItem[] = [
  {
    value: 'ONLINE',
    label: 'Online',
  },
  {
    value: 'OUTDOOR',
    label: 'Outdoor',
  },
]

export const approvalAttrMapping: any = {
  OUTDOOR: 'outdoorApproved',
  ONLINE: 'onlineApproved',
}
