import { ProgramType } from '@campgladiator/cg-common.types.types'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Divider } from '@campgladiator/cgui-core.atoms.divider'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Toggle } from '@campgladiator/cgui-core.atoms.toggle'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import DaysSelection from 'components/form-ui/days-selection'
import { Dropdown } from 'components/form-ui/dropdown'
import { getIn } from 'formik'
import { ProductTypes } from 'types/product.d'
import { ProgramScheduleDTO } from 'types/schedule'
import ScheduleAddress from './components/ScheduleAddress'
import ScheduleReassign from './components/ScheduleReassign/ScheduleReassign'
import useScheduleForm from './hooks/use-schedule-form'
import styles from './schedule-form.module.scss'

export interface IScheduleFormProps {
  programId: string
  programType?: ProgramType
  productType: ProductTypes
  scheduleData?: ProgramScheduleDTO
  onAfterSubmitForm?: () => void
}

const ScheduleForm = ({
  programId,
  programType,
  productType,
  scheduleData,
  onAfterSubmitForm,
}: IScheduleFormProps) => {
  const {
    disableLatandLong,
    disableAddress1,
    isGrowlVisible,
    growlContent,
    getFieldProps,
    errors,
    touched,
    isSubmitting,
    setGrowlVisible,
    isEditing,
    scheduleTypes,
    handleSubmit,
    isAlternativeAddressVisible,
    setIsAlternativeAddressVisible,
    scheduleReassignProps,
  } = useScheduleForm({
    programId,
    programType,
    productType,
    scheduleData,
    onAfterSubmitForm,
  })

  return (
    <div className={styles.formContainer}>
      <GrowlMessage
        autoClose={false}
        isVisible={isGrowlVisible}
        onClick={() => setGrowlVisible(false)}
      >
        {growlContent}
      </GrowlMessage>
      <ScheduleReassign {...scheduleReassignProps} />
      <form className={styles.formSection} onSubmit={handleSubmit}>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Start Date"
            forId="startDate"
            className={styles.fieldsetItem}
            errorMsg={(touched.startDate && errors.startDate) || undefined}
          >
            <Input
              id="startDate"
              placeholder="Start Date"
              type="date"
              {...getFieldProps('startDate')}
            />
          </FormPart>
          <FormPart
            label="End Date"
            forId="endDate"
            className={styles.fieldsetItem}
            errorMsg={(touched.endDate && errors.endDate) || undefined}
          >
            <Input
              id="endDate"
              placeholder="End Date"
              type="date"
              {...getFieldProps('endDate')}
            />
          </FormPart>
        </Fieldset>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Schedule Type"
            forId="tagId"
            className={styles.fieldsetItem}
            errorMsg={(touched.tagId && errors.tagId) || undefined}
          >
            <Dropdown
              id="tagId"
              label="Select a Schedule Type"
              options={scheduleTypes}
              {...getFieldProps('tagId')}
            />
          </FormPart>
        </Fieldset>
        <Fieldset className={styles.fieldset}>
          <FormPart
            forId="daysOfTheWeek"
            className={styles.fieldsetItem}
            errorMsg={
              (touched.daysOfTheWeek && errors.daysOfTheWeek) || undefined
            }
          >
            <DaysSelection
              id="daysOfTheWeek"
              {...getFieldProps('daysOfTheWeek')}
            />
          </FormPart>
        </Fieldset>
        <Fieldset className={styles.fieldset}>
          <FormPart
            label="Start Time (24H Format)"
            forId="startTime"
            className={styles.fieldsetItem}
            errorMsg={(touched.startTime && errors.startTime) || undefined}
          >
            <Input
              id="startTime"
              type="time"
              placeholder="15:00"
              {...getFieldProps('startTime')}
            />
          </FormPart>

          <FormPart
            label="Duration (Minutes)"
            forId="durationInMinutes"
            className={styles.fieldsetItem}
            errorMsg={
              (touched.durationInMinutes && errors.durationInMinutes) ||
              undefined
            }
          >
            <Input
              id="durationInMinutes"
              placeholder="Minutes"
              {...getFieldProps('durationInMinutes')}
            />
          </FormPart>
        </Fieldset>
        {productType === ProductTypes.STRENGTH && (
          <Fieldset className={styles.fieldset}>
            <FormPart
              label="Capacity"
              forId="capacity"
              className={styles.fieldsetItemHalf}
              errorMsg={(touched.capacity && errors.capacity) || undefined}
            >
              <Input
                id="capacity"
                placeholder="Capacity"
                {...getFieldProps('capacity')}
              />
            </FormPart>
          </Fieldset>
        )}
        <FormPart
          label="Description"
          forId="description"
          className={styles.fieldsetItem}
          errorMsg={(touched.description && errors.description) || undefined}
        >
          <Input
            id="description"
            placeholder="Description"
            {...getFieldProps('description')}
          />
        </FormPart>
        <Divider />
        {productType === ProductTypes.STRENGTH && (
          <>
            <Heading className={styles.header} type="h5">
              Price
            </Heading>
            <FormPart
              label="Name"
              forId="name"
              className={styles.fieldsetItem}
              errorMsg={
                (getIn(touched.newPrice, 'name') &&
                  getIn(errors.newPrice, 'name')) ||
                ''
              }
            >
              <Input
                id="name"
                placeholder="Name"
                disabled={!!isEditing}
                {...getFieldProps('newPrice.name')}
              />
            </FormPart>
            <FormPart
              label="Description"
              forId="description"
              className={styles.fieldsetItem}
              errorMsg={
                (getIn(touched.newPrice, 'description') &&
                  getIn(errors.newPrice, 'description')) ||
                ''
              }
            >
              <Input
                id="description"
                placeholder="Description"
                disabled={!!isEditing}
                {...getFieldProps('newPrice.description')}
              />
            </FormPart>
            <FormPart
              label="Amount"
              forId="amount"
              className={styles.fieldsetItem}
              errorMsg={
                (getIn(touched.newPrice, 'amount') &&
                  getIn(errors.newPrice, 'amount')) ||
                ''
              }
            >
              <Input
                id="amount"
                placeholder="Amount"
                type="number"
                disabled={!!isEditing}
                {...getFieldProps('newPrice.amount')}
              />
            </FormPart>

            <Divider />
          </>
        )}
        {programType === 'ONLINE' ? (
          <FormPart
            label="Link"
            forId="link"
            className={styles.fieldsetItem}
            errorMsg={(touched.link && errors.link) || undefined}
          >
            <Input
              id="link"
              placeholder="https://www.zoom.com"
              {...getFieldProps('link')}
            />
          </FormPart>
        ) : (
          <div className={styles.formSection}>
            <Heading className={styles.header} type="h5">
              Primary Address
            </Heading>
            <ScheduleAddress
              errors={errors}
              getFieldProps={getFieldProps}
              touched={touched}
              disableAddress={disableAddress1}
              isEditing={isEditing}
              disableLatandLong={disableLatandLong}
              fieldName="address"
            />
            <FormPart
              label="Alternative Address"
              forId="alternativeAddressVisile"
            >
              <Toggle
                checked={isAlternativeAddressVisible}
                onChange={(e) =>
                  setIsAlternativeAddressVisible(e.target.checked)
                }
              />
              {isAlternativeAddressVisible && (
                <>
                  <Heading className={styles.header} type="h5">
                    Alternative Address
                  </Heading>
                  <ScheduleAddress
                    fieldName="alternativeAddress"
                    errors={errors}
                    getFieldProps={getFieldProps}
                    touched={touched}
                    disableAddress={disableAddress1}
                    isEditing={isEditing}
                    disableLatandLong={disableLatandLong}
                  />
                </>
              )}
            </FormPart>
          </div>
        )}
        <Divider />
        {isEditing && (
          <FormPart
            label="Effective Date"
            forId="effectiveDate"
            className={styles.fieldsetItem}
            errorMsg={
              (touched.effectiveDate && errors.effectiveDate) || undefined
            }
          >
            <Input
              id="effectiveDate"
              type="date"
              {...getFieldProps('effectiveDate')}
            />
          </FormPart>
        )}

        <div className={styles.buttons}>
          <Button
            emphasis="primary"
            className={styles.submitButton}
            type="submit"
            disabled={isSubmitting}
            theme="trainer"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ScheduleForm
