import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Divider } from '@campgladiator/cgui-core.atoms.divider'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { TextArea } from '@campgladiator/cgui-core.atoms.textarea'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import { Dropdown } from 'components/form-ui/dropdown'
import { useFormik } from 'formik'
import StateDropDownItems from 'services/data/address'
import { AddressTypes, Address } from 'types/address.d'
import * as Yup from 'yup'
import styles from './address-form.module.scss'

type AddressFormProps = {
  onSubmitForm: (params: Address) => void
}

const addressTypeOptions = [
  {
    value: AddressTypes.TRAINER_HOME,
    label: 'Trainer Home',
  },
  {
    value: AddressTypes.CLIENT_HOME,
    label: 'Client Home',
  },
  {
    value: AddressTypes.TRAINING_FACILITY,
    label: 'Training Facility',
  },
  {
    value: AddressTypes.OUTDOOR,
    label: 'Outdoor',
  },
  {
    value: AddressTypes.FLEX,
    label: 'Flex',
  },
  {
    value: AddressTypes.UNKNOWN,
    label: 'Unknown',
  },
]

const validationSchema = Yup.object({
  type: Yup.mixed<AddressTypes>()
    .oneOf(Object.values(AddressTypes))
    .required('Type is required'),
  name: Yup.string()
    .trim()
    .max(100, 'Must be 100 characters or less')
    .required('Name is required'),
  address1: Yup.string()
    .trim()
    .max(300, 'Must be 300 characters or less')
    .required('Address 1 is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().trim().required('City is required'),
  zip: Yup.string()
    .required('Zip code is required')
    .min(5, 'Invalid zip')
    .max(5, 'Invalid zip'),
  longitude: Yup.number().transform((value) =>
    isNaN(value) ? undefined : value,
  ),
  latitude: Yup.number().transform((value) =>
    isNaN(value) ? undefined : value,
  ),
})

const AddressForm = ({ onSubmitForm }: AddressFormProps) => {
  const {
    handleSubmit,
    getFieldProps,
    setSubmitting,
    resetForm,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      id: '',
      type: AddressTypes.OUTDOOR,
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      latitude: '',
      longitude: '',
      directions: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await onSubmitForm(validationSchema.cast(values))
        resetForm()
      } catch (error: any) {
        console.log(error)
      }

      setSubmitting(false)
    },
  })

  return (
    <form className={styles.formSection} onSubmit={handleSubmit}>
      <FormPart
        label="Type*"
        forId="type"
        errorMsg={(touched.type && errors.type) || ''}
      >
        <Dropdown
          id="type"
          options={addressTypeOptions}
          {...getFieldProps('type')}
        />
      </FormPart>
      <FormPart
        label="Name*"
        forId="name"
        errorMsg={(touched.name && errors.name) || ''}
      >
        <Input id="name" placeholder="Name" {...getFieldProps('name')} />
      </FormPart>
      <Divider />
      <FormPart
        label="Address 1*"
        forId="address1"
        errorMsg={(touched.address1 && errors.address1) || ''}
      >
        <Input
          id="address1"
          placeholder="Address 1"
          {...getFieldProps('address1')}
        />
      </FormPart>
      <FormPart label="Address 2" forId="address2">
        <Input
          id="address2"
          placeholder="Address 2"
          {...getFieldProps('address2')}
        />
      </FormPart>
      <FormPart
        label="City*"
        forId="city"
        errorMsg={(touched.city && errors.city) || ''}
      >
        <Input id="city" placeholder="City" {...getFieldProps('city')} />
      </FormPart>
      <Fieldset className={styles.fieldset}>
        <FormPart
          label="State*"
          forId="state"
          className={styles.fieldsetItem}
          errorMsg={(touched.state && errors.state) || ''}
        >
          <Dropdown
            id="state"
            label="Choose a state"
            options={StateDropDownItems}
            {...getFieldProps('state')}
          />
        </FormPart>
        <FormPart
          label="Zip code*"
          forId="zip"
          className={styles.fieldsetItem}
          errorMsg={(touched.zip && errors.zip) || ''}
        >
          <Input
            id="zip"
            placeholder="Zip code"
            type="number"
            {...getFieldProps('zip')}
          />
        </FormPart>
      </Fieldset>
      <Fieldset className={styles.fieldset}>
        <FormPart
          label="Latitude"
          forId="latitude"
          className={styles.fieldsetItem}
          errorMsg={(touched.latitude && errors.latitude) || ''}
        >
          <Input
            id="latitude"
            placeholder="Latitude"
            type="number"
            {...getFieldProps('latitude')}
          />
        </FormPart>
        <FormPart
          label="Longitude"
          forId="longitude"
          className={styles.fieldsetItem}
          errorMsg={(touched.longitude && errors.longitude) || ''}
        >
          <Input
            id="longitude"
            placeholder="Longitude"
            type="number"
            {...getFieldProps('longitude')}
          />
        </FormPart>
      </Fieldset>
      <Divider />
      <FormPart label="Directions" forId="directions">
        <TextArea
          rows={4}
          id="directions"
          placeholder="Directions"
          className={styles.textArea}
          {...getFieldProps('directions')}
        />
      </FormPart>
      <div className={styles.buttons}>
        <Button
          emphasis="primary"
          className={styles.submitButton}
          disabled={isSubmitting}
          type="submit"
          theme="trainer"
        >
          Submit New Address
        </Button>
      </div>
    </form>
  )
}

export default AddressForm
