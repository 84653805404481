import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Input } from '@campgladiator/cgui-core.atoms.input'
import { Icon } from '@campgladiator/cgui-core.atoms.input/dist/input'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  Table,
  TData,
  THead,
  TRow,
} from '@campgladiator/cgui-core.organisms.table'
import { useAppDispatch } from 'app/redux/store'
import { debounce } from 'lodash'
import { getAllRegions } from 'services/api/locale'
import { Locale, LocalePageData } from 'types/locale'
import styles from './regions.module.scss'

export const filterRegions = (regions: Locale[], query: string) => {
  return regions.filter((region) => {
    const queryableName = `${region.name?.toLowerCase()}`
    const queryableDescription = `${region.description?.toLowerCase()}`
    const isMatchingName = queryableName.includes(query.toLowerCase())
    const isMatchingDescription = queryableDescription.includes(
      query.toLowerCase(),
    )
    return isMatchingName || isMatchingDescription
  })
}

export const getSearchBarIcon = (searchValueLength: number): Icon =>
  searchValueLength === 0
    ? { type: 'solid', name: 'icon-search' }
    : { type: 'monochrome', name: 'times-circle' }

const Regions = () => {
  const [regionData, setRegionData] = useState<Locale[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredRegions, setFilteredRegions] = useState<Locale[]>(regionData)
  const isSearchApplied = searchValue.length > 2
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getRegions)
  }, [dispatch])

  const getRegions = async () => {
    const response: LocalePageData = await getAllRegions()
    const filteredData = response.content
      .filter((locale) => !locale.deleted)
      .sort((a, b) => a.name.localeCompare(b.name))
    setRegionData(filteredData)
  }

  const searchQuery = useCallback(() => {
    if (isSearchApplied) {
      const filteredRegions = filterRegions(regionData, searchValue)
      setFilteredRegions(filteredRegions)
    } else {
      setFilteredRegions(regionData)
    }
  }, [regionData, isSearchApplied, searchValue])

  const delayedSearchQuery = useMemo(
    () => debounce(searchQuery, 50),
    [searchQuery],
  )

  useEffect(() => {
    delayedSearchQuery()
    return delayedSearchQuery.cancel
  }, [searchValue, delayedSearchQuery])

  return (
    <div className={styles.page}>
      <Card className={styles.title}>
        <Heading className={styles.pageHeader} type="h3">
          Regions
        </Heading>
        <Button
          className={styles.createButton}
          emphasis="primary"
          icon={{ name: 'icon-plus-circle', type: 'line' }}
          size="default"
          variation="solid"
          theme="trainer"
          onClick={() => navigate('/regions/create')}
        >
          New Region
        </Button>
      </Card>
      <div className={styles.searchWrapper}>
        <Input
          className={styles.search}
          name="search"
          id="search"
          placeholder="Search a region name or description"
          icon={getSearchBarIcon(searchValue.length)}
          onInputChange={setSearchValue}
          value={searchValue}
          onIconClick={() => setSearchValue('')}
        />
      </div>
      <div className={styles.wrapper}>
        <Table.Manual showFooter>
          <thead>
            <TRow>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Region Name
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Description
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Status
                </Heading>
              </THead>
              <THead>
                <Heading type="h6" className={styles.tableHeader}>
                  Edit
                </Heading>
              </THead>
            </TRow>
          </thead>
          <tbody>
            {filteredRegions.map(({ id, name, description, active }) => (
              <TRow key={id}>
                <TData>
                  <Paragraph size="small" weight="book">
                    {name}
                  </Paragraph>
                </TData>
                <TData>
                  <Paragraph size="small" weight="book">
                    {description}
                  </Paragraph>
                </TData>
                <TData>
                  <Paragraph size="small" weight="book">
                    {active ? 'Active' : 'Inactive'}
                  </Paragraph>
                </TData>
                <TData>
                  <Paragraph size="small" weight="book">
                    <Button
                      emphasis="secondary"
                      icon={{ name: 'icon-edit', type: 'solid' }}
                      size="large"
                      variation="text"
                      onClick={() =>
                        navigate(`/regions/${id}`, { state: { id, name } })
                      }
                      theme="trainer"
                    ></Button>
                  </Paragraph>
                </TData>
              </TRow>
            ))}
          </tbody>
        </Table.Manual>
      </div>
    </div>
  )
}

export default Regions
