import { AppThunk } from 'app/redux/store'
import { groupBy } from 'lodash'
import { getSubscriptionSummary } from 'services/api/subscriptions'
import { getUserById } from 'services/api/user'
import { SubscriptionSummary } from 'types/subscription'
import {
  clearActiveUser,
  clearMembershipSummary,
  setActiveUser,
  setMembershipSummary,
} from './active-user-reducer'

export const loadUserDetails =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await getUserById(userId)
      dispatch(setActiveUser(response))
    } catch (errorMessage) {
      dispatch(clearActiveUser())
    }
  }

export const loadUserMembershipSummaryDetails =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await getSubscriptionSummary(userId)
      const filterdMembershipSummary = Object.values(
        groupBy(response, (it) => `${it.type}-${it.subType}`),
      ).map(
        (it) =>
          it
            .sort((a, b) => {
              if (a.periodEndDate === null && b.periodEndDate !== null) {
                return -1
              }

              if (a.periodEndDate !== null && b.periodEndDate === null) {
                return 1
              }

              if (a.periodEndDate === null && b.periodEndDate === null) {
                return 0
              }

              return b.periodEndDate.localeCompare(a.periodEndDate)
            })
            .at(0) as SubscriptionSummary,
      )
      dispatch(setMembershipSummary(filterdMembershipSummary))
    } catch (errorMessage) {
      dispatch(clearMembershipSummary())
    }
  }
