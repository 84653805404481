import { useCallback, useEffect, useState } from 'react'
import { useAppAccess } from 'app/contexts/app-access'
import { ActiveProgramType } from 'app/redux/features/active-program/active-program-reducer'
import { capitalize } from 'lodash'
import { getSchedulesByProgramId } from 'services/api/schedule'
import { getWorkoutsByScheduleId, putWorkout } from 'services/api/workout'
import { ProductSubTypes } from 'types/product.d'
import { Schedule } from 'types/schedule'
import { PagedWorkouts, Workout } from 'types/workout'
import { currentUtcDateTimeString } from 'utils/date'

type UseWorkoutsListProps = {
  programDetails: ActiveProgramType
}

const useWorkoutsList = ({ programDetails }: UseWorkoutsListProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [schedules, setSchedules] = useState<Schedule[]>([])
  const [scheduleId, setScheduleId] = useState<string>('')
  const [workouts, setWorkouts] = useState<Workout[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)

  const { invalidateUserOnUnauthorizedResponse, updateUser } = useAppAccess()

  const programType =
    programDetails?.productDetailSummary.subType || ProductSubTypes.IN_PERSON
  const programTimezone = programDetails?.timezone

  const nonExpiredSchedules = schedules.filter((schedule) => {
    if (!schedule.endDate) {
      return true
    }

    const endDate = new Date(schedule.endDate)
    const today = new Date()

    return endDate >= today
  })

  const scheduleDropdownOptions = nonExpiredSchedules.map((schedule) => {
    const daysOfWeek = schedule.daysOfTheWeek
      .split(',')
      .map((dow) => capitalize(dow.substring(0, 3)))
      .join(', ')

    const startTime = schedule.startTime.substring(0, 5)

    return {
      label: `${daysOfWeek} - ${startTime}`,
      value: schedule.id || '',
    }
  })

  const handleScheduleIdChange = (scheduleId: string) => {
    setCurrentPage(1)
    setTotalPages(0)
    setScheduleId(scheduleId)
  }

  const fetchSchedules = useCallback(async () => {
    setIsLoading(true)
    try {
      const schedules = await getSchedulesByProgramId(programDetails?.id || '')
      setSchedules(schedules)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [programDetails?.id])

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const fetchWorkouts = useCallback(async () => {
    setIsLoading(true)

    if (!scheduleId) {
      setWorkouts([])
      setCurrentPage(1)
      setTotalPages(0)
      setIsLoading(false)
      return
    }

    try {
      const workouts: PagedWorkouts = await getWorkoutsByScheduleId(
        scheduleId,
        {
          pageNumber: currentPage - 1,
          pageSize: 10,
        },
        invalidateUserOnUnauthorizedResponse,
        updateUser,
      )
      setWorkouts(workouts.content)
      setTotalPages(workouts.totalPages)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [
    currentPage,
    scheduleId,
    invalidateUserOnUnauthorizedResponse,
    updateUser,
  ])

  const handleStatusToggle = async (isActive: boolean, workoutId?: number) => {
    const [workout] = workouts.filter((workout) => workout.id === workoutId)

    if (!workout) return

    try {
      const savedWorkout = await putWorkout(
        {
          ...workout,
          archived: isActive ? '' : currentUtcDateTimeString,
        },
        invalidateUserOnUnauthorizedResponse,
        updateUser,
      )

      setWorkouts((currentWorkouts) => {
        return currentWorkouts.map((workout) => {
          if (workout.id === savedWorkout.id) return savedWorkout
          else return workout
        })
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchSchedules()
  }, [fetchSchedules])

  useEffect(() => {
    fetchWorkouts()
  }, [fetchWorkouts])

  return {
    currentPage,
    isLoading,
    programTimezone,
    programType,
    scheduleDropdownOptions,
    scheduleId,
    totalPages,
    workouts,
    handlePageChange,
    handleScheduleIdChange,
    handleStatusToggle,
  }
}

export default useWorkoutsList
