import { OrganizationDTO } from '@campgladiator/cg-common.types.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/redux/store'

export type ActiveOrganizationState = {
  organization: OrganizationDTO | null
  error: string | null
  isLoading: boolean
}

export const initialState: ActiveOrganizationState = {
  isLoading: false,
  error: '',
  organization: null,
}

const activeOrganizationReducer = createSlice({
  name: 'activeOrganization',
  initialState,
  reducers: {
    setActiveOrganizationPending: (
      state: ActiveOrganizationState,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoading = action.payload
      state.error = initialState.error
      state.organization = initialState.organization
    },
    setActiveOrganization: (
      state: ActiveOrganizationState,
      action: PayloadAction<ActiveOrganizationState['organization']>,
    ) => {
      state.isLoading = initialState.isLoading
      state.organization = action.payload
      state.error = initialState.error
    },
    setOrganizationFailure: (
      state: ActiveOrganizationState,
      action: PayloadAction<ActiveOrganizationState['error']>,
    ) => {
      state.error = action.payload
      state.isLoading = initialState.isLoading
      state.organization = initialState.organization
    },
  },
})

export const {
  setActiveOrganizationPending,
  setActiveOrganization,
  setOrganizationFailure,
} = activeOrganizationReducer.actions

export const activeOrganizationSelector = (state: RootState) =>
  state.activeOrganization

export default activeOrganizationReducer.reducer
