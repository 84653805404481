import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  TRow,
  Table,
  TData,
  THead,
} from '@campgladiator/cgui-core.organisms.table'
import { activeProgramSelector } from 'app/redux/features/active-program/active-program-reducer'
import { PageTitleWithSearchInputAndNewButton } from 'components/module/shared/page-title-search-input-new-button'
import { capitalize } from 'lodash'
import { getSchedulesByProgramId } from 'services/api/schedule'
import { ProductSubTypes } from 'types/product.d'
import { ProgramScheduleDTO } from 'types/schedule'
import { displayAddress, hasAddressData } from 'utils/address-helper'
import {
  ChangePageFunction,
  SchedulePages,
} from '../schedule-container/schedule-container'
import styles from './schedule-list.module.scss'

export type ScheduleListProps = {
  changePage: ChangePageFunction
}

const ScheduleList = ({ changePage }: ScheduleListProps) => {
  const { id } = useParams()

  const [programSchedules, setProgramSchedules] = useState<
    ProgramScheduleDTO[]
  >([])

  const programDetails = useSelector(activeProgramSelector)

  const programType =
    programDetails?.productDetailSummary.subType || ProductSubTypes.IN_PERSON

  const fetchSchedules = useCallback(async (programId: string) => {
    const schedules = await getSchedulesByProgramId(programId)
    setProgramSchedules(schedules)
  }, [])

  const handleNewButtonClick = () => {
    changePage(SchedulePages.Create)
  }

  const handleViewDetailsClick = (id?: string) => {
    changePage(SchedulePages.Edit, { scheduleId: id })
  }

  useEffect(() => {
    if (!id) return
    fetchSchedules(id).catch((err) => console.log(err))
  }, [fetchSchedules, id])

  return (
    <>
      <PageTitleWithSearchInputAndNewButton
        title="Schedules"
        placeholder=""
        newButtonText="New Schedule"
        showSearchInput={false}
        onSearch={() => {}}
        onNewButtonClick={() => handleNewButtonClick()}
      />

      <div className={styles.tableWrapper}>
        <Table.Manual>
          <thead>
            <TRow>
              <THead>
                <Heading type="h6">Days of week</Heading>
              </THead>
              <THead>
                <Heading type="h6">Start time</Heading>
              </THead>
              <THead>
                <Heading type="h6">Type</Heading>
              </THead>
              <THead>
                <Heading type="h6">Duration</Heading>
              </THead>
              <THead>
                <Heading type="h6">
                  {programType === ProductSubTypes.ONLINE && 'Link'}
                  {programType === ProductSubTypes.IN_PERSON && 'Address'}
                </Heading>
              </THead>
              <THead>
                <Heading type="h6">Capacity</Heading>
              </THead>
              <THead>
                <Heading type="h6">Start date</Heading>
              </THead>
              <THead>
                <Heading type="h6">End date</Heading>
              </THead>
              <THead>
                <Heading type="h6">Actions</Heading>
              </THead>
            </TRow>
          </thead>
          <tbody>
            {!programSchedules.length && (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    No schedules to display
                  </Paragraph>
                </TData>
              </TRow>
            )}

            {!!programSchedules.length &&
              programSchedules.map(
                ({
                  id,
                  daysOfTheWeek,
                  startTime,
                  durationInMinutes,
                  address,
                  capacity,
                  startDate,
                  endDate,
                  link,
                  tag,
                }) => (
                  <TRow key={id}>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {daysOfTheWeek
                          .split(',')
                          .map((dow) => capitalize(dow.substring(0, 3)))
                          .join(', ')}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {startTime.substring(0, 5)}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {tag.title}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {durationInMinutes}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {programType === ProductSubTypes.IN_PERSON &&
                          hasAddressData(address) &&
                          displayAddress(address)}

                        {programType === ProductSubTypes.ONLINE && (
                          <a href={link}>Zoom link</a>
                        )}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {capacity || '-'}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {startDate}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        {endDate || '-'}
                      </Paragraph>
                    </TData>
                    <TData>
                      <Paragraph size="small" weight="book">
                        <Button
                          emphasis="secondary"
                          icon={{ name: 'icon-edit', type: 'solid' }}
                          size="large"
                          variation="text"
                          onClick={() => handleViewDetailsClick(id)}
                          theme="trainer"
                        />
                      </Paragraph>
                    </TData>
                  </TRow>
                ),
              )}
          </tbody>
        </Table.Manual>
      </div>
    </>
  )
}

export default ScheduleList
