import { ReactNode, useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from '@stripe/stripe-js'
import { configuration } from 'config'
import { createSetupIntent } from 'services/api/payment'

export type StripeElementsProviderProps = {
  stripeCustomerId: string
  children: ReactNode
}

const stripePromise = loadStripe(configuration.stripePublishableKey)

export const StripeElementsProvider = ({
  stripeCustomerId,
  children,
}: StripeElementsProviderProps) => {
  const [clientSecret, setClientSecret] = useState<string | undefined>('')

  useEffect(() => {
    createSetupIntent({ customerId: stripeCustomerId }).then((response) =>
      setClientSecret(response.clientSecret),
    )
  }, [stripeCustomerId])

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      borderRadius: '100px',
      fontSizeBase: '14px',
      fontLineHeight: '18px',
    },
    rules: {
      '.Label': {
        color: '#333333',
        fontWeight: '600',
        fontSize: '14px',
        marginTop: '5px',
        marginBottom: '7px',
      },
      '.Input': {
        boxShadow: 'none',
        paddingLeft: '20px',
        paddingRight: '20px',
        borderColor: '#656566',
        border: '1px solid',
        outline: 'none',
        fontWeight: 'bold',
      },
    },
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }

  return (
    <>
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          {children}
        </Elements>
      )}
    </>
  )
}
