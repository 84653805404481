import { LanguageType, TrainerType } from '@campgladiator/cg-common.types.types'
import { convertSnakeCaseToSpaceCase } from 'utils/string'

const trainerType: TrainerType[] = [
  'SERVICING',
  'TEAM_LEAD',
  'FIELD_GROWTH_DIRECTOR',
  'REGION_VICE_PRESIDENT',
]

const arrTrainerBioLanguages = Object.keys(LanguageType) as LanguageType[]

const searchOptions = ['Trainer Name', 'Trainer Email']

const TrainerSearchDropdownItems = searchOptions.map((searchOption) => ({
  value: searchOption,
  label: searchOption,
}))

const arrTrainerBioSocialMedia = ['FACEBOOK', 'INSTAGRAM', 'TWITTER', 'YOUTUBE']

export const TrainerBioSocialMediaDropdownItems = arrTrainerBioSocialMedia.map(
  (searchOption) => ({
    value: searchOption,
    label: searchOption,
  }),
)

export const TrainerBioLanguageDropdownItems = arrTrainerBioLanguages.map(
  (searchOption) => ({
    value: searchOption,
    label: searchOption,
  }),
)

export const trainerTypeDropdownItems = [
  { value: '', label: '' },
  ...trainerType.map((value) => ({
    label: convertSnakeCaseToSpaceCase(value),
    value,
  })),
]

export default TrainerSearchDropdownItems
