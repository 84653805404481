import { upperCase } from 'lodash'

const genderList = ['Male', 'Female', 'Other']

const GenderDropdownItems = genderList.map((gender) => ({
  value: upperCase(gender),
  label: gender,
}))

export default GenderDropdownItems
