import { Address } from 'types/address'

export const hasAddressData = (address: Address | undefined) => {
  return address && address.address1 && address.city && address.state
}

export const displayAddress = (address: Address | undefined) => {
  return address
    ? `${address?.address1}, ${address?.city}, ${address?.state}`
    : '-'
}
