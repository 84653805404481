import { useState } from 'react'
import { CheckinDTO } from '@campgladiator/cg-common.types.types'
import { AddCheckIn } from '../add-checkIn'
import { CheckInsList } from '../checkIns-list'

export enum CheckInsPages {
  List,
  Create,
}

export type CheckInsContainerProps = {
  onAfterCheckInUpdate: (checkIn: CheckinDTO) => void
}

export type ChangePageFunction = (page: CheckInsPages, data?: any) => void

const CheckInsContainer = ({
  onAfterCheckInUpdate,
}: CheckInsContainerProps) => {
  const [currentPage, setCurrentPage] = useState<CheckInsPages>(
    CheckInsPages.List,
  )
  const [data, setData] = useState<any>({})

  const changePage: ChangePageFunction = (page: CheckInsPages, data = {}) => {
    setCurrentPage(page)
    setData(data)
  }

  return (
    <>
      {currentPage === CheckInsPages.Create && (
        <AddCheckIn
          changePage={changePage}
          {...data}
          onAfterCheckInUpdate={onAfterCheckInUpdate}
        />
      )}
      {currentPage === CheckInsPages.List && (
        <CheckInsList changePage={changePage} {...data} />
      )}
    </>
  )
}

export default CheckInsContainer
