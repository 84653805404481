import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { UserDTO } from '@campgladiator/cg-common.types.types'
import { Avatar } from '@campgladiator/cgui-core.atoms.avatar'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { activeTrainerSelector } from 'app/redux/features/active-trainer/active-trainer-reducer'
import { uploadUserImage } from 'services/api/user'
import formatPhoneNumber from 'utils/format-phone-number'
import { imageMimeType } from 'utils/regex'
import styles from './trainer-side-panel.module.scss'

export type TrainerSidePanelProps = {
  onAfterTrainerImageUpdate: (trainer: UserDTO) => void
}

const TrainerSidePanel = ({
  onAfterTrainerImageUpdate,
}: TrainerSidePanelProps) => {
  const trainer = useSelector(activeTrainerSelector)
  const [isGrowlVisible, setIsGrowlVisible] = useState(false)
  const [growlContent, setGrowlContent] = useState('')
  const [image, setUserImage] = useState<string | undefined>()

  useEffect(() => {
    if (trainer) setUserImage(trainer.photo)
  }, [trainer])

  const onImageChange = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0]
      if (!imageFile.type.match(imageMimeType)) {
        setGrowlView(
          true,
          `${imageFile.name} is not a valid image file. The image may be corrupted or an unsupported image format`,
        )
      } else {
        setUserImage(URL.createObjectURL(imageFile))
        uploadImage(imageFile)
      }
    }
  }

  const uploadImage = (imageFile: File) => {
    const formData = new FormData()
    formData.append('image', imageFile)
    uploadUserImage(trainer?.userId!, formData)
      .then((response) => {
        if (response) {
          onAfterTrainerImageUpdate(response)
        }
      })
      .catch((e) => {
        console.log('error', e)
      })
  }

  const setGrowlView = (isVisible: boolean, growlContent: string) => {
    setIsGrowlVisible(isVisible)
    setGrowlContent(growlContent)
  }

  const clearError = () => {
    setIsGrowlVisible(false)
  }

  const handleClick = (event: any) => {
    const { target = {} } = event || {}
    target.value = ''
  }

  return (
    <>
      <GrowlMessage isVisible={isGrowlVisible} onClick={clearError}>
        {growlContent}
      </GrowlMessage>

      <div className={styles.panelContainer}>
        <div className={styles.imageContainer}>
          <div className={styles.editImage}>
            <Icon.Solid name={'icon-camera'} className={styles.cameraIcon} />
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={onImageChange}
              onClick={handleClick}
            />
          </div>
          {!image ? (
            <Avatar size="170px" className={styles.avatar} />
          ) : (
            <Avatar
              alt="Trainer's picture"
              size="170px"
              className={styles.avatar}
              src={image}
              onError={() => setUserImage('')}
            />
          )}
        </div>
        <div className={styles.userName}>
          <Paragraph weight="bold" size="xlarge">
            {trainer?.firstName} {trainer?.lastName}
          </Paragraph>
        </div>

        <div className={styles.trainerButton}>
          <Paragraph weight="book" size="small" className={styles.role}>
            Trainer
          </Paragraph>
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.infoItem}>
            <Icon.Monochrome
              className={styles.infoIcon}
              name={'envelope'}
              emphasis="gladiator"
            />
            <Paragraph weight="book" size="small">
              {trainer?.email}
            </Paragraph>
          </div>
          {trainer?.contactNumber && (
            <div className={styles.infoItem}>
              <Icon.Monochrome
                className={styles.infoIcon}
                name={'phone-volume'}
                emphasis="gladiator"
              />
              <Paragraph weight="book" size="small">
                {`+1 ${formatPhoneNumber(trainer?.contactNumber)}`}
              </Paragraph>
            </div>
          )}
          <div className={styles.infoItem}>
            <Icon.Monochrome
              className={styles.infoIcon}
              name={'map-marker'}
              emphasis="gladiator"
            />
            <Paragraph weight="book" size="small">
              {trainer?.localeSummary?.regionName}
            </Paragraph>
          </div>
        </div>
      </div>
    </>
  )
}

export default TrainerSidePanel
