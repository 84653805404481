import { useEffect, useState } from 'react'
import { DayOfWeek } from '@campgladiator/cg-common.types.types'
import { Toggle } from '@campgladiator/cgui-core.atoms.toggle'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import capitalize from 'lodash/capitalize'
import styles from './days-selection.module.scss'

type IProps = {
  id: string
  value: string
  onChange: (event: {
    target: {
      id: string
      value: string
    }
  }) => void
  onBlur: (event: {
    target: {
      id: string
    }
  }) => void
}

const daysOfTheWeek: DayOfWeek[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
]

const DaysSelection = ({ id, value, onChange, onBlur }: IProps) => {
  const [daysChecked, setDaysChecked] = useState<string[]>([])

  useEffect(() => {
    setDaysChecked(value ? value.split(',') : [])
  }, [value])

  const handleDaysChange = (day: string, checked: boolean) => {
    setDaysChecked((currentDaysChecked) => {
      const newDaysChecked = checked
        ? [...currentDaysChecked, day]
        : currentDaysChecked.filter((currentDay) => currentDay !== day)

      return newDaysChecked
    })
    onBlur({ target: { id } })
  }

  useEffect(() => {
    onChange({ target: { id, value: daysChecked.join(',') } })
  }, [id, onChange, daysChecked])

  return (
    <div className={styles.daysSelectionContainer}>
      {daysOfTheWeek.map((day) => (
        <div key={day}>
          <Paragraph size="xsmall">{capitalize(day)}</Paragraph>

          <Toggle
            id={day}
            toggleSize="xsmall"
            checked={daysChecked.includes(day)}
            onChange={(e) => handleDaysChange(day, e.target.checked)}
          />
        </div>
      ))}
    </div>
  )
}

export default DaysSelection
