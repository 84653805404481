import { KeanuUserRole, UserDTO } from '@campgladiator/cg-common.types.types'
import { request } from 'app/networking/fetch'
import configuration from 'config/configuration'
import { LoginParams } from 'types/auth'
import { AuthToken } from 'types/authentication'

export const authEndpointBasePath = '/auth/v1'
const baseUrl = configuration.apiGatewayUrl

export const loginUser = async (params: LoginParams) => {
  const endpoint = '/auth/v1/authentications'
  const method = 'POST'
  const body = JSON.stringify({
    email: params.credential,
    password: params.password,
  })

  const response = await request({ baseUrl, endpoint, method, body })
  const result = await response.json()

  return response.ok ? (result as AuthToken) : Promise.reject(result.message)
}

export const getAuthorizationByToken = async (
  token: string,
  claims: KeanuUserRole[],
) => {
  const endpoint = '/auth/v1/authorizations'
  const method = 'POST'
  const body = JSON.stringify({
    token,
    claims,
  })

  const response = await request({ baseUrl, endpoint, method, body })
  const result = await response.json()

  return response.ok ? result : Promise.reject(result.message)
}

export const getRefreshToken = async (refreshToken: string) => {
  const endpoint = `/auth/v1/refresh-tokens/${refreshToken}`
  const method = 'GET'

  const response = await request({ baseUrl, endpoint, method })
  const result = await response.json()

  return response.ok ? (result as AuthToken) : Promise.reject(result.message)
}

export const fetchKeanuUserById = async (
  userServiceId: string,
): Promise<UserDTO> => {
  const endpoint = `/user/v1/users/${userServiceId}`
  const method = 'GET'
  try {
    const response = await request({
      baseUrl,
      endpoint,
      method,
    })

    if (!response.ok) {
      throw new Error('Failed to retrieve user')
    }

    const result: UserDTO = await response.json()
    return result
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    throw new Error(errorMessage as string)
  }
}
