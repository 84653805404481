import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/redux/store'
import { ProgramDetails } from 'types/program'

export type ActiveProgramType = ProgramDetails | null
export const initialState = null as ActiveProgramType

const activeProgramReducer = createSlice({
  name: 'activeProgram',
  initialState,
  reducers: {
    setActiveProgram: (
      state: ActiveProgramType,
      action: PayloadAction<ProgramDetails>,
    ) => action.payload,
    clearActiveProgram: () => initialState,
  },
})

export const { setActiveProgram, clearActiveProgram } =
  activeProgramReducer.actions

export const activeProgramSelector = (state: RootState) => state.activeProgram

export default activeProgramReducer.reducer
