import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { UserDTO } from '@campgladiator/cg-common.types.types'
import { useAppAccess } from 'app/contexts/app-access'
import {
  loadUserDetails,
  loadUserMembershipSummaryDetails,
} from 'app/redux/features/active-user/active-user-actions'
import { activeUserSelector } from 'app/redux/features/active-user/active-user-reducer'
import { useAppDispatch } from 'app/redux/store'
import { MainLayout } from 'components/layouts/main-layout'
import { Tabs } from 'components/layouts/routed-tabs'
import { Billing } from 'components/module/user/billing'
import { CheckInsContainer } from 'components/module/user/checkIns/checkIns-container'
import { EditUser } from 'components/module/user/edit-user'
import { MembershipContainer } from 'components/module/user/memberships/membership-container'
import { Notes } from 'components/module/user/notes'
import { Transactions } from 'components/module/user/transactions'
import { NewTransaction } from 'components/module/user/transactions/new-transaction'
import UserDetailSidePanel, {
  UserDetailSidePanelProps,
} from 'components/module/user/user-detail-side-panel/user-detail-side-panel'
import { getCheckinsCountByUserId } from 'services/api/checkIn'

const UserDetails = () => {
  const user = useSelector(activeUserSelector)

  const [updatedUser, setUpdatedUser] = useState<UserDTO>()
  const [userDetailSidePanelProps, setUserDetailSidePanelProps] =
    useState<UserDetailSidePanelProps>({
      user: updatedUser ?? user,
    } as UserDetailSidePanelProps)

  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { authToken, invalidateUserOnUnauthorizedResponse, updateUser } =
    useAppAccess()

  useEffect(() => {
    if (id) {
      dispatch(loadUserDetails(id))
      dispatch(loadUserMembershipSummaryDetails(id))
    }
  }, [id, dispatch])

  const updateUserWithCheckin = useCallback(() => {
    if (user?.id && authToken) {
      getCheckinsCountByUserId({
        userId: user.id,
        invalidateUserOnUnauthorizedResponse,
        updateUser,
      })
        .then(({ totalCheckins }) => {
          setUserDetailSidePanelProps({
            user: updatedUser || user,
            totalCheckins,
          })
        })
        .catch(() => {
          setUserDetailSidePanelProps({
            user: updatedUser || user,
          })
        })
    }
  }, [
    authToken,
    invalidateUserOnUnauthorizedResponse,
    updateUser,
    updatedUser,
    user,
  ])

  const routes = [
    {
      title: 'Basic info',
      path: 'basic-info',
      component: <EditUser onAfterUserUpdate={setUpdatedUser} />,
    },
    { title: 'Billing info', path: 'billing-info', component: <Billing /> },
    {
      title: 'Memberships',
      path: 'memberships',
      component: <MembershipContainer />,
    },
    {
      title: 'Billing & Transactions ',
      path: 'billing-transactions',
      component: <Transactions />,
      subRoutes: [
        {
          path: 'billing-transactions/new-transaction',
          component: <NewTransaction />,
        },
      ],
    },
    {
      title: 'Checkins',
      path: 'check-ins',
      component: (
        <CheckInsContainer onAfterCheckInUpdate={updateUserWithCheckin} />
      ),
    },
    { title: 'Notes', path: 'notes', component: <Notes /> },
  ]

  const flattenedRoutes = routes.reduce<
    { path: string; component: JSX.Element }[]
  >((acc, route) => {
    acc.push({ path: route.path, component: route.component })

    if (route.subRoutes) {
      route.subRoutes.forEach((subRoute) => {
        acc.push({ path: subRoute.path, component: subRoute.component })
      })
    }

    return acc
  }, [])

  useEffect(() => {
    updateUserWithCheckin()
  }, [updateUserWithCheckin])

  if (!user) return null

  return (
    <MainLayout
      sidePanel={<UserDetailSidePanel {...userDetailSidePanelProps} />}
    >
      <Tabs tabs={routes} />

      <Routes>
        <Route path="/" element={<Navigate to="basic-info" replace />} />
        {flattenedRoutes.map(({ path, component }) => (
          <Route key={path} path={path} element={component} />
        ))}
      </Routes>
    </MainLayout>
  )
}

export default UserDetails
