import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TrainerDTO } from '@campgladiator/cg-common.types.types'
import { ListItemProps } from '@campgladiator/cgui-core.atoms.list-item'
import { ListMenuProps } from '@campgladiator/cgui-core.molecules.list-menu'
import { useFormik } from 'formik'
import moment from 'moment'
import { getActiveTeams, getTeamById } from 'services/api/locale'
import { Locale } from 'types/locale'
import {
  currentDate,
  stringToDate,
  dateTimeStringToLocalDateSameZone,
} from 'utils/date'
import {
  trainerPhoneValidation,
  emailWithDomainValidation,
  imageMimeType,
} from 'utils/regex'
import * as Yup from 'yup'
import { ITrainerFormProps } from '../trainer-form'

type UseTrainerFormProps = Pick<
  ITrainerFormProps,
  'isEdit' | 'onSubmit' | 'trainer'
>

const validationSchema = () =>
  Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .required('Email address is required')
      .matches(new RegExp(emailWithDomainValidation), 'Invalid email address'),
    contactNumber: Yup.string()
      .required('Contact number is required')
      .matches(trainerPhoneValidation, 'Contact number is not valid'),
    startDate: Yup.date().required('Trainer Start Date is required'),
    archived: Yup.date()
      .notRequired()
      .min(Yup.ref('startDate'), "End date can't be before start date"),
    localeId: Yup.string().required('Team is required'),
    type: Yup.string().required('Trainer Type is required'),
  })

const useTrainerForm = ({ isEdit, onSubmit, trainer }: UseTrainerFormProps) => {
  const { state } = useLocation()
  const [teams, setTeams] = useState<ListMenuProps['items']>([])
  const [image, setUserImage] = useState<string>(
    trainer?.photo ? trainer?.photo : state?.user?.imageURL,
  )
  const [selectedFile, setSelectedFile] = useState<File | undefined>()
  const [isGrowlVisible, setIsGrowlVisible] = useState<boolean>(false)
  const [growlContent, setGrowlContent] = useState<string>('')
  const [selectedTeam, setSelectedTeam] = useState<string>('')

  const fetchTeamName = async (teamId: string) => {
    try {
      const response = await getTeamById(teamId)
      setSelectedTeam(response.name)
    } catch (error) {
      console.error(error)
    }
  }

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldError,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      nationTrainerId: state?.user?.nationUserId || trainer?.nationTrainerId,
      firstName: state?.user?.firstName || trainer?.firstName,
      lastName: state?.user?.lastName || trainer?.lastName,
      photo: isEdit ? trainer?.photo : state?.user?.imageURL,
      payrollId: trainer?.payrollId || '',
      startDate: trainer?.startDate?.split('T')[0] || '',
      archived: trainer?.archived?.split('T')[0] || '',
      email: trainer?.email || '',
      contactNumber: trainer?.contactNumber ? `+1${trainer.contactNumber}` : '',
      localeId: trainer?.localeId || '',
      planType: trainer?.planType || 'BASIC',
      type: trainer?.type,
      approvedPrograms: {
        outdoorApproved: trainer?.approvedPrograms?.outdoorApproved || false,
        onlineApproved: trainer?.approvedPrograms?.onlineApproved || false,
        nutritionApproved:
          trainer?.approvedPrograms?.nutritionApproved || false,
        strengthApproved: trainer?.approvedPrograms?.strengthApproved || false,
      },
    },
    validationSchema,
    onSubmit: (values: Partial<TrainerDTO>) => {
      const selectedStartDate = stringToDate(values.startDate)
      const startDate = dateTimeStringToLocalDateSameZone(trainer?.startDate)
      if (
        isEdit &&
        !moment(startDate).isSame(selectedStartDate) &&
        moment(selectedStartDate).isBefore(currentDate)
      ) {
        setFieldError('startDate', 'Start date cannot be past date')
      } else {
        onSubmit(
          {
            ...values,
            userId: isEdit ? trainer?.userId : state.user.id,
            photo: isEdit
              ? trainer?.photo
              : !selectedFile
              ? state?.user?.imageURL
              : undefined,
            startDate:
              values.startDate && new Date(values.startDate).toISOString(),
            archived: isEdit
              ? values.archived && new Date(values.archived).toISOString()
              : undefined,
            contactNumber: values?.contactNumber?.slice(2),
          },
          selectedFile,
        )
      }
    },
  })

  const getTeams = async () => {
    try {
      const response = await getActiveTeams()

      if (response) {
        const teams: ListMenuProps['items'] = []
        response.content
          .filter((team: Locale) => !team.deleted)
          .forEach(({ id, name }: Locale) => {
            teams.push({
              itemId: id!,
              text: name,
              type: 'item',
            })
          })
        setTeams(teams)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onImageChange = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0]
      if (!imageFile.type.match(imageMimeType)) {
        setGrowlView(
          true,
          `${imageFile.name} is not a valid image file. The image may be corrupted or an unsupported image format`,
        )
      } else {
        setSelectedFile(imageFile)
        setUserImage(URL.createObjectURL(imageFile))
      }
    }
  }

  const handleClick = (event: any) => {
    const { target = {} } = event || {}
    target.value = ''
  }

  const setGrowlView = (isVisible: boolean, growlContent: string) => {
    setIsGrowlVisible(isVisible)
    setGrowlContent(growlContent)
  }

  const clearError = () => {
    setIsGrowlVisible(false)
  }

  const handleOnChange = (event: any) => {
    const value = event.target.value
    if (value.length >= 0 && !value.includes('+1')) {
      setFieldValue('contactNumber', '+1')
    } else {
      setFieldValue('contactNumber', event.target.value)
    }
  }

  const handleSetTeam = (record: ListItemProps) => {
    if (!record) {
      setSelectedTeam('')
      setFieldValue('localeId', '')
      return
    }
    setFieldValue('localeId', record?.itemId || '')
    setSelectedTeam(record?.text || '')
  }

  useEffect(() => {
    if (trainer?.localeId) {
      fetchTeamName(trainer.localeId)
    }
  }, [trainer?.localeId])

  useEffect(() => {
    getTeams()
  }, [])

  return {
    errors,
    growlContent,
    image,
    isGrowlVisible,
    selectedTeam,
    state,
    teams,
    touched,
    values,
    clearError,
    getFieldProps,
    handleClick,
    handleOnChange,
    handleSetTeam,
    handleSubmit,
    onImageChange,
    setFieldValue,
    setUserImage,
  }
}

export default useTrainerForm
