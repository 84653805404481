import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from '@campgladiator/cgui-core.organisms.footer'
import { useAppAccess } from 'app/contexts/app-access'
import { Header } from '../header'
import styles from './protected-layout.module.scss'

type ProtectedLayoutProps = {
  loginUrl: string
}

const ProtectedLayout = ({ loginUrl }: ProtectedLayoutProps) => {
  const { isLoggedIn, validateUserAccess } = useAppAccess()

  useEffect(() => {
    validateUserAccess()
  }, [validateUserAccess])

  return isLoggedIn ? (
    <div className={styles.layout}>
      <Header loginUrl={loginUrl} />
      <Outlet />
      <Footer />
    </div>
  ) : null
}

export default ProtectedLayout
