import opentelemetry from '@opentelemetry/api'
import { BasicTracerProvider } from '@opentelemetry/sdk-trace-base'

type HoneycombTracerType = {
  tracerName: string
  spanName: string
  attributeName: string
  value: any
}

export const setHoneycombTracer = ({
  tracerName,
  spanName,
  attributeName,
  value,
}: HoneycombTracerType) => {
  new BasicTracerProvider().register()
  const span = opentelemetry.trace.getTracer(tracerName).startSpan(spanName)
  span.setAttribute(attributeName, value)

  console.log(
    `(Honeycomb): Event recorded: [tracer]: ${tracerName}, [span]: ${spanName}, [attributeName]: ${attributeName}`,
  )
  span.end()
}
