import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  Table,
  TData,
  THead,
  TRow,
} from '@campgladiator/cgui-core.organisms.table'
import { useAppSelector } from 'app/redux/store'
import PageTitle from 'components/layouts/page-title/page-title'
import { memoize } from 'lodash'
import { getTeamsByRegion, getRegionById } from 'services/api/locale'
import { Locale, LocalePageData } from 'types/locale'
import {
  ChangePageFunction,
  TeamPages,
} from '../teams-container/teams-container'

export type TeamListProps = {
  changePage: ChangePageFunction
}

const Teams = ({ changePage }: TeamListProps) => {
  const { id } = useParams()
  const { lastRefreshTime } = useAppSelector((state) => state.team)
  const [loading, setLoading] = useState<boolean>(false)
  const [teams, setTeams] = useState<Locale[]>([])

  const getParentRegionName = memoize(async (id: string | null) => {
    if (!id) return ''

    const { name } = await getRegionById(id)
    return name
  })

  useEffect(() => {
    getTeam(id)
    //eslint-disable-next-line
  }, [id, lastRefreshTime])

  const getTeam = (regionId?: string) => {
    if (regionId) {
      setLoading(true)
      try {
        getTeamsByRegion(regionId).then(async (response: LocalePageData) => {
          if (response) {
            const filteredTeams = response.content.filter(
              (data) => !data.deleted,
            )
            setTeams(
              await Promise.all(
                filteredTeams.map(async (team) => ({
                  ...team,
                  parentRegionName: await getParentRegionName(team.parentId!),
                })),
              ),
            )
          }
          setLoading(false)
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
  }

  const handleEditClick = (team: Locale) => {
    changePage(TeamPages.Edit, { team: team })
  }

  return (
    <>
      <PageTitle>Teams</PageTitle>
      <Table.Manual>
        <thead>
          <TRow>
            <THead>
              <Heading type="h6">Team Name</Heading>
            </THead>
            <THead>
              <Heading type="h6">Parent Region</Heading>
            </THead>
            <THead>
              <Heading type="h6">Status</Heading>
            </THead>
            <THead>
              <Heading type="h6">Actions</Heading>
            </THead>
          </TRow>
        </thead>
        <tbody>
          {teams.length === 0 || loading ? (
            <TRow>
              <TData>
                <Paragraph size="small" weight="book">
                  {loading ? <Loader /> : 'No teams to display'}
                </Paragraph>
              </TData>
            </TRow>
          ) : (
            teams.map((team) => (
              <TRow key={id}>
                <TData>
                  <Paragraph size="small" weight="book">
                    {team.name}
                  </Paragraph>
                </TData>
                <TData>
                  <Paragraph size="small" weight="book">
                    {team.parentRegionName}
                  </Paragraph>
                </TData>
                <TData>
                  <Paragraph size="small" weight="book">
                    {team.active ? 'Active' : 'Inactive'}
                  </Paragraph>
                </TData>
                <TData>
                  <Paragraph size="small" weight="book">
                    <Button
                      emphasis="secondary"
                      icon={{ name: 'icon-edit', type: 'solid' }}
                      size="large"
                      variation="text"
                      onClick={() => handleEditClick(team)}
                      theme="trainer"
                    />
                  </Paragraph>
                </TData>
              </TRow>
            ))
          )}
        </tbody>
      </Table.Manual>
    </>
  )
}

export default Teams
