import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PaymentMethodDTO } from '@campgladiator/cg-common.types.types'
import { activeUserSelector } from 'app/redux/features/active-user/active-user-reducer'
import { getAllPaymentMethods } from 'services/api/payment'
import { PaymentCardProps } from './payment-card'

type UseWithPaymentCardArgs = Pick<PaymentCardProps, 'id' | 'formLabel'>

const useWithPaymentCard = ({ id, formLabel }: UseWithPaymentCardArgs) => {
  const [cards, setPaymentCards] = useState<PaymentMethodDTO[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null)

  const user = useSelector(activeUserSelector)

  const getPaymentMethods = async (stripeCustomerId: string) => {
    setLoading(true)
    try {
      const response = await getAllPaymentMethods(stripeCustomerId)
      if (response) {
        const sortedCards = response.sort((a, b) => (b.default ? 1 : -1))
        setPaymentCards(sortedCards)
        setSelectedCardId(sortedCards[0]?.id || null)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user?.stripeCustomerId) {
      getPaymentMethods(user.stripeCustomerId)
    }
  }, [user?.stripeCustomerId])

  return {
    cards,
    formLabel,
    id,
    loading,
    selectedCardId,
    setSelectedCardId,
  }
}

export default useWithPaymentCard
