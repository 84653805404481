import {
  PaymentMethodDTO,
  SetupIntentResponseDTO,
} from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import { configuration } from 'config'
import { CreateSetupIntentRequest } from 'types/payment'

const baseUrl = configuration.apiGatewayUrl

export const getAllPaymentMethods = async (
  stripeCustomerId: string,
): Promise<PaymentMethodDTO[]> => {
  try {
    const endpoint = `/billing/v1/payment-methods?stripeCustomerId=${stripeCustomerId}`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-all-payment-methods',
      attributeName: 'get-all-payment-methods',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getPaymentMethodBySubscriptionId = async (
  subscriptionId: string,
): Promise<PaymentMethodDTO> => {
  try {
    const endpoint = `/billing/v1/subscriptions/${subscriptionId}/payment-methods`
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-payment-method-by-subscriptionId',
      attributeName: 'get-payment-method-by-subscriptionId',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putPaymentMethod = async (
  args: any,
  subscriptionId: string,
): Promise<boolean> => {
  try {
    const endpoint = `/billing/v1/subscriptions/${subscriptionId}/payments`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(args),
    })

    if (response.ok) return true
    const result = await response.json()
    return Promise.reject(result.message || 'Unknown error')
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-update-payment-method',
      attributeName: 'put-payment-method',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const createSetupIntent = async (
  requestBody: CreateSetupIntentRequest,
): Promise<SetupIntentResponseDTO> => {
  try {
    const endpoint = '/billing/v1/payment-methods/setup-intents'
    const method = 'POST' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()

    if (response.ok) return result
    else
      return result && result.message
        ? Promise.reject(result.message)
        : Promise.reject('Unknown error')
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-create-setup-intent',
      attributeName: 'create-setup-intent',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const updatePaymentMethodAsDefault = async (
  paymentMethodId: string,
): Promise<PaymentMethodDTO> => {
  try {
    const endpoint = `/billing/v1/payment-methods/${paymentMethodId}`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify({ default: true }),
    })

    const result = await response.json()

    if (response.ok) return result
    else
      return result && result.message
        ? Promise.reject(result.message)
        : Promise.reject('Unknown error')
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-update-payment-method-as-default',
      attributeName: 'update-payment-method-as-default',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const updatePaymentMethod = async (
  paymentMethodId: string,
  paymentDetails: any,
): Promise<PaymentMethodDTO> => {
  try {
    const endpoint = `/billing/v1/payment-methods/${paymentMethodId}`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(paymentDetails),
    })

    const result = await response.json()

    if (response.ok) return result
    else
      return result && result.message
        ? Promise.reject(result.message)
        : Promise.reject('Unknown error')
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-update-payment-method',
      attributeName: 'update-payment-method',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
