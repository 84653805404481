import { OrganizationDTO } from '@campgladiator/cg-common.types.types'
import { setHoneycombTracer } from 'app/libs/honeycomb'
import { HttpMethod, request } from 'app/networking/fetch'
import configuration from 'config/configuration'

const baseUrl = configuration.apiGatewayUrl

export const getOrganization = async (id: string): Promise<OrganizationDTO> => {
  try {
    const endpoint = `/billing/v1/organizations/${id}`
    const method = 'GET' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok
      ? result
      : Promise.reject({ statusCode: response.status, message: result.message })
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'billing-organization',
      attributeName: 'get-organization',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const getAllOrganizations = async (
  searchTerm?: string,
  page?: number,
  size?: number,
): Promise<OrganizationDTO[]> => {
  try {
    let endpoint = '/billing/v1/organizations'
    endpoint += '?'
    if (searchTerm) {
      endpoint += `name=${searchTerm}&`
    }
    if (page !== undefined && size !== undefined) {
      endpoint += `page=${page}&size=${size}`
    }
    const method = 'GET' as HttpMethod

    const response = await request({ baseUrl, endpoint, method })
    const result = await response.json()

    return response.ok
      ? searchTerm || (page !== undefined && size !== undefined)
        ? result.content
        : result
      : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'keanu-get-all-users-organizations',
      attributeName: 'get-all-organizations',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const putOrganization = async (
  id: string,
  data: Partial<OrganizationDTO>,
): Promise<OrganizationDTO> => {
  try {
    const endpoint = `/billing/v1/organizations/${id}`
    const method = 'PUT' as HttpMethod

    const response = await request({
      baseUrl,
      endpoint,
      method,
      body: JSON.stringify(data),
    })
    const result = await response.json()

    return response.ok ? result : Promise.reject(result.message)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'
    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'billing-organizatio',
      attributeName: 'put-organization',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}

export const postOrganization = async (data: Partial<OrganizationDTO>) => {
  try {
    const endpoint = '/billing/v1/organizations'
    const method = 'POST' as HttpMethod
    const body = JSON.stringify(data)

    const response = await request({ baseUrl, endpoint, method, body })
    const result = await response.json()

    if (!response.ok) throw result.message
    return Promise.resolve(result)
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    setHoneycombTracer({
      tracerName: 'default',
      spanName: 'post-organization',
      attributeName: 'post-organization',
      value: new Error(errorMessage as string),
    })

    throw new Error(errorMessage as string)
  }
}
