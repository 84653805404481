import React from 'react'
import { useRef } from 'react'
import { useWithFormInput } from '../../../../app/hooks/useWithFormInput'

const FORM_ID = 'login-form'

type UseLogin = {
  error?: string | null
  isLoading: boolean
  onSubmitPasswordForLogin: (credential: string, password: string) => void
}

export const useLoginForm = ({
  error,
  isLoading,
  onSubmitPasswordForLogin,
}: UseLogin) => {
  const formRef = useRef<HTMLFormElement>(null)

  const email = useWithFormInput({
    id: 'credential',
    placeholder: 'Enter your Email',
    title: 'Please enter a valid email address',
  })

  const password = useWithFormInput({
    id: 'password',
    placeholder: 'Enter your Password',
    title: 'Please enter a valid password',
    type: 'password',
  })

  const passwordErrorMsg =
    error === 'invalidCredentials'
      ? 'Invalid combination of user and password. Please try again.'
      : undefined
  const shouldDisableButton = !email.value || password.value === '' || isLoading

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (formRef?.current?.reportValidity()) {
      return onSubmitPasswordForLogin(email.value, password.value)
    }
  }

  return {
    email,
    FORM_ID,
    formRef,
    password,
    passwordErrorMsg,
    shouldDisableButton,
    onFormSubmit,
  }
}
