import { TrainerDTO } from '@campgladiator/cg-common.types.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/redux/store'

export type ActiveTrainerType = TrainerDTO | null
export const initialState = null as ActiveTrainerType

const activeTrainerReducer = createSlice({
  name: 'activeTrainer',
  initialState,
  reducers: {
    setActiveTrainer: (
      state: ActiveTrainerType,
      action: PayloadAction<TrainerDTO>,
    ) => action.payload,
    clearActiveTrainer: () => initialState,
  },
})

export const { setActiveTrainer, clearActiveTrainer } =
  activeTrainerReducer.actions

export const activeTrainerSelector = (state: RootState) => state.activeTrainer
export const activeTrainerBioSelector = (state: RootState) =>
  state.activeTrainer?.trainerBio || null

export default activeTrainerReducer.reducer
