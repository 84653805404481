import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { LoginForm } from 'components/module/login/login-form'
import { useAuth } from './user-auth'

type AuthProps = {
  redirectPathOnLoginSuccess: string
}

const Auth = ({ redirectPathOnLoginSuccess }: AuthProps) => {
  const {
    isGrowlVisible,
    growlContent,
    isLoading,
    error,
    clearError,
    onSubmitPasswordForLogin,
  } = useAuth({ redirectPathOnLoginSuccess })

  return (
    <>
      <LoginForm
        error={error}
        isLoading={isLoading}
        onSubmitPasswordForLogin={onSubmitPasswordForLogin}
      />

      {isGrowlVisible && (
        <GrowlMessage autoClose isVisible={isGrowlVisible} onClick={clearError}>
          {growlContent}
        </GrowlMessage>
      )}
    </>
  )
}

export default Auth
